export enum BudgetTypes {
  Economy = "Economy",
  MidRange = "Mid-range",
  Luxury = "Luxury",
}

export enum SORTBY {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ButtonVariants {
  Text = "text",
  Contained = "contained",
  Outlined = "outlined",
}

export enum ButtonSizes {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum ChipVariants {
  Deletable = "Deletable",
  Clickable = "Clickable",
}

export enum EnvType {
  DEV = "dev",
  STAGING = "staging",
  PRODUCTION = "production",
}

export enum ScreenTypes {
  ForYou = "forYou",
  SingleItinerary = "singleItinerary",
  Reorder = "reorder",
  ItineraryInfo = "itineraryInfo",
  Adjust = "adjust",
  Edit = "edit",
  Preference = "preference",
  EmptyPage = "emptyPage",
  SavedItinerary = "savedItinerary",
  Booking = "booking",
  HotelDetails = "hotelDetails",
  TripDetails = "tropDetails",
  SaveTripsList = "saveTripsList",
  CurrentTrip = "currentTrip",
  MyTrips = "myTrips",
  Preferences = "preferences",
  help = "help",
  topDestination = "topDestination",
  ExploreCity = "exploreCity",
  FaqAssistance = "faqAssistance",
  TravellerInfo = "travellerInfo",
  FlightDelay = "flightDelay",
  WeatherAdvisory = "weatherAdvisory",
  NewTrip = "newTrip",
  modifyHotels = "modifyHotels",
  modifyFlight = "modifyFlight",
}

export enum localStorageName {
  TriptroveFrequentlyViewTrips = "_triptrove_frequently_view_trips",
  TripTroveUserDetails = "_trip_trove_user_details",
  UserInfo = "user_info",
  AllCategory = "_all_category",
  TripTroveAppConfig = "_trip_trove_app_config",
}

export enum LOGIN_MESSAG {
  Success = "",
}

export enum ACTIVITY_TYPE_IDENTIFIER {
  Flight = "transportation.flight",
  LargeHotel = "accommodations.large_hotel",
  Hotel = "accommodation.hotel",
}

export enum API_METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum HOTEL_CARD_TYPES {
  ADDRESSS = "ADDRESS",
  REFUND = "REFUND",
  SEARCH = "SEARCH",
  ROOM = "ROOM",
}
