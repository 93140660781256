import React from "react";
import styles from "./dayActivityText.module.scss";
import Markdown from "react-markdown";

export default function DayActivityText({
  key,
  dayItineraryEle,
}: Readonly<{
  key: string;
  dayItineraryEle: any;
}>) {
  return (
    <div key={key} className={styles.timeInADay}>
      <h1 className={styles.title}>{dayItineraryEle?.time_of_the_day}</h1>
      <div className={styles.descriptionContainer}>
        <p>•</p>
        <Markdown className={styles.description}>
          {dayItineraryEle?.description}
        </Markdown>
      </div>
    </div>
  );
}
