import React from "react";
import styles from "./tripDetailsHeader.module.scss";
import {
  getDDYYYY,
  getMonthDate,
  getMonthDateUTC,
  getYear,
} from "../../../utils/dateUtils";
import { getPassengerCount } from "../../../utils";
import { APP_URL } from "../../../constant/environment";
import { Button } from "@mui/material";
import { shareIcon, shareIconWithText } from "../../../constant";
import { RWebShare } from "react-web-share";

export default function TripDetailsHeader({
  travellersInfo = {},
  tripName = "2024 Europe Trip",
  startDate = "DEC 19 - 24, 2024",
  endDate = "DEC 19 - 24, 2024",
  adults = "2",
  children = "2",
  itineraryNumber = "SDF-154-S85",
  bookedDate = "",
  handleShare = () => {},
  tripOrItinearyId = "",
  isSharedTrip = false,
}: {
  travellersInfo?: any;
  tripName?: string;
  startDate?: string;
  endDate?: string;
  adults?: string;
  children?: string;
  itineraryNumber?: string;
  bookedDate?: string;
  handleShare?: any;
  tripOrItinearyId?: string;
  isSharedTrip?: boolean;
}) {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.descriptionContainer}>
        <h1 className={styles.headingText}>{tripName}</h1>
        {startDate && endDate && (
          <h1>
            {getMonthDateUTC(startDate)} - {getDDYYYY(endDate)}
          </h1>
        )}
        <p>
          {getPassengerCount(
            travellersInfo?.adults ? Number(travellersInfo?.adults) : 0,
            travellersInfo?.children ? Number(travellersInfo?.children) : 0,
            travellersInfo?.infants ? Number(travellersInfo?.infants) : 0
          )}
          {/* {adults} adults, {children} children */}
        </p>
        <p>Itinerary Number: {itineraryNumber}</p>
        {bookedDate !== "" && (
          <p>
            Booked on: &nbsp; {getMonthDate(bookedDate)}, {getYear(bookedDate)}
          </p>
        )}
      </div>
      {!isSharedTrip && (
        <div className={styles.shareButtonContainer}>
          <RWebShare
            data={{
              url: `${APP_URL}trip-details/?id=${tripOrItinearyId}`,
              title: "Zenvoya",
            }}
            onClick={handleShare}
          >
            <Button
              className={`${styles.rowAlignButton}`}
              onClick={handleShare}
            >
              {/* <img className={styles.iconButton} src={shareIcon} alt="share" />
              SHARE */}
                 <img className={styles.iconButton} src={shareIconWithText} alt="share" />
                 {/* SHARE */}
            </Button>
          </RWebShare>
        </div>
      )}
    </div>
  );
}
