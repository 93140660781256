import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./inputWithMic.module.scss";
import {
  WEB_SPEECH_MESSAGE,
  micIcon,
  sendIcon,
  stopMicIcon,
} from "../../../constant";
import { IconButton, TextareaAutosize } from "@mui/material";
// import TextareaAutosize from "react-textarea-autosize";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import toast from "react-hot-toast";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
// import { scrollToBottom } from "../../../utils";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import { scrollToBottom } from "../../../utils";

export default function InputWithMic({
  disableInput,
  value,
  setValue,
  onSend,
  onStopRecording = () => {},
  // handleClick = () => {},
  disable,
  handleCancel = () => {},
  setDisableInput,
  disableSendBtn,
  placeholder = "Describe your trip",
  onMicStartListeningFunc,
  onMicStopListeningFunc,
}: {
  disableInput?: boolean;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onSend?: any;
  onStopRecording?: any;
  // handleClick?: any;
  disable?: boolean;
  handleCancel?: () => void;
  setDisableInput?: Dispatch<SetStateAction<boolean>>;
  disableSendBtn?: boolean;
  placeholder?: string;
  onMicStartListeningFunc?: () => void;
  onMicStopListeningFunc?: () => void;
}) {
  const globalContext = useContext(StateContext);
  const { scrollContainerRef, setInputFocus }: iGlobalContext = globalContext;
  const [pulseAnimation, setPulseAnimation] = useState({});
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [textFieldValue, setTextFieldValue] = useState("");

  function handleStartRecording() {
    triggerGAEvent(GA_LABEL_ENUMS.new_trip_input_field_mic_send_click);
    triggerGAEvent(GA_LABEL_ENUMS.home_input_field_mic_send_click);
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_input_field_mic_send_click);

    setTextFieldValue(value);
    // setTimeout(() => {
    //     window.scrollTo({
    //         top: document.documentElement.scrollHeight,
    //         behavior: 'smooth'
    //     });
    // }, 1)
    if (listening) return;
    if (!browserSupportsSpeechRecognition) {
      toastMessage.error("OOPS!", WEB_SPEECH_MESSAGE);
      return;
    }
    // if (value === "") resetTranscript();
    onMicStartListeningFunc?.();
    SpeechRecognition.startListening({ continuous: true });

    setTimeout(() => {
      scrollToBottom(scrollContainerRef);
    }, 750);
  }

  function handleStopRecording() {
    triggerGAEvent(GA_LABEL_ENUMS.home_input_field_mic_stop_click);
    triggerGAEvent(GA_LABEL_ENUMS.new_trip_input_field_mic_stop_click);
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_input_field_mic_stop_click);

    onMicStopListeningFunc?.();
    SpeechRecognition.stopListening();
    resetTranscript();
    // onStopRecording();
  }

  function handleOnInputChange(e: any) {
    handleStopRecording();
    setValue(e.target.value);
    // scrollToBottom(scrollContainerRef);
    // // console.log(scrollContainerRef);
  }

  useEffect(() => {
    // setValue((prev: any) => prev + transcript);
    if (transcript) setValue(textFieldValue + " " + transcript);
  }, [transcript]);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight,
  //   });
  // }, [value]);

  /**
   * When user speaks pulse animation
   */
  useEffect(() => {
    if (listening) {
      setPulseAnimation((prev) => ({
        ...prev,
        outline: "15px solid #eee",
      }));
      setTimeout(() => {
        setPulseAnimation((prev) => ({
          ...prev,
          outline: "0px solid #eee",
        }));
      }, 100);
    }
  }, [value]);

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSend();
    }
  };

  const handleSendIconClick = () => {
    triggerGAEvent(GA_LABEL_ENUMS.home_input_field_send_click);
    triggerGAEvent(GA_LABEL_ENUMS.Send_Prompt_click);
    triggerGAEvent(GA_LABEL_ENUMS.new_trip_input_field_send_click);
    triggerGAEvent(GA_LABEL_ENUMS.top_destination_input_field_send_click);
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_input_field_click);

    setTimeout(() => {
      onSend();
    }, 0);
  };

  function handleOnFocus() {
    // setInputFocus(true);
    // scrollToBottom(scrollContainerRef);
    // setTimeout(() => {
    // }, );
  }
  function handleOnBlur() {
    setInputFocus(false);
    scrollToBottom();
  }

  const inputRef = useRef<any>(null);

  // useEffect(() => {
  //   const handleHtmlScroll = () => {
  //     console.log("HTML is scrolling!");
  //     // if (inputRef?.current) {
  //     //   inputRef?.current?.blur();
  //     // }
  //     setInputFocus(false);
  //   };

  //   window.addEventListener("scroll", handleHtmlScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleHtmlScroll);
  //   };
  // }, []);

  return (
    <div
      className={`${styles.mainContainer}
    ${disable && styles.disableBgContainer}
    `}
    >
      <div className={styles.inputSendBtnContainer}>
        <div className={styles.inputContainer}>
          {/* <input
            placeholder={disable ? "" : placeholder}
            value={
              disable ? "" : value.charAt(0).toUpperCase() + value.slice(1)
            }
            onChange={handleOnInputChange}
            className={styles.input}
            onKeyDown={handleKeyDown}
            spellCheck="true"
            disabled={disable}
          /> */}
          {/* <input
            placeholder={disable ? "" : placeholder}
            // value={
            //   disable ? "" : value.charAt(0).toUpperCase() + value.slice(1)
            // }
            onChange={handleOnInputChange}
            className={styles.input}
            // maxRows={3}
            onKeyDown={handleKeyDown}
            // spellCheck="true"
            // disabled={disable}
            // className={styles.input}
          /> */}
          <TextareaAutosize
            maxRows={2}
            ref={inputRef}
            placeholder={disable ? "" : placeholder}
            value={
              disable ? "" : value.charAt(0).toUpperCase() + value.slice(1)
            }
            onChange={handleOnInputChange}
            className={styles.input}
            // onKeyDown={handleKeyDown}
            spellCheck="true"
            disabled={disable}
            onFocus={handleOnFocus}
            // onBlur={handleOnBlur}
          />
          {!disable && (
            <IconButton
              className={styles.micButton}
              onClick={() => {
                if (!disableInput)
                  setTimeout(() => {
                    handleStartRecording();
                  }, 0);
              }}
            >
              <img className={styles.micIcon} src={micIcon} alt="mic" />
            </IconButton>
          )}
        </div>
        {disable && !disableInput ? (
          <IconButton
            className={styles.sendButton}
            onClick={() => {
              triggerGAEvent(GA_LABEL_ENUMS.Stop_click);
              triggerGAEvent(GA_LABEL_ENUMS.new_trip_input_field_stop_click);
              triggerGAEvent(GA_LABEL_ENUMS.home_input_field_stop_click);
              triggerGAEvent(
                GA_LABEL_ENUMS.itinerary_details_input_field_stop_click
              );

              handleCancel();
              if (setDisableInput) {
                setDisableInput(false);
              }
            }}
          >
            <StopCircleOutlinedIcon
              sx={{ color: "#000000", width: 35, height: 35 }}
            />
          </IconButton>
        ) : (
          <IconButton
            className={`${disableSendBtn ? styles.inActiveSendBtn : styles.sendButton}`}
            onClick={disableSendBtn ? () => {} : handleSendIconClick}
          >
            <img className={styles.sendIcon} alt="send" src={sendIcon} />
          </IconButton>
        )}
      </div>
      
      <h1 className={styles.disclamerText}>Zenvoya is in <b>beta</b> — we are still fine tuning results!</h1>
      
      <div
        className={`
                ${styles.stopRecording}
                ${listening && !disableInput && styles.show}
            `}
      >
        <IconButton
          className={styles.disableHover}
          onClick={() =>
            setTimeout(() => {
              handleStopRecording();
            }, 0)
          }
        >
          <img
            className={`${styles.stopMicIcon}
                            ${listening && styles.showChild}
                        `}
            alt="stop recording"
            src={stopMicIcon}
          />
          <div
            className={`${styles.pulse}
                            ${listening && styles.showChild}
                        `}
            style={pulseAnimation}
          ></div>
        </IconButton>
        <h1
          className={`${styles.stopText}
                    ${listening && styles.showChild}
                `}
          onClick={() =>
            setTimeout(() => {
              handleStopRecording();
            }, 0)
          }
        >
          Tap to stop
        </h1>
      </div>

    </div>
  );
}
