import React, { useEffect } from "react";
import styles from "./selectedTrip.module.scss";
import { avatarIcon, zenvoyaChatIcon } from "../../../constant";
import { Button, Grid } from "@mui/material";
import DestinationCard from "../../../component/reusableComponent/destinationCard/DestinationCard";
import { iChatData, iCityDetailsResult } from "../../../types";
import Markdown from "react-markdown";
import DestinationCardWithImages from "../../../component/reusableComponent/destinationCardWithImages/DestinationCardWithImages";
import { CHAT_BOT_NAME } from "../../../constant/common";
import ChatContent from "../chatContent/ChatContent";
import { TripTypes } from "../../../interface";

export default function SelectedTrip({
  chatData,
  handleViewTripDetails,
}: {
  chatData: iChatData;
  handleViewTripDetails?: any;
}) {
  useEffect(() => {
    // console.log("Here it is", chatData);
  }, [chatData]);

  return (
    <div className={styles.container}>
      <div className={styles.systemChatIcon}>
        <img
          className={styles.chatIcon}
          alt="chat logo"
          // src={zenvoyaChatIcon}
          src={avatarIcon}
        />
        <h3 className={styles.heading}>{CHAT_BOT_NAME.name}</h3>
      </div>
      <Markdown className={styles.preText}>{chatData?.preText}</Markdown>
      <div
        className={styles.multipleCityContainer}
        onClick={handleViewTripDetails}
      >
        <DestinationCardWithImages
          multiCity={
            chatData?.trip_type
              ? chatData?.trip_type === TripTypes.MultiCity
              : chatData?.destinationList?.length > 1
          }
          itineraryVersion={chatData?.itinerary_version || 0}
          destinationList={chatData.destinationList}
        />
      </div>
      {/* <Markdown className={styles.postText}>{chatData?.postText}</Markdown> */}
      {chatData?.postText && (
        <div className={styles.postTextContainer}>
          <ChatContent
            isUserMessage={false}
            // message={getConfigMessage(
            //   appConfig,
            //   APP_CONFIG.MODIFY_DESTINATION_LIST
            // )}
            message={chatData?.postText || ""}
            // preText={postText}
            isLoading={false}
          />
        </div>
      )}
    </div>
  );
}
