import { addDays } from "date-fns";
import { iHotelStayTypes } from "../../../types";

// export const getHotelTravelDetails = (tripData: iHotelStayTypes[]) => {
//   if (!tripData || tripData.length === 0) {
//     return [];
//   }

//   const travelDetails: any[] = [];

//   tripData.forEach((trip) => {
//     const numberOfDays = trip?.tripDetails?.length || 0;

//     const hotels: any[] = [];
//     let startDate: string | null = null;
//     let endDate: string | null = null;

//     trip?.tripDetails?.forEach((day, index) => {
//       const dateInfo = day?.date || "";
//       const dayHotels = day?.itinerary_by_time_of_the_day_data?.filter(
//         (activity) =>
//           activity?.activity_type
//             ?.toLowerCase()
//             .startsWith("accommodation.".toLowerCase())
//       );

//       dayHotels?.forEach((hotel) => {
//         const hotelDetails = {
//           dateInfo,
//           accommodation_location_name: hotel?.accommodation_location_name || "",
//           accommodation_location_city: hotel?.accommodation_location_city || "",
//           accommodation_location_country_code:
//             hotel?.accommodation_location_country_code || "",
//           accommodation_location_latitude:
//             hotel?.accommodation_location_latitude ||
//             hotel.accommodation_location_lattitude ||
//             "",
//           accommodation_location_longitude:
//             hotel?.accommodation_location_longitude || "",
//         };

//         const hasValidData = Object.values(hotelDetails).some(
//           (value) => value !== ""
//         );

//         if (hasValidData) {
//           hotels.push(hotelDetails);
//           if (!startDate) {
//             //first day of accommodation.hotel
//             startDate = dateInfo;
//           }
//         }
//       });

//       // last day
//       if (index === trip?.tripDetails?.length - 1) {
//         endDate = dateInfo;
//       }
//     });

//     if (hotels.length > 0) {
//       travelDetails.push({
//         city_name: trip?.city_name || "",
//         number_of_days: numberOfDays,
//         hotels,
//         start_date: startDate,
//         end_date: endDate,
//       });
//     }
//   });

//   return travelDetails;
// };

// export const getHotelTravelDetails = (tripData: iHotelStayTypes[]) => {
//   if (!tripData || tripData.length === 0) {
//     return [];
//   }

//   const travelDetails: any[] = [];

//   tripData.forEach((trip) => {
//     const numberOfDays = trip?.tripDetails?.length || 0;

//     const hotels: any[] = [];
//     let startDate: string | null = null;

//     trip?.tripDetails?.forEach((day) => {
//       const dateInfo = day?.date || "";
//       const dayHotels = day?.itinerary_by_time_of_the_day_data?.filter(
//         (activity) =>
//           activity?.activity_type
//             ?.toLowerCase()
//             .startsWith("accommodation.".toLowerCase())
//       );

//       dayHotels?.forEach((hotel) => {
//         const hotelDetails = {
//           dateInfo,
//           accommodation_location_name: hotel?.accommodation_location_name || "",
//           accommodation_location_city: hotel?.accommodation_location_city || "",
//           accommodation_location_country_code:
//             hotel?.accommodation_location_country_code || "",
//           accommodation_location_latitude:
//             hotel?.accommodation_location_latitude ||
//             hotel.accommodation_location_lattitude ||
//             "",
//           accommodation_location_longitude:
//             hotel?.accommodation_location_longitude || "",
//         };

//         const hasValidData = Object.values(hotelDetails).some(
//           (value) => value !== ""
//         );

//         if (hasValidData) {
//           hotels.push(hotelDetails);
//           if (!startDate) {
//             // Set startDate to the first valid accommodation date
//             startDate = dateInfo;
//           }
//         }
//       });
//     });

//     if (hotels.length > 0) {
//       const endDate = startDate
//         ? addDays(new Date(startDate), numberOfDays).toISOString().split("T")[0]
//         : null;

//       travelDetails.push({
//         city_name: trip?.city_name || "",
//         number_of_days: numberOfDays,
//         hotels,
//         start_date: startDate,
//         end_date: endDate,
//       });
//     }
//   });

//   return travelDetails;
// };

export const getHotelTravelDetails = (tripData: iHotelStayTypes[]) => {
  if (!tripData || tripData.length === 0) {
    return [];
  }

  const travelDetails: any[] = [];

  tripData.forEach((trip) => {
    let numberOfDays = 0; // Initialize to 0
    const hotels: any[] = [];
    let startDate: string | null = null;

    trip?.tripDetails?.forEach((day) => {
      const dateInfo = day?.date || "";
      const dayHotels = day?.itinerary_by_time_of_the_day_data?.filter(
        (activity) =>
          activity?.activity_type
            ?.toLowerCase()
            .startsWith("accommodation.".toLowerCase())
      );

      dayHotels?.forEach((hotel) => {
        const hotelDetails = {
          dateInfo,
          accommodation_location_name: hotel?.accommodation_location_name || "",
          accommodation_location_city: hotel?.accommodation_location_city || "",
          accommodation_location_country_code:
            hotel?.accommodation_location_country_code || "",
          accommodation_location_latitude:
            hotel?.accommodation_location_latitude ||
            hotel.accommodation_location_lattitude ||
            "",
          accommodation_location_longitude:
            hotel?.accommodation_location_longitude || "",
        };

        const hasValidData = Object.values(hotelDetails).some(
          (value) => value !== ""
        );

        if (hasValidData) {
          hotels.push(hotelDetails);
          if (!startDate) {
            // Set startDate to the first valid accommodation date
            startDate = dateInfo;
          }
          // Update numberOfDays based on accommodation_number_of_nights
          const accommodationNights = parseInt(
            hotel?.accommodation_number_of_nights || "0",
            10
          );
          numberOfDays = Math.max(numberOfDays, accommodationNights + 1);
        }
      });
    });

    if (hotels.length > 0) {
      const endDate = startDate
        ? addDays(new Date(startDate), numberOfDays - 1) // Subtract 1 to align dates
            .toISOString()
            .split("T")[0]
        : null;

      travelDetails.push({
        city_name: trip?.city_name || "",
        number_of_days: numberOfDays,
        hotels,
        start_date: startDate,
        end_date: endDate,
      });
    }
  });

  return travelDetails;
};
