import { RoomDetails } from "../types";

export const handleRoomAllocation = (
  adultsCount: number,
  childrenCount: number,
  seniorsCount: number,
  numberOfRoomCount: number
): RoomDetails[] => {
  const roomDetails: RoomDetails[] = [];
  let remainingAdults = adultsCount;
  let remainingChildren = childrenCount;
  let remainingSeniors = seniorsCount;

  for (let roomNo = 1; roomNo <= numberOfRoomCount; roomNo++) {
    let roomAdults = 0;
    let roomChildren = 0;
    let roomSeniors = 0;

    // Prioritize adults and children together in the same room
    if (remainingChildren > 0) {
      if (remainingAdults > 0) {
        roomAdults = Math.min(remainingAdults, 2);
        remainingAdults = remainingAdults - roomAdults;

        roomChildren = Math.min(remainingChildren, 4 - roomAdults);
        remainingChildren = remainingChildren - roomChildren;
      } else if (remainingSeniors > 0) {
        roomSeniors = Math.min(remainingSeniors, 2);
        remainingSeniors = remainingSeniors - roomSeniors;

        roomChildren = Math.min(remainingChildren, 4 - roomSeniors);
        remainingChildren = remainingChildren - roomChildren;
      }
    } else {
      // No children left, fill with adults and seniors
      roomAdults = Math.min(remainingAdults, 4);
      remainingAdults -= roomAdults;

      const remainingCapacity = 4 - roomAdults;
      roomSeniors = Math.min(remainingSeniors, remainingCapacity);
      remainingSeniors = remainingSeniors - roomSeniors;
    }
    const roomDetail: RoomDetails = {
      roomNo,
      adults: roomAdults,
      child: roomChildren,
      seniors: roomSeniors,
    };
    if (roomChildren > 0) {
      roomDetail.childAges = Array(roomChildren).fill(5).join(",");
    }
    if (roomAdults > 0 || roomChildren > 0 || roomSeniors > 0) {
      roomDetails.push(roomDetail);
    }
  }

  return roomDetails;
};
