import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomAccordion from "../../../reusableComponent/customAccordion/CustomAccordion";
import {
  formatToYYYYMMDD,
  getDayOfWeek,
  getMonthDate,
} from "../../../../utils/dateUtils";
import { PathIcon, planeEnginesLight, ROUTES } from "../../../../constant";
import Button from "../../../button/button";
import FlightDetails from "../flightDetails/flightDetail";
import { iCityDetailsResult, Transaction } from "../../../../types";
import CustomNewCheckBox from "../../../reusableComponent/customNewCheckBox/customNewCheckBox";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { getAirports } from "../../../../api/booking/booking";
import { ScreenTypes } from "../../../../enums";
import styles from "./modify.module.scss";
interface FlightDataProps {
  readonly flightDetails: Transaction[];
}
function ModifyFlights(props: FlightDataProps): JSX.Element {
  const { flightDetails } = props;
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setBookingDetails,
    setSelectedFlightOptions,
    setAirPorts,
    setIsFetchingAirports,
    selectedChatData,
    bookingDetails,
    isMobile,
    setScreen,
    airPorts,
  }: iGlobalContext = state;
  const [selectedFlights, setSelectedFlights] = useState<Transaction[]>([]);
  const EnableModifyButton = selectedFlights.length > 0;
  let fetchAirportController: any = useRef(null);

  const handleSelection = useCallback((flightData: Transaction) => {
    setSelectedFlights((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (selected) => selected.transactionId === flightData.transactionId
      );
      if (isAlreadySelected) {
        // Remove flight from the selected list
        return prevSelected.filter(
          (selected) => selected.transactionId !== flightData.transactionId
        );
      } else {
        // Add flight to the selected list
        return [...prevSelected, flightData];
      }
    });
  }, []);

  const getTravelDetails = (tripData: iCityDetailsResult[]) => {
    if (!tripData || tripData.length === 0) {
      return [];
    }
    const travelDetails: any[] = [];

    tripData.forEach((trip) => {
      // Count the number of days in the city
      const numberOfDays = trip?.tripDetails?.length || 0;
      // Collect flight details for the city
      const flights: any = [];
      trip?.tripDetails?.forEach((day) => {
        const dayFlights = day?.itinerary_by_time_of_the_day_data?.filter(
          (activity: any) => activity?.activity_type === "transportation.flight"
        );

        dayFlights?.forEach((flight: any) => {
          flights?.push({
            departure_city_name: [
              flight?.departure_city_name,
              flight?.departure_city_state,
              flight?.departure_city_country,
            ]
              .filter(Boolean)
              .join(", "),
            departure_city_latitude: flight?.departure_city_lattitude || "",
            departure_city_longitude: flight?.departure_city_longitude || "",
            departure_city_country_code:
              flight?.departure_city_country_code || "",
            arrival_city_name: [
              flight?.arrival_city_name,
              flight?.arrival_city_state,
              flight?.arrival_city_country,
            ]
              .filter(Boolean)
              .join(", "),
            arrival_city_country_code: flight?.arrival_city_country_code || "",
            arrival_city_latitude: flight?.arrival_city_lattitude || "",
            arrival_city_longitude: flight?.arrival_city_longitude || "",
          });
        });
      });

      // Add the city details with number of days and flights
      travelDetails.push({
        city_name: trip?.city_name || "",
        number_of_days: numberOfDays,
        flights: flights,
      });
    });

    return travelDetails;
  };
  const flightHistory = getTravelDetails(selectedChatData?.destinationList);
  const extractFlightCities = useCallback((data: any) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    const citiesSet = new Set();

    data.forEach((location: any) => {
      location.flights.forEach((flight: any) => {
        const departureCity = JSON.stringify({
          name: flight?.departure_city_name,
          latitude: flight?.departure_city_latitude,
          longitude: flight?.departure_city_longitude,
          countryCode: flight?.departure_city_country_code,
        });

        const arrivalCity = JSON.stringify({
          name: flight.arrival_city_name,
          latitude: flight.arrival_city_latitude,
          longitude: flight.arrival_city_longitude,
          countryCode: flight.arrival_city_country_code,
        });

        citiesSet.add(departureCity);
        citiesSet.add(arrivalCity);
      });
    });

    return Array.from(citiesSet).map((city: any) => JSON.parse(city));
  }, []);
  const airportPayload = useMemo(
    () => extractFlightCities(flightHistory),
    [flightHistory, extractFlightCities]
  );
  const getAirportData = async () => {
    const isValidCity = (city: any) =>
      Object.values(city).every(
        (value) => value !== undefined && value !== null
      );
    setIsFetchingAirports(true);
    const cities = airportPayload?.filter(isValidCity);
    if (!cities || cities.length === 0) {
      setBookingDetails((prevData) => ({
        ...prevData,
        hideFlights: true,
      }));
      return;
    }

    const requestBody = {
      cities: cities,
      radius: "100 miles",
    };
    fetchAirportController.current = new AbortController();
    try {
      const data = await getAirports(
        requestBody,
        fetchAirportController.current
      );
      if (fetchAirportController.current.signal.aborted) {
        return null;
      }
      setAirPorts(data);
      setIsFetchingAirports(false);
      return data;
    } catch (err) {
      setAirPorts([]);
      setIsFetchingAirports(false);
      console.error("Failed to fetch flight data");
      return [];
    }
  };

  const handleModifyButton = useCallback(async () => {
    const transformedAirPortData = selectedFlights.map((flight) => ({
      arrival: flight?.arrival?.airport,
      departure: flight?.departure?.airport,
      fromCity: "",
      destinationCity: "",
      departureDate: formatToYYYYMMDD(flight?.departure?.date || ""),
      returnDate: "",
      departureAirportFullName: flight.departure.airportName,
      arrivalAirportFullName: flight.arrival.airportName,
      arrivalCityLatitude: "",
      arrivalCityLongitude: "",
      departureCityLatitude: "",
      departureCityLongitude: "",
      noOfDays: 0,
      newArrivalCitySearch: false,
      newDepartureCitySearch: false,
      arrivalCityDetails: "",
      departureCityDetails: "",
      arrivalSelectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      departureSelectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
    }));
    setSelectedFlightOptions((prevData) => ({
      ...prevData,
      flights: true,
      hotels: false,
    }));
    setBookingDetails((prevData) => ({
      ...prevData,
      airPort: transformedAirPortData,
    }));
    // Wait for the getAirportData function to finish
    try {
      await getAirportData();
    } catch (error) {
      console.error("Error fetching airport data:", error);
      return;
    }
    isMobile
      ? navigate(ROUTES.ModifyFlights)
      : setScreen(ScreenTypes.modifyFlight);
  }, [selectedFlights, navigate, setBookingDetails, setSelectedFlightOptions]);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {flightDetails.map((flightData, index) => (
          <CustomAccordion
            key={index}
            className={styles.customFlightStyles}
            summaryClass={styles.summaryFlightClass}
            accordianDetailsClass={styles.accordFlightDetailsClass}
            defaultExpanded={true}
            hideExpandIcon={true}
            onChange={() => {}}
            headingComponent={
              <div className={styles.headingFlightComp}>
                <div className={styles.accordTitleCard}>
                  <p className={styles.dayAndCity}>
                    {getDayOfWeek(flightData?.departure?.date)}{" "}
                    {getMonthDate(flightData?.departure?.date)}
                  </p>

                  <p className={styles.accordDesc}>
                    {`${flightData?.departure?.airportName} (${flightData?.departure?.airport})`}{" "}
                    -{" "}
                    {`${flightData?.arrival?.airportName} (${flightData?.arrival?.airport})`}
                  </p>
                </div>
                <img
                  src={planeEnginesLight}
                  alt="plane"
                  className={`${flightData?.arrival?.airport ? styles.planeArrivalIcon : styles.planeDepartureIcon}`}
                />
              </div>
            }
            bodyComponent={
              <div
                className={styles.flightDetails}
                onClick={() => handleSelection(flightData)}>
                <CustomNewCheckBox
                  name={flightData?.transactionId}
                  checked={selectedFlights?.some(
                    (selected) =>
                      selected?.transactionId === flightData?.transactionId
                  )}
                  onChange={() => handleSelection(flightData)}
                />
                <div
                  className={`${
                    selectedFlights.some(
                      (selected) =>
                        selected.transactionId === flightData.transactionId
                    )
                      ? styles.flightCard
                      : styles.inActive
                  }`}>
                  <div className={styles.flightSubCard}>
                    <FlightDetails
                      airportCode={flightData?.departure?.airport}
                      airportName={flightData?.departure?.airportName}
                      date={flightData?.departure?.date}
                      flightType="departure"
                    />
                  </div>
                  {flightData?.stops.map((airport, index) => (
                    <div key={index} className={styles.flightCard2}>
                      <img src={PathIcon} alt="" className={styles.backIcon} />
                      <FlightDetails
                        airportCode={airport.airport}
                        airportName={airport.airportName}
                        date={airport.departureDate}
                        flightType="arrival"
                      />
                    </div>
                  ))}
                  <div className={styles.flightCard2}>
                    <img src={PathIcon} alt="" className={styles.backIcon} />
                    <FlightDetails
                      airportCode={flightData?.arrival?.airport}
                      airportName={flightData?.arrival?.airportName}
                      date={flightData?.arrival?.date}
                      flightType="arrival"
                    />
                  </div>
                </div>
              </div>
            }
            zeroMarginOnExpand
            borderBottom
          />
        ))}
        <div className={styles.modifyCard}>
          <p className={styles.info}>
            Tap above to select all flights to modify
          </p>
          <Button disabled={!EnableModifyButton} onClick={handleModifyButton}>
            Modify Selected
          </Button>
          <p className={styles.subInfo}>and ignore unselected flights</p>
        </div>
      </div>
    </div>
  );
}

export default ModifyFlights;
