import { useContext, useState } from "react";
import { arrowRightArrowLeftRegular } from "../../../../constant";
import {
  AirportDataTypes,
  BookingDetails,
  LocationTypes,
} from "../../../../types";
import AirportSelectionPopover from "../airportSelectionPopover/AirportSelectionPopover";
import React from "react";
import styles from "./flight.module.scss";

interface iProps {
  className?: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  hideTitle?: boolean;
  departure: string;
  arrival: string;
  fromCity: string;
  destinationCity: string;
  indexNumber: number;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityLatitude: string;
  departureCityLongitude: string;
  departureAirportFullName: string;
  arrivalAirportFullName: string;
  newDepartureCitySearch: boolean;
  newArrivalCitySearch: boolean;
  arrivalCityDetails: string;
  departureCityDetails: string;
  arrivalSelectedLocation: LocationTypes;
  departureSelectedLocation: LocationTypes;
}

function AirPortPicker({
  className,
  bookingDetails,
  setBookingDetails,
  hideTitle,
  departure,
  arrival,
  fromCity,
  destinationCity,
  indexNumber,
  arrivalCityLatitude,
  arrivalCityLongitude,
  departureCityLatitude,
  departureCityLongitude,
  arrivalAirportFullName,
  departureAirportFullName,
  newDepartureCitySearch,
  newArrivalCitySearch,
  arrivalCityDetails,
  departureCityDetails,
  arrivalSelectedLocation,
  departureSelectedLocation,
}: iProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  //   data: AirportDataTypes[] | undefined | null,
  //   fromCity: string,
  //   destinationCity: string
  // ) => {
  //   if (!data) {
  //     console.error("Airport data is undefined or null");
  //     return [];
  //   }

  //   return data.filter(
  //     (city) => city.city === fromCity || city.city === destinationCity
  //   );
  // };

  // const airPortData = filterCities(airPorts, fromCity, destinationCity);
  return (
    <div className={`${className} ${styles.travelCard}`}>
      <AirportSelectionPopover
        indexNumber={indexNumber}
        arrival={arrival}
        departure={departure}
        fromCity={fromCity}
        destinationCity={destinationCity}
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        arrivalCityLatitude={arrivalCityLatitude}
        arrivalCityLongitude={arrivalCityLongitude}
        departureCityLatitude={departureCityLatitude}
        departureCityLongitude={departureCityLongitude}
        newDepartureCitySearch={newDepartureCitySearch}
        newArrivalCitySearch={newArrivalCitySearch}
        arrivalCityDetails={arrivalCityDetails}
        departureCityDetails={departureCityDetails}
        arrivalSelectedLocation={arrivalSelectedLocation}
        departureSelectedLocation={departureSelectedLocation}
        popoverTrigger={
          <div className={`${className} ${styles.main}`}>
            <React.Fragment>
              <p className={styles.label}>AIRPORTS</p>
              <div
                className={`${isPopoverOpen ? styles.activeBtn : styles.btn}`}>
                <div className={styles.card}>
                  <p className={styles.btnName}>{departure}</p>
                  <p className={styles.city}>
                    {departureAirportFullName || "Select"}
                  </p>
                </div>
                <img
                  src={arrowRightArrowLeftRegular}
                  className={styles.arrowIcon}
                  alt=""
                />
                <section className={styles.card}>
                  <p className={styles.btnName}>{arrival}</p>
                  <p className={styles.city}>
                    {arrivalAirportFullName || "Select"}
                  </p>
                </section>
              </div>
            </React.Fragment>
          </div>
        }
      />
    </div>
  );
}

export default AirPortPicker;
