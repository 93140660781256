import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Chip } from "@mui/material";
import { ButtonSizes, ButtonVariants } from "../../../enums";
import { localDataTypes } from "../../../types";
import styles from "./exp.module.scss";
interface iProps {
  selectedActivities: string[];
  setSelectedActivities: Dispatch<SetStateAction<string[]>>;
  setIsFormUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

function Experience({
  selectedActivities,
  setSelectedActivities,
  setIsFormUpdated,
}: iProps): JSX.Element {
  const [expData, setExpData] = useState<localDataTypes[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleChipClick = (activity: localDataTypes) => {
    if (selectedActivities.includes(activity.data)) {
      setSelectedActivities(
        selectedActivities.filter((food) => food !== activity.data)
      );
    } else {
      setSelectedActivities([...selectedActivities, activity.data]);
      setIsFormUpdated(true);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem("_all_category");
    try {
      const parsedData = storedData ? JSON.parse(storedData) : [];

      if (Array.isArray(parsedData)) {
        const Experiences = parsedData?.find(
          (item) => item?.name === "Experiences"
        );

        if (Experiences && Experiences?.tag) {
          setExpData(Experiences.tag);
        }
      } else {
        console.error("Parsed data is not an array:", parsedData);
      }
    } catch (error) {
      console.error("Error parsing storedData:", error);
    }
  }, []);

  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={styles.main}>
      <span className={styles.title}>Activities I like:</span>
      <div className={styles.chipCard}>
        {expData &&
          expData
            .slice(0, showAll ? expData.length : 6)
            .map((activity, index) => (
              <Chip
                label={activity.data}
                size={ButtonSizes.Medium}
                variant={ButtonVariants.Outlined}
                onClick={() => handleChipClick(activity)}
                key={index}
                className={
                  selectedActivities &&
                  selectedActivities.includes(activity.data)
                    ? styles.activeChip
                    : styles.chip
                }
                style={{
                  backgroundColor:
                    selectedActivities.includes(activity.data) === true
                      ? "#A4D1F0"
                      : "#ffff",
                }}
              />
            ))}
      </div>
      {showAll ? (
        <span className={styles.seeMore} onClick={handleSeeMoreClick}>
          See less
        </span>
      ) : (
        <span className={styles.seeMore} onClick={handleSeeMoreClick}>
          See more
        </span>
      )}
    </div>
  );
}

export default Experience;
