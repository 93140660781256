import { Button } from "@mui/material";
import styles from "./today.module.scss";
import {
  NotificationData,
  TodayItineraryData,
} from "../../../constant/dummyData";
import UpcomingTripCard from "../../reusableComponent/upcomingTripCard/UpcomingTripCard";
import { ROUTES, zenvoyaChatIcon } from "../../../constant";
import WeatherWidget from "../../weatherWidget/weatherWidget";
import { useContext, useEffect, useState } from "react";
import DelayNotifications from "../../booking/delayNotifications/delayNotifications";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { ScreenTypes } from "../../../enums";
import { iCityDetailsResult, notType } from "../../../types";
import { getWeatherDetailsAPI } from "../../../api/getWeatherDetailsAPI";
import { iCurrentTripContext } from "../../../context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "../../../context/currentTripContext/currentTripContext";
import {
  getDayOfWeek,
  getMonthDate,
  isSameDate,
} from "../../../utils/dateUtils";
import Markdown from "react-markdown";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import { getNearbyResturantAPI } from "../../../api/currentItinerary/getNearbyResturant";
import { getNearbyActivitiesAPI } from "../../../api/currentItinerary/getNearbyActivities";
import { parseResultToJSON } from "../../../utils";
import { TRIP_DETAILS_TABS } from "../../myTripsPage/myTripsConstants";

interface HighlightCitiesProps {
  text: string;
}

export interface iWeatherData {
  temp: number;
  high: number;
  low: number;
  iconCode: string;
  condition: string;
  iconURL: string;
  alerts: [];
  alertsMessage: string;
}

export interface iNotificationData {
  id: number;
  notificationType: string;
  title: string;
  notificationDetails: string;
  bgImage: string;
  weatherType: string;
  showNotification: boolean;
}

function TodayLandingPage(): JSX.Element {
  const navigate = useNavigate();
  const globalContext = useContext(StateContext);
  const {
    setPrevRoute,
    isMobile,
    setScreen,
    setTripOrItinearyId,
    userInfo,
    setActiveTripDetailsTab,
  }: iGlobalContext = globalContext;
  const { currentTripData }: iCurrentTripContext = useCurrentTripContext();

  const [weatherData, setWeatherData] = useState({} as iWeatherData);
  const [notification, setNotification] = useState<notType>({
    flight: true,
    weather: true,
  });
  const [notificationData, setNotificationData] = useState(
    NotificationData as iNotificationData[]
  );
  const [todayActivity, setTodayActivity] = useState({} as any);

  useEffect(() => {
    if (weatherData?.condition) {
      setTimeout(() => {
        setNotificationData(
          notificationData.map((ele: iNotificationData) => {
            if (ele.notificationType === "Weather")
              return {
                ...ele,
                showNotification: true,
                notificationDetails:
                  weatherData.alertsMessage || weatherData.condition,
              };
            else return ele;
          })
        );
      }, 1500);
    }
  }, [weatherData]);

  const [cityname, setcityname] = useState("");

  /**
   * Side effect to listen if user has added the question in the URL
   */
  useEffect(() => {
    triggerGAEvent(GA_LABEL_ENUMS.current_trips_page_loads);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("city");
    if (data) {
      const formattedData = decodeURIComponent(data)
        .replace(/\+/g, " ")
        .replace(/=/g, "")
        .replace(/\?/g, "");
      setcityname(formattedData);
    }
  }, []);

  function handleExploreCityClick() {
    triggerGAEvent(GA_LABEL_ENUMS.today_explore_city_click);
    if (isMobile) {
      navigate(ROUTES.ExploreCity);
      return;
    }
    setScreen(ScreenTypes.ExploreCity);
  }

  /**
   * Get weather details
   */
  async function getWeatherDetails() {
    const response = await getWeatherDetailsAPI(cityname);
    // // console.log(response);
    if (response) setWeatherData(response);
  }

  /**
   * Get nearby Restuarnat and Activities
   */
  const [nearbyRestuarant, setNearbyRestuarant] = useState<any>([]);
  const [nearbyActivities, setNearbyActivities] = useState<any>([]);

  async function getNearbyResturant() {
    const response = await getNearbyResturantAPI(
      userInfo?.id,
      currentTripData?.data
    );
    // // console.log("PARSE", { response }, { currentTripData });
    // // console.log("PARSE RESTUA", parseResultToJSON(response));
    const parseData = parseResultToJSON(response);
    if (parseData) setNearbyRestuarant(parseData);
  }
  async function getNearbyActvities() {
    const response = await getNearbyActivitiesAPI(
      userInfo?.id,
      currentTripData?.data
    );
    // console.log("ACTIVITES", { response });
    // console.log("PARSE ACTIVI", parseResultToJSON(response));
    const parseData: any = parseResultToJSON(response);
    if (parseData) setNearbyActivities([parseData]);
  }

  useEffect(() => {
    getNearbyResturant();
    getNearbyActvities();
  }, []);

  // useEffect(() => {
  //   console.log("PARSE DATA", nearbyActivities, nearbyRestuarant);
  // }, [nearbyActivities, nearbyRestuarant]);

  function isSameDateCurrentTrip(timestamp1: string, timestamp2: string) {
    const date1 = new Date(timestamp1).toISOString().split("T")[0];
    const date2 = new Date(timestamp2).toISOString().split("T")[0];
    return date1 === date2;
  }

  useEffect(() => {
    data?.destinationList?.map(
      (destinationEle: iCityDetailsResult, firstIndex: number) =>
        destinationEle?.tripDetails?.map((ele: any, index: number) => {
          const match = ele?.day.match(/\d+/); // Matches any digit(s) in the string
          let count = match ? parseInt(match[0], 10) - 1 : 0;
          const itineraryDate = String(
            new Date(
              new Date(currentTripData?.startDate).setDate(
                new Date(currentTripData?.startDate).getUTCDate() + count
              )
            )
          );
          // const todayDate = "Fri Oct 9 2024 05:30:00 GMT+0530";
          const todayDate = new Date();
          // console.log(
          //   "CURENT DATE",
          //   itineraryDate + " ---- " + todayDate,
          //   " --- ",
          //   isSameDateCurrentTrip(itineraryDate, String(todayDate))
          // );
          if (isSameDateCurrentTrip(itineraryDate, String(todayDate))) {
            setcityname(
              ele?.city_name ||
                ele?.destination_city ||
                ele.cityName?.split(",")[0]
            );
            setTodayActivity(ele);
          }
        })
    );
  }, []);

  useEffect(() => {
    if (cityname) getWeatherDetails();
  }, [cityname]);

  const data =
    typeof currentTripData?.metaData === "string"
      ? currentTripData?.metaData &&
        JSON?.parse(currentTripData?.metaData)[0]?.selectedChatData
      : currentTripData?.metaData?.selectedChatData;

  // console.log("currentTripData", data);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.itineraryCard}>
          <div className={styles.dayCard}>
            {/* Weather Widget */}
            <section className={styles.widget}>
              <div className={styles.widgetContainer}>
                <WeatherWidget
                  weatherData={weatherData}
                  setWeatherData={setWeatherData}
                  cityName={cityname}
                />
                <p className={styles.city}>{cityname}</p>
              </div>
            </section>
            <div className={styles.todayCard}>
              <p className={styles.today}>{TodayItineraryData.day}</p>
            </div>

            {/* Notification List */}
            {(notification.flight || notification.weather) && (
              <div className={styles.notificationContainer}>
                {notificationData.length > 0 &&
                  notificationData.map((i, index) => (
                    <section key={index}>
                      {i.showNotification && (
                        <DelayNotifications
                          notification={notification}
                          setNotification={setNotification}
                          notificationType={i.notificationType}
                          title={i.title}
                          notificationDetails={i.notificationDetails}
                          bgImage={i.bgImage}
                          weatherType={i.weatherType}
                          weatherIconCode={weatherData.iconCode}
                          onClick={() => {
                            // // console.log("clicked");
                            if (i.notificationType === "flight_delay")
                              triggerGAEvent(
                                GA_LABEL_ENUMS.today_flight_delay_click
                              );
                            if (i.notificationType !== "flight_delay")
                              triggerGAEvent(
                                GA_LABEL_ENUMS.today_weather_advisory_click
                              );
                            if (isMobile) {
                              i.notificationType === "flight_delay"
                                ? navigate(ROUTES.FlightDelay)
                                : navigate(ROUTES.weatherAdvisory);
                              return;
                            }
                            i.notificationType === "flight_delay"
                              ? setScreen(ScreenTypes.FlightDelay)
                              : setScreen(ScreenTypes.WeatherAdvisory);
                          }}
                        />
                      )}
                    </section>
                  ))}
              </div>
            )}
          </div>

          {/* Day activity */}
          <div>
            <p className={styles.day}>
              {getDayOfWeek(String(new Date())) +
                " " +
                getMonthDate(String(new Date()))}
            </p>
            <p className={styles.explore}>
              {" "}
              {todayActivity?.day_itinerary_description}
            </p>
            {todayActivity?.itinerary_by_time_of_the_day?.map(
              (subEle: any, index: number) => (
                <div className={styles.dayWiseItineraryCard} key={index}>
                  <p className={styles.morning}>{subEle?.time_of_the_day}</p>
                  <Markdown className={styles.description}>
                    {subEle?.description}
                    {/* <HighlightCities text={i.description} /> */}
                  </Markdown>
                </div>
              )
            )}
          </div>
          {/* {data?.destinationList?.map(
            (destinationEle: iCityDetailsResult, firstIndex: number) =>
              destinationEle?.tripDetails?.map((ele: any, index: number) => {
                const match = ele?.day.match(/\d+/); // Matches any digit(s) in the string
                let count = match ? parseInt(match[0], 10) - 1 : 0;
                const itineraryDate = String(
                  new Date(
                    new Date(currentTripData?.startDate).setDate(
                      new Date(currentTripData?.startDate).getUTCDate() + count
                    )
                  )
                );
                const todayDate = new Date();
                if (isSameDate(itineraryDate, String(todayDate))) {
                  return (
                    <div key={ele?.id || index}>
                      <p className={styles.day}>
                        {getDayOfWeek(itineraryDate) +
                          " " +
                          getMonthDate(itineraryDate)}
                      </p>
                      <p className={styles.explore}>
                        {" "}
                        {ele?.day_itinerary_description}
                      </p>
                      {ele?.itinerary_by_time_of_the_day?.map(
                        (subEle: any, index: number) => (
                          <div
                            className={styles.dayWiseItineraryCard}
                            key={index}
                          >
                            <p className={styles.morning}>
                              {subEle?.time_of_the_day}
                            </p>
                            <Markdown className={styles.description}>
                              {subEle?.description}
                            </Markdown>
                          </div>
                        )
                      )}
                    </div>
                  );
                }
                return <></>;
              })
          )} */}
        </div>
        <div className={styles.divider} />

        {/* Nearby Restuarant and Activities */}
        {/* {TodayItineraryData.nearByActivity.map((i, index) => (
          <div className={styles.nearByPlaces} key={index}>
            <p className={styles.restaurants}>{i.actName}</p>
            <div className={styles.specCard}>
              <div className={styles.specs}>
                <p className={styles.specTitle}>{i.actTitle}</p>
                <p className={styles.specDesc}>{i.actDesc}</p>
              </div>
              <p className={styles.distance}>{i.distance}</p>
            </div>
          </div>
        ))} */}
        {nearbyRestuarant?.length > 0 &&
          JSON.stringify(nearbyRestuarant[0]) !== "{}" &&
          nearbyRestuarant?.map((ele: any, index: number) => (
            <div className={styles.nearByPlaces} key={index}>
              <p className={styles.restaurants}>Restaurants</p>
              <div className={styles.specCard}>
                <div className={styles.specs}>
                  <p className={styles.specTitle}>{ele?.restaurant_name}</p>
                  <p className={styles.specDesc}>
                    {ele?.restaurant_latest_review}
                  </p>
                </div>
                <p className={styles.distance}>{ele?.distance_from_location}</p>
              </div>
            </div>
          ))}
        {nearbyActivities?.length > 0 &&
          JSON.stringify(nearbyActivities[0]) !== "{}" &&
          nearbyActivities?.map((ele: any, index: number) => (
            <div className={styles.nearByPlaces} key={index}>
              <p className={styles.restaurants}>Activities</p>
              <div className={styles.specCard}>
                <div className={styles.specs}>
                  <p className={styles.specTitle}>{ele?.activity_name}</p>
                  <p className={styles.specDesc}>
                    {ele?.activity_latest_review}
                  </p>
                </div>
                <p className={styles.distance}>{ele?.distance_from_location}</p>
              </div>
            </div>
          ))}

        {/* Explore city */}
        <div className={styles.exploreCard}>
          <Button
            className={styles.exploreBtn}
            onClick={handleExploreCityClick}
          >
            EXPLORE THE CITY
          </Button>
        </div>

        {/* Itinerary details Card */}
        <div className={styles.upcomingTripContainer}>
          <h1 className={styles.viewTrip}>View Trip Details</h1>
          <div className={styles.upcomingTripSubContainer}>
            <UpcomingTripCard
              data={currentTripData}
              onCardClick={() => {
                triggerGAEvent(GA_LABEL_ENUMS.today_view_trip_details_click);
                setTripOrItinearyId(currentTripData?.id);
                if (isMobile) {
                  setPrevRoute("/today");
                  navigate(ROUTES.TripDetails);
                  setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
                } else {
                  setScreen(ScreenTypes.TripDetails);
                }
              }}
              showNextIcon={true}
            />
          </div>
        </div>

        {/* Zenvoya floating icon */}
        <div className={styles.chatCard}>
          <Button
            className={styles.chatBtn}
            onClick={() => {
              triggerGAEvent(GA_LABEL_ENUMS.today_zenvoya_assistance_click);
              if (isMobile) {
                navigate(ROUTES.FaqAssistance);
                return;
              }
              setScreen(ScreenTypes.FaqAssistance);
            }}
          >
            <img src={zenvoyaChatIcon} alt="chat" className={styles.chatImg} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TodayLandingPage;
