import { ENDPOINT } from "../../constant/environment";

export async function fetchEmailVerificationApi(token: string) {
  const URL = `${ENDPOINT}/app/v1/users/detailByAuthToken?token=${token}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  try {
    const response = await fetch(URL, config);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error("Error during API call:", error.message);
    return null;
  }
}
