import { useCallback, useContext, useEffect, useState } from "react";
import { AirPort, BookingDetails, iHotelInputPayload } from "../../../../types";
import AirPortPicker from "../../inputFields/airportPicker/airportPicker";
import DateRangePicker from "../../inputFields/dateRangePicker/dateRangePicker";
import Duration from "../../inputFields/duration/duration";
import RouteAndClass from "../../inputFields/routeAndClassNew/routeAndClass";
import SearchButton from "../../inputFields/searchButton/searchButton";
import styles from "./flight.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import Travelers from "../../inputFields/travelers/travelers";
import PropertyType from "../../inputFields/propertyType/propertyType";
import CityLocation from "../../inputFields/location/location";
import Stepper from "../flightSingleCityInputFields/stepper/stepper";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { calculateTotalDays } from "../../../../utils/hotelStayCalculator";
interface iProps {
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  searchFlights: () => void;
  searchHotels: () => void;
  getTravelData: () => void;
  handleUpdateTravelData: () => {};
  cities: iHotelInputPayload[];
}

function FlightHotelInputFields({
  bookingDetails,
  setBookingDetails,
  searchFlights,
  searchHotels,
  getTravelData,
  handleUpdateTravelData,
  cities,
}: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const simplifyFlights = (flights: AirPort[]) => {
    return flights.map((flight) => {
      const simplifiedFlight = {
        arrival: flight.arrival,
        departure: flight.departure,
        departureDate: flight.departureDate,
      };

      if (bookingDetails.tripType !== 3 && flight.returnDate) {
        return { ...simplifiedFlight, returnDate: flight.returnDate };
      }

      return simplifiedFlight;
    });
  };
  const handleFlightHotelInputValidation = useCallback(async () => {
    const {
      airPort,
      adultsCount,
      childrenCount,
      seniorsCount,
      travelFromDate,
    } = bookingDetails || {};
    setBookingDetails((prevData) => ({
      ...prevData,
      selectedFlightId: "",
    }));
    const flights = simplifyFlights(bookingDetails?.airPort);
    const checkDeparture = airPort?.some((flight) => flight.departure);
    const checkArrival = airPort?.some((flight) => flight.arrival);
    const missingFields = flights?.some(
      (flight) => !flight.arrival || !flight.departure
    );
    if (!cities || cities?.length === 0) {
      setEnableBtn(true);
      return toastMessage.error("", "Please select a cities to search");
    }
    if (missingFields) {
      setEnableBtn(true);
      return toastMessage.error(
        "",
        "Please select both arrival and departure airports"
      );
    }
    const sameAirports = flights?.some(
      (flight) => flight.arrival === flight.departure
    );
    if (sameAirports) {
      return toastMessage.error(
        "",
        "Arrival and departure airports cannot be the same"
      );
    }
    if (travelFromDate === "") {
      toastMessage.error("", "Please select a travel date.");
      setEnableBtn(true);
      return;
    }
    if (!checkDeparture) {
      toastMessage.error("", "Please select a departure airport.");
      setEnableBtn(true);
      return;
    }
    if (!checkArrival) {
      toastMessage.error("", "Please select a arrival airport.");
      setEnableBtn(true);
      return;
    }

    const totalAdults = adultsCount + seniorsCount;

    if (totalAdults === 0) {
      toastMessage.error("", "At least 1 adult or senior is required.");
      setEnableBtn(true);
      return;
    }

    if (childrenCount > 0 && totalAdults === 0) {
      toastMessage.error(
        "",
        "A child must be accompanied by at least 1 adult or senior."
      );
      setEnableBtn(true);
      return;
    }
    try {
      await Promise.all([searchFlights(), searchHotels()]);
    } catch (error) {
      console.error("Error during API calls:", error);
    } finally {
      // console.log("");
    }
  }, [bookingDetails, searchFlights, searchHotels]);
  useEffect(() => {
    const {
      airPort,
      adultsCount,
      seniorsCount,
      childrenCount,
      travelFromDate,
      numberOfRoomCount,
    } = bookingDetails || {};
    const isCityFieldMissing = cities?.some((city) => {
      const {
        checkIn,
        checkOut,
        country,
        city: cityName,
        latitude,
        longitude,
      } = city;
      return (
        !checkIn ||
        !checkOut ||
        !country ||
        !cityName ||
        !latitude ||
        !longitude
      );
    });
    const isFieldMissing =
      !airPort?.length ||
      airPort.some((flight) => {
        return !flight.departure || !flight.arrival;
      });

    const isTravelDateMissing = !travelFromDate;
    const isRoomCountInvalid = numberOfRoomCount < 1;
    const shouldDisableButton =
      isFieldMissing ||
      isTravelDateMissing ||
      (childrenCount === 1 && adultsCount < 1 && seniorsCount < 1) ||
      (adultsCount < 1 && seniorsCount < 1) ||
      isRoomCountInvalid ||
      isCityFieldMissing;

    setEnableBtn(shouldDisableButton);
  }, [bookingDetails, cities]);
  const hotelStay = calculateTotalDays(cities);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.newInputCard1}>
        <DateRangePicker
          startDate={bookingDetails.travelFromDate}
          endDate={bookingDetails.airportTo}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          handleUpdateData={() => {
            // getTravelData();
            handleUpdateTravelData();
          }}
        />

        {bookingDetails.tripType !== 1 && (
          <div className={styles.card3}>
            <Duration
              bookingDetails={bookingDetails}
              flight={true}
              hotel={true}
              stayCount={hotelStay}
            />
          </div>
        )}

        <div className={styles.card1}>
          <Travelers
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            showRoom={true}
          />
        </div>
      </div>
      <div className={styles.newInputCard1}>
        <div className={styles.card4}>
          <PropertyType
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
          />
        </div>

        {selectedChatData?.destinationList &&
        bookingDetails.isThisMultiCityTrip ? (
          <RouteAndClass
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            tripType={3}
          />
        ) : (
          <div className={styles.locationCard}>
            <CityLocation />
          </div>
        )}
      </div>
      <div className={styles.newInputCard3}>
        <div className={styles.card4}>
          {bookingDetails.isThisMultiCityTrip === false && (
            <RouteAndClass
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              tripType={bookingDetails.tripType}
            />
          )}
        </div>

        {selectedChatData.destinationList &&
          bookingDetails.isThisMultiCityTrip === false && (
            <>
              {bookingDetails?.airPort &&
                bookingDetails?.airPort?.length >= 1 &&
                bookingDetails.airPort.map((i, index) => (
                  <div className={styles.newInputCard2} key={index}>
                    <div className={styles.card2}>
                      <AirPortPicker
                        indexNumber={index}
                        arrival={i.arrival}
                        departure={i.departure}
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        departureAirportFullName={i.departureAirportFullName}
                        arrivalAirportFullName={i.arrivalAirportFullName}
                        handleUpdateData={() => {
                          // getTravelData();
                          handleUpdateTravelData();
                        }}
                        arrivalCity={i.destinationCity}
                        departureCity={i.fromCity}
                      />
                    </div>
                  </div>
                ))}
            </>
          )}
      </div>
      {selectedChatData.destinationList &&
        bookingDetails.isThisMultiCityTrip && (
          <div className={styles.stepper}>
            <section className={styles.stepperDetails}>
              {bookingDetails.travelFromDate &&
                bookingDetails.travelToDate &&
                bookingDetails.airPort.length >= 1 && (
                  <Stepper
                    steps={bookingDetails.airPort}
                    initialStep={0}
                    finalStep={bookingDetails.airPort.length - 1}
                    showAccomodation={true}
                    cities={cities}
                  />
                )}
            </section>
          </div>
        )}
      <div
        className={`${bookingDetails.isThisMultiCityTrip ? styles.searchBox : styles.singleCitySearch}`}>
        <SearchButton
          onClick={handleFlightHotelInputValidation}
          disableButton={enableBtn}
        />
      </div>
    </div>
  );
}

export default FlightHotelInputFields;
