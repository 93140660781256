import { useContext, useEffect, useState } from "react";
import { calendarRangeDark } from "../../../../constant";
import { BookingDetails } from "../../../../types";
import styles from "./range.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import SingleDatePickerPopover from "../../bookingPopover/singleDatePickerPopover/SingleDatePickerPopver";
import FormatInputToUTC from "../../../../hooks/utcDateFormater";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

interface iProps {
  className?: string;
  startDate: string;
  endDate: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  handleUpdateData: () => void;
  label?: string;
}

const DateRangePicker: React.FC<iProps> = ({
  className = "",
  bookingDetails,
  setBookingDetails,
  handleUpdateData,
  label = "travel date",
}) => {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [dateRangeValues, setDateRangeValues] = useState<string | null>(null);
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const handleDateChange = (value: Date | null, days: number) => {
    if (value) {
      const startDate = value;
      const endDate = addDays(startDate, (days ?? 1) - 1);
      const formattedStartDate = formatDate(startDate);

      const formattedEndDate = formatDate(endDate);
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        travelFromDate: formattedStartDate,
        travelToDate: formattedEndDate,
        update: true,
      }));
    } else {
      // console.log("No valid date selected");
    }
  };

  useEffect(() => {
    if (bookingDetails.travelToDate === "") {
      return;
    }
    handleUpdateData();
  }, [bookingDetails.travelToDate]);

  const handleApplyButton = () => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_travel_date_apply_click);
    const { number_of_days } = selectedChatData;
    const days = number_of_days;
    if (dateRangeValues && days) {
      const date = new Date(dateRangeValues);
      handleDateChange(date, days);
      // handleUpdateData();
    } else {
      console.error("No date selected");
    }
  };
  useEffect(() => {
    const updateStartDate = () => {
      const { number_of_days } = selectedChatData || {};
      const days = number_of_days;
      const travelFromDate = bookingDetails?.travelFromDate;
      if (!travelFromDate) {
        const today = new Date();
        const nextMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          today.getDate()
        );

        const formattedNextMonthDate = formatDate(nextMonth);
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          travelFromDate: formattedNextMonthDate,
          update: true,
        }));

        // Set date range values
        setDateRangeValues(`${formattedNextMonthDate}T00:00:00`);

        // If days exist, handle date
        if (days) {
          handleDateChange(
            new Date(`${formattedNextMonthDate}T00:00:00`),
            days
          );
        }
      } else {
        // Ensure consistent ISO format for existing travelFromDate
        const formattedDate =
          new Date(travelFromDate).toISOString().split("T")[0] + "T00:00:00";
        setDateRangeValues(formattedDate);

        if (days) {
          handleDateChange(new Date(formattedDate), days);
        }
      }
    };
    updateStartDate();
  }, [bookingDetails?.travelFromDate]);

  useEffect(() => {
    const { number_of_days } = selectedChatData;
    const days = number_of_days;

    if (bookingDetails.travelFromDate && days) {
      const date = new Date(bookingDetails.travelFromDate);
      const formattedDate = date.toISOString().split("T")[0] + "T00:00:00";
      handleDateChange(new Date(formattedDate), days);
    }
  }, []);

  return (
    <div>
      <SingleDatePickerPopover
        value={dateRangeValues}
        setValue={setDateRangeValues}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        handleApplyButton={handleApplyButton}
        popoverTrigger={
          <div className={`${className} ${styles.main}`}>
            <p className={styles.label}>{label}</p>
            <div
              className={styles[isPopoverOpen ? "activeTravelBtn" : "btn"]}
              onClick={() => {
                triggerGAEvent(GA_LABEL_ENUMS.booking_downward_accordion_click);
              }}
            >
              <img
                src={calendarRangeDark}
                alt="icon"
                className={styles.calendarIcon}
              />
              {bookingDetails.travelFromDate ? (
                <p className={styles.btnName}>
                  {FormatInputToUTC(bookingDetails.travelFromDate, true)}
                </p>
              ) : (
                <p className={styles.btnName}></p>
              )}{" "}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default DateRangePicker;
