import { hotelRoomIcon, modifyEditIcon, redBedIcon } from "../../../constant";

export const MANAGE_HOTEL_CONSTANTS = [
  {
    id: 1,
    name: "MODIFY",
    icon: modifyEditIcon,
  },
  {
    id: 2,
    name: "CANCEL",
    icon: redBedIcon,
  },
];

export const hotelInformationArr = [
  {
    checkInCheckOutDate: "Fri Dec 18 - Sun Dec 20",
    hotelPlace: "Barcelona",
    hotelImage: hotelRoomIcon,
    hotelStar: 5,
    hotelName: "Hotel Catalan - Ciutadella",
    place: "Sant Marti",
    address: "1297 via Las ramblas, Barcelona",
    roomDetails: [
      {
        roomCount: 1,
        bedSize: "King",
        bedCount: 1,
      },
      {
        roomCount: 1,
        bedSize: "Queen",
        bedCount: 2,
      },
    ],
  },
  {
    checkInCheckOutDate: "SUN Dec 20 - Wed Dec 23",
    hotelPlace: "Barcelona (BCN) - Washington DC (IAD)",
    hotelImage: hotelRoomIcon,
    hotelStar: 5,
    hotelName: "Queen Victoria Suites",
    place: "Leichester Square",
    address: "2548 Halls Circus, London W34",
    roomDetails: [
      {
        roomCount: 1,
        bedSize: "King",
        bedCount: 1,
      },
      {
        roomCount: 1,
        bedSize: "Queen",
        bedCount: 2,
      },
    ],
  },
];
