import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  arrowRightArrowLeftRegular,
  expandIcon,
  planeDepartureRegular,
  ROUTES,
} from "../../constant";
import RangePicker from "../../component/booking/inputFields/rangePicker/rangePicker";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import NewSingleAirportPicker from "../../component/booking/inputFields/newSingleAirportPicker/newSingleAirportPicker";
import CabinPicker from "../../component/booking/inputFields/cabinPicker/CabinPicker";
import { useNavigate } from "react-router-dom";
import { getDayOfWeek, getMonthDate } from "../../utils/dateUtils";
import styles from "./modify.module.scss";
import { changeFlight } from "../../api/booking/postBooking";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { toastMessage } from "../../utils/ToastProvider";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { ScreenTypes } from "../../enums";

function ModifyFlights(): JSX.Element {
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    userInfo,
    setFlightChange,
    isMobile,
    setScreen,
  }: iGlobalContext = state;
  const navigate = useNavigate();
  const { handleCustomError } = useCustomErrorHandler();
  const handleCancel = useCallback(() => {
    if (isMobile) {
      navigate(ROUTES.TripDetails);
      setScreen(ScreenTypes.TripDetails);
    }
    setScreen(ScreenTypes.TripDetails);
  }, [navigate]);

  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(
    null
  );
  const handleExpandClick = useCallback((index: number) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const handleFlightModify = useCallback(async () => {
    toastMessage.newSuccess(
      TOAST_MESSAGES.PostBooking.flightChangeRequest.messageTitle,
      TOAST_MESSAGES.PostBooking.flightChangeRequest.message
    );
    setFlightChange({ flightCancel: false, flightChange: true });
    if (isMobile) {
      navigate(ROUTES.TripDetails);
      setScreen(ScreenTypes.TripDetails);
    }
    setScreen(ScreenTypes.TripDetails);

    // const requestBody = {
    //   userId: userInfo?.id,
    //   itineraryId: "0K9-2Q4-MWV",
    //   tripType: 2,
    //   segments: bookingDetails.airPort.map((airport) => ({
    //     transactionId: "PyZUdoNhFAs1MRX1_1",
    //     arrival: airport.arrival,
    //     departure: airport.departure,
    //     departureDate: airport.departureDate,
    //   })),
    // };

    // try {
    //   const flightModify = await changeFlight(requestBody);

    //   // Handle errors based on status code
    //   if (flightModify?.statusCode !== 200) {
    //     handleCustomError(flightModify?.statusCode, "modify.flight");
    //     return null;
    //   }

    //   // Log the response
    //   console.log(flightModify);
    // } catch (error) {
    //   console.error("Error modifying flight:", error);
    // }
  }, [userInfo, handleCustomError, bookingDetails]);

  const handleDepartureAirportChange = useMemo(
    () =>
      (
        index: number,
        {
          airportCode,
          airportName,
        }: { airportCode: string; airportName: string }
      ) => {
        setBookingDetails((prevData) => ({
          ...prevData,
          airPort: prevData.airPort.map((airport, i) =>
            i === index
              ? {
                  ...airport,
                  departure: airportCode,
                  departureAirportFullName: airportName,
                }
              : airport
          ),
        }));
      },
    [setBookingDetails]
  );

  const handleArrivalAirportChange = useMemo(
    () =>
      (
        index: number,
        {
          airportCode,
          airportName,
        }: { airportCode: string; airportName: string }
      ) => {
        setBookingDetails((prevData) => ({
          ...prevData,
          airPort: prevData.airPort.map((airport, i) =>
            i === index
              ? {
                  ...airport,
                  arrival: airportCode,
                  arrivalAirportFullName: airportName,
                }
              : airport
          ),
        }));
      },
    [setBookingDetails]
  );

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <p className={styles.title}>Change Flights</p>
        <section className={styles.contentCard}>
          <div className={styles.modifyCard}>
            {bookingDetails.airPort.map((airportData, index) => (
              <div className={styles.subCard} key={index}>
                <div className={styles.flightHeader}>
                  <div className={styles.flightDetails}>
                    <p className={styles.dateInfo}>
                      {getDayOfWeek(airportData.departureDate)}{" "}
                      {getMonthDate(airportData.departureDate)}
                    </p>
                    <p className={styles.flightInfo}>
                      {airportData.departureAirportFullName} (
                      {airportData.departure}) -{" "}
                      {airportData.arrivalAirportFullName} (
                      {airportData.arrival})
                    </p>
                  </div>
                  <div className={styles.accordion}>
                    <img
                      src={planeDepartureRegular}
                      alt=""
                      className={styles.flightIcon}
                    />
                    <button
                      className={styles.expandButton}
                      onClick={() => handleExpandClick(index)}
                      aria-label="Expand">
                      <img
                        src={expandIcon}
                        alt="Expand Icon"
                        className={`${expandedCardIndex === index ? styles.hideExpandIcon : styles.expandIcon}`}
                      />
                    </button>
                  </div>
                </div>
                {expandedCardIndex === index && (
                  <div className={styles.inputFields}>
                    <div className={styles.dateClassCard}>
                      <RangePicker
                        startDate={bookingDetails.travelFromDate}
                        endDate={bookingDetails.travelToDate}
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        handleUpdateData={() => {}}
                        label="DATE"
                      />
                      <CabinPicker
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        tripType={bookingDetails.tripType}
                        label="CLASS"
                      />
                    </div>
                    <div className={styles.airportCard}>
                      <div className={styles.newInputCard2} key={index}>
                        <div className={styles.card2}>
                          <NewSingleAirportPicker
                            arrival={airportData.arrival}
                            departure={airportData.departure}
                            bookingDetails={bookingDetails}
                            departureAirportFullName={
                              airportData.departureAirportFullName
                            }
                            handleUpdateData={() => {}}
                            label="AIRPORT"
                            title="Departure City Name"
                            value={airportData.departure}
                            airportName={airportData.departureAirportFullName}
                            handleAirportChange={(event) =>
                              handleDepartureAirportChange(index, event)
                            }
                          />
                        </div>
                        <img
                          src={arrowRightArrowLeftRegular}
                          alt="icon"
                          className={styles.leftRightIcon}
                        />
                        <div className={styles.card2}>
                          <NewSingleAirportPicker
                            arrival={airportData.arrival}
                            departure={airportData.departure}
                            bookingDetails={bookingDetails}
                            departureAirportFullName={
                              airportData.departureAirportFullName
                            }
                            handleUpdateData={() => {}}
                            title="Arrival City Name"
                            value={airportData.arrival}
                            airportName={airportData.arrivalAirportFullName}
                            handleAirportChange={(event) =>
                              handleArrivalAirportChange(index, event)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className={styles.buttonCard}>
            <button className={styles.cancelBtn} onClick={handleCancel}>
              Cancel
            </button>
            <button className={styles.searchBtn} onClick={handleFlightModify}>
              Submit
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ModifyFlights;
