import React, { useContext, useEffect } from "react";
import styles from "./dailyTabs.module.scss";
import CityTabs from "../../../routes/itineraryDetails/cityTabs/CityTabs";
import { iChatData, iCityDetailsResult } from "../../../types";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";

interface DailyTabsProps {
  readonly startDate?: string;
  readonly status?: string;
  readonly flightSummary?: any;
}

export default function DailyTabs({
  status = "draft",
  startDate = "",
  flightSummary,
}: DailyTabsProps) {
  const globalContext = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = globalContext;

  useEffect(() => {}, [selectedChatData]);
  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);

  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.headingItinerary}>Itinerary</h1>
      <CityTabs
        itineraryDescription={selectedChatData?.complete_itinerary_description}
        itineraryVersion={selectedChatData?.itinerary_version ?? 0}
        // destinationList={activitiesData?.destinationList?.filter(
        //   (ele: iCityDetailsResult) => ele.checkedFlag
        // )}
        destinationList={selectedChatData?.destinationList?.filter(
          (ele: iCityDetailsResult) => ele.checkedFlag
        )}
        isThereFlightBooked={flightSummary}
        disableInput={false}
        flexDirection={"column-reverse"}
        startDate={startDate}
        showWeatherAdvisory
        showEditActivityFlag={showEditActivityFlag}
        setShowEditActivityFlag={setShowEditActivityFlag}
        savedStatus={status}
        handleHeadingClick={() =>
          triggerGAEvent(
            GA_LABEL_ENUMS.trip_details_daily_activity_accordion_click
          )
        }
        handleEditGAActivity={() =>
          triggerGAEvent(
            GA_LABEL_ENUMS.trip_details_activities_modify_activities_click
          )
        }
        handleEditActivityGADone={() =>
          triggerGAEvent(GA_LABEL_ENUMS.trip_details_activities_done_click)
        }
      />
    </div>
  );
}
