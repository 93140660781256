import { Stack } from "@mui/material";
import styles from './singleSigninLoginButton.module.scss'

export function SingleSignInLoginButton({
    onClick,
    icon,
    text,
  }: {
    onClick: any;
    icon: any;
    text: string;
  }) {
    return (
      <button
        className={styles.googleBtn}
        style={{
          border: "1px solid #979797",
          borderRadius: "4px",
          width: "100%",
        }}
        onClick={onClick}
        // onClick={renderProps.onClick}
        // disabled={renderProps.disabled}
        // onClick={() => handleLoginWithGoogleBtn()}
      >
        <Stack
          direction="row"
          height={42}
          borderRadius={1}
          alignItems="center"
          spacing={0}
          bgcolor=" #ffff"
          sx={{ cursor: "pointer" }}
        >
          <img style={{ height: "42px" }} src={icon} alt={""} />
          <h3
            style={{
              marginLeft: "-14px",
              color: "#272727",
              fontSize: "11px",
              fontFamily: "Lato",
              letterSpacing: "0.5px",
              width: "100%",
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            {text}
          </h3>
        </Stack>
      </button>
    );
  }
  