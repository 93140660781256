import { useContext } from "react";
import styles from "./location.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import SingleHotelCityLocation from "../../../googleMapInputFields/singleCityHotelLocation/singleCityHotelLocation";

function CityLocation(): JSX.Element {
  const state = useContext(StateContext);
  const { bookingDetails, setBookingDetails }: iGlobalContext = state;

  const handleDeparturePlaceSelected = () => {
    setBookingDetails((prevData) => ({
      ...prevData,
    }));
  };

  const handleClearInputFields = () => {
    setBookingDetails((prevData) => ({
      ...prevData,
      hotels: prevData.hotels.map((hotel) => ({
        ...hotel,
        city: "",
        country: "",
        latitude: "",
        longitude: "",
      })),
    }));
  };

  return (
    <div className={styles.main}>
      <p className={styles.label}>CITY</p>
      <div className={styles.btn}>
        {bookingDetails?.hotels[0]?.cityNotAvailable ? (
          <SingleHotelCityLocation
            onPlaceSelected={handleDeparturePlaceSelected}
            setSelectedLocation={setBookingDetails}
            setAddress={setBookingDetails}
            address={bookingDetails?.hotels[0]?.city || ""}
            handleClearInputFields={handleClearInputFields}
          />
        ) : (
          // <p className={styles.btnName}>{""}</p>

          <p className={styles.btnName}>
            <p className={styles.countryName}>
              {bookingDetails?.hotels[0]?.city || ""}
            </p>
            <p className={styles.countryName}>
              {bookingDetails?.hotels[0]?.country || ""}
            </p>
          </p>
        )}
      </div>
    </div>
  );
}

export default CityLocation;
