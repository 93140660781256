import React, { Dispatch, SetStateAction, useContext } from "react";
import styles from "./assistance.module.scss";
import ChatContent from "../../../routes/topDestination/chatContent/ChatContent";
import { iAppConfig, iChatData } from "../../../types";
import InputWithMic from "../inputWithMic/InputWithMic";
import {
  backIcon,
  exploreCityLogo,
  ROUTES,
  zenvoyaChatIcon,
} from "../../../constant";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";

export default function Assistance({
  title,
  headingText,
  chatData,
  appConfig,
  appConfigKey,
  disableInput,
  handleSendMessage,
  chatInputText,
  setChatInputText,
  handleCancel,
  handleBack,
  placeholder,
  onBackClick,
  onMicStartListeningFunc,
  onMicStopListeningFunc,
}: {
  title: string;
  headingText: string;
  chatData: iChatData[];
  appConfig: any;
  appConfigKey: any;
  disableInput: boolean;
  handleSendMessage: any;
  chatInputText: string;
  setChatInputText: Dispatch<SetStateAction<string>>;
  handleCancel: any;
  handleBack?: () => {};
  placeholder?: string;
  onBackClick?: () => void;
  onMicStartListeningFunc?: () => void;
  onMicStopListeningFunc?: () => void;
}) {
  const navigate = useNavigate();
  const globalContext = useContext(StateContext);
  const { isMobile, setScreen, scrollContainerRef }: iGlobalContext =
    globalContext;
  return (
    <div className={styles.superContainer}>
      <div
        ref={scrollContainerRef}
        className={styles.mainContainer}
        style={{
          justifyContent: chatData?.length > 0 ? "flex-start" : "flex-end",
        }}
      >
        <IconButton
          className={styles.backIconBtn}
          onClick={() => {
            if (onBackClick) onBackClick();
            if (isMobile) {
              navigate("/today");
              return;
            }
            setScreen("");
          }}
        >
          <img src={backIcon} alt="back button" />
          Back
        </IconButton>
        {chatData?.length === 0 && (
          <div className={styles.logoContainer}>
            <img
              className={styles.logo}
              src={exploreCityLogo}
              alt="assistanceIcon"
            />
            <h1>{title}</h1>
          </div>
        )}
        <div className={styles.sectionContainer}>
          <div className={styles.systemChatIcon}>
            <img
              className={styles.chatIcon}
              alt="chat logo"
              src={zenvoyaChatIcon}
            />
            <h3 className={styles.heading}>zenvoya</h3>
          </div>
          <h1 className={styles.headingInfo}>{headingText}</h1>
          <div
            className={styles.constText}
            dangerouslySetInnerHTML={{
              __html:
                appConfig?.length > 0
                  ? appConfig?.filter(
                      (ele: iAppConfig) => ele.key === appConfigKey
                    )[0]?.value
                  : "",
            }}
          />
        </div>
        {chatData.map((eleChatData: iChatData, index: number) => (
          <div key={index}>
            {!eleChatData?.singleSelectedDestination && (
              <ChatContent
                paddingLeft={true}
                isUserMessage={eleChatData.isUserMessage}
                message={eleChatData.message}
                preText={eleChatData.preText}
                isLoading={
                  eleChatData?.destinationList?.length > 0
                    ? false
                    : eleChatData.isLoading
                }
              />
            )}
          </div>
        ))}
      </div>

      <div
        className={styles.inputContainer}
        // style={{ position: chatData?.length > 0 ? "absolute" : "relative" }}
      >
        <InputWithMic
          // disable={disableInput}
          onSend={() => handleSendMessage(chatInputText)}
          onStopRecording={() => handleSendMessage(chatInputText)}
          value={chatInputText}
          setValue={setChatInputText}
          handleCancel={handleCancel}
          placeholder={placeholder}
          // disableSendBtn={chatInputText === "" ? true : false}
          onMicStartListeningFunc={onMicStartListeningFunc}
          onMicStopListeningFunc={onMicStopListeningFunc}
          // disableSendBtn={chatInputText === "" ? true : false}
          disableSendBtn={false}
        />
      </div>
    </div>
  );
}
