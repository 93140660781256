import React, { useState } from "react";
import styles from "./custom.module.scss";

interface CustomNewCheckBoxProps {
  readonly name: string;
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly onChange?: (checked: boolean) => void;
}

function CustomNewCheckBox({
  name,
  checked = false,
  disabled = false,
  onChange,
}: CustomNewCheckBoxProps): JSX.Element {
  const [selected, setSelected] = useState<boolean>(checked);

  const handleChange = () => {
    const newChecked = !selected;
    setSelected(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <section className={styles.inputBox}>
      <input
        type="checkbox"
        name={name}
        checked={selected}
        onChange={handleChange}
        className={styles.customCheckbox}
        disabled={disabled}
      />
      <span className={styles.checkmark}></span>
    </section>
  );
}

export default CustomNewCheckBox;
