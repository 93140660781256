import { useContext, useEffect, useState } from "react";
import { calenderRangeLight } from "../../../../constant";
import { BookingDetails } from "../../../../types";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import SingleDatePickerPopover from "../../bookingPopover/singleDatePickerPopover/SingleDatePickerPopver";
import FormatInputToUTC from "../../../../hooks/utcDateFormater";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import styles from "./input.module.scss";
import { getDaysBetweenDates } from "../../../../utils/booking/getDaysBetweenDates";
import { toastMessage } from "../../../../utils/ToastProvider";
import { formatDisplayDate } from "../../../../utils/newBookingInputFieldDateFormater";

interface iProps {
  className?: string;
  startDate: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  handleUpdateData: () => void;
  label: string;
}

const InputDatePicker: React.FC<iProps> = ({
  className = "",
  bookingDetails,
  setBookingDetails,
  handleUpdateData,
  label,
}) => {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [dateRangeValues, setDateRangeValues] = useState<string | null>(null);
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (value: Date | null) => {
    if (!value) {
      return;
    }

    const formattedEndDate = formatDate(value);
    const { travelFromDate } = bookingDetails;

    if (travelFromDate === formattedEndDate) {
      toastMessage.error("Start date and end date cannot be same!");
      return;
    }
    const numberOfDays = getDaysBetweenDates(travelFromDate, formattedEndDate);

    setBookingDetails((prev) => ({
      ...prev,
      travelToDate: formattedEndDate,
      numberOfDays,
      update: true,
    }));
  };

  useEffect(() => {
    if (bookingDetails.travelToDate === "") {
      return;
    }
    handleUpdateData();
  }, [bookingDetails.travelToDate]);

  const handleApplyButton = () => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_travel_date_apply_click);
    const { number_of_days } = selectedChatData;
    const days = number_of_days;
    if (dateRangeValues && days) {
      const date = new Date(dateRangeValues);
      handleDateChange(date);
    } else {
      console.error("No date selected");
    }
  };
  useEffect(() => {
    const updateStartDate = () => {
      const { number_of_days } = selectedChatData || {};
      const days = number_of_days;
      const travelToDate = bookingDetails?.travelToDate;
      if (!travelToDate) {
        const today = new Date();
        const nextMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          today.getDate() + Number(days)
        );

        const formattedNextMonthDate = formatDate(nextMonth);
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          travelToDate: formattedNextMonthDate,
          update: true,
        }));

        // Set date range values
        setDateRangeValues(`${formattedNextMonthDate}T00:00:00`);

        // If days exist, handle date
        if (days) {
          handleDateChange(new Date(`${formattedNextMonthDate}T00:00:00`));
        }
      } else {
        // Ensure consistent ISO format for existing travelFromDate
        const formattedDate =
          new Date(travelToDate).toISOString().split("T")[0] + "T00:00:00";
        setDateRangeValues(formattedDate);

        if (days) {
          handleDateChange(new Date(formattedDate));
        }
      }
    };
    updateStartDate();
  }, [bookingDetails?.travelToDate]);

  useEffect(() => {
    if (bookingDetails.travelToDate) {
      const date = new Date(bookingDetails.travelToDate);
      const formattedDate = date.toISOString().split("T")[0] + "T00:00:00";
      handleDateChange(new Date(formattedDate));
    }
  }, [bookingDetails?.travelToDate]);

  return (
    <div>
      <SingleDatePickerPopover
        value={dateRangeValues}
        setValue={setDateRangeValues}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        handleApplyButton={handleApplyButton}
        popoverTrigger={
          <div className={`${className} ${styles.main}`}>
            <p className={styles.label}>{label}</p>
            <div
              className={styles[isPopoverOpen ? "activeTravelBtn" : "btn"]}
              onClick={() => {
                triggerGAEvent(GA_LABEL_ENUMS.booking_downward_accordion_click);
              }}>
              <img
                src={calenderRangeLight}
                alt="icon"
                className={styles.calendarIcon}
              />
              {bookingDetails.travelToDate ? (
                <p className={styles.btnName}>
                  {formatDisplayDate(bookingDetails.travelToDate)}
                </p>
              ) : (
                <p className={styles.btnName}></p>
              )}{" "}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default InputDatePicker;
