import React, { useState } from "react";
import styles from "./RoomWithImages.module.scss";
import ImageSlider from "../../imageSlider/imageSlider";
import {
  circleCheckIcon,
  circleInfoLight,
  expandIcon,
  eyeLight,
  greenOutlinedTickMark,
} from "../../../../../constant";
import { iSelectedHotelRoom } from "../../../../../context/globalContext/interface";
import Button from "../../../../button/button";

interface iProps {
  room: any;
  setShowRoomDetails: (value: React.SetStateAction<boolean>) => void;
  setRoomDetailsData: (value: any) => void;
  selectedHotelRoom: iSelectedHotelRoom | null | undefined;
  isChecking: boolean;
  checkHotelAvailability: (
    hotelUniqueId: string,
    sessionId: string,
    type: string,
    price: number,
    actualPrice: number,
    feesIncluded: boolean,
    taxIncluded: boolean
  ) => Promise<void>;
  sessionId: string;
}

function RoomWithImages(props: iProps): JSX.Element {
  const {
    room,
    setShowRoomDetails,
    setRoomDetailsData,
    selectedHotelRoom,
    checkHotelAvailability,
    isChecking,
    sessionId,
  } = props || {};
  const {
    roomImages = [],
    roomName = "",
    roomPrices: roomTypes = [],
    roomId = "",
  } = room || {};

  const [roomPricesLength, setRoomPricesLength] = useState(1);

  return (
    <div key={roomId} className={styles.mainRoomContainer}>
      {roomImages.length > 0 && (
        <ImageSlider
          className={styles.imageClass}
          noPaddSwipeBtn
          imagesArr={roomImages.map((img: any) => img.url)}
        />
      )}

      <div className={styles.viewRoomDetailsContainer}>
        <p className={styles.roomType}>{roomName}</p>
        <div
          className={styles.amenitiesFooter}
          // onClick={() => {}}
          onClick={() => {
            // setViewRoomDetailsModal(true);
            setShowRoomDetails(true);
            setRoomDetailsData(room);
          }}
        >
          VIEW ROOM DETAILS
          <img src={eyeLight} alt="icon" className={styles.amenityFooterIcon} />
        </div>
      </div>

      {roomTypes.slice(0, roomPricesLength).map((item: any) => {
        const {
          rateType: type,
          // includesArr = [],
          sellingPrice: price,
          cancellationPolicy: discountValidity = "",
          suggestedSellingPrice: actualPrice = "",
          taxIncluded = "",
          feesIncluded = "",
          uniqueId = "",
          roomOnly = false,
        } = item || {};

        const discountPercentage = Math.round(
          ((actualPrice - price) * 100) / actualPrice
        );
        return (
          <div key={uniqueId} className={styles.main}>
            <div className={styles.container}>
              <div className={styles.contentCard}>
                <div className={styles.hotelListCard}>
                  <div className={styles.card1}>
                    <div className={styles.typeName}>
                      {type}
                      <img
                        src={circleInfoLight}
                        className={styles.tickMarkIcon}
                        alt="green tick mark"
                      />
                    </div>
                    {!roomOnly && (
                      <div className={styles.discountAndPriviliges}>
                        {/* {discountValidity && (
                        <div className={styles.discountValidity}>
                          {discountValidity}
                        </div>
                      )} */}
                        {/* {!roomOnly && ( */}
                        <div className={styles.includesArrContainer}>
                          {/* {includesArr.map((eachItem: any, idx: any) => {
                            return ( */}
                          <div className={styles.eachIncludes}>
                            <img
                              src={circleCheckIcon}
                              className={styles.tickMarkIcon}
                              alt="green tick mark"
                            />
                            Breakfast included
                          </div>
                          {/* );
                          })} */}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.card3Andcard4Container}>
                    {Number(discountPercentage) > 0 && (
                      <div className={styles.card3}>
                        <div className={styles.discount}>
                          {discountPercentage} % off
                        </div>

                        <div className={styles.actualPrice}>
                          ${Math.round(actualPrice)}
                        </div>
                      </div>
                    )}

                    <Button
                      className={styles.bookBtn}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isChecking) return;
                        checkHotelAvailability(
                          uniqueId,
                          sessionId,
                          type,
                          price,
                          actualPrice,
                          feesIncluded,
                          taxIncluded
                        );
                      }}
                    >
                      +${Math.round(price)}
                    </Button>
                    {/* <div className={styles.card4}>
                      <div className={styles.priceAndFlightContainer}>
                        <p className={styles.price}>${Math.round(price)}</p>

                        <span className={styles.flightClass}>
                          {taxIncluded ? "inc." : "excl."} Taxes
                        </span>

                        {!feesIncluded && (
                          <span className={styles.cancellation}>
                            + Property fees
                          </span>
                        )}
                      </div>

                      <div
                        className={`${uniqueId === selectedHotelRoom?.selectedRoomId ? styles.selectedBtn : styles.bookBtn}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (isChecking) return;
                          checkHotelAvailability(
                            uniqueId,
                            sessionId,
                            type,
                            price,
                            actualPrice,
                            feesIncluded,
                            taxIncluded
                          );
                        }}
                      >
                        {uniqueId === selectedHotelRoom?.selectedRoomId
                          ? "Booked"
                          : "Book"}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {roomTypes.length > 1 && roomPricesLength < roomTypes.length && (
        <p
          className={styles.showMoreContainer}
          onClick={() =>
            setRoomPricesLength(
              roomTypes.length - roomPricesLength > 3
                ? roomPricesLength + 3
                : roomTypes.length
            )
          }
        >
          Show{" "}
          {roomTypes.length - roomPricesLength > 3
            ? "3"
            : roomTypes.length - roomPricesLength}{" "}
          more rates
          <img
            src={expandIcon}
            className={styles.expandIcon}
            alt="expand-icon"
          />
        </p>
      )}
      {roomTypes.length > 1 && roomPricesLength === roomTypes.length && (
        <p
          className={styles.showMoreContainer}
          onClick={() => setRoomPricesLength(1)}
        >
          Show less
          <img
            src={expandIcon}
            className={styles.rotateExpandIcon}
            alt="expand-icon"
          />
        </p>
      )}
    </div>
  );
}

export default RoomWithImages;
