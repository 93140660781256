import { useEffect, useState } from "react";
import { BookingDetails } from "../../../../types";
import DateRangePicker from "../../inputFields/dateRangePicker/dateRangePicker";
import Duration from "../../inputFields/duration/duration";
import SearchButton from "../../inputFields/searchButton/searchButton";
import Travelers from "../../inputFields/travelers/travelers";
import PropertyType from "../../inputFields/propertyType/propertyType";
import CityLocation from "../../inputFields/location/location";
import Stepper from "./stepper/stepper";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { calculateTotalDays } from "../../../../utils/hotelStayCalculator";
import styles from "./hotel.module.scss";
interface iProps {
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  searchHotels: () => void;
  // cities: iHotelInputPayload[];
  handleUpdateTravelData: () => {};
}

function HotelInputFields({
  bookingDetails,
  setBookingDetails,
  searchHotels,
  // cities,
  handleUpdateTravelData,
}: iProps): JSX.Element {
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const handleHotelInputValidation = () => {
    const { adultsCount, childrenCount, seniorsCount, travelFromDate } =
      bookingDetails || {};

    // const checkDepartureDate = airPort?.some((flight) => flight?.departureDate);

    if (travelFromDate === "") {
      toastMessage.error("", "Please select a travel date.");
      setEnableBtn(true);
      return;
    }

    const totalAdults = adultsCount + seniorsCount;

    if (totalAdults === 0) {
      toastMessage.error("", "At least 1 adult or senior is required.");
      setEnableBtn(true);
      return;
    }

    if (childrenCount > 0 && totalAdults === 0) {
      toastMessage.error(
        "",
        "A child must be accompanied by at least 1 adult or senior."
      );
      setEnableBtn(true);
      return;
    }

    searchHotels();
  };

  useEffect(() => {
    const {
      adultsCount,
      childrenCount,
      seniorsCount,
      numberOfRoomCount,
      travelFromDate,
    } = bookingDetails || {};
    const isCitiesInputValid =
      Array.isArray(bookingDetails.hotels) &&
      bookingDetails.hotels.length > 0 &&
      !bookingDetails.hotels.some(
        (hotel) =>
          hotel.city === "" ||
          hotel.latitude === "" ||
          hotel.longitude === "" ||
          hotel.country === ""
      );

    const shouldDisableButton = !(
      travelFromDate &&
      isCitiesInputValid &&
      (adultsCount || childrenCount || seniorsCount) &&
      numberOfRoomCount > 0
    );

    setEnableBtn(shouldDisableButton);
  }, [bookingDetails]);

  const hotelStay = calculateTotalDays(bookingDetails.hotels);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.newInputCard1}>
        <DateRangePicker
          startDate={bookingDetails.travelFromDate}
          endDate={bookingDetails.airportTo}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          // handleUpdateData={handleUpdateData}
          handleUpdateData={() => {
            handleUpdateTravelData();
          }}
        />

        {bookingDetails.tripType !== 1 && (
          <div className={styles.card3}>
            <Duration
              bookingDetails={bookingDetails}
              flight={false}
              hotel={true}
              stayCount={hotelStay}
            />
          </div>
        )}

        <div className={styles.card1}>
          <Travelers
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            showRoom={true}
          />
        </div>
      </div>
      <div className={styles.newInputCard2}>
        <div className={styles.card4}>
          <PropertyType
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
          />
        </div>

        {bookingDetails.hotels.length < 2 && (
          <div className={styles.card2}>
            <CityLocation />
          </div>
        )}
      </div>
      {bookingDetails.hotels.length > 1 && (
        <div className={styles.stepper}>
          <section className={styles.stepperDetails}>
            <Stepper
              steps={bookingDetails.hotels}
              initialStep={0}
              finalStep={bookingDetails.hotels.length - 1}
            />
          </section>
        </div>
      )}
      <div
        className={`${bookingDetails.hotels.length > 1 ? styles.searchBox : styles.singleCitySearch}`}>
        <SearchButton
          onClick={handleHotelInputValidation}
          disableButton={enableBtn}
        />
      </div>
    </div>
  );
}

export default HotelInputFields;
