import React, { ReactNode, useEffect, useMemo, useState } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import CustomChip from "../../../reusableComponent/customChip/CustomChip";
import { BookingDetails } from "../../../../types";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import styles from "./routeClassPopover.module.scss";

export default function RouteClassPopover({
  popoverTrigger,
  route,
  setRoute,
  classData,
  setClass,
  bookingDetails,
  setBookingDetails,
  isPopoverOpen,
  setIsPopoverOpen,
  hideRoute,
}: {
  readonly popoverTrigger: ReactNode;
  readonly route: any;
  readonly setRoute: any;
  readonly classData: any;
  readonly setClass: any;
  readonly bookingDetails: BookingDetails;
  readonly setBookingDetails: React.Dispatch<
    React.SetStateAction<BookingDetails>
  >;
  readonly isPopoverOpen: boolean;
  readonly setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly hideRoute?: boolean;
}) {
  const [tempRoute, setTempRoute] = useState(route);
  const [tempClassData, setTempClassData] = useState(classData);

  function handleChangeClass(id: any) {
    triggerGAEvent(GA_LABEL_ENUMS.booking_route_and_class_class_click);
    const updatedClassData = tempClassData.map((ele: any) =>
      ele.id === id
        ? { ...ele, checkedFlag: true }
        : { ...ele, checkedFlag: false }
    );
    setTempClassData(updatedClassData);
  }

  function handleChangeRoute(id: any) {
    const updatedRouteData = tempRoute.map((ele: any) =>
      ele.id === id
        ? { ...ele, checkedFlag: true }
        : { ...ele, checkedFlag: false }
    );
    setTempRoute(updatedRouteData);
  }

  function handleApply() {
    triggerGAEvent(GA_LABEL_ENUMS.booking_route_and_class_apply_click);

    // Apply class changes
    setClass(tempClassData);
    const selectedClass = tempClassData.find((ele: any) => ele.checkedFlag);
    let flightClass = "";
    if (selectedClass) {
      switch (selectedClass.value.trim()) {
        case "Economy":
          flightClass = "E";
          break;
        case "Premium Plus":
          flightClass = "P";
          break;
        case "Business":
          flightClass = "B";
          break;
        case "First Class":
          flightClass = "F";
          break;
        default:
          flightClass = "";
      }
    }

    // Apply route changes
    setRoute(tempRoute);
    const selectedRoute = tempRoute.find((ele: any) => ele.checkedFlag);
    let tripType = 1;
    if (selectedRoute) {
      switch (selectedRoute.value.trim()) {
        case "One-Way":
          tripType = 1;
          break;
        case "Round-Trip":
          tripType = 2;
          break;
        case "Multi-City":
          tripType = 3;
          break;
        default:
          tripType = 1;
      }
    }

    // Update booking details
    setBookingDetails((prevData) => ({
      ...prevData,
      flightClass,
      tripType,
      update: true,
    }));

    // Close the popover
    setIsPopoverOpen(false);
  }
  //Auto Update class if any saved prev
  const memoizedClassData = useMemo(() => classData, [classData]);
  useEffect(() => {
    if (memoizedClassData && bookingDetails?.flightClass) {
      const updatedClassData = memoizedClassData.map((ele: any) => ({
        ...ele,
        checkedFlag: ele.value.startsWith(bookingDetails.flightClass),
      }));

      setTempClassData(updatedClassData);
    }
  }, []);

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          {hideRoute ? null : (
            <div className={styles.container}>
              <h1 className={styles.heading}>Route</h1>
              <div className={styles.routeCard}>
                {tempRoute?.length > 0 &&
                  tempRoute.map((ele: any) => (
                    <CustomChip
                      key={ele.id}
                      onClick={() => handleChangeRoute(ele.id)}
                      label={ele.value}
                      selected={ele.checkedFlag}
                    />
                  ))}
                <br />
              </div>
              <p className={styles.info}>
                This is determined by your itinerary
              </p>
            </div>
          )}
          <div className={styles.container}>
            <h1 className={styles.heading}>Class</h1>
            <div className={styles.classChipContainer}>
              {tempClassData?.length > 0 &&
                tempClassData.map((ele: any) => (
                  <div key={ele.id}>
                    <CustomChip
                      onClick={() => handleChangeClass(ele.id)}
                      label={ele.value}
                      selected={ele.checkedFlag}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      }
      handleApplyButton={handleApply}
    />
  );
}
