import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { APP_URL } from "../../constant/environment";
import styles from "./EmailVerificationMessage.module.scss";
import { fetchEmailVerificationApi } from "../../api/createAccount/emailVerification";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";

const EmailVerificationMessage = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const checkTokenStatus = async (token: string) => {
      try {
        const response = await fetchEmailVerificationApi(token);
        console.log(response, "response");

        if (response?.statusCode === 200) {
          setMessage(TOAST_MESSAGES.AUTHENTICATION.verifyEmail.message);
        } else {
          setMessage(TOAST_MESSAGES.AUTHENTICATION.emailExpire.message);
        }
      } catch (error) {
        setMessage(TOAST_MESSAGES.ApiBreakdown.message);
      } finally {
        setLoading(false);
      }
    };

    const token = searchParams.get("token");

    if (token) {
      checkTokenStatus(token);
    } else {
      setMessage(TOAST_MESSAGES.ClientSide400.message);
      setLoading(false);
    }
  }, [searchParams]);

  const handleOkClick = () => {
    window.location.href = APP_URL;
  };

  return (
    <section className={styles.container}>
      {loading ? (
        <p className={styles.message}>Loading...</p>
      ) : (
        <div className={styles.emailVerificationMessage}>
          <p className={styles.message}>{message}</p>
        </div>
      )}
      {!loading && (
        <button
          className={styles.okButton}
          onClick={handleOkClick}
          disabled={loading}
        >
          OK
        </button>
      )}
    </section>
  );
};

export default EmailVerificationMessage;
