import { useContext } from "react";
import {  profileIcon, avatarIcon } from "../../../constant";
import { CHAT_BOT_NAME } from "../../../constant/common";
import styles from "./initial.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";

interface iProps {
  message: string;
  showUser?: boolean;
  maxWidth?: string;
}

function InitialChat({
  message,
  showUser = true,
  maxWidth = "360px",
}: iProps): JSX.Element {
  const context = useContext(StateContext);
  const { userInfo }: iGlobalContext = context;
  return (
    <div className={styles.main}>
      <div
        className={styles.container}
        // style={{ maxWidth: maxWidth }}
      >
        {showUser && (
          <div className={styles.userCard}>
            <div className={styles.avatarBox}>
              <img
                alt=""
                className={styles.userAvatar}
                src={userInfo?.profile_pic ? userInfo?.profile_pic : profileIcon}
              />
              <p className={styles.userName}>YOU</p>
            </div>
            <p className={styles.message}>{message.trim()}</p>
          </div>
        )}
        <div className={styles.zenCard}>
          <img
            alt=""
            className={styles.zenAvatar}
            // src={zenvoyaChatIcon}
            src={avatarIcon}
          />
          <p className={styles.zenTitle}>{CHAT_BOT_NAME.name}</p>
        </div>
      </div>
    </div>
  );
}

export default InitialChat;
