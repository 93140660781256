import { iHotelInputPayload } from "../types";

export const calculateTotalDays = (
  data: iHotelInputPayload[] | undefined | null
) => {
  if (!data || data.length === 0) {
    return 0;
  }

  return data?.reduce((totalDays, stay) => {
    const checkInDate = new Date(stay.checkIn);
    const checkOutDate = new Date(stay.checkOut);

    if (isNaN(checkInDate.getTime()) || isNaN(checkOutDate.getTime())) {
      return totalDays;
    }

    if (checkOutDate < checkInDate) {
      return totalDays;
    }

    const diffInTime = checkOutDate.getTime() - checkInDate.getTime(); // Difference in milliseconds
    const diffInDays = diffInTime / (1000 * 60 * 60 * 24); // Convert to days

    return totalDays + diffInDays + 1; // Include the checkOut day
  }, 0);
};
