import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Chip, FormControl, RadioGroup } from "@mui/material";
import {
  backIcon,
  expandIcon,
  ROUTES,
  updatedSeeMoreIcon,
} from "../../constant";
import CustomCheckbox from "../../component/booking/bookingCheckBox/CustomCheckbox";
import FlightListing from "../../component/booking/flightBooking/flightListing/flightListing";
import SelectedOptions from "../../component/booking/selectedOptions/selectedOptions";
import HotelListing from "../../component/booking/hotelBooking/hotelListing/hotelListing";
import FlightSelectButtons from "../../component/booking/flightSelectButton/flightSelectButton";
import BookedSuccessDialog from "../../component/dialog/bookedSuccessDialog/BookedSuccessDialog";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/globalContext/context";
import {
  iGlobalContext,
  iSelectedHotelRoomArr,
} from "../../context/globalContext/interface";
import convertToCustomTime from "../../hooks/flightTimeFormater";
import Loader from "../../component/reusableComponent/loader/Loader";
import NoData from "../../component/booking/noData/noData";
import { searchFlights, searchHotels } from "../../api/booking/booking";
import styles from "./book.module.scss";
import { BookingDetails, iHotelInputPayload } from "../../types";
import DiscardChanges from "../../component/dialog/discardChanges/discardChanges";
import { toastMessage } from "../../helpers/toast/toastMessage";
import HotelInputFields from "../../component/booking/hotelBooking/hotelSingleCityInputFields/hotelInputFields";
import FlightInputFields from "../../component/booking/flightBooking/flightSingleCityInputFields/flightInputFields";
import FlightHotelInputFields from "../../component/booking/flightBooking/flightHotelSingleCityInputFields/flightHotelInputFields";
import BookingLandingPage from "../../component/booking/landingPage/landingPage";
import TravelDetails from "../../component/booking/travelDetails/travelDetails";
import { ScreenTypes } from "../../enums";
import { saveBookInputFields } from "../../api/booking/saveBookedTransactionDetailsAPI";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";
import FormatHotelListingDateFormat from "../../utils/formatHotelListingDateFormat";
import FlightHotelSelect from "../../component/booking/flightHotelSelect/flightHotelSelect";
import {
  isThisHotelRoomSelected,
  updateDaywiseTripDate,
  updateDaywiseTripDateForMetaData,
} from "../../utils";
import { useResetState } from "../../utils/resetBookingData";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { iFlightSearchResponse, iHotelSearchResponse } from "../../interface";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";
import { handleRoomAllocation } from "../../utils/handleRoomAllocation";
import { handleFlightTime } from "../../utils/booking/handleFlightTime";
import { getFlightTravelDetails } from "../../utils/booking/flight/getFlightTravelDetails";
import { transformHotelData } from "../../utils/booking/hotel/transformHotelData";
import { arrayToString } from "../../utils/booking/hotel/arrayToString";
import { getFlightTravelData } from "../../utils/booking/flight/getFlightTravelData";
import { simplifyFlights } from "../../utils/booking/flight/simplifyFlights";
import { getHotelTravelDetails } from "../../utils/booking/hotel/getHotelTravelDetails";
import { updateAirportData } from "../../utils/booking/flight/updateAirportData";
import { getPreferenceCount } from "../../utils/booking/getPreferenceCount";
import { iHistoryContext } from "../../context/historyContext/interface";
import { useHistoryContext } from "../../context/historyContext/historyContext";
import DepartureTimes from "../../component/booking/departureTimes/departureTime";

function Booking(): JSX.Element {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    bookingDetails,
    setBookingDetails,
    setScreen,
    isMobile,
    flightData,
    setFlightData,
    selectedFlightOptions,
    setSelectedFlightOptions,
    selectedChatData,
    setGetHotelRoomsBody,
    hotelData,
    setHotelData,
    selectedHotelRoomArr,
    setSelectedHotelRoomArr,
    showMore,
    setShowMore,
    setIsFetchingFlights,
    setIsFetchingHotels,
    isFetchingHotels = false,
    prevRoute,
    tripOrItinearyId,
    setSelectedChatData,
    isFetchingFlights,
    setTripOrItinearyId,
    setPrevRoute,
    setHotelPagination,
    hotelPagination,
    userInfo,
    showBookingPreferences,
    setShowBookingPreferences,
    setIsFetchingAirports,
    setNoFlightFound,
  }: iGlobalContext = state;
  const { setCallSaveHistoryAPIFlag }: iHistoryContext = useHistoryContext();

  const [showDetails, setShowDetails] = useState(false);
  const [checkBackButton, setCheckBackButton] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedHotelId, setSelectedHotelId] = useState<number | null>(null);
  const [openBookedSuccessDialog, setOpenBookedSuccessDialog] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [loadingCity, setLoadingCity] = useState<string | null>(null);
  const [isModified, setIsModified] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadMoreFlights, setLoadMoreFlights] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const resetState = useResetState();
  const saveBookingInfoRef = useRef(bookingDetails);
  const { handleCustomError } = useCustomErrorHandler();
  let fetchHotelController: any = useRef(null);
  let fetchFlightController: any = useRef(null);

  const filteredBookingDetails = {
    adultsCount: bookingDetails.adultsCount,
    childrenCount: bookingDetails.childrenCount,
    seniorsCount: bookingDetails.seniorsCount,
    numberOfRoomCount: bookingDetails.numberOfRoomCount,
    travelFromDate: bookingDetails.travelFromDate,
    travelToDate: bookingDetails.travelToDate,
    flightClass: bookingDetails.flightClass,
    tripType: bookingDetails.tripType,
    filters: bookingDetails?.filters?.starRating?.map((starRating) => ({
      starRating: starRating,
    })),
    // Flight Preferences
    flightConnections: bookingDetails?.flightPreferences?.connections || 0,
    flightDepartureTime:
      bookingDetails?.flightPreferences?.departureTime.map(
        (departureTime) => departureTime
      ) || [],
    refundableFlights: bookingDetails?.flightPreferences?.refundable || false,
    preferredAirlines: bookingDetails?.flightPreferences?.airlines || [],

    // Hotel Preferences
    refundableHotels: bookingDetails?.hotelPreferences?.refundable || false,
    hotelChains: bookingDetails?.hotelPreferences?.hotelChains || [],
    hotels: bookingDetails.hotels.map((city) => ({
      city,
    })),
    airPort: bookingDetails.airPort.map(({ arrival, departure }) => ({
      arrival,
      departure,
    })),
  };
  const prevBookingDetailsRef = useRef(
    bookingDetails ? filteredBookingDetails : null
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_flight_hotel_checkbox_click);

    const { name, checked } = event.target;

    setSelectedFlightOptions((prevState) => {
      // Ensure at least one option is always selected
      if (!checked && Object.values(prevState).filter(Boolean).length === 1) {
        return prevState;
      }

      return {
        ...prevState,
        [name]: checked,
      };
    });
  };

  //Reset form data
  const handleResetFormDate = async () => {
    setCheckBackButton(true);
    await resetState();

    setSelectedHotelRoomArr([]);
    setSelectedFlightOptions({ flights: true, hotels: false });
    handleBackButton();
  };

  //Get and Save Itinerary

  const getItineraryDetails = async () => {
    setShowLoader(true);
    let itineraryId =
      prevRoute === ROUTES.MyTrips ? tripOrItinearyId : selectedChatData.id;

    if (!itineraryId) {
      setShowLoader(false);
      return;
    }

    if (prevRoute === ROUTES.MyTrips) setTripOrItinearyId("");

    try {
      const response = await getItineraryDetailsAPI(itineraryId);

      if (response?.statusCode !== 200) {
        handleCustomError(response?.statusCode, "itinerary.details");
        setShowLoader(false);
        setIsFetchingAirports(false);
        return;
      }
      const updatedBookingDetails = {
        ...bookingDetails,
        ...response.bookFilter,
        airPort: response.bookFilter?.airPort
          ? bookingDetails.airPort.map((airport, index) => ({
              ...airport,
              ...response.bookFilter.airPort[index],
            }))
          : bookingDetails.airPort,
      };

      setBookingDetails(updatedBookingDetails);

      // Mark data as loaded after successful fetch
      setIsFetchingAirports(false);
    } catch (error) {
      console.error("Error fetching itinerary details:", error);
      setIsFetchingAirports(false);
    } finally {
      setShowLoader(false);
      setIsFetchingAirports(false);
    }
  };

  useEffect(() => {
    getItineraryDetails();
  }, [tripOrItinearyId]);

  const handleSaveBookingInfo = useCallback(async (): Promise<boolean> => {
    const bookFilter = bookingDetails;
    const userId = userInfo?.id;
    const status = "draft";
    const metaData: any = {
      selectedChatData: {
        ...selectedChatData,
        prompt: [],
        postText: "",
        preText: "",
      },
    };

    try {
      setIsModified(true);
      const response = await saveBookInputFields(
        selectedChatData.id,
        userId,
        metaData,
        bookFilter,
        status
      );
      setCallSaveHistoryAPIFlag({
        flag: true,
        method: "PUT",
      });
      console.log("Save successful:", response);
      return true;
    } catch (error) {
      console.error("Save failed:", error);
      return false;
    }
  }, [bookingDetails, selectedChatData, userInfo]);

  useEffect(() => {
    const prevBookingDetails = saveBookingInfoRef.current;
    if (
      !isSaving &&
      JSON.stringify(prevBookingDetails) !== JSON.stringify(bookingDetails)
    ) {
      setIsSaving(true);
      handleSaveBookingInfo()
        .then(() => {
          setIsSaving(false);
        })
        .catch((error) => {
          console.error("Error saving booking info:", error);
          setIsSaving(false);
        });
    }

    saveBookingInfoRef.current = bookingDetails;
  }, [bookingDetails, handleSaveBookingInfo, isSaving]);

  //Handle Back button
  const handleBackButton = async () => {
    setScreen(ScreenTypes.ItineraryInfo);
    // const isValid = await handleSaveBookingInfo();
    // if (!isValid) return;

    if (isMobile) {
      navigate(ROUTES.ItineraryDetails);
      setOpen(false);
      setPrevRoute("");
    }
  };

  const handleClickOpen = () => {
    bookingDetails.update ? setOpen(true) : handleBackButton();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHotelSelect = (
    hotelId: number,
    checkIn: string,
    checkOut: string,
    city: string
  ) => {
    const getHotelRoomsBody = {
      hotelId: hotelId,
      checkIn,
      checkOut,
      currency: "USD",
      noOfRooms: bookingDetails.numberOfRoomCount,
      pagination: {
        offset: 0,
        count: 1000,
      },
      city,
      roomInfo: handleRoomAllocation(
        bookingDetails.adultsCount,
        bookingDetails.childrenCount,
        bookingDetails.seniorsCount,
        bookingDetails.numberOfRoomCount
      ),
      // roomInfo: Array.from(
      //   { length: bookingDetails.numberOfRoomCount },
      //   (_obj, idx) => {
      //     return {
      //       roomNo: idx + 1,
      //       adults: bookingDetails.adultsCount,
      //       child: bookingDetails.childrenCount,
      //       seniors: bookingDetails.seniorsCount,
      //     };
      //   }
      // ),
    };
    setGetHotelRoomsBody(getHotelRoomsBody);
    setSelectedHotelId(hotelId);
  };

  //update date to itinerary

  const handleUpdateDatesToItinerary = useCallback(
    (travelDate: string) => {
      setSelectedChatData((prevData: any) => {
        const updatedDestinationList = updateDaywiseTripDateForMetaData(
          prevData?.destinationList,
          travelDate
        );

        const updatedResults = updateDaywiseTripDate(
          prevData?.results,
          travelDate
        );

        return {
          ...prevData,
          destinationList: updatedDestinationList || prevData?.destinationList,
          results: updatedResults || prevData?.results,
        };
      });
    },
    [setSelectedChatData]
  );

  //hotel related operations
  const updateDateWithHotelItinerary = getHotelTravelDetails(
    selectedChatData?.destinationList
  );

  const hotelCityPayload = transformHotelData(
    updateDateWithHotelItinerary,
    bookingDetails?.hotels?.length > 1
  );
  const updateHotelPayloadData = async () => {
    // if (bookingDetails?.isDataModified?.hotel) {
    //   return;
    // }

    const updatedHotelCityPayload = hotelCityPayload.map((hotel) => ({
      ...hotel,
      cityNotAvailable: !hotel?.city,
    }));

    setBookingDetails((prevData) => ({
      ...prevData,
      isDataModified: {
        ...prevData.isDataModified,
        hotel: true,
      },
      hotels: updatedHotelCityPayload,
    }));
  };

  useEffect(() => {
    if (hotelCityPayload[0]?.checkIn) {
      updateHotelPayloadData();
    }
  }, [hotelCityPayload[0]?.checkIn]);

  const citiesPayload = bookingDetails.hotels.map(
    ({ cityNotAvailable, ...exceptCityNotAvailable }) => exceptCityNotAvailable
  );

  // flight related operations
  const flightTravelDetailsFromItinerary = getFlightTravelDetails(
    selectedChatData?.destinationList
  );

  const handleFlightSearch = async (count: number) => {
    const flights = simplifyFlights(bookingDetails?.airPort, bookingDetails);
    if (flights?.some((flight) => !flight.arrival || !flight.departure)) {
      toastMessage.error(
        "",
        "Please select both arrival and departure airports"
      );
      return;
    }

    // Check for same arrival and departure airports
    if (flights?.some((flight) => flight.arrival === flight.departure)) {
      setShowDetails(false);
      toastMessage.error(
        "",
        "Arrival and departure airports cannot be the same"
      );
      return;
    }

    // loading state
    if (count < 4) {
      setLoading(true);
      setLoadMoreFlights(false);
    }
    setShowDetails(true);
    const flightTime = handleFlightTime(bookingDetails.travelJourneyData);
    // payload
    const requestBody = {
      userId: userInfo?.id,
      pagination: { offset: count, count: 4 },
      adults: bookingDetails.adultsCount,
      children: bookingDetails.childrenCount,
      infants: 0,
      seniors: bookingDetails.seniorsCount,
      tripType: bookingDetails.tripType,
      cabin: bookingDetails.flightClass,
      currency: "USD",
      segments: flights,
      filters: {
        airlineInclude:
          bookingDetails.flightPreferences?.airlines?.join(", ") || null,
        refundable: bookingDetails?.flightPreferences?.refundable ?? null,
        daypart: flightTime || null,
        stopsCount: bookingDetails?.flightPreferences?.connections || null,
      },
    };

    // Save previous booking details for reference
    prevBookingDetailsRef.current = filteredBookingDetails;

    try {
      if (count === 0) setIsFetchingFlights(true);

      // Create an AbortController for this request
      fetchFlightController.current = new AbortController();
      const FlightData: iFlightSearchResponse = await searchFlights(
        requestBody,
        fetchFlightController.current
      );
      setIsModified(true);
      // Handle errors based on status code
      if (FlightData?.statusCode !== 200) {
        handleCustomError(FlightData?.statusCode, "flight.search");
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.message
        );
        return null;
      }

      // Handle empty sectors
      const newSectors = FlightData?.data?.sectors || [];
      if (newSectors.length === 0) {
        setLoadMoreFlights(false);
        setNoFlightFound(true);
        setIsFetchingFlights(false);
        // toastMessage.error(
        //   TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.messageTitle,
        //   TOAST_MESSAGES.BOOKING.Search.NoFlightsFound.message
        // );
        return;
      }

      // Update flight data and booking details
      setFlightData((prevData: any) => {
        const prevSectors = prevData?.sectors || [];
        if (newSectors.length < 4) {
          setBookingDetails((prevData) => ({
            ...prevData,
            noMoreFlights: true,
          }));
        }
        return {
          ...prevData,
          sectors: [...prevSectors, ...newSectors],
          encryptedKey: FlightData?.data?.encryptedKey,
          pagination: FlightData?.data?.pagination,
        };
      });

      if (count === 0) setIsFetchingFlights(false);

      // Update visibility for pagination
      setShowMore((prevShowMore) => ({
        ...prevShowMore,
        visibleList: {
          ...prevShowMore.visibleList,
          flight: count,
        },
      }));
    } catch (err) {
      if (err instanceof Error) {
        if (
          err?.name === "AbortError" ||
          err.message.includes("signal is aborted")
        ) {
          console.log("Flight search request was canceled by the user.");
          return;
        }

        // other errors
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.FlightSearchFailure.message
        );
      } else {
        console.error("An unexpected error occurred:", err);
      }

      // Reset state on failure
      if (count === 0) setIsFetchingFlights(false);
      setLoadMoreFlights(false);
      setFlightData(null);
    } finally {
      if (count === 0) setIsFetchingFlights(false);
      setLoading(false);
      setLoadMoreFlights(false);
    }
  };

  const handleHotelSearch = async (
    count: number,
    cities: iHotelInputPayload[],
    isFetchingFreshly: boolean = false,
    hotelUniqueId?: string
  ) => {
    if (!cities || cities?.length === 0) {
      return toastMessage.error("", "Please select a cities to search");
    }

    setShowDetails(true);
    // const hoteRatings = bookingDetails.filters[0]
    const requestBody = {
      userId: userInfo?.id,
      pagination: {
        offset: count,
        count: 4,
        uniqueId: hotelUniqueId,
      },
      // cities: groupConsecutiveStays(modifiedData),
      cities: cities,
      currency: "USD",
      noOfRooms: bookingDetails.numberOfRoomCount,
      roomInfo: handleRoomAllocation(
        bookingDetails.adultsCount,
        bookingDetails.childrenCount,
        bookingDetails.seniorsCount,
        bookingDetails.numberOfRoomCount
      ),
      filters: {
        hotelChain:
          bookingDetails?.hotelPreferences?.hotelChains?.toString() || null,
        starRating: arrayToString(bookingDetails?.filters?.starRating),
        refundable: bookingDetails?.hotelPreferences?.refundable ?? null,
      },
    };
    prevBookingDetailsRef.current = filteredBookingDetails;
    try {
      if (isFetchingFreshly) setIsFetchingHotels(true);
      fetchHotelController.current = new AbortController();
      const response: iHotelSearchResponse = await searchHotels(
        requestBody,
        fetchHotelController.current
      );
      setIsModified(true);
      if (response.statusCode !== 200) {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
        );
        handleCustomError(response?.statusCode, "hotel.search");
        return null;
      }
      if (response?.data?.pagination) {
        setBookingDetails((prevBookingDetails) => ({
          ...prevBookingDetails,
          hotelUniqueId: response?.data?.pagination.uniqueId || "",
          visibleList: {
            ...showMore.visibleList,
            hotel: response?.data?.pagination.offset,
          },
        }));
      }

      if (response?.data?.data?.length > 0) {
        const updateReceivedData = Array?.isArray(response?.data)
          ? response?.data
          : response?.data?.data;
        setHotelData((prevHotelData: any) => {
          if (!prevHotelData || !prevHotelData.data) {
            // Initialize if no previous data
            return { data: updateReceivedData };
          }

          // Append new data without replacing old data
          const updatedHotelData = [...prevHotelData.data];

          updateReceivedData.forEach((newItemHotel: any) => {
            const existingHotelIndex = updatedHotelData?.findIndex(
              (itemHotel: any) =>
                itemHotel.city === newItemHotel?.city &&
                itemHotel.checkIn === newItemHotel?.checkIn &&
                itemHotel.checkOut === newItemHotel?.checkOut
            );

            if (existingHotelIndex > -1) {
              // If the city and check-in/out match, append the hotels to the existing entry
              updatedHotelData[existingHotelIndex] = {
                ...updatedHotelData[existingHotelIndex],
                hotels: [
                  ...updatedHotelData[existingHotelIndex].hotels,
                  ...newItemHotel.hotels, // Append new hotels
                ],
              };
            } else {
              // If city/check-in/out doesn't exist, add the new entry
              updatedHotelData.push(newItemHotel);
            }
          });

          return {
            ...prevHotelData,
            data: updatedHotelData, // Return updated data with new hotels appended
          };
        });

        // const selectedHotelRoomSet =
        //   selectedHotelRoomArr?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];

        // const fetchedHotelArgSet =
        //   response?.data?.map((item: any) => ({
        //     checkIn: item?.checkIn,
        //     checkOut: item?.checkOut,
        //     city: item?.city,
        //   })) || [];
        // // console.log(
        //   selectedHotelRoomArr,
        //   selectedHotelRoomSet,
        //   fetchedHotelArgSet,
        //   areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet),
        //   "testing"
        // );

        if (
          // !areTwoArrObjectsEqual(selectedHotelRoomSet, fetchedHotelArgSet) &&
          isFetchingFreshly
          // &&          isThisAMulticityTrip
        ) {
          setSelectedHotelRoomArr(
            response?.data?.data?.map(
              (item: any): iSelectedHotelRoomArr => ({
                checkIn: item?.checkIn,
                checkOut: item?.checkOut,
                city: item?.city,
                selectedHotelRoom: null,
              })
            )
          );
          setIsFetchingHotels(false);
        }
        setShowMore((prevShowMore) => ({
          ...prevShowMore,
          visibleList: {
            ...prevShowMore?.visibleList,
            hotel: count,
          },
        }));

        // setLoadMore(false);
      } else {
        if (isFetchingFreshly) setIsFetchingHotels(false);
        console.log("No new hotels found");
        setLoadMore(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        if (
          err?.name !== "AbortError" &&
          !err.message.includes("signal is aborted")
        ) {
          // toastMessage.error(
          //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
          //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
          // );
          console.error("An api request was cancelled by user", err);
        }
      } else {
        console.error("An unexpected error occurred:", err);
      }
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setHotelData(null);
      // toastMessage.error(
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.messageTitle,
      //   TOAST_MESSAGES.BOOKING.Search.HotelSearchFailure.message
      // );
      setLoadMore(false);
    } finally {
      if (isFetchingFreshly) setIsFetchingHotels(false);
      setLoading(false);
      setLoadMore(false);
    }
  };

  const selectFlightHotel = () => {
    const areAllRoomsSelected = selectedHotelRoomArr.every((eachHotel) => {
      if (!eachHotel?.selectedHotelRoom || !eachHotel?.selectedHotelRoom) {
        // toast.dismiss();
        toastMessage.error(
          `Please select any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      if (
        !eachHotel?.selectedHotelRoom?.preBookId ||
        !eachHotel?.selectedHotelRoom?.sessionId
      ) {
        toastMessage.error(
          `Please selected any room for the ${eachHotel.city} for the checkIn date ${eachHotel?.checkIn}`
        );
        return false;
      }
      return true;
    });
    if (!areAllRoomsSelected) return;
    if (!isMobile) {
      setScreen(ScreenTypes.TravellerInfo);
    }
    if (isMobile) navigate(ROUTES.TravelerInformation);
    // navigate(ROUTES.TravelerInformation);
  };

  function addOffsetData(data: any) {
    return data.map((city: any) => {
      return {
        ...city,
        offset: 0,
        hasMoreHotels: true,
      };
    });
  }

  const hotelSearch = (isFetchingFreshly: boolean = false) => {
    const cities = citiesPayload;
    setHotelPagination(addOffsetData(cities));
    setLoading(true);
    setLoadMore(false);
    setTimeout(() => {
      handleHotelSearch(0, cities, isFetchingFreshly);
    }, 10);
  };
  useEffect(() => {
    if (!isFetchingHotels) {
      fetchHotelController?.current?.abort();
    }
    if (!isFetchingFlights) {
      fetchFlightController?.current?.abort();
    }
  }, [isFetchingHotels, isFetchingFlights]);

  const handleShowMoreFlights = () => {
    setLoadMoreFlights(true);

    setBookingDetails((prevDetails) => {
      const newFlightCount = prevDetails?.visibleList?.flight + 4;

      setTimeout(() => {
        handleFlightSearch(newFlightCount);
      }, 10);

      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          flight: newFlightCount,
        },
      };
    });
  };

  const handleFlightSearchClick = () => {
    setFlightData(null);
    setBookingDetails((prevDetails) => {
      return {
        ...prevDetails,
        noMoreFlights: false,
        visibleList: {
          ...prevDetails.visibleList,
          flight: 0,
        },
      };
    });
    handleFlightSearch(0);
  };

  const handleSearchClick = (isFetchingFreshly: boolean = false) => {
    setHotelData(null);
    setBookingDetails((prevDetails) => {
      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: 4,
        },
      };
    });
    hotelSearch(isFetchingFreshly);
  };

  const handleSingleCityShowMoreHotels = () => {
    setLoading(false);
    setLoadMore(true);
    setBookingDetails((prevDetails) => {
      const newHotelCount = prevDetails?.visibleList?.hotel + 4;
      const cities = citiesPayload;
      setTimeout(() => {
        handleHotelSearch(newHotelCount, cities, false);
      }, 10);
      return {
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: newHotelCount,
        },
      };
    });
  };

  const handleShowMoreHotels = (city: string) => {
    setLoading(false);
    setLoadMore(true);
    setLoadingCity(city);
    // offset update
    setHotelPagination((prevPagination) => {
      return prevPagination.map((hotel) => {
        if (hotel.city === city) {
          const currentCityData = hotelData?.data.find(
            (c: any) => c.city === city
          );
          const totalHotels = currentCityData?.hotels.length ?? 0;
          const nextOffset = hotel.offset + 4;
          const hasMore = nextOffset <= totalHotels;
          return {
            ...hotel,
            offset: hotel.offset + 4,
            hasMoreHotels: hasMore,
          };
        }
        return hotel; // other city entries unchanged
      });
    });
  };
  const updatedCity = hotelPagination.find(
    (hotel) => hotel.city === loadingCity
  );

  useEffect(() => {
    const isUsingMobile = window.matchMedia("(max-width: 768px)").matches;
    if (
      Object?.keys(selectedChatData)?.length === 0 &&
      !hotelData &&
      !flightData &&
      isUsingMobile
    ) {
      navigate(ROUTES.Home, { replace: true });
    }
    if (!hotelData || hotelData.length === 0) {
      return;
    }
    const updatedCity = hotelPagination.find(
      (hotel) => hotel?.city === loadingCity
    );

    if (updatedCity) {
      const cityHotels = hotelPagination
        .filter((hotel) => hotel?.city === loadingCity)
        .map(
          ({ offset, hasMoreHotels, ...hotelWithoutOffset }) =>
            hotelWithoutOffset
        );

      handleHotelSearch(
        updatedCity.offset,
        cityHotels,
        false,
        bookingDetails.hotelUniqueId
      );
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        visibleList: {
          ...prevDetails.visibleList,
          hotel: updatedCity.offset,
        },
      }));
    }
  }, [loadingCity, updatedCity?.offset]);

  const handleFlightBook = () => {
    localStorage.removeItem("_traveler_info_array_state");
    localStorage.removeItem("_traveler_info_array");
    isMobile
      ? navigate(ROUTES.TravelerInformation)
      : setScreen(ScreenTypes.TravellerInfo);
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      selectedFlightId: event.target.value,
    }));
  };
  const handleToggleAccordion = (index: number) => {
    setBookingDetails((prevDetails) => {
      const expandedAccordions = prevDetails?.expandedAccordions || [];
      const isExpanded = expandedAccordions?.includes(index);
      const newExpanded = isExpanded
        ? expandedAccordions?.filter((i: number) => i !== index)
        : [...expandedAccordions, index];

      return {
        ...prevDetails,
        expandedAccordions: newExpanded,
      };
    });
  };

  const flightTravelData = getFlightTravelData(
    flightTravelDetailsFromItinerary
  );

  //map date function
  function mapDatesToTravelJourneyData(
    data: any[],
    setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>
  ) {
    try {
      if (!Array.isArray(data)) {
        return;
      }
      if (data.length === 0) {
        return;
      }

      data.forEach((segment, index) => {
        if (!segment || typeof segment !== "object") {
          console.log(
            `Invalid segment at index ${index}. Each segment must be an object.`
          );
        }

        if (typeof segment.totalDays !== "number" || segment.totalDays < 0) {
          console.log(
            `Invalid or missing totalDays for segment at index ${index}: ${segment.totalDays}`
          );
        }
      });
      const updatedData = data.map((segment, index) => {
        const departureDate = new Date(segment.departureDate);

        // Calculate return date based on segment.totalDays
        const returnDate = new Date(departureDate);
        returnDate.setDate(returnDate.getDate() + segment.totalDays - 1);

        return {
          ...segment,
          departureDate: departureDate.toISOString().split("T")[0],
          returnDate: bookingDetails.isThisMultiCityTrip
            ? returnDate.toISOString().split("T")[0]
            : bookingDetails.travelToDate,
        };
      });
      setBookingDetails((prevData: any) => ({
        ...prevData,
        travelJourneyData: updatedData,
      }));
    } catch (error) {
      console.error("Error in mapDates:", error);
    }
  }

  //calling the map function again to update dates
  useEffect(() => {
    if (bookingDetails.travelFromDate) {
      mapDatesToTravelJourneyData(flightTravelData, setBookingDetails);
    }
  }, [flightTravelData[0]?.departureDate]);

  //booking reference data for input update validation
  const bookingRefData = {
    adultsCount: bookingDetails?.adultsCount,
    childrenCount: bookingDetails?.childrenCount,
    seniorsCount: bookingDetails?.seniorsCount,
    numberOfRoomCount: bookingDetails?.numberOfRoomCount,
    travelFromDate: bookingDetails?.travelFromDate,
    travelToDate: bookingDetails.travelToDate || "",
    flightClass: bookingDetails.flightClass,
    tripType: bookingDetails.tripType,
    filters: bookingDetails?.filters.starRating.map((starRating) => ({
      starRating: starRating,
    })),
    // Flight Preferences
    flightConnections: bookingDetails?.flightPreferences?.connections || 0,
    flightDepartureTime:
      bookingDetails?.flightPreferences?.departureTime.map(
        (departureTime) => departureTime
      ) || [],
    refundableFlights: bookingDetails?.flightPreferences?.refundable || false,
    preferredAirlines: bookingDetails?.flightPreferences?.airlines || [],

    // Hotel Preferences
    refundableHotels: bookingDetails?.hotelPreferences?.refundable || false,
    hotelChains: bookingDetails?.hotelPreferences?.hotelChains || [],
    hotels: bookingDetails.hotels.map((city) => ({
      city: city || "",
    })),
    airPort: bookingDetails?.airPort.map(({ arrival, departure }) => ({
      arrival: arrival || "",
      departure: departure || "",
    })),
  };

  //reset api data
  const handleBookingDetailsChange = async () => {
    if (checkBackButton) return;
    const { ...currentDetailsWithoutVisibleList } = bookingRefData || {};
    const { ...prevDetailsWithoutVisibleList } =
      prevBookingDetailsRef.current || {};

    if (
      isModified &&
      JSON.stringify(currentDetailsWithoutVisibleList) !==
        JSON.stringify(prevDetailsWithoutVisibleList)
    ) {
      // toastMessage.error(
      //   "Please click 'Search' to see results based on your new filters"
      // );
      await setFlightData(null);
      await setHotelData(null);
      setIsModified(false);
      setBookingDetails((prevData) => ({
        ...prevData,
        isDataModified: {
          ...prevData.isDataModified,
          flight: true,
          hotel: true,
        },
      }));
    }
  };
  useEffect(() => {
    if (flightData === null && hotelData === null) {
      return;
    }
    handleBookingDetailsChange();
  }, [bookingDetails, isModified]);

  // booking data update;

  const getTravelData = async () => {
    try {
      await mapDatesToTravelJourneyData(flightTravelData, setBookingDetails);
    } catch (error) {
      console.error("Error in getTravelData:", error);
    }
  };

  const checkTravelJourneyData = bookingDetails.travelJourneyData?.some(
    (i) =>
      i.arrivalCityAddress ||
      i.departureCityAddress ||
      i.departureDate ||
      i.flightCheckOut
  );

  const handleUpdateTravelData = useCallback(async () => {
    await getTravelData();
    await handleUpdateDatesToItinerary(bookingDetails.travelFromDate);
    setBookingDetails((prevData) => ({
      ...prevData,
      isDataModified: {
        ...prevData.isDataModified,
        flight: false,
        hotel: false,
      },
      flightHistory: flightTravelDetailsFromItinerary,
    }));
    updateAirportData(
      bookingDetails,
      setBookingDetails,
      bookingDetails.isThisMultiCityTrip
    );
    updateHotelPayloadData();
  }, [
    getTravelData,
    checkTravelJourneyData,
    bookingDetails,
    setBookingDetails,
  ]);
  const departureAndReturnDates = bookingDetails.travelJourneyData
    .map((item) => `${item.departureDate}-${item.returnDate}`)
    .join(",");

  useEffect(() => {
    handleUpdateTravelData();
  }, [departureAndReturnDates]);

  const handleUpdateHotelTravelData = useCallback(async () => {
    await handleUpdateDatesToItinerary(bookingDetails.travelFromDate);
    setBookingDetails((prevData) => ({
      ...prevData,
      isDataModified: {
        ...prevData.isDataModified,
        flight: false,
        hotel: false,
      },
    }));
    updateHotelPayloadData();
  }, [bookingDetails, setBookingDetails]);

  if (showLoader) {
    return (
      <div className={styles.newLoaderContainer}>
        <NewLoader
          text="Please wait"
          className={styles.loaderMain}
          cancelButtonClassName={styles.cancelBtn}
          isOpen={true}
          onClose={() => {
            setShowLoader(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <BookedSuccessDialog
        open={openBookedSuccessDialog}
        onClose={() => {
          setOpenBookedSuccessDialog(false);
        }}
      />
      <DiscardChanges
        open={open}
        onClose={handleClose}
        handleDiscard={handleResetFormDate}
        message="Discarding changes will result in losing your modifications"
      />
      <div className={styles.container}>
        <header className={styles.header}>
          <Button
            className={`${styles.columnAlignButton}`}
            // onClick={handleBackButton}
            onClick={handleClickOpen}>
            <img src={backIcon} alt="Close" />
            <p>BACK TO ITINERARY</p>
          </Button>
          <p className={styles.bookingTitle}>Search Flights & Hotels </p>
        </header>
        {showDetails ? (
          <TravelDetails
            handleShowDetails={() => setShowDetails(false)}
            bookingDetails={bookingDetails}
            selectedOptions={selectedFlightOptions}
          />
        ) : (
          <div className={styles.inputBox}>
            <div
              className={`${styles.inputHeader} ${styles.collapseContainer}`}>
              <section
                className={styles.expandIconBox}
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_collapse_accordion_click
                  );
                }}>
                <div
                  className={styles.showDetailsBtn}
                  onClick={() => setShowDetails(true)}>
                  <p className={styles.showBtnTitle}>Collapse</p>
                  <img src={expandIcon} alt="" className={styles.expandIcon} />
                </div>
              </section>
            </div>
            <div className={styles.inputHeader}>
              <section className={styles.checkBox}>
                <CustomCheckbox
                  label="Flights"
                  name="flights"
                  handleCheckboxChange={handleChange}
                  selectedOptions={selectedFlightOptions}
                  onClick={() => {
                    triggerGAEvent(GA_LABEL_ENUMS.booking_back_click);
                    if (bookingDetails.hideFlights)
                      toastMessage.error(
                        "You do not have flights for this itinerary"
                      );
                  }}
                  disable={bookingDetails.hideFlights}
                />
                <CustomCheckbox
                  label="Hotels"
                  name="hotels"
                  handleCheckboxChange={handleChange}
                  selectedOptions={selectedFlightOptions}
                  onClick={() => {
                    triggerGAEvent(GA_LABEL_ENUMS.booking_hotel_checkbox_click);
                    if (bookingDetails.hideHotels)
                      toastMessage.error(
                        "You do not have hotels for this itinerary"
                      );
                  }}
                  disable={bookingDetails.hideHotels}
                />
              </section>
              <section
                className={styles.expandIconBox}
                onClick={() => {
                  triggerGAEvent(
                    GA_LABEL_ENUMS.booking_collapse_accordion_click
                  );
                }}>
                <div className={styles.showDetailsBtn}>
                  <Chip
                    label={`Preferences (${getPreferenceCount(
                      bookingDetails.flightPreferences,
                      bookingDetails.hotelPreferences,
                      selectedFlightOptions
                    )})`}
                    size="medium"
                    variant="outlined"
                    sx={{
                      height: "20px",
                    }}
                    onClick={() => {
                      setShowBookingPreferences(true);
                    }}
                    className={styles.chip}
                    style={{
                      backgroundColor: showBookingPreferences
                        ? "#A4D1F0"
                        : "#ffff",
                    }}
                  />
                </div>
              </section>
            </div>
            {selectedFlightOptions.flights && !selectedFlightOptions.hotels && (
              <FlightInputFields
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                searchFlights={handleFlightSearchClick}
                getTravelData={() =>
                  mapDatesToTravelJourneyData(
                    flightTravelData,
                    setBookingDetails
                  )
                }
                handleUpdateTravelData={handleUpdateTravelData}
                cities={citiesPayload}
              />
            )}
            {!selectedFlightOptions.flights && selectedFlightOptions.hotels && (
              <HotelInputFields
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                searchHotels={() => {
                  setSelectedHotelRoomArr([]);
                  handleSearchClick(true);
                }}
                handleUpdateTravelData={handleUpdateHotelTravelData}
              />
            )}
            {selectedFlightOptions.flights && selectedFlightOptions.hotels && (
              <FlightHotelInputFields
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                searchFlights={handleFlightSearchClick}
                searchHotels={() => {
                  setSelectedHotelRoomArr([]);
                  setHotelData([]);
                  hotelSearch(true);
                }}
                getTravelData={() =>
                  mapDatesToTravelJourneyData(
                    flightTravelData,
                    setBookingDetails
                  )
                }
                handleUpdateTravelData={handleUpdateTravelData}
                cities={citiesPayload}
              />
            )}
          </div>
        )}

        {/* Flight listing */}

        {selectedFlightOptions.flights && !selectedFlightOptions.hotels && (
          <section className={styles.list}>
            {/* {flightData?.sectors && (
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={selectedFlightOptions}
                  flightData={flightData?.sectors || "Flight"}
                />
              </section>
            )} */}
            {flightData && (
              <section className={styles.selected}>
                <SelectedOptions
                  selectedOptions={selectedFlightOptions}
                  flightData={flightData?.sectors || "Flight"}
                />
              </section>
            )}

            <section className={styles.listCard}>
              <FormControl className={styles.radioControl}>
                <RadioGroup
                  onChange={handleSelect}
                  value={bookingDetails.selectedFlightId}
                  className={styles.radioGroup}>
                  {flightData?.sectors
                    // ?.slice(0, bookingDetails.visibleList.flight)
                    ?.filter(
                      (flight: any, index: number, self: any[]) =>
                        index ===
                        self.findIndex((f) => f.uniqueId === flight.uniqueId)
                    )
                    ?.map((flight: any, index: number) => {
                      const outboundFlight = flight.outbound?.[0];
                      if (!outboundFlight) return null;

                      const {
                        departureDate,
                        arrivalDate,
                        airlineName,
                        airlineImage,
                        stops,
                        stopsCount,
                        route,
                      } = outboundFlight;

                      return (
                        <div key={index}>
                          <FlightListing
                            selectedData={bookingDetails.selectedFlightId}
                            handleSelect={handleSelect}
                            setFlightData={setFlightData}
                            flightData={flightData}
                            key={index}
                            startTime={
                              departureDate &&
                              convertToCustomTime(departureDate)
                            }
                            endTime={
                              arrivalDate && convertToCustomTime(arrivalDate)
                            }
                            flightName={airlineName}
                            flightImage={airlineImage}
                            stopsInfo={
                              stops
                                ?.map((stop: any) => stop.airport)
                                .join(", ") || ""
                            }
                            stops={stopsCount}
                            stopTime={stops?.[0]?.layover || ""}
                            airports={route}
                            flightCost={flight?.price}
                            flightClass={bookingDetails.flightClass}
                            tourPackage={false}
                            buttonName="Book"
                            sup="1"
                            detailsButton="Flight Details"
                            handleBook={handleFlightBook}
                            encryptedKey={flightData?.encryptedKey}
                            uniqueId={flight?.uniqueId}
                            inOut="outbound"
                          />
                        </div>
                      );
                    })}
                </RadioGroup>
              </FormControl>

              {flightData?.sectors && (
                <>
                  {bookingDetails.noMoreFlights ? null : (
                    <div className={styles.showCard}>
                      {loadMoreFlights ? (
                        <Loader />
                      ) : (
                        <Button
                          className={styles.showMoreBtn}
                          endIcon={
                            <img
                              src={updatedSeeMoreIcon}
                              alt="btn"
                              className={styles.showMoreIcon}
                            />
                          }
                          onClick={handleShowMoreFlights}>
                          Show More
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            </section>
            {/* {!loading && (
            )} */}
            {flightData?.sectors && flightData?.sectors.length === 0 && (
              <NoData
                message={
                  TOAST_MESSAGES.BOOKING.Search.NoFlightsFoundScreenMessage
                    .message
                }
              />
            )}
            {flightData && flightData.length === 0 && (
              <>
                {loading ? null : (
                  <NoData
                    message={
                      TOAST_MESSAGES.BOOKING.Search.NoFlightsFoundScreenMessage
                        .message
                    }
                  />
                )}
              </>
            )}
          </section>
        )}

        {/* Hotel listing */}

        {selectedFlightOptions.hotels && !selectedFlightOptions.flights && (
          <section className={styles.list}>
            {/* {hotelData && hotelData?.data && ( */}
            {hotelData?.data && (
              <section className={styles.selected}>
                <SelectedOptions selectedOptions={selectedFlightOptions} />
              </section>
            )}
            {/* )} */}
            {!loading && (
              <>
                {bookingDetails.hotels.length > 1 ? (
                  <section className={styles.listCard}>
                    {hotelData &&
                      hotelData?.data?.map(
                        (cityData: any, cityIndex: number) => {
                          if (!cityData?.hotels?.length) {
                            return (
                              <div
                                key={cityIndex}
                                className={styles.listCardContainer}>
                                <p className={styles.noHotelsMessage}>
                                  No hotels found in {cityData?.city}
                                </p>
                              </div>
                            );
                          }

                          return (
                            <div
                              key={cityIndex}
                              className={styles.listCardContainer}>
                              <CustomAccordion
                                className={styles.customFlightStyles}
                                summaryClass={styles.summaryClass}
                                accordianDetailsClass={
                                  styles.accordFlightDetailsClass
                                }
                                // defaultExpanded={cityIndex === 0}
                                defaultExpanded={
                                  bookingDetails?.expandedAccordions?.includes(
                                    cityIndex
                                  ) || false
                                }
                                onChange={() =>
                                  handleToggleAccordion(cityIndex)
                                }
                                hideExpandIcon={false}
                                listing={true}
                                headingComponent={
                                  <div className={styles.headingFlightComp}>
                                    <div>
                                      <p className={styles.checkInCheckout}>
                                        {FormatHotelListingDateFormat(
                                          cityData?.checkIn
                                        )}{" "}
                                        -{" "}
                                        {FormatHotelListingDateFormat(
                                          cityData?.checkOut
                                        )}
                                      </p>

                                      <p className={styles.city}>
                                        {cityData?.city}
                                      </p>
                                    </div>

                                    <FlightHotelSelect
                                      flight={false}
                                      selected={isThisHotelRoomSelected(
                                        selectedHotelRoomArr,
                                        cityData
                                      )}
                                    />
                                  </div>
                                }
                                bodyComponent={
                                  <div className={styles.hotelListing}>
                                    {cityData?.hotels
                                      ?.map((hotel: any) => ({
                                        ...hotel,
                                        checkIn: cityData?.checkIn,
                                        checkOut: cityData?.checkOut,
                                        city: cityData?.city,
                                      }))
                                      ?.filter(
                                        (hotel: any) =>
                                          hotel?.city === cityData?.city
                                      )
                                      ?.filter(
                                        (
                                          hotel: any,
                                          index: number,
                                          self: any[]
                                        ) =>
                                          index ===
                                          self?.findIndex(
                                            (h) => h?.hotelId === hotel?.hotelId
                                          )
                                      )
                                      ?.map(
                                        (hotel: any, hotelIndex: number) => {
                                          if (!hotel) return null;

                                          const {
                                            hotelMainImage,
                                            stars,
                                            name,
                                            address,
                                            rating,
                                            reviewCount,
                                            reviewHeading,
                                            amenities,
                                            totalPrice,
                                            cancellationPolicy,
                                            hotelId,
                                            checkIn = "",
                                            checkOut = "",
                                            city = "",
                                          } = hotel;

                                          return (
                                            <div key={hotelIndex}>
                                              <HotelListing
                                                id={hotelIndex}
                                                hotelMainImage={hotelMainImage}
                                                star={stars}
                                                hotelName={name}
                                                hotelLocation={address}
                                                hotelRating={rating}
                                                reviewCount={reviewCount}
                                                cost={totalPrice.price}
                                                cancellation={
                                                  cancellationPolicy
                                                }
                                                Amenity={amenities}
                                                hotelList={true}
                                                discount={""}
                                                discountCost={""}
                                                taxesIncluded={
                                                  totalPrice.taxesIncluded
                                                }
                                                reviewHeading={reviewHeading}
                                                hotelId={hotelId}
                                                hotelDetails={hotel}
                                                onSelect={() =>
                                                  handleHotelSelect(
                                                    hotelId,
                                                    checkIn,
                                                    checkOut,
                                                    city
                                                  )
                                                }
                                                city={city}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    <div className={styles.showMoreBox}>
                                      {loadMore &&
                                      loadingCity === cityData?.city ? (
                                        <Loader />
                                      ) : (
                                        hotelPagination?.find(
                                          (hotel) =>
                                            hotel?.city === cityData?.city
                                        )?.hasMoreHotels && (
                                          <Button
                                            className={styles.showMoreBtn}
                                            endIcon={
                                              <img
                                                src={updatedSeeMoreIcon}
                                                alt="btn"
                                                className={styles.showMoreIcon}
                                              />
                                            }
                                            onClick={() =>
                                              handleShowMoreHotels(
                                                cityData?.city
                                              )
                                            }>
                                            Show More
                                          </Button>
                                        )
                                      )}
                                    </div>
                                  </div>
                                }
                                zeroMarginOnExpand
                                borderBottom
                              />
                            </div>
                          );
                        }
                      )}

                    {hotelData && hotelData?.length === 0 && (
                      <NoData
                        message={
                          TOAST_MESSAGES.BOOKING.Search
                            .NoHotelsFoundScreenMessage.message
                        }
                      />
                    )}
                  </section>
                ) : (
                  <section className={styles.listCard}>
                    {hotelData &&
                      hotelData?.data
                        ?.flatMap((cityData: any) =>
                          cityData?.hotels?.map((hotel: any) => ({
                            ...hotel,
                            checkIn: cityData?.checkIn,
                            checkOut: cityData?.checkOut,
                            city: cityData?.city,
                          }))
                        )
                        ?.filter(
                          (hotel: any, index: number, self: any[]) =>
                            index ===
                            self?.findIndex(
                              (h) => h?.hotelId === hotel?.hotelId
                            )
                        )
                        ?.map((hotel: any, index: number) => {
                          if (!hotel) return null;

                          const {
                            hotelMainImage,
                            stars,
                            name,
                            address,
                            rating,
                            reviewCount,
                            reviewHeading,
                            amenities,
                            totalPrice,
                            cancellationPolicy,
                            hotelId,
                            checkIn = "",
                            checkOut = "",
                            city = "",
                          } = hotel;

                          return (
                            <div key={index}>
                              <HotelListing
                                id={index}
                                hotelMainImage={hotelMainImage}
                                star={stars}
                                hotelName={name}
                                hotelLocation={address}
                                hotelRating={rating}
                                reviewCount={reviewCount}
                                cost={totalPrice.price}
                                cancellation={cancellationPolicy}
                                Amenity={amenities}
                                hotelList={true}
                                discount={""}
                                discountCost={""}
                                taxesIncluded={totalPrice.taxesIncluded}
                                reviewHeading={reviewHeading}
                                hotelId={hotelId}
                                hotelDetails={hotel}
                                onSelect={() =>
                                  handleHotelSelect(
                                    hotelId,
                                    checkIn,
                                    checkOut,
                                    city
                                  )
                                }
                                city={city}
                              />
                            </div>
                          );
                        })}
                    {hotelData?.data?.flatMap(
                      (cityData: any) => cityData?.hotels
                    )?.length >= showMore?.visibleList?.hotel && (
                      <div className={styles.packageShowMoreCard}>
                        {loadMore ? (
                          <Loader />
                        ) : (
                          <Button
                            className={styles.showMoreBtn}
                            endIcon={
                              <img
                                src={updatedSeeMoreIcon}
                                alt="btn"
                                className={styles.showMoreIcon}
                              />
                            }
                            onClick={handleSingleCityShowMoreHotels}>
                            Show More
                          </Button>
                        )}
                      </div>
                    )}
                    {hotelData && hotelData?.length === 0 && (
                      <NoData
                        message={
                          TOAST_MESSAGES.BOOKING.Search
                            .NoHotelsFoundScreenMessage.message
                        }
                      />
                    )}
                  </section>
                )}
              </>
            )}
            {bookingDetails.hotels.length > 1 && hotelData?.data && (
              <section className={styles.buttonCard}>
                {!loading && (
                  <FlightSelectButtons
                    onContinue={selectFlightHotel}
                    // onContinue={() => setOpenBookedSuccessDialog(true)}
                    selectFlightHotel={selectFlightHotel}
                    selectedFlightId={bookingDetails.selectedFlightId}
                    selectedHotelId={selectedHotelId}
                    flight={selectedFlightOptions.flights}
                    hotel={selectedFlightOptions.hotels}
                  />
                )}
              </section>
            )}
          </section>
        )}

        {/* Package listing */}
        {!loading &&
          selectedFlightOptions?.hotels &&
          selectedFlightOptions?.flights && (
            <div>
              {flightData === null || hotelData === null ? (
                <BookingLandingPage />
              ) : (
                <>
                  {(flightData?.sectors?.length === 0 ||
                    hotelData?.length === 0) &&
                  !loading ? (
                    <>
                      {isFetchingHotels || isFetchingFlights ? null : (
                        <NoData
                          message={
                            "Sorry, no flights and hotels available for your destination on selected dates. Please try again by changing your search options."
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <section className={styles.list}>
                        {flightData && (
                          <section className={styles.selected}>
                            <SelectedOptions
                              selectedOptions={{ hotels: false, flights: true }}
                              selectTitle="Flight below"
                              flightData={flightData?.sectors || []}
                            />
                          </section>
                        )}
                        {!loading && (
                          <section className={styles.listCard}>
                            {flightData?.sectors
                              // ?.slice(0, bookingDetails.visibleList.flight)
                              ?.filter(
                                (flight: any, index: number, self: any[]) =>
                                  index ===
                                  self?.findIndex(
                                    (f) => f?.uniqueId === flight?.uniqueId
                                  )
                              )
                              ?.map((flight: any, index: number) => {
                                const outboundFlight = flight?.outbound?.[0];
                                if (!outboundFlight) return null;

                                const {
                                  departureDate,
                                  arrivalDate,
                                  airlineName,
                                  airlineImage,
                                  stops,
                                  stopsCount,
                                  route,
                                  transactionId,
                                } = outboundFlight;

                                return (
                                  <div key={index}>
                                    <FlightListing
                                      selectedData={
                                        bookingDetails.selectedFlightId
                                      }
                                      handleSelect={handleSelect}
                                      key={index}
                                      setFlightData={setFlightData}
                                      flightData={flightData}
                                      startTime={
                                        departureDate &&
                                        convertToCustomTime(departureDate)
                                      }
                                      endTime={
                                        arrivalDate &&
                                        convertToCustomTime(arrivalDate)
                                      }
                                      flightName={airlineName}
                                      flightImage={airlineImage}
                                      stopsInfo={
                                        stops
                                          ?.map((stop: any) => stop?.airport)
                                          .join(", ") || ""
                                      }
                                      stops={stopsCount}
                                      stopTime={stops?.[0]?.layover || ""}
                                      airports={route}
                                      flightCost={flight?.price}
                                      flightClass={bookingDetails.flightClass}
                                      tourPackage={true}
                                      buttonName="Book"
                                      sup="1"
                                      detailsButton={"FLight Details"}
                                      handleBook={() => {
                                        // navigate(ROUTES.TravelerInformation)
                                      }}
                                      selected={
                                        bookingDetails.selectedFlightId ===
                                        transactionId
                                      }
                                      encryptedKey={flightData?.encryptedKey}
                                      uniqueId={flight?.uniqueId}
                                      inOut="outbound"
                                    />
                                  </div>
                                );
                              })}

                            {flightData?.sectors && (
                              <>
                                {bookingDetails?.noMoreFlights ? null : (
                                  <div className={styles.packageShowMoreCard}>
                                    {loadMoreFlights ? (
                                      <Loader />
                                    ) : (
                                      <Button
                                        className={styles.showMoreBtn}
                                        endIcon={
                                          <img
                                            src={updatedSeeMoreIcon}
                                            alt="btn"
                                            className={styles.showMoreIcon}
                                          />
                                        }
                                        onClick={handleShowMoreFlights}>
                                        Show More
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </section>
                        )}
                        {flightData?.sectors &&
                          flightData?.sectors?.length === 0 && (
                            <NoData
                              message={
                                TOAST_MESSAGES.BOOKING.Search
                                  .NoFlightsFoundScreenMessage.message
                              }
                            />
                          )}
                        {flightData && flightData.length === 0 && (
                          <>
                            {loading ? null : (
                              <NoData
                                message={
                                  TOAST_MESSAGES.BOOKING.Search
                                    .NoFlightsFoundScreenMessage.message
                                }
                              />
                            )}
                          </>
                        )}
                      </section>
                      <section className={styles.list}>
                        {hotelData?.data && (
                          <section className={styles.selected}>
                            <SelectedOptions
                              selectedOptions={{ hotels: true, flights: false }}
                            />
                          </section>
                        )}
                        {/* )} */}

                        {!loading && (
                          <>
                            {bookingDetails.hotels.length > 1 ? (
                              <section className={styles.listCard}>
                                {hotelData &&
                                  hotelData?.data?.map(
                                    (cityData: any, cityIndex: number) => {
                                      if (!cityData?.hotels?.length) {
                                        return (
                                          <div
                                            key={cityIndex}
                                            className={
                                              styles.listCardContainer
                                            }>
                                            <p
                                              className={
                                                styles.noHotelsMessage
                                              }>
                                              No hotels found in{" "}
                                              {cityData?.city}
                                            </p>
                                          </div>
                                        );
                                      }

                                      return (
                                        <div
                                          key={cityIndex}
                                          className={styles.listCardContainer}>
                                          <CustomAccordion
                                            className={
                                              styles.customFlightStyles
                                            }
                                            summaryClass={styles.summaryClass}
                                            accordianDetailsClass={
                                              styles.accordFlightDetailsClass
                                            }
                                            // defaultExpanded={cityIndex === 0}
                                            defaultExpanded={
                                              bookingDetails?.expandedAccordions?.includes(
                                                cityIndex
                                              ) || false
                                            }
                                            onChange={() =>
                                              handleToggleAccordion(cityIndex)
                                            }
                                            hideExpandIcon={false}
                                            listing={true}
                                            headingComponent={
                                              <div
                                                className={
                                                  styles.headingFlightComp
                                                }>
                                                <div>
                                                  <p
                                                    className={
                                                      styles.checkInCheckout
                                                    }>
                                                    {FormatHotelListingDateFormat(
                                                      cityData?.checkIn
                                                    )}{" "}
                                                    -{" "}
                                                    {FormatHotelListingDateFormat(
                                                      cityData?.checkOut
                                                    )}
                                                  </p>

                                                  <p className={styles.city}>
                                                    {cityData?.city}
                                                  </p>
                                                </div>

                                                <FlightHotelSelect
                                                  flight={false}
                                                  selected={isThisHotelRoomSelected(
                                                    selectedHotelRoomArr,
                                                    cityData
                                                  )}
                                                />
                                              </div>
                                            }
                                            bodyComponent={
                                              <div
                                                className={styles.hotelListing}>
                                                {cityData.hotels
                                                  ?.map((hotel: any) => ({
                                                    ...hotel,
                                                    checkIn: cityData?.checkIn,
                                                    checkOut:
                                                      cityData?.checkOut,
                                                    city: cityData?.city,
                                                  }))
                                                  ?.filter(
                                                    (hotel: any) =>
                                                      hotel?.city ===
                                                      cityData?.city
                                                  )
                                                  ?.filter(
                                                    (
                                                      hotel: any,
                                                      index: number,
                                                      self: any[]
                                                    ) =>
                                                      index ===
                                                      self?.findIndex(
                                                        (h) =>
                                                          h?.hotelId ===
                                                          hotel?.hotelId
                                                      )
                                                  )
                                                  ?.map(
                                                    (
                                                      hotel: any,
                                                      hotelIndex: number
                                                    ) => {
                                                      if (!hotel) return null;

                                                      const {
                                                        hotelMainImage,
                                                        stars,
                                                        name,
                                                        address,
                                                        rating,
                                                        reviewCount,
                                                        reviewHeading,
                                                        amenities,
                                                        totalPrice,
                                                        cancellationPolicy,
                                                        hotelId,
                                                        checkIn = "",
                                                        checkOut = "",
                                                        city = "",
                                                      } = hotel;

                                                      return (
                                                        <div key={hotelIndex}>
                                                          <HotelListing
                                                            id={hotelIndex}
                                                            hotelMainImage={
                                                              hotelMainImage
                                                            }
                                                            star={stars}
                                                            hotelName={name}
                                                            hotelLocation={
                                                              address
                                                            }
                                                            hotelRating={rating}
                                                            reviewCount={
                                                              reviewCount
                                                            }
                                                            cost={
                                                              totalPrice.price
                                                            }
                                                            cancellation={
                                                              cancellationPolicy
                                                            }
                                                            Amenity={amenities}
                                                            hotelList={true}
                                                            discount={""}
                                                            discountCost={""}
                                                            taxesIncluded={
                                                              totalPrice.taxesIncluded
                                                            }
                                                            reviewHeading={
                                                              reviewHeading
                                                            }
                                                            hotelId={hotelId}
                                                            hotelDetails={hotel}
                                                            onSelect={() =>
                                                              handleHotelSelect(
                                                                hotelId,
                                                                checkIn,
                                                                checkOut,
                                                                city
                                                              )
                                                            }
                                                            city={city}
                                                          />
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                <div
                                                  className={
                                                    styles.showMoreBox
                                                  }>
                                                  {loadMore &&
                                                  loadingCity ===
                                                    cityData?.city ? (
                                                    <Loader />
                                                  ) : (
                                                    hotelPagination?.find(
                                                      (hotel) =>
                                                        hotel?.city ===
                                                        cityData?.city
                                                    )?.hasMoreHotels && (
                                                      <Button
                                                        className={
                                                          styles.showMoreBtn
                                                        }
                                                        endIcon={
                                                          <img
                                                            src={
                                                              updatedSeeMoreIcon
                                                            }
                                                            alt="btn"
                                                            className={
                                                              styles.showMoreIcon
                                                            }
                                                          />
                                                        }
                                                        onClick={() =>
                                                          handleShowMoreHotels(
                                                            cityData?.city
                                                          )
                                                        }>
                                                        Show More
                                                      </Button>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            }
                                            zeroMarginOnExpand
                                            borderBottom
                                          />
                                        </div>
                                      );
                                    }
                                  )}

                                {hotelData && hotelData?.length === 0 && (
                                  <NoData
                                    message={
                                      TOAST_MESSAGES.BOOKING.Search
                                        .NoHotelsFoundScreenMessage.message
                                    }
                                  />
                                )}
                              </section>
                            ) : (
                              <section className={styles.listCard}>
                                {hotelData &&
                                  hotelData?.data
                                    ?.flatMap((cityData: any) =>
                                      cityData?.hotels?.map((hotel: any) => ({
                                        ...hotel,
                                        checkIn: cityData?.checkIn,
                                        checkOut: cityData?.checkOut,
                                        city: cityData?.city,
                                      }))
                                    )
                                    ?.filter(
                                      (
                                        hotel: any,
                                        index: number,
                                        self: any[]
                                      ) =>
                                        index ===
                                        self?.findIndex(
                                          (h) => h?.hotelId === hotel?.hotelId
                                        )
                                    )
                                    ?.map((hotel: any, index: number) => {
                                      if (!hotel) return null;

                                      const {
                                        hotelMainImage,
                                        stars,
                                        name,
                                        address,
                                        rating,
                                        reviewCount,
                                        reviewHeading,
                                        amenities,
                                        totalPrice,
                                        cancellationPolicy,
                                        hotelId,
                                        checkIn = "",
                                        checkOut = "",
                                        city = "",
                                      } = hotel;

                                      return (
                                        <div key={index}>
                                          <HotelListing
                                            id={index}
                                            hotelMainImage={hotelMainImage}
                                            star={stars}
                                            hotelName={name}
                                            hotelLocation={address}
                                            hotelRating={rating}
                                            reviewCount={reviewCount}
                                            cost={totalPrice.price}
                                            cancellation={cancellationPolicy}
                                            Amenity={amenities}
                                            hotelList={true}
                                            discount={""}
                                            discountCost={""}
                                            taxesIncluded={
                                              totalPrice.taxesIncluded
                                            }
                                            reviewHeading={reviewHeading}
                                            hotelId={hotelId}
                                            hotelDetails={hotel}
                                            onSelect={() =>
                                              handleHotelSelect(
                                                hotelId,
                                                checkIn,
                                                checkOut,
                                                city
                                              )
                                            }
                                            city={city}
                                          />
                                        </div>
                                      );
                                    })}
                                {hotelData?.data?.flatMap(
                                  (cityData: any) => cityData?.hotels
                                )?.length >= showMore?.visibleList?.hotel && (
                                  <div className={styles.packageShowMoreCard}>
                                    {loadMore ? (
                                      <Loader />
                                    ) : (
                                      <Button
                                        className={styles.showMoreBtn}
                                        endIcon={
                                          <img
                                            src={updatedSeeMoreIcon}
                                            alt="btn"
                                            className={styles.showMoreIcon}
                                          />
                                        }
                                        onClick={
                                          handleSingleCityShowMoreHotels
                                        }>
                                        Show More
                                      </Button>
                                    )}
                                  </div>
                                )}
                                {hotelData && hotelData?.length === 0 && (
                                  <NoData
                                    message={
                                      TOAST_MESSAGES.BOOKING.Search
                                        .NoHotelsFoundScreenMessage.message
                                    }
                                  />
                                )}
                              </section>
                            )}
                          </>
                        )}
                        {((flightData && flightData.length > 0) ||
                          hotelData?.data) && (
                          <section className={styles.buttonCard}>
                            {!loading && (
                              <FlightSelectButtons
                                onContinue={selectFlightHotel}
                                // onContinue={() => setOpenBookedSuccessDialog(true)}
                                selectFlightHotel={selectFlightHotel}
                                selectedFlightId={
                                  bookingDetails.selectedFlightId
                                }
                                selectedHotelId={selectedHotelId}
                                flight={selectedFlightOptions.flights}
                                hotel={selectedFlightOptions.hotels}
                              />
                            )}
                          </section>
                        )}
                      </section>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        {!loading && (
          <div className={styles.landingCard}>
            {flightData === null &&
              selectedFlightOptions.flights &&
              !selectedFlightOptions.hotels && <BookingLandingPage />}
            {hotelData === null &&
              selectedFlightOptions.hotels &&
              !selectedFlightOptions.flights && <BookingLandingPage />}
            {/* {flightData === null ||
              (hotelData === null &&
                selectedFlightOptions.hotels &&
                selectedFlightOptions.flights && <BookingLandingPage />)} */}
          </div>
        )}
      </div>
    </div>
  );
}

export default Booking;
