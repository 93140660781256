import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { gapi } from "gapi-script";
import "./App.scss";
import "./root.css";
import { StateContext } from "./context/globalContext/context";
import styles from "./app.module.scss";
import { createTheme, ThemeProvider } from "@mui/material";
import Dialog from "./component/dialog/Dialog";

import ReactGA from "react-ga4";
import {
  ENDPOINT,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_AUTH_CLIENT_ID,
} from "./constant/environment";
import { APP_CONFIG, HomePageNav } from "./constant/common";
import { iGlobalContext } from "./context/globalContext/interface";
import AppRoutes from "./routes/AppRoutes";
import Header from "./component/reusableComponent/header/Header";
import { checkIfUserIsLoggedIn } from "./utils/loginAndLocalStorageutils";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "./constant";
import { getConfig } from "./api/getConfig";
import { HelmetProvider } from "react-helmet-async";
import NetworkError from "./component/NetworkEror/NetworkEror";
import { getConfigMessage } from "./utils";
import { toastMessage } from "./helpers/toast/toastMessage";
import SelectRoomModal from "./component/hotelDetails/components/selectRoomModal/selectRoomModal";
import ReviewsModal from "./component/hotelDetails/components/reviewsModal/reviewsModal";
import PropertyDetailsModal from "./component/hotelDetails/components/propertyDetailsModal/propertyDetailsModal";
import axios from "axios";
import { iHistoryContext } from "./context/historyContext/interface";
import { useHistoryContext } from "./context/historyContext/historyContext";
import Loader from "./component/reusableComponent/loader/Loader";
import { iCurrentTripContext } from "./context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "./context/currentTripContext/currentTripContext";
import { ScreenTypes } from "./enums";
import useMediaQuery from "@mui/material/useMediaQuery";
import BookingModals from "./component/reusableComponent/bookingModal/BookingModals";
import { useResetState } from "./utils/resetBookingData";
import useCustomErrorHandler from "./hooks/useCustomErrorHandler";
import { isIPhone } from "./utils/isIphone";
import { GetConfigResponse } from "./interface";

const theme = createTheme({
  button: {
    main: "#f44336",
  },
  palette: {
    primary: {
      main: "#27A9E0",
      contrastText: "#696969",
    },
    secondary: {
      main: "#FCB400",
      contrastText: "black",
    },
  },
});

function App() {
  const localIsMobile = useMediaQuery("(max-width:768px)");
  // Network Error handling
  const [networkError, setNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const { currentTripData }: iCurrentTripContext = useCurrentTripContext();
  const { handleCustomError } = useCustomErrorHandler();
  const handleRefresh = () => {
    if (navigator.onLine) {
      window.location.reload();
      setNav(HomePageNav.LANDING_PAGE);
      setNetworkError(false);
    }
  };

  const state = React.useContext(StateContext);
  const {
    loginCreateAccountState,
    setNav,
    setUserInfo,
    isMobile,
    setIsMobile,
    setAppConfig,
    userInfo,
    appConfig,
    showSelectRoomModal,
    setShowSelectRoomModal,
    setShowReviews,
    showReviewsModal,
    showPropertyDetails,
    setShowPropertyDetails,
    hotelDetailsPageState: hotelDetails,
    setScreen,
    setFirstScreen,
    setIsOpenMobileSidebar,
    isOpenMobileSidebar,
    isUserNavigatingFromPaymentGateway,
    firstScreen,
    chatData,
    setIosNativeLoginData,
  }: iGlobalContext = state;
  const {
    getHistory,
    callSaveHistoryAPIFlag,
    saveHistory,
    setCallSaveHistoryAPIFlag,
  }: iHistoryContext = useHistoryContext();
  const resetState = useResetState();

  useEffect(() => {
    if (!isUserNavigatingFromPaymentGateway)
      if (
        !currentTripData ||
        (JSON.stringify(currentTripData) === "{}" && firstScreen === "")
      ) {
        // navigate("/");
        setScreen("");
        setFirstScreen("");
      }
  }, [currentTripData]);

  React.useEffect(() => {
    if (!userInfo?.loggedIn) checkIfUserIsLoggedIn(setUserInfo);
    let offineDetectInterval = window.setInterval(() => {
      if (!navigator.onLine) {
        setNetworkError(true);
        setErrorMessage(
          getConfigMessage(appConfig, APP_CONFIG.NO_INTERNET_MESSAGE.info)
        );
        return;
      }
      setNetworkError(false);
    }, 800);

    // gapi.load("client:auth2", () => {
    //   gapi.client.init({
    //     clientId: GOOGLE_AUTH_CLIENT_ID,
    //     // "176254359773-2u5j9dv1qpg1dngo1nqr7n6ttn6knvi5.apps.googleusercontent.com",
    //     // "643694378557-60uta6pjlhds86si515v007r4ecuulrg.apps.googleusercontent.com",
    //     plugin_name: "chat",
    //   });
    // });
    return () => {
      clearInterval(offineDetectInterval);
    };
  }, []);

  // useEffect(() => {
  //   const ele = document?.getElementById("iosNative");
  //   if (ele) {
  //     if (ele instanceof HTMLInputElement || ele instanceof HTMLTextAreaElement) {
  //       ele.value = "true";
  //     }
  //   }
  // }, []);

  /**
   * Side effect to save chat content to History
   */
  useEffect(() => {
    async function saveAndGetHistory() {
      // console.log("callSaveHistoryAPIFlag", callSaveHistoryAPIFlag);
      if (
        callSaveHistoryAPIFlag.flag &&
        chatData &&
        chatData?.length > 0 &&
        userInfo?.id
      ) {
        await saveHistory(chatData, callSaveHistoryAPIFlag.method);
        if (!isMobile) {
          await getHistory(true);
          // setTimeout(async () => {
          // }, 4000);
        }
      }
      setCallSaveHistoryAPIFlag({
        flag: false,
        method: "",
      });
    }
    saveAndGetHistory();
  }, [
    // chatData,
    callSaveHistoryAPIFlag.flag,
  ]);

  useEffect(() => {
    getHistory();
  }, [userInfo]);

  // useEffect(() => {

  //   setIsIOSNativeApp(nativeElement?.textContent === "true");
  // }, []);

  useEffect(() => {
    if (!userInfo?.id) {
      setFirstScreen("");
      setScreen("");
    }
  }, []);

  /**
   * Google Analytics and getting the APP config
   */
  React.useEffect(() => {
    async function fetchConfig() {
      try {
        const response: GetConfigResponse | any = await getConfig();
        if (response?.statusCode !== 200) {
          handleCustomError(response?.statusCode, "config.details");
          return null;
        }
        if (response?.data) {
          setAppConfig(response?.data);
        }
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    }

    fetchConfig();

    // Initialize Google Analytics
    if (!ReactGA.isInitialized) {
      ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);

  const checkMobileView = useCallback(() => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [setIsMobile]);

  const handleMobileUpdate = useCallback(() => {
    setIsMobile(true);
  }, [setIsMobile]);

  const networkErrorMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.NO_INTERNET_CONNECTION)
      ?.value ??
    "NO INTERNET CONNECTION Please check your connection and try again.";

  useLayoutEffect(() => {
    const isHomeOrTopDestination =
      pathname === ROUTES.Home ||
      pathname === ROUTES.TopDestination ||
      pathname === ROUTES.Faqs ||
      pathname === ROUTES.AboutUs ||
      pathname === ROUTES.PaymentSuccess ||
      pathname === ROUTES.ModifyHotelsSuccess ||
      ROUTES.PaymentFailure;
    const isResetPasswordRoute = pathname.startsWith("/reset-password/");
    const updateFunction =
      isHomeOrTopDestination || isResetPasswordRoute
        ? checkMobileView
        : handleMobileUpdate;

    window.addEventListener("resize", updateFunction);
    updateFunction();

    return () => window.removeEventListener("resize", updateFunction);
  }, [checkMobileView, handleMobileUpdate, pathname]);

  const weatherAdvisoryRoutes = [
    ROUTES.weatherAdvisory,
    ROUTES.FlightDelay,
    ROUTES.FlightSelect,
  ];
  const isWeatherAdvisory = weatherAdvisoryRoutes.includes(location.pathname);

  const [loading, setLoading] = useState(true);

  /**
   * Get user Preferences
   */
  const getUserPreferences = useCallback(async () => {
    try {
      const res = await axios.get(
        `${ENDPOINT}/app/v1/users/preferences?userId=${userInfo?.id}`
      );
      const data = res.data.data;

      if (data) {
        setUserInfo((prevDetails) => ({
          ...prevDetails,
          preferences: data,
        }));
      }
    } catch (error) {
      console.error(error);
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    } finally {
    }
  }, [userInfo?.id]);

  useEffect(() => {
    if (userInfo?.id) getUserPreferences();
  }, [userInfo?.id]);

  useEffect(() => {
    if (isOpenMobileSidebar && isMobile) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "auto";
    }
    return () => {
      document.documentElement.style.overflowY = "auto";
    };
  }, [isMobile, isOpenMobileSidebar]);

  /**
   * Check if user has current trip and then navigate
   */
  const { getCurrentTrip }: iCurrentTripContext = useCurrentTripContext();
  const navigate = useNavigate();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ifPromptInURL = urlParams.get("prompt");
    if (ifPromptInURL) {
      setIsOpenMobileSidebar(false);
      setFirstScreen("");
    }

    async function checkIfthereIsCurrentTrip() {
      if (!userInfo?.loggedIn) {
        setLoading(false);
        return;
      }
      const isThereCurrentTrip = await getCurrentTrip(userInfo?.id);
      if (
        isThereCurrentTrip &&
        (pathname === ROUTES.Home || pathname === ROUTES.Today) &&
        !ifPromptInURL
      ) {
        resetState();
        if (localIsMobile) navigate(ROUTES.Today);
        else setFirstScreen(ScreenTypes.CurrentTrip);
      }
      setLoading(false);
    }
    checkIfthereIsCurrentTrip();
  }, []);

  // const nameStored = localStorage.getItem("domain2name");

  // useEffect(() => {
  //   alert(nameStored);
  // }, [nameStored]);
  // useEffect(() => {
  //   if (localIsMobile) {
  //     document.addEventListener("visibilitychange", () => {
  //       if (document.visibilityState === "visible") {
  //         window.location.reload(); // Forces a reload when the tab becomes active
  //       }
  //     });
  //   } else {
  //     console.log("This is not Safari.");
  //   }
  // }, []);

  const lastTabLeaveTimeRef: any = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // User is leaving the tab, record the time
        lastTabLeaveTimeRef.current = Date.now();
      } else if (document.visibilityState === "visible") {
        // User has returned to the tab
        const now = Date.now();
        const timeAway = now - (lastTabLeaveTimeRef.current || now);
        // console.log(timeAway);

        // Check if user was away for 15 minutes (900,000 ms)
        if (timeAway >= 900000 && localIsMobile) {
          window.location.reload();
        }

        // Reset the last leave time
        lastTabLeaveTimeRef.current = null;
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  (window as any).fromFlutter = function (data: any) {
    // alert(data);
    const params = data;
    if (typeof params === "string") setIosNativeLoginData(JSON.parse(data));
    else setIosNativeLoginData(data);
  };

  if (networkError)
    return (
      <NetworkError
        onRefresh={handleRefresh}
        message={
          // appConfig.find(
          //   (item) => item?.key === APP_CONFIG.NO_INTERNET_CONNECTION
          // )?.value ??
          `NO INTERNET CONNECTION!\n Please check your connection and try again.`
        }
      />
    );

  if (loading)
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        {isMobile && (
          <div className={styles.headerContainer}>
            <Header />
          </div>
        )}
        <div
          className={`${isWeatherAdvisory ? styles.hideContentContainer : styles.contentContainer}`}
        >
          <AppRoutes />
        </div>

        {/* Dialog for Log in, create account */}
        {loginCreateAccountState.flag && (
          <div className={styles.dialogMainContainer}>
            <div className={styles.overlay}></div>
            <Dialog />
          </div>
        )}
        <SelectRoomModal
          isOpen={showSelectRoomModal}
          onClose={() => {
            setShowSelectRoomModal(false);
          }}
        />
        <ReviewsModal
          isOpen={showReviewsModal}
          onClose={() => {
            setShowReviews(false);
          }}
        />
        <PropertyDetailsModal
          propertyDetails={hotelDetails}
          isOpen={showPropertyDetails}
          onClose={() => setShowPropertyDetails(false)}
        />
        <BookingModals />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
