import { useContext, useEffect, useState } from "react";
import { BookingDetails, iHotelInputPayload } from "../../../../types";
import AirPortPicker from "../../inputFields/airportPicker/airportPicker";
import DateRangePicker from "../../inputFields/dateRangePicker/dateRangePicker";
import Duration from "../../inputFields/duration/duration";
import RouteAndClass from "../../inputFields/routeAndClassNew/routeAndClass";
import SearchButton from "../../inputFields/searchButton/searchButton";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import Travelers from "../../inputFields/travelers/travelers";
import Stepper from "./stepper/stepper";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { calculateTotalDays } from "../../../../utils/hotelStayCalculator";
import styles from "./flight.module.scss";

interface FlightInputFieldsProps {
  readonly bookingDetails: BookingDetails;
  readonly setBookingDetails: React.Dispatch<
    React.SetStateAction<BookingDetails>
  >;
  readonly searchFlights: () => void;
  readonly getTravelData: () => void;
  readonly handleUpdateTravelData: () => {};
  readonly cities: iHotelInputPayload[];
}

function FlightInputFields({
  bookingDetails,
  setBookingDetails,
  searchFlights,
  getTravelData,
  handleUpdateTravelData,
  cities,
}: FlightInputFieldsProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedChatData }: iGlobalContext = state;
  const [enableBtn, setEnableBtn] = useState<boolean>(true);

  const handleFlightInputValidation = () => {
    const {
      airPort,
      adultsCount,
      childrenCount,
      seniorsCount,
      travelFromDate,
    } = bookingDetails || {};
    setBookingDetails((prevData) => ({
      ...prevData,
      selectedFlightId: "",
    }));
    const checkDeparture = airPort?.some((flight) => flight.departure);
    const checkArrival = airPort?.some((flight) => flight.arrival);
    if (travelFromDate === "") {
      toastMessage.error("", "Please select a travel date.");
      setEnableBtn(true);
      return;
    }
    if (!checkDeparture) {
      toastMessage.error("", "Please select a departure airport.");
      setEnableBtn(true);
      return;
    }
    if (!checkArrival) {
      toastMessage.error("", "Please select a arrival airport.");
      setEnableBtn(true);
      return;
    }

    const totalAdults = adultsCount + seniorsCount;

    if (totalAdults === 0) {
      toastMessage.error("", "At least 1 adult or senior is required.");
      setEnableBtn(true);
      return;
    }

    if (childrenCount > 0 && totalAdults === 0) {
      toastMessage.error(
        "",
        "A child must be accompanied by at least 1 adult or senior."
      );
      setEnableBtn(true);
      return;
    }

    searchFlights();
  };

  useEffect(() => {
    const {
      airPort,
      adultsCount,
      seniorsCount,
      childrenCount,
      travelFromDate,
    } = bookingDetails || {};

    // Check if airPort is empty or has missing fields
    const isFieldMissing =
      !airPort?.length ||
      airPort.some((flight) => {
        return !flight.departure || !flight.arrival;
      });

    // Check if travelFromDate is missing or empty
    const isTravelDateMissing = !travelFromDate;

    const shouldDisableButton =
      isFieldMissing ||
      isTravelDateMissing ||
      (childrenCount === 1 && adultsCount < 1 && seniorsCount < 1) ||
      (adultsCount < 1 && seniorsCount < 1);

    setEnableBtn(shouldDisableButton);
  }, [bookingDetails]);

  const hotelStay = calculateTotalDays(cities);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.newInputCard1}>
        <DateRangePicker
          startDate={bookingDetails.travelFromDate}
          endDate={bookingDetails.travelToDate}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          handleUpdateData={() => {
            getTravelData();
            handleUpdateTravelData();
          }}
        />

        <div className={styles.card3}>
          {bookingDetails.tripType !== 1 && (
            <Duration
              bookingDetails={bookingDetails}
              flight={true}
              hotel={false}
              stayCount={hotelStay}
            />
          )}
        </div>

        <div className={styles.card1}>
          <Travelers
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            showRoom={false}
          />
        </div>
      </div>
      <div className={styles.newInputCard2}>
        <div className={styles.card4}>
          {selectedChatData?.destinationList && (
            <RouteAndClass
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              tripType={bookingDetails.tripType}
            />
          )}
        </div>

        {selectedChatData.destinationList &&
          selectedChatData.destinationList.length === 1 && (
            <>
              {bookingDetails?.airPort &&
                bookingDetails?.airPort?.length >= 1 &&
                bookingDetails.airPort.map((i, index) => (
                  <div className={styles.newInputCard2} key={index}>
                    <div className={styles.card2}>
                      <AirPortPicker
                        indexNumber={index}
                        arrival={i.arrival}
                        departure={i.departure}
                        bookingDetails={bookingDetails}
                        setBookingDetails={setBookingDetails}
                        departureAirportFullName={i.departureAirportFullName}
                        arrivalAirportFullName={i.arrivalAirportFullName}
                        handleUpdateData={() => {
                          getTravelData();
                          handleUpdateTravelData();
                        }}
                        arrivalCity={i.destinationCity}
                        departureCity={i.fromCity}
                      />
                    </div>
                  </div>
                ))}
            </>
          )}
      </div>
      {selectedChatData.destinationList &&
        selectedChatData.destinationList.length > 1 && (
          <div className={styles.stepper}>
            {bookingDetails.travelFromDate && bookingDetails.travelToDate && (
              <section className={styles.stepperDetails}>
                <Stepper
                  steps={bookingDetails?.airPort}
                  initialStep={0}
                  finalStep={bookingDetails?.airPort.length - 1}
                  showAccomodation={false}
                  cities={cities}
                />
              </section>
            )}
          </div>
        )}
      <div
        className={`${selectedChatData?.destinationList.length > 1 ? styles.searchBox : styles.singleCitySearch}`}>
        <SearchButton
          onClick={handleFlightInputValidation}
          disableButton={enableBtn}
        />
      </div>
    </div>
  );
}

export default FlightInputFields;
