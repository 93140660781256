import { useContext, useEffect, useState } from "react";
import styles from "./history.module.scss";
import { getHistoryAPICall } from "../../../api/getHistory";
import toast from "react-hot-toast";
import { ROUTES, VIEW_SAVE_HISTORY } from "../../../constant";
import Loader from "../../reusableComponent/loader/Loader";
import { Stack } from "@mui/material";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { useNavigate } from "react-router-dom";
import { getConfigMessage } from "../../../utils";
import { sortDatesInAscendingOrder } from "../../../utils/dateUtils";
import ReactGA from "react-ga4";
import { APP_CONFIG } from "../../../constant/common";
import SwipeToDelete from "../../reusableComponent/swipeToDelete";
import { deleteHistoryAPI } from "../../../api/deleteHistory";
import { ScreenTypes } from "../../../enums";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { iHistoryContext } from "../../../context/historyContext/interface";
import { useHistoryContext } from "../../../context/historyContext/historyContext";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import useCustomErrorHandler from "../../../hooks/useCustomErrorHandler";

function HistoryTab({
  // todayHistory,
  // setTodayHistory,
  // yesterdayHistory,
  // setYesterdayHistory,
  // oneWeekAgoHistory,
  // setOneWeekAgoHistory,
  // lastOneMonth,
  // setLastOneMonth,
  setIsOpenMobileSidebar,
  isOpenMobileSidebar = false,
}: {
  // todayHistory: any;
  // setTodayHistory: any;
  // yesterdayHistory: any;
  // setYesterdayHistory: any;
  // oneWeekAgoHistory: any;
  // setOneWeekAgoHistory: any;
  // lastOneMonth: any;
  // setLastOneMonth: any;
  setIsOpenMobileSidebar?: any;
  isOpenMobileSidebar?: boolean;
}): JSX.Element {
  // const [isLoading, setIsLoading] = useState(true);
  const globalContext = useContext(StateContext);
  const {
    setChatData,
    isMobile,
    setFirstScreen,
    setScreen,
    userInfo,
  }: iGlobalContext = globalContext;
  const {
    isLoading,
    todayHistory,
    yesterdayHistory,
    oneWeekAgoHistory,
    lastOneMonth,
    historyPaginationLoaderRef,
    hasMoreHistory,
    setCurrentHistoryId,
  }: iHistoryContext = useHistoryContext();
  const { handleCustomError } = useCustomErrorHandler();
  const navigate = useNavigate();

  function handleViewHistory(data: any) {
    setScreen("");
    // console.log("HISTORY", data);
    setCurrentHistoryId(data?.id || "");
    if (data?.history) setChatData(data.history);
    // console.log(data.history);
    if (setIsOpenMobileSidebar) setIsOpenMobileSidebar(false);
    if (isMobile) navigate(ROUTES.TopDestination);
    else setFirstScreen(ScreenTypes.topDestination);

    triggerGAEvent(GA_LABEL_ENUMS.History_click);
    // ReactGA.event({
    //   category: VIEW_SAVE_HISTORY.CATEGORY,
    //   action: VIEW_SAVE_HISTORY.ACTION,
    //   label: ` ${VIEW_SAVE_HISTORY.LABEL} ${data?.history[0]?.message}`,
    // });
  }

  // useEffect(() => {
  //   console.log(
  //     todayHistory,
  //     yesterdayHistory,
  //     lastOneMonth,
  //     oneWeekAgoHistory
  //   );
  // }, [todayHistory, yesterdayHistory, lastOneMonth, oneWeekAgoHistory]);

  async function handleDelete(data: any) {
    toast.loading("Deleting history...");
    // console.log(data);
    const response = await deleteHistoryAPI(data.id || "");
    // Null check for the API response
    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "delete.history");
      return null;
    }
    if (response && response?.statusCode === 200) {
      // setListItinery(listItinery.filter((item: any) => item.id !== i.id));
      toast.dismiss();
      toastMessage.success("SUCCESS", "History deleted!");
      // // console.log(i);
      // ReactGA.event({
      //   category: DELETE_SAVED_ITINERARY.CATEGORY,
      //   action: DELETE_SAVED_ITINERARY.ACTION,
      //   label: ` ${DELETE_SAVED_ITINERARY.LABEL} ${i?.tripName}`,
      // });
      // setTripCount((prev: any) => prev - 1);
      triggerGAEvent(GA_LABEL_ENUMS.History_delete_click);
    } else {
      toast.dismiss();
      toastMessage.error("OOPS!", "Deleting failed, please try again");
    }
  }

  // console.log({todayHistory}, {yesterdayHistory}, {lastOneMonth})

  if (userInfo?.id === "") {
    return <></>;
  }

  if (
    isLoading &&
    todayHistory?.length === 0 &&
    oneWeekAgoHistory?.length === 0 &&
    yesterdayHistory?.length === 0 &&
    lastOneMonth?.length === 0
  )
    return (
      <Stack mt={2.5}>
        <Loader />
      </Stack>
    );

  return (
    <div className={styles.main}>
      {!isLoading &&
      todayHistory?.length === 0 &&
      yesterdayHistory?.length === 0 &&
      oneWeekAgoHistory?.length === 0 &&
      lastOneMonth?.length === 0 ? (
        <h1 className={styles.noData}>No Data</h1>
      ) : (
        <div className={styles.scrollContainer}>
          {todayHistory.length > 0 && (
            <HistoryList
              historyName="Today"
              list={todayHistory}
              handleDelete={handleDelete}
              handleViewHistory={handleViewHistory}
              isMobile={isMobile}
            />
          )}

          {yesterdayHistory.length > 0 && (
            <HistoryList
              historyName="Yesterday"
              list={yesterdayHistory}
              handleDelete={handleDelete}
              handleViewHistory={handleViewHistory}
              isMobile={isMobile}
            />
          )}

          {oneWeekAgoHistory.length > 0 && (
            <HistoryList
              historyName="1 week ago"
              list={oneWeekAgoHistory}
              handleDelete={handleDelete}
              handleViewHistory={handleViewHistory}
              isMobile={isMobile}
            />
          )}

          {lastOneMonth.length > 0 && (
            <HistoryList
              historyName="Last 30 days"
              list={lastOneMonth}
              handleDelete={handleDelete}
              handleViewHistory={handleViewHistory}
              isMobile={isMobile}
            />
          )}
          {hasMoreHistory && (
            // true
            <div className={styles.loader} ref={historyPaginationLoaderRef}>
              <Loader />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default HistoryTab;

function HistoryList({
  list,
  handleDelete,
  handleViewHistory,
  isMobile,
  historyName,
}: {
  list: any;
  handleDelete: any;
  handleViewHistory: any;
  isMobile: boolean;
  historyName: string;
}) {
  return (
    <>
      <div className={styles.historyCard}>
        <p className={styles.title}>{historyName}</p>
        <div className={styles.todayContainer}>
          {list.length > 0 &&
            list.map((ele: any, index: any) => {
              return (
                <SwipeToDelete
                  key={index}
                  // height={70}
                  height={isMobile ? 34 : 24}
                  onDelete={() => handleDelete(ele)}
                  onClick={() => handleViewHistory(ele)}
                >
                  <p className={styles.desc}>
                    {ele?.history[0]?.message?.length > (isMobile ? 35 : 25)
                      ? ele?.history[0]?.message.substring(
                          0,
                          isMobile ? 35 : 25
                        ) + " ..."
                      : ele?.history[0]?.message}
                    {/* {" >> " +
                  new Date(ele.createdAt).getUTCDate() +
                  "-" +
                  new Date(ele.createdAt).getUTCMonth()} */}
                  </p>
                </SwipeToDelete>
              );
            })}
        </div>
      </div>
      <div className={styles.divider} />
    </>
  );
}
