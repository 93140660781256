import { useEffect, useMemo, useState } from "react";
import { BookingDetails, TripOption } from "../../../../types";
import styles from "./route.module.scss";
import RouteClassPopover from "../../bookingPopover/routeClassPopover/RouteClassPopover";

interface CabinPickerProps {
  readonly className?: string;
  readonly label?: string;
  readonly bookingDetails: BookingDetails;
  readonly setBookingDetails: React.Dispatch<
    React.SetStateAction<BookingDetails>
  >;
  readonly tripType: number;
}
function CabinPicker({
  className,
  bookingDetails,
  setBookingDetails,
  tripType,
  label,
}: CabinPickerProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const data: TripOption[] = [
    { id: 1, value: "One-Way", checkedFlag: false },
    { id: 2, value: "Round-Trip", checkedFlag: false },
    { id: 3, value: "Multi-City", checkedFlag: false },
  ];

  const filterByTripType = (
    tripType: number,
    data: TripOption[]
  ): TripOption[] => {
    if (tripType === 3) {
      return data.filter((item) => item.id === 3);
    } else if (tripType === 1 || tripType === 2) {
      return data.filter((item) => item.id === 1 || item.id === 2);
    }
    return [];
  };

  const filteredData = filterByTripType(tripType, data);

  const updateTripType = useMemo(() => {
    return (tripType: number, data: TripOption[]): TripOption[] => {
      return data.map((item) => ({
        ...item,
        checkedFlag: item.id === tripType,
      }));
    };
  }, []);
  const updatedData = useMemo(
    () => updateTripType(tripType, filteredData),
    [tripType, filteredData, updateTripType]
  );

  const [routeData, setRouteData] = useState(updatedData);

  const [classData, setClassData] = useState([
    {
      id: 2,
      value: "Economy",
      checkedFlag: true,
    },
    {
      id: 3,
      value: "Premium Plus",
      checkedFlag: false,
    },
    {
      id: 4,
      value: "Business",
      checkedFlag: false,
    },
    {
      id: 5,
      value: "First Class",
      checkedFlag: false,
    },
  ]);

  const selectedValues = useMemo(() => {
    return classData
      .filter((item) => item.checkedFlag)
      .map((item) => item.value)
      .join(", ");
  }, [classData]);

  const selectedRouteValues = useMemo(() => {
    return routeData
      .filter((item) => item.checkedFlag)
      .map((item) => item.value)
      .join(", ");
  }, [routeData]);

  useEffect(() => {
    if (bookingDetails) {
      setBookingDetails((prevData) => ({
        ...prevData,
        tripType: tripType,
      }));
    }
  }, [tripType]);

  useEffect(() => {
    if (bookingDetails.flightClass) {
      const mappedClassData = classData.map((ele: any) => {
        let match = false;
        switch (bookingDetails.flightClass) {
          case "E":
            match = ele.value === "Economy";
            break;
          case "P":
            match = ele.value === "Premium Plus";
            break;
          case "B":
            match = ele.value === "Business";
            break;
          case "F":
            match = ele.value === "First Class";
            break;
          default:
            match = false;
        }
        return { ...ele, checkedFlag: match };
      });

      setClassData(mappedClassData);
    }
  }, [bookingDetails.flightClass]);

  return (
    <>
      {bookingDetails && (
        <RouteClassPopover
          route={routeData}
          setRoute={setRouteData}
          classData={classData}
          setClass={setClassData}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          hideRoute={true}
          popoverTrigger={
            <div className={`${className} ${styles.main}`}>
              <p className={styles.label}>{label}</p>
              <div
                className={`${isPopoverOpen ? styles.activeRoundBox : styles.roundBox}`}>
                {/* <p className={styles.tripType}>{selectedRouteValues}</p> */}
                <p className={styles.btnName}>{selectedValues}</p>
              </div>
            </div>
          }
        />
      )}
    </>
  );
}

export default CabinPicker;
