import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import { getHistoryAPICall } from "../../api/getHistory";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { apiFailedTryAgain, getConfigMessage } from "../../utils";
import { APP_CONFIG } from "../../constant/common";
import { sortDatesInAscendingOrder } from "../../utils/dateUtils";
import { iHistoryContext } from "./interface";
import { addHistoryAPI } from "../../api/saveHistory";
import { useInView } from "react-intersection-observer";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { GetHistoryResponse } from "../../interface";
const HistoryContext = createContext({} as iHistoryContext);
export const HistoryContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [todayHistory, setTodayHistory] = useState<any>([]);
  const [yesterdayHistory, setYesterdayHistory] = useState<any>([]);
  const [oneWeekAgoHistory, setOneWeekAgoHistory] = useState<any>([]);
  const [lastOneMonth, setLastOneMonth] = useState<any>([]);
  const globalContext = useContext(StateContext);
  const { userInfo, appConfig }: iGlobalContext = globalContext;
  const { handleCustomError } = useCustomErrorHandler();
  const [hasMoreHistory, setHasMoreHistory] = useState(true);
  const [skipLimitHistory, setSkipLimitHistory] = useState({
    skip: 0,
    limit: 20,
  });
  const { ref: historyPaginationLoaderRef, inView } = useInView({});

  function resetState() {
    setTodayHistory((prev: any) => []);
    setYesterdayHistory((prev: any) => []);
    setOneWeekAgoHistory((prev: any) => []);
    setLastOneMonth((prev: any) => []);
    setSkipLimitHistory({
      skip: 0,
      limit: 20,
    });
    setHasMoreHistory(true);
  }

  useEffect(() => {
    if (!userInfo?.id) {
      resetState();
    }
  }, [userInfo]);

  useEffect(() => {
    console.log("VIEW", inView, skipLimitHistory);
    if (inView) getHistory();
  }, [inView]);

  async function getHistory(reload?: boolean, openWithSideBar?: boolean) {
    if ((!userInfo?.id || !hasMoreHistory) && !openWithSideBar) return;

    setIsLoading(true);
    const response = await getHistoryAPICall(
      userInfo?.id,
      reload ? 0 : skipLimitHistory?.skip,
      reload && skipLimitHistory?.skip > 0
        ? skipLimitHistory?.skip
        : skipLimitHistory?.limit
    );
    // if (response?.statusCode !== 200) {
    //   handleCustomError(response?.statusCode, "view.history");
    //   return null;
    // }
    if (reload) resetState();
    if (response) {
      setIsLoading(false);
      // // console.log(response);
      // console.log({ response });
      if (response?.length === 0) {
        setHasMoreHistory(false);
      }
    }
    if (!response) {
      // toastMessage.error(
      //   "OOPS!",
      //   getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      // );
      setIsLoading(false);
      return;
    }
    // // console.log(response);
    // const yesterday = new Date(
    //   new Date().setDate(new Date().getUTCDate() - 2)
    // ).getTime();
    // const now = new Date();
    // const startOfToday = new Date(
    //   now.getUTCFullYear(),
    //   now.getUTCMonth(),
    //   now.getUTCDate()
    // ).getTime();
    // const endOfToday = new Date(
    //   now.getUTCFullYear(),
    //   now.getUTCMonth(),
    //   now.getUTCDate(),
    //   23,
    //   59,
    //   59,
    //   999
    // ).getTime();

    // const startOfYesterday = new Date(
    //   now.getUTCFullYear(),
    //   now.getUTCMonth(),
    //   now.getUTCDate() - 1
    // ).getTime();
    // const endOfYesterday = new Date(
    //   now.getUTCFullYear(),
    //   now.getUTCMonth(),
    //   now.getUTCDate() - 1,
    //   23,
    //   59,
    //   59,
    //   999
    // ).getTime();

    // // Calculate the start of the day one week ago
    // const startOfWeekAgo = new Date(
    //   now.getUTCFullYear(),
    //   now.getUTCMonth(),
    //   now.getUTCDate() - 7,
    //   0,
    //   0,
    //   0,
    //   0
    // ).getTime();

    setSkipLimitHistory({
      skip: skipLimitHistory.skip + skipLimitHistory.limit,
      limit: 10,
    });

    const now = new Date();

    // Calculate the start and end of today
    const startOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0,
      0
    ).getTime();
    
    const endOfToday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    ).getTime();
    
    // Calculate the start and end of yesterday
    const startOfYesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1,
      0,
      0,
      0,
      0
    ).getTime();
    
    const endOfYesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1,
      23,
      59,
      59,
      999
    ).getTime();
    
    // Calculate the start of the day one week ago
    const startOfWeekAgo = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7,
      0,
      0,
      0,
      0
    ).getTime();
    
    // Calculate the start of the day two days ago
    const yesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 2,
      0,
      0,
      0,
      0
    ).getTime();

    // setTodayHistory((prev: any) => [
    //   ...prev,
    //   ...sortDatesInAscendingOrder(
    //     response.filter(
    //       (ele: any) =>
    //         new Date(ele.createdAt).getTime() >= startOfToday &&
    //         new Date(ele.createdAt).getTime() <= endOfToday
    //     ),
    //     "createdAt"
    //   ),
    // ]);
    // setTodayHistory((prev: any) => {
    //   const unique = new Map(prev.map((item: any) => [item.id, item]));
    //   return Array.from(unique.values());
    // });
    setTodayHistory((prev: any) => {
      const merged = [
        ...prev,
        ...sortDatesInAscendingOrder(
          response.filter(
            (ele: any) =>
              new Date(ele.createdAt).getTime() >= startOfToday &&
              new Date(ele.createdAt).getTime() <= endOfToday
          ),
          "createdAt"
        ),
      ];

      // Remove duplicates based on the `id` field
      const uniqueHistory = Array.from(
        new Map(merged.map((item: any) => [item.id, item])).values()
      );

      return uniqueHistory;
    });
    setYesterdayHistory((prev: any) => {
      const merged = [
        ...prev,
        ...sortDatesInAscendingOrder(
          response.filter(
            (ele: any) =>
              new Date(ele.createdAt).getTime() >= startOfYesterday &&
              new Date(ele.createdAt).getTime() <= endOfYesterday
          ),
          "createdAt"
        ),
      ];

      // Remove duplicates based on the `id` field
      const uniqueHistory = Array.from(
        new Map(merged.map((item: any) => [item.id, item])).values()
      );

      return uniqueHistory;
    });

    setOneWeekAgoHistory((prev: any) => {
      const merged = [
        ...prev,
        ...sortDatesInAscendingOrder(
          response.filter(
            (ele: any) =>
              new Date(ele.createdAt).getTime() <= yesterday &&
              new Date(ele.createdAt).getTime() >= startOfWeekAgo
          ),
          "createdAt"
        ),
      ];

      // Remove duplicates based on the `id` field
      const uniqueHistory = Array.from(
        new Map(merged.map((item: any) => [item.id, item])).values()
      );

      return uniqueHistory;
    });

    setLastOneMonth((prev: any) => {
      const merged = [
        ...prev,
        ...sortDatesInAscendingOrder(
          response.filter(
            (ele: any) => new Date(ele.createdAt).getTime() <= startOfWeekAgo
          ),
          "createdAt"
        ),
      ];

      // Remove duplicates based on the `id` field
      const uniqueHistory = Array.from(
        new Map(merged.map((item: any) => [item.id, item])).values()
      );

      return uniqueHistory;
    });

    //   setYesterdayHistory((prev: any) => [
    //     ...prev,
    //     ...sortDatesInAscendingOrder(
    //       response.filter(
    //         (ele: any) =>
    //           new Date(ele.createdAt).getTime() >= startOfYesterday &&
    //           new Date(ele.createdAt).getTime() <= endOfYesterday
    //       ),
    //       "createdAt"
    //     ),
    //   ]);
    //   setOneWeekAgoHistory((prev: any) => [
    //     ...prev,
    //     ...sortDatesInAscendingOrder(
    //       response.filter(
    //         (ele: any) =>
    //           new Date(ele.createdAt).getTime() <= yesterday &&
    //           new Date(ele.createdAt).getTime() >= startOfWeekAgo
    //       ),
    //       "createdAt"
    //     ),
    //   ]);
    //   setLastOneMonth((prev: any) => [
    //     ...prev,
    //     ...sortDatesInAscendingOrder(
    //       response.filter(
    //         (ele: any) => new Date(ele.createdAt).getTime() <= startOfWeekAgo
    //       ),
    //       "createdAt"
    //     ),
    //   ]);
  }

  /**
   * Save history
   */
  const [currentHistoryId, setCurrentHistoryId] = useLocalStorage(
    "_trip_trove_current_history_id",
    ""
  );
  const [callSaveHistoryAPIFlag, setCallSaveHistoryAPIFlag] = useState({flag: false, method: ""});
  async function saveHistory(chatDataArgs: any, method: "POST" | "PUT") {
    if (!chatDataArgs || !userInfo?.id) return;
    let historyData: any = {
      userId: userInfo?.id || "",
      history: chatDataArgs,
    };
    if (currentHistoryId && method === "PUT")
      historyData = {
        id: currentHistoryId || "",
        userId: userInfo?.id || "",
        history: chatDataArgs,
      };
    if (userInfo?.id) {
      const response: GetHistoryResponse | any = await addHistoryAPI(
        historyData,
        method
      );
      setCurrentHistoryId(response?.data?.id);
    }
  }

  const value = {
    hasMoreHistory,
    historyPaginationLoaderRef,
    getHistory,
    isLoading,
    todayHistory,
    yesterdayHistory,
    oneWeekAgoHistory,
    lastOneMonth,
    saveHistory,
    callSaveHistoryAPIFlag,
    setCallSaveHistoryAPIFlag,
    setCurrentHistoryId,
  };
  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
export const useHistoryContext = () => useContext(HistoryContext);
