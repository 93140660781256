import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./save.module.scss";
import toast from "react-hot-toast";
import { formatDate } from "../../utils/dateUtils";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { useNavigate } from "react-router-dom";
import {
  DELETE_SAVED_ITINERARY,
  ROUTES,
  VIEW_SAVED_ITINERARY,
} from "../../constant";
import Loader from "../reusableComponent/loader/Loader";
import SwipeToDelete from "../reusableComponent/swipeToDelete";
import { deleteItineraryAPI } from "../../api/deleteItinerary";
import ReactGA from "react-ga4";
import { ScreenTypes } from "../../enums";
import { toastMessage } from "../../helpers/toast/toastMessage";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { DeleteItineraryResponse } from "../../interface";
interface iProps {
  value: number;
  tripCount: number;
  setTripCount: Dispatch<SetStateAction<number>>;
  setIsOpenMobileSidebar: any;
  listItinery: any;
  setListItinery: Dispatch<SetStateAction<any>>;
  setIsLoadingItineraryList: any;
  isLoadingItineraryList: any;
}

function SaveTrips({
  value,
  setTripCount,
  tripCount,
  setIsOpenMobileSidebar,
  listItinery,
  setListItinery,
  setIsLoadingItineraryList,
  isLoadingItineraryList,
}: iProps): JSX.Element {
  const navigate = useNavigate();
  const state = React.useContext(StateContext);
  const { setSharedItineraryId, isMobile, setScreen }: iGlobalContext = state;
  const { handleCustomError } = useCustomErrorHandler();
  function handleViewItineraryDetails(data: any) {
    // console.log(data, "PARSE");
    setSharedItineraryId(`${data.id}`);
    ReactGA.event({
      category: VIEW_SAVED_ITINERARY.CATEGORY,
      action: VIEW_SAVED_ITINERARY.ACTION,
      label: ` ${VIEW_SAVED_ITINERARY.LABEL} ${data?.tripName}`,
    });
    if (isMobile) navigate(ROUTES.SavedItineraryDetails);
    else setScreen(ScreenTypes.SavedItinerary);
    setIsOpenMobileSidebar(false);
  }

  async function handleDelete(i: any) {
    toastMessage.loading("Deleting Itinerary...");
    const response:DeleteItineraryResponse = await deleteItineraryAPI(i.id);
    // Null check for the API response
    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "delete.itinerary");
      return null;
    }
    if (response && response?.statusCode === 200) {
      setListItinery(listItinery.filter((item: any) => item.id !== i.id));
      toast.dismiss();
      toastMessage.success("Success", "Itinerary deleted!");
      // console.log(i);
      ReactGA.event({
        category: DELETE_SAVED_ITINERARY.CATEGORY,
        action: DELETE_SAVED_ITINERARY.ACTION,
        label: ` ${DELETE_SAVED_ITINERARY.LABEL} ${i?.tripName}`,
      });
      setTripCount((prev: any) => prev - 1);
    } else {
      toast.dismiss();
      toastMessage.error("OOPS!", "Deleting failed, please try again");
    }
  }

  if (isLoadingItineraryList && tripCount === 0)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.main}>
      {tripCount === 0 ? (
        <p>No Data found</p>
      ) : (
        <div className={styles.listContainer}>
          {" "}
          {listItinery &&
            listItinery?.map((i: any, index: number) => (
              <SwipeToDelete
                key={i.id}
                height={40}
                onDelete={() => handleDelete(i)}>
                <div
                  className={styles.card}
                  key={index}
                  onClick={() => handleViewItineraryDetails(i)}>
                  {isMobile ? (
                    <span className={styles.title}>
                      {i.tripName.length > 35
                        ? i.tripName.substring(0, 35) + ".."
                        : i.tripName}
                    </span>
                  ) : (
                    <span className={styles.title}>
                      {i.tripName.length > 25
                        ? i.tripName.substring(0, 25) + ".."
                        : i.tripName}
                    </span>
                  )}
                  <section className={styles.detailsCard}>
                    <span className={styles.dateInfo}>
                      {/* 3 days - January 2024 */}
                      {i?.numberOfDaysSelected
                        ? Number(i?.numberOfDaysSelected) > 1
                          ? i?.numberOfDaysSelected + " days"
                          : i?.numberOfDaysSelected + " day"
                        : ""}
                      &nbsp; &nbsp;
                      {i?.selectedMonth && i?.year
                        ? i?.selectedMonth + " " + i?.year
                        : ""}
                    </span>
                    <span className={styles.createdOn}>
                      Created on {formatDate(i.createdAt)}
                    </span>
                  </section>
                </div>
              </SwipeToDelete>
            ))}
        </div>
      )}
    </div>
  );
}

export default SaveTrips;
