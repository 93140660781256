import React from "react";
import styles from "./flight.module.scss";
import {
  planeArrivalRegular,
  planeDepartureRegular,
} from "../../../../constant";
import { formatDepartureDate } from "../../../../utils/flightDepartureTimeFormater";

interface FlightDetailsProps {
  readonly airportCode: string;
  readonly airportName: string;
  readonly date: string;
  readonly flightType: string;
}

function FlightDetails(props: FlightDetailsProps): JSX.Element {
  const { airportCode, airportName, date, flightType } = props;

  return (
    <div className={styles.main}>
      <p className={styles.title}>
        <span className={styles.airportCode}>{airportCode || ""}</span> <b>|</b>{" "}
        {airportName || ""}
      </p>
      <section className={styles.descriptionCard}>
        <img
          src={
            flightType === "departure"
              ? planeDepartureRegular
              : planeArrivalRegular
          }
          alt="flight"
          className={styles.flightIcon}
        />
        {date && <p className={styles.dateInfo}>{formatDepartureDate(date)}</p>}
      </section>
    </div>
  );
}

export default FlightDetails;
