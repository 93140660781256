import { AirPort, BookingDetails } from "../../../types";

export const simplifyFlights = (
  flights: AirPort[],
  bookingDetails: BookingDetails
) => {
  return flights.map((flight) => {
    const simplifiedFlight = {
      arrival: flight.arrival,
      departure: flight.departure,
      departureDate: flight.departureDate,
    };

    if (bookingDetails.tripType !== 3 && flight.returnDate) {
      return { ...simplifiedFlight, returnDate: flight.returnDate };
    }

    return simplifiedFlight;
  });
};
