import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./savedTrips.module.scss";
import {
  MY_TRIP_TABS,
  savedDummyTripDataArr,
  TRIP_DETAILS_TABS,
} from "../../myTripsConstants";
import MyTripCard from "../myTripsCard/myTripsCard";
import { ROUTES, whiteArrowIcon } from "../../../../constant";
import toast from "react-hot-toast";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { getItinerariesListAPI } from "../../../../api/getSaveItinerariesList";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import {
  sortDatesInAscendingOrder,
  sortDatesOldtoNew,
} from "../../../../utils/dateUtils";
import { Itineraries } from "../../../../types";
import Loader from "../../../reusableComponent/loader/Loader";
import { useNavigate } from "react-router-dom";
import { ScreenTypes } from "../../../../enums";
import { Button } from "@mui/material";
import { getItineraryDetailsAPI } from "../../../../api/getItineraryDetails";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import { useInView } from "react-intersection-observer";
import useCustomErrorHandler from "../../../../hooks/useCustomErrorHandler";
import { GetItinerariesListResponse, ItineraryDetailsResponse } from "../../../../interface";

interface iProps {
  className?: string;
  handleEditTripName: any;
  isTripNameEdited?: boolean;
  editTripNameData?: any;
  tripName?: any;
}

function SavedTrips(props: iProps): JSX.Element {
  const {
    className,
    handleEditTripName,
    isTripNameEdited = false,
    editTripNameData,
    tripName,
  } = props;
  const globalContext = useContext(StateContext);
  const {
    setTripOrItinearyId,
    setPrevRoute,
    setScreen,
    isMobile,
    setSelectedChatData,
    setActiveTripDetailsTab,
    userInfo,
  }: iGlobalContext = globalContext;
  const [isLoadingItineraryList, setIsLoadingItineraryList] = useState(true);
  const [listItinery, setListItinery] = useState<Itineraries>([]);
  const { handleCustomError } = useCustomErrorHandler();
  const [hasMore, setHasMore] = useState(true);
  const [skipLimit, setSkipLimit] = useState({
    skip: 0,
    limit: 10,
  });
  const { ref, inView } = useInView({});

  const getListItineries = useCallback(
    async function () {
      try {
        if (!hasMore || (!inView && skipLimit.skip > 0)) return;
        setIsLoadingItineraryList(true);
        // setTripCount(0)
        const response:GetItinerariesListResponse = await getItinerariesListAPI(
          userInfo?.id,
          "saved",
          skipLimit.skip,
          skipLimit.limit
        );
        if (response?.statusCode !== 200) {
          handleCustomError(response?.statusCode, "itinerary.list");
          return null;
        }
        setIsLoadingItineraryList(false);
        if (response && response?.data) {
          setSkipLimit({
            skip: skipLimit.skip + skipLimit.limit,
            limit: 10,
          });
          setListItinery([
            ...listItinery,
            ...sortDatesInAscendingOrder(response?.data, "createdAt"),
          ]);
          if (response?.data?.length === 0) setHasMore(false);
          // setListItinery(sortDatesOldtoNew(response?.data, "startDate"));
          // setTripCount(response?.data.length);

          // console.log(
          //   sortDatesInAscendingOrder(response?.data, "startDate"),
          //   "response"
          // );
        } else {
          throw new Error("No response data");
        }
      } catch (error) {
        toastMessage.error("Something went wrong!");
      }
    },
    [userInfo?.id, inView]
  );
  useEffect(() => {
    getListItineries();
  }, [inView]);

  useEffect(() => {
    setListItinery(
      listItinery.map((ele: any) =>
        ele?.id === editTripNameData?.id ? { ...ele, tripName: tripName } : ele
      )
    );
  }, [isTripNameEdited]);

  const navigate = useNavigate();
  const handleViewTrip = useCallback(async (trip: any) => {
    setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
    toast.dismiss();
    setTripOrItinearyId(trip?.id || "");
    // navigate(ROUTES.TripDetails);
    setPrevRoute(ROUTES.MyTrips);
    if (!isMobile) setScreen(ScreenTypes.TripDetails);
    else navigate(ROUTES.TripDetails);
  }, []);

  async function handleBookTrip(trip: any) {
    setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
    toast.dismiss();
    try {
      setTripOrItinearyId(trip?.id || "");
      setPrevRoute(ROUTES.MyTrips);

      const response: ItineraryDetailsResponse = await getItineraryDetailsAPI(
        trip?.id || ""
      );
      if (response?.statusCode !== 200) {
        handleCustomError(response?.statusCode, "itinerary.details");
        return;
      }

      const metaData =
        typeof response?.data?.metaData === "string"
          ? JSON.parse(response?.data?.metaData)[0]
          : response?.data?.metaData;
      const selectedChatData = metaData?.selectedChatData;
      setSelectedChatData(selectedChatData);
      triggerGAEvent(GA_LABEL_ENUMS.my_trips_saved_trips_book_click);
      if (!isMobile) {
        setScreen(ScreenTypes.ItineraryInfo);
      } else {
        navigate(ROUTES.ItineraryDetails);
      }
    } catch (error) {
      console.error("Error booking trip:", error);
    }
  }

  if (isLoadingItineraryList && listItinery.length === 0)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );

  return (
    <div className={`${className} ${styles.savedTripsContainer}`}>
      {listItinery?.length > 0 ? (
        <div>
          {listItinery.map((trip: any, index: any) => {
            return (
              <div className={styles.upcomingTripSubContainer} key={index}>
                <MyTripCard
                  trip={{ ...trip, tripType: MY_TRIP_TABS.SAVED }}
                  onCardClick={() => {
                    // handleViewTrip(trip);
                    handleBookTrip(trip);
                  }}
                  showNextIcon={true}
                  handleEditTripName={() =>
                    handleEditTripName(trip, MY_TRIP_TABS.SAVED)
                  }
                />
                <p
                  className={styles.iconWithBookAgainText}
                  onClick={() => handleBookTrip(trip)}
                >
                  <p>BOOK</p>
                  <img
                    className={styles.nextIcon}
                    // src={selectedIcon}
                    src={whiteArrowIcon}
                    alt="selected"
                  />
                </p>
                {/* <h1 className={styles.bookedDate}>{trip?.bookedOnDate}</h1> */}
                {/* <h1 className={styles.bookedDate}>{trip?.bookedOnDate}</h1> */}
              </div>
            );
          })}
          {hasMore && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <p className={styles.noData}>You don't have any saved trips.</p>
      )}
    </div>
  );
}

SavedTrips.defaultProps = {
  className: "",
};

export default SavedTrips;
