import React, { useContext, useEffect, useRef, useState } from "react";
import { Stack, Button } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { checkIfUserIsLoggedIn } from "../../utils/loginAndLocalStorageutils";
import BackButtonHandler from "../../component/BackButtonHandler";
import { APP_URL } from "../../constant/environment";
import styles from "./itineraryDetails.module.scss";
import { iGlobalContext } from "../../context/globalContext/interface";
import ReactGA from "react-ga4";
import {
  APP_CONFIG,
  DialogSelected,
} from "../../constant/common";
import CityTabs from "./cityTabs/CityTabs";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  backIcon,
  buyIcon,
  cartIcon,
  editIcon,
  ROUTES,
  saveIcon,
  SENDING_CHAT_MESSAGE,
  shareIcon,
} from "../../constant";
import {
  BookingDetails,
  iChatData,
  iCityDetailsResult,
  iHotelStayTypes,
} from "../../types";
import {
  checkIfThereIsDestination,
  getConfigMessage,
  getItineraryVersion,
  scrollToBottom,
  scrollToTop,
} from "../../utils";
import { ScreenTypes } from "../../enums";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { v4 } from "uuid";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { modifyItineraryAPI } from "../../api/llm/modifyItinerary";
import toast from "react-hot-toast";
import { saveItineraryDetailsAPI } from "../../api/saveItineraryDetails";
import { iHistoryContext } from "../../context/historyContext/interface";
import { useHistoryContext } from "../../context/historyContext/historyContext";
import { getAirports } from "../../api/booking/booking";
import Loader from "../../component/reusableComponent/loader/Loader";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import MessagePopupDialog from "./messagePopupDialog/MessagePopupDialog";
import useValidateDate from "../../hooks/useValidateDate";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { isIPhone } from "../../utils/isIphone";
import { SaveItineraryDetailsResponse } from "../../interface/saveItinarary";
import { appendErrorMessageTochat } from "../../utils/appendErrorMessageToChat";
import { iItineraryDetailsContext } from "./itineraryDetailsContext/itineraryDetailsInterface";
import { useItineraryContext } from "./itineraryDetailsContext/ItineraryDetailsContext";

let abortPlanTripDetails = new AbortController();

export default function ItineraryDetails() {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setSaveScreenState,
    setLoginCreateAccountState,
    loginCreateAccountState,
    setNav,
    setMenuOpenState,
    tripForNDays,
    setTripForNDays,
    setExtraMeta,
    extraMeta,
    sharedItineraryId,
    setDataStream,
    query,
    setShareClick,
    setIsItinerarySavedFlag,
    setSelectedChatData,
    chatData,
    selectedChatData,
    scrollContainerRef,
    setScreen,
    isMobile,
    screen,
    setChatData,
    userInfo,
    setUserInfo,
    setModifyItineraryPrompt,
    setBookingDetails,
    setSelectedCityListPrompContext,
    setSelectedFlightOptions,
    selectedFlightOptions,
    setFlightData,
    setHotelData,
    appConfig,
    setShowMore,
    setAirPorts,
    isFetchingAirports,
    setIsFetchingAirports,
    setPrevRoute,
    setHotelPagination,
    prevRoute,
    setFirstScreen,
  }: iGlobalContext = state;
  const {
    showLoader,
    fetchAirportController,
    setShowLoader,
    openModifyItineraryDialog,
    setOpenModifyItineraryDialog,
    handleBackButton,
    noOfCitySelected,
    handleNav,
    handleSaveItinerary,
    handleOnShare,
    handleShare,
    handleBook,
    tempSelectedChatData,
    handleEditActivity,
    disableInput,
    showEditActivityFlag,
    setShowEditActivityFlag,
    handleSendMessage,
    inputValue,
    setInputValue,
    handleCancel,
  }: iItineraryDetailsContext = useItineraryContext();

  useEffect(() => {
      scrollToTop(scrollContainerRef);
  }, []);

  if (JSON.stringify(selectedChatData) === "{}")
    return (
      <Stack mt={5}>
        <Loader />
      </Stack>
    );
  if (showLoader)
    return (
      <div className={styles.newLoaderContainer}>
        <NewLoader
          text="Please wait"
          className={styles.loaderMain}
          cancelButtonClassName={styles.cancelBtn}
          isOpen={true}
          onClose={() => {
            if (fetchAirportController?.current)
              fetchAirportController?.current.abort();
            setShowLoader(false);
          }}
        />
      </div>
    );
  return (
    <div className={styles.desktopScrollContainer}>
      {/* {disableInput && (
        <div className={styles.overlay}>
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        </div>
      )} */}
      <MessagePopupDialog
        open={openModifyItineraryDialog?.isOpen}
        message={openModifyItineraryDialog?.message || ""}
        onClose={() => {
          setOpenModifyItineraryDialog({
            ...openModifyItineraryDialog,
            isOpen: false,
          });
        }}
      />
      <div className={styles.mainContainer} ref={scrollContainerRef}>
        <BackButtonHandler
          onBack={() => {
            handleBackButton();
            setAirPorts([]);
          }}
        />
        {/* Save and Share Button */}
        <div className={styles.shareSaveAdjustContainer}>
          {noOfCitySelected > 1 &&
            !selectedChatData.singleSelectedDestination && (
              <Button
                onClick={handleNav}
                className={`${styles.button} ${styles.adjustButton}`}
              >
                <img className={styles.iconButton} src={editIcon} alt="share" />
                ADJUST
              </Button>
            )}
        </div>

        {/* Itinerary Details */}
        <div className={styles.stickyHeading}>
          <div className={styles.closeBtnContainer}>
            <Button
              className={`${styles.columnAlignButton}`}
              onClick={handleBackButton}
            >
              <img src={backIcon} alt="Close" />
              <p>BACK</p>
            </Button>
          </div>
          <div className={styles.buttonContainer}>
            {/* <Button className={`${styles.rowAlignButton}`} onClick={handleEdit}>
            <img className={styles.iconButton} src={editIcon} alt="edit" />
            EDIT
          </Button> */}

            <Button
              onClick={() => {
                handleSaveItinerary();
                setShareClick(false);
              }}
              disabled={selectedChatData?.isSaved}
              className={`${styles.rowAlignButton} ${styles.saveBtn}`}
            >
              <img className={styles.iconButton} src={saveIcon} alt="save" />
              <span>{selectedChatData?.isSaved ? "SAVED" : "SAVE"}</span>
            </Button>

            {selectedChatData?.isSaved ? (
              <RWebShare
                data={{
                  url: `${APP_URL}shared/${selectedChatData?.id}`,
                  title: "Zenvoya",
                }}
                onClick={handleOnShare}
              >
                <Button
                  className={`${styles.rowAlignButton}`}
                  onClick={handleShare}
                >
                  <img
                    className={styles.iconButton}
                    src={shareIcon}
                    alt="share"
                  />
                  SHARE
                </Button>
              </RWebShare>
            ) : (
              <Button
                className={`${styles.rowAlignButton}`}
                onClick={handleShare}
              >
                <img
                  className={styles.iconButton}
                  src={shareIcon}
                  alt="share"
                />
                SHARE
              </Button>
            )}

            <Button className={`${styles.rowAlignButton}`} onClick={handleBook}>
              <img
                className={styles.iconButton}
                src={true ? cartIcon : buyIcon}
                alt="buy"
              />
              <p>
                {" "}
                {true ? (
                  "Book"
                ) : (
                  <span>
                    VIEW FLIGHTS <br /> & HOTELS
                  </span>
                )}
              </p>
            </Button>
          </div>
        </div>

        {/* <div className={styles.headingContainer}>
        {noOfCitySelected === 1 && tempSelectedChatData?.length > 0 && (
          <div className={styles.headingText}>
            <h1>{tempSelectedChatData[0]?.cityName?.split(",")[0]}</h1>
            <h2>
              {
                tempSelectedChatData[0]?.cityName?.split(",")[
                  tempSelectedChatData[0]?.cityName?.split(",").length - 1
                ]
              }
            </h2>
          </div>
        )}
      </div> */}

        {/* Tab container */}
        <CityTabs
          isThereFlightBooked={true}
          itineraryVersion={selectedChatData?.itinerary_version || 0}
          itineraryDescription={
            selectedChatData?.complete_itinerary_description
          }
          destinationList={tempSelectedChatData}
          handleEditActivity={handleEditActivity}
          disableInput={disableInput}
          showEditActivityFlag={showEditActivityFlag}
          setShowEditActivityFlag={setShowEditActivityFlag}
        />
        <div className={styles.inputContainer}>
          <InputWithMic
            placeholder="Type here to update the itinerary"
            disable={disableInput || showEditActivityFlag}
            onSend={() => {
              handleSendMessage();
              SpeechRecognition.stopListening();
            }}
            onStopRecording={() => {}}
            value={inputValue}
            setValue={setInputValue}
            handleCancel={handleCancel}
            disableSendBtn={inputValue === "" ? true : false}
          />
        </div>
      </div>
    </div>
  );
}
//  onSend={() => handleSendMessage(chatInputText)}
//               onStopRecording={() => handleSendMessage(chatInputText)}
//               value={chatInputText}
//               setValue={setChatInputText}
//               handleCancel={handleCancel}
//               disableSendBtn={chatInputText === "" ? true : false}
