export interface GetConfigResponse {
    statusCode: number;
    message:    string;
    data:       Config[];
}

export interface Config {
    id:             string;
    key:            string;
    value:          null | string;
    configCategory: ConfigCategory;
    description:    null | string;
    status:         boolean;
    createdAt:      Date;
    updatedAt:      Date;
    deletedAt:      null;
}

export enum ConfigCategory {
    ErrorsCategory = "errorsCategory",
    FAQCategory = "faqCategory",
    LlmCategory = "llmCategory",
    MessagesCategory = "messagesCategory",
    PromptTemplate = "promptTemplate",
}
