import axios from "axios";
import { ENDPOINT, environment } from "../constant/environment";
import { toastMessage } from "../utils/ToastProvider";
import { getConfigMessage } from "../utils";
import { APP_CONFIG, ENVIRONMENT_TYPE } from "../constant/common";

export async function chatInteractionAPI(
  userId: string,
  userInput: string,
  messages: [],
  signal?: AbortSignal,
  iteneryInput?: any,
  appConfig?: any
) {
  const URL = `${ENDPOINT}/app/v1/itineraries/search`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    signal,
    // timeout: 1000
  };
  let body = {
    userId,
    userInput,
    messages,
    iteneryInput,
  };
  try {
    // console.log(body);
    const response = await axios.post(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error: any) {
    if (error?.code !== "ERR_CANCELED" && environment !== ENVIRONMENT_TYPE.PRODUCTION) 
      toastMessage.error(
        "OOPS!",
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    console.log(error);
    return null;
  }
}
