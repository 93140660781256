import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import { localStorageName, ScreenTypes } from "../../enums";
import {
  ROUTES,
  SENDING_CHAT_MESSAGE,
  VIEW_RECOMMENDED_TRIPS,
} from "../../constant";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import { RESPONSE_DUMMY } from "../../constant/responseDummy";
import {
  checkIfThereIsDestination,
  getConfigMessage,
  getItineraryVersion,
  scrollToBottom,
} from "../../utils";
import {
  APP_CONFIG,
  ENVIRONMENT_TYPE,
  INVALID_JSON_PROMPT,
  LLM_RESPONSE_ERROR,
} from "../../constant/common";
import toast from "react-hot-toast";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { useNavigate } from "react-router-dom";
import { getFrequentlyViewTripsApi } from "../../api/getFrequentlyViewTrips";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { iGlobalContext } from "../globalContext/interface";
import { StateContext } from "../globalContext/context";
import ReactGA from "react-ga4";
import SpeechRecognition from "react-speech-recognition";
import { v4 } from "uuid";
import { iRecommendedTripContext } from "./recommendedTripContextInterface";
import { getUserRecommendedTripBasedOnPreferencesAPI } from "../../api/getUserRecommendTripBasedOnPreferences";
import { iHistoryContext } from "../historyContext/interface";
import { useHistoryContext } from "../historyContext/historyContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getItinerariesListAPI } from "../../api/getSaveItinerariesList";
import { iChatData } from "../../types";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { environment } from "../../constant/environment";
import { appendErrorMessageTochat } from "../../utils/appendErrorMessageToChat";
import { GetItinerariesListResponse } from "../../interface";

const RecommendedTripContext = createContext({} as iRecommendedTripContext);

export const RecommendedTripContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    setShowMoreFlag,
    setApiResponse,
    setOpenOverlayScreen,
    setChatData,
    setFirstScreen,
    setSharedItineraryId,
    setIsItinerarySavedFlag,
    setenableMultiSelect,
    setScreen,
    openDetailsSliderFlag,
    appConfig,
    setSelectedChatData,
    isMobile,
    chatData,
    userInfo,
  }: iGlobalContext = context;
  const {
    setCallSaveHistoryAPIFlag,
    getHistory,
    callSaveHistoryAPIFlag,
  }: iHistoryContext = useHistoryContext();
  const { handleCustomError } = useCustomErrorHandler();
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [temFreqViewTrips, setFreqViewTrips] = useSessionStorage(
    localStorageName.TriptroveFrequentlyViewTrips,
    [] as any
  );
  const [index, setIndex] = useState({
    first: 0,
    last: 3,
  });
  useEffect(() => {
    setIndex({
      first: 0,
      last: temFreqViewTrips.length - 1,
    });
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 5320,
      });
    }
  }, [temFreqViewTrips]);

  const [frequentlyViewTrips, setFrequentlyViewTrips] = useState([] as any[]);
  async function getFrequentlyViewTrips() {
    const response = await getFrequentlyViewTripsApi();
    if (response && response?.statusCode === 200) {
      setFreqViewTrips(response?.data);
      setFrequentlyViewTrips([
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
      ]);
    } else {
    }
  }

  const [urlParamQuestion, setURLParamQuestion] = useState("");
  /**
   * Side effect to listen if user has added the question in the URL
   */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("prompt");
    if (data) {
      const formattedData = decodeURIComponent(data)
        .replace(/\+/g, " ")
        .replace(/=/g, "")
        .replace(/\?/g, "");
      setURLParamQuestion(formattedData);
      setChatInputText(formattedData);
    }
  }, []);

  useEffect(() => {
    if (urlParamQuestion) {
      setChatInputText(urlParamQuestion);
      if (chatInputText) {
        setChatData([
          {
            id: v4(),
            message: urlParamQuestion,
            isUserMessage: true,
            prompt: [],
            isLoading: false,
            destinationList: [],
            singleSelectedDestination: false,
          },
        ]);
        handleCreateItinerary();
      }
    }
  }, [urlParamQuestion]);

  /**
   * Get user recomended trip
   */
  useEffect(() => {
    async function getUserRecommendedTripBasedOnPreferences() {
      let response = await getUserRecommendedTripBasedOnPreferencesAPI(
        "85df03ef-082e-4cb1-9e5c-3c0281c7da8a"
      );
      // console.log("RECOMMEND _ 1", response);
      response = checkIfThereIsDestination(response);

      // console.log("RECOMMEND _ 2", response);
      const citiesList = response?.data?.cities;
      if (citiesList?.length > 0) {
        // console.log("HERE IT IS RES", citiesList);
        const tempResponse = citiesList.map((ele: any) => ({
          ...ele,
          id: v4(),
          // checkedFlag: response?.data?.singleCity,
          checkFlag: false,
          checkedFlag: true,
          locations: [],
        }));
        const currentItineraryList = tempResponse;
        for (let i = 0; i < currentItineraryList.length; i++) {
          const cityName = currentItineraryList[i].cityName;
          let imageRespone: any = await getItineraryImageURL(cityName);
          let count = 0;
          while (!imageRespone || !imageRespone?.data?.image) {
            // Limiting the number of API call to 10 times incase of 409 error
            count++;
            if (count === 3) break;
            // re calling the API
            imageRespone = await getItineraryImageURL(cityName);
          }
          if (imageRespone?.statusCode === 200) {
            currentItineraryList[i].imageURL = imageRespone.data.image;
          } else {
            currentItineraryList[i].imageURL = "https://error.error";
            // console.log("RESPONSE TOP Error");
          }
        }

        const data: any = {
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: tempResponse,

          complete_itinerary_description:
            response?.data?.complete_itinerary_description,
        };
        // console.log("RECOMMEND _ 3", data);

        setFreqViewTrips([data]);
        setFrequentlyViewTrips([data, data, data, data, data, data, data]);
      }
    }
    // if (userInfo?.loggedIn) {
    //   getUserRecommendedTripBasedOnPreferences();
    // } else {
    //   getFrequentlyViewTrips();
    // }

    // getFrequentlyViewTrips();
  }, []);

  const navigate = useNavigate();
  async function handleCreateItinerary() {
    scrollToBottom(scrollContainerRef);
    setenableMultiSelect(false);
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setOpenOverlayScreen(true);
    setDisableInput(true);
    SpeechRecognition.stopListening();
    setApiResponse([]);
    setShowMoreFlag(false);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userInfo?.id || "",
      chatInputText,
      [],
      abortController.signal,
      null,
      appConfig
    );
    scrollToBottom(scrollContainerRef);
    if (!response && abortController.signal.aborted) {
      setChatData((prev: iChatData[]) => {
        const length = prev.length;
        if (prev.length > chatData.length)
          return [...prev.splice(0, length - 2)];
        return prev;
      });
      return;
    }

    /**
     * Recalling the API if it fails
     */
    if (
      // response === null ||
      // (!response && !abortController.signal.aborted) ||
      // !response?.data?.result ||
      checkIfThereIsDestination(response) === LLM_RESPONSE_ERROR.jsonError.error
    ) {
      response = await chatInteractionAPI(
        userInfo?.id || "",
        chatInputText + INVALID_JSON_PROMPT,
        [],
        abortController.signal,
        null,
        appConfig
      );
    }

    // console.log("RES RES RES", response);
    if (
      response === null ||
      (!response && !abortController.signal.aborted) ||
      !response?.data?.result
    ) {
      // setChatData((prev: iChatData[]) => {
      //   const length = prev.length;
      //   if (prev.length > chatData.length)
      //     return [...prev.splice(0, length - 1)];
      //   return prev;
      // });
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
      appendErrorMessageTochat(
        chatData,
        setChatData,
        LLM_RESPONSE_ERROR.timeOut.message
      );

      setOpenOverlayScreen(false);
      setDisableInput(false);
      setScreen("");
      setCallSaveHistoryAPIFlag({
        flag: true,
        method: "POST"
      });
      if (isMobile) navigate(ROUTES.TopDestination);
      else setFirstScreen(ScreenTypes.topDestination);
      setChatInputText("");
      return;
      return;
    }

    setOpenOverlayScreen(false);
    response = checkIfThereIsDestination(response);
    // console.log("HERE", response);
    if (response === LLM_RESPONSE_ERROR.jsonError.error) {
      setDisableInput(false);
      if (environment !== ENVIRONMENT_TYPE.PRODUCTION) {
        toastMessage.error(
          TOAST_MESSAGES.SaveItinerary.destinationError.messageTitle,
          // getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
          TOAST_MESSAGES.SaveItinerary.destinationError.message
        );
      }
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          id: v4(),
          message: LLM_RESPONSE_ERROR.jsonError.message,
          isUserMessage: false,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);

      setOpenOverlayScreen(false);
      setDisableInput(false);
      setScreen("");
      setCallSaveHistoryAPIFlag({
        flag: true,
        method: "POST"
      });
      if (isMobile) navigate(ROUTES.TopDestination);
      else setFirstScreen(ScreenTypes.topDestination);
      setChatInputText("");
      return;
    }
    const citiesList = response?.data?.cities;
    // console.log("CITYLIST", response?.data);
    if (citiesList?.length > 0) {
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        // checkedFlag: citiesList?.length === 1 ? true : false,
        // checkedFlag: response?.data?.singleCity,
        checkFlag: false,
        locations: [],
      }));
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          ...response?.data,
          results: response?.data?.results,
          cities: [],
          city_wise_itinerary: [],
          result: "",
          messages: [],
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: tempResponse,
          itinerary_version: "1",
          complete_itinerary_description:
            response?.data?.complete_itinerary_description,
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        // console.log({ currentItineraryList });
        const cityName =
          currentItineraryList[i]?.destination_city ||
          currentItineraryList[i]?.city_name ||
          currentItineraryList[i]?.destination_city ||
          "";
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 3) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          // console.log("RESPONSE TOP Error");
        }
      }
    } else {
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
    // setFirstScreen("");
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });

    setOpenOverlayScreen(false);
    setDisableInput(false);
    setScreen("");
    setCallSaveHistoryAPIFlag({
      flag: true,
      method: "POST"
    });
    if (isMobile) navigate(ROUTES.TopDestination);
    else setFirstScreen(ScreenTypes.topDestination);
    setChatInputText("");
    scrollToBottom(scrollContainerRef);
  }

  const [, setSelectedRecommendedTripId] = useState("");

  function handleViewItineraryDetails(data: any) {
    // console.log(JSON.parse(data?.metaData)[0].selectedChatData);
    setSelectedChatData(
      typeof data?.metaData === "string"
        ? JSON.parse(data?.metaData)[0].selectedChatData
        : data?.metaData.selectedChatData
    );
    setSelectedRecommendedTripId(data.id);
    // if (!userInfo?.loggedIn)
    //   setSharedItineraryId(`${data.id}` || "");
    // else {
    //   setSelectedChatData(data);
    // }
    setSharedItineraryId("");
    setIsItinerarySavedFlag(false);
    // ReactGA.event({
    //   category: VIEW_RECOMMENDED_TRIPS.CATEGORY,
    //   action: VIEW_RECOMMENDED_TRIPS.ACTION,
    //   label: ` ${VIEW_RECOMMENDED_TRIPS.LABEL}  ${data?.tripName}`,
    // });
    triggerGAEvent(
      GA_LABEL_ENUMS.Clicks_Recommended_Trips_card,
      data?.tripName
    );
    if (isMobile) navigate(ROUTES.RecommendedTripDetails);
    setScreen(ScreenTypes.SavedItinerary);
  }

  useEffect(() => {
    if (openDetailsSliderFlag === false) setSelectedRecommendedTripId("");
  }, [openDetailsSliderFlag]);

  // const scrollHandler = (elementRef: any) => {
  //   elementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  // };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setOpenOverlayScreen(false);
      setChatInputText("");
      setDisableInput(false);
    }
  };
  function handleScrollLeft() {
    if (index.first > 0 && sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -190,
        behavior: "smooth",
      });
      setIndex({
        first: index.first - 1,
        last:
          (index.last - 1 + temFreqViewTrips.length) % temFreqViewTrips.length,
      });
    }
  }

  function handleScrollRight() {
    setFrequentlyViewTrips([
      ...frequentlyViewTrips,
      temFreqViewTrips[index.first],
    ]);
    setIndex({
      first: (index.first + 1) % temFreqViewTrips.length,
      last: (index.last + 1) % temFreqViewTrips.length,
    });
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 190,
        behavior: "smooth",
      });
    }
  }

  /**
   * Upcoming trip
   */
  const [upcomingTripArr, setUpcomingTripArr] = useLocalStorage(
    "_trip_trove_upcoming_trip_arr",
    []
  );
  // async function getUpcomingTrip() {
  //   const response = await getItinerariesListAPI(
  //     userInfo?.id,
  //     "upcoming",
  //     0,
  //     10
  //   );
  //   if (response?.statusCode !== 200) {
  //     handleCustomError(response?.statusCode, "itinerary.list");
  //     return null;
  //   }
  //   if (response) {
  //     setUpcomingTripArr(response?.data || []);
  //   }
  // }
  async function getUpcomingTrip() {
    try {
      const response:GetItinerariesListResponse | any = await getItinerariesListAPI(
        userInfo?.id,
        "upcoming",
        0,
        10
      );

      if (response?.statusCode === 200) {
        setUpcomingTripArr(response?.data || []);
      } else {
        handleCustomError(response?.statusCode, "itinerary.list");
        return null;
      }
    } catch (error) {
      console.error("Error fetching upcoming trips:", error);
    }
  }

  useEffect(() => {
    getUpcomingTrip();
  }, [userInfo]);

  const value = {
    scrollContainerRef,
    frequentlyViewTrips,
    index,
    handleScrollLeft,
    sliderRef,
    handleViewItineraryDetails,
    handleScrollRight,
    disableInput,
    handleCreateItinerary,
    handleCancel,
    setDisableInput,
    upcomingTripArr,
    setUpcomingTripArr,
  };
  return (
    <RecommendedTripContext.Provider value={value}>
      {children}
    </RecommendedTripContext.Provider>
  );
};
export const useRecommendedTripContext = () =>
  useContext(RecommendedTripContext);
