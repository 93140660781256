import { LLM_RESPONSE_ERROR } from "../constant/common";
import { v4 } from "uuid";
import { iChatData } from "../types";

export function appendErrorMessageTochat(
  chatData: iChatData[],
  setChatData: any,
  errorMessage: string,
) {
  setChatData((prev: iChatData[]) => {
    // console.log(prev.length, chatData.length);
    if (prev.length > chatData.length) {
      return [
        ...prev.splice(0, prev.length - 1),
        {
          id: v4(),
          message: errorMessage,
          prompt: [],
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ];
    }
    return [
      ...prev,
      {
        id: v4(),
        message: errorMessage,
        prompt: [],
        isUserMessage: false,
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ];
  });
}
