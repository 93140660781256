import styles from "./search.module.scss";
import SearchIcon from "../../assets/SearchGrayIcon.svg";

interface iProps {
  title: string;
}

function SearchBox({ title }: iProps): JSX.Element {
  return (
    <div className={styles.searchCard}>
      <img src={SearchIcon} alt="" className={styles.search} />
      <p className={styles.title}>{title}</p>
    </div>
  );
}

export default SearchBox;
