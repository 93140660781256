import { planeDepartureRegular, seatArlineRegular } from "../../../../constant";
import { AirlineTypes } from "../../../../types";
import { formatDepartureDate } from "../../../../utils/flightDepartureTimeFormater";
import NewFlightDetails from "../../../booking/newFlightDetails/newFlightDetails";
import ArrivalOnDifferentDate from "../../../reusableComponent/arrivalOnDifferentDate/ArrivalOnDifferentDate";
import styles from "./flightTabs.module.scss";

function NewFlightStepperElement({
  flightData,
  showAllSteps,
  initialStep,
  handleViewDetails,
  airline,
  flightType,
  lastStop = false,
  departureFromLayover = false,
  departureDate,
  date,
  airportCode,
  airportName,
  pnrNo,
  flightOperatorData,
}: {
  flightData: any;
  showAllSteps: boolean;
  initialStep: boolean;
  handleViewDetails?: any;
  airline?: any;
  flightType?: string;
  lastStop?: boolean;
  departureFromLayover?: boolean;
  departureDate?: string;
  date?: any;
  airportCode?: string;
  airportName?: string;
  pnrNo?: string;
  flightOperatorData?: AirlineTypes;
}) {
  function addHHMM(time: string) {
    if (typeof time !== "string" || time.split(":").length < 2) return "";
    const splitTime = time.split(":");
    return splitTime[0] + "h " + splitTime[1] + "m";
  }

  function checkIfArrivalIsNextDate(timestamp1: string, timestamp2: string) {
    if (!timestamp1 || !timestamp2) return false;
    const date1 = timestamp1.split("-");
    const date2 = timestamp2.split("-");
    return !(
      date1[0] === date2[0] &&
      date1[1] === date2[1] &&
      date1[2].substring(0, 2) === date2[2].substring(0, 2)
    );
  }
  return (
    <div className={styles.main}>
      <div className={styles.stepper}>
        <div className={styles.step}>
          <div className={styles.circle}></div>
          <div className={styles.stepContent}>
            <p className={styles.title}>
              <span className={styles.airportCode}>
                {airportCode}{" "}
                {airportName && (
                  <span className={styles.airportName}>
                    &nbsp; <b>|</b> &nbsp; {airportName}
                  </span>
                )}
              </span>
            </p>
            <div className={styles.arrivalDateContainer}>
              <img
                src={planeDepartureRegular}
                alt=""
                className={styles.planeIcon}
              />
              {date && (
                <p
                  className={`${checkIfArrivalIsNextDate(departureDate || "", date) ? styles.delayDescription : styles.description}`}>
                  {date && formatDepartureDate(date)}
                </p>
              )}
              <span className={styles.seatCard}>
                <img
                  src={seatArlineRegular}
                  alt=""
                  className={styles.airlineSeat}
                />
                <p className={styles.seatInfo}>Economy</p>
              </span>
              {checkIfArrivalIsNextDate(departureDate || "", date) && (
                <ArrivalOnDifferentDate />
              )}
            </div>
            {/* {initialStep && (
            <Button
              endIcon={
                <img
                  src={expandIcon}
                  alt=""
                  className={`${showAllSteps ? styles.hideIcon : styles.viewIcon}`}
                />
              }
              className={styles.viewDetailsButton}
              onClick={() => {
                handleViewDetails();
              }}>
              {showAllSteps ? "Collapse" : "Details"}
            </Button>
          )} */}
            {showAllSteps && (
              <div className={styles.card}>
                {flightType === "Departure" && (
                  <img
                    src={airline?.image}
                    alt="flight"
                    className={styles.airlineImg}
                  />
                )}
                {flightType === "Departure" && (
                  <div className={styles.subCard}>
                    <NewFlightDetails
                      title={airline?.name}
                      subTitle={`${airline?.code && flightData?.flightNumber ? "Flight " + airline?.code + flightData?.flightNumber : ""}`}
                      description={pnrNo ? "Booking reference: " + pnrNo : ""}
                      imgUrl={airline?.image}
                      hide={true}
                      flightName={airline?.name}
                      flightNumber={`${airline?.code && flightData?.flightNumber ? " " + airline?.code + flightData?.flightNumber : ""}`}
                      duration={flightData?.flyDuration}
                      manufactureName="BOEING 787-9"
                      operatorName={flightOperatorData?.name}
                    />

                    {/* ${getTimeDifference(flightData?.departureDate, flightData?.arrivalDate)} */}
                    {/* <NewFlightDetails
                                title={"Seats"}
                                subTitle={""}
                                description={""}
                                imgUrl={""}
                              /> */}
                  </div>
                )}
                {flightType === "Layover" && (
                  <div className={styles.subCard}>
                    {/* <NewFlightDetails
                      title={"Arrival"}
                      subTitle={""}
                      // description={getTwelveHourTimeFormat(flightData?.date)}
                      description={getTwelveHourTimeFormat(date)}
                      imgUrl={""}
                    /> */}
                    <NewFlightDetails
                      title={flightType || ""}
                      subTitle={""}
                      // description={`${getTwelveHourTimeFormat(flightData?.date) && getTwelveHourTimeFormat(flightData?.date) + " | "} Flight time: ${flightData?.flyDuration || flightData?.layover}`}
                      description={
                        // addHHMM(flightData?.flyDuration) ||
                        addHHMM(flightData?.layover) || ""
                      }
                      imgUrl={""}
                    />
                  </div>
                )}

                {flightType === "Arrival" && (
                  <div className={styles.subCard}>
                    {!lastStop && (
                      <NewFlightDetails
                        title={flightType}
                        subTitle={`${airline?.code && flightData?.flightNumber ? "Flight " + airline?.code + flightData?.flightNumber : ""}`}
                        description=""
                        imgUrl={airline?.image}
                      />
                    )}
                    {/* <NewFlightDetails
                      title={flightType || ""}
                      subTitle={""}
                      description={
                        <>
                          {flightType === "Arrival" && !lastStop
                            ? ""
                            : getTwelveHourTimeFormat(date) && (
                                <>
                                  {getTwelveHourTimeFormat(date)}
                                  {!lastStop && (
                                    <span>
                                      &nbsp;&nbsp;<b> | </b> &nbsp;&nbsp;
                                    </span>
                                  )}
                                </>
                              )}
                          {(addHHMM(flightData?.flyDuration) !== "" ||
                            addHHMM(flightData?.layover) !== "") && (
                            <>
                              {flightType === "Arrival" && lastStop
                                ? ""
                                : "Flight time: "}
                              {departureFromLayover
                                ? ""
                                : (!lastStop &&
                                    addHHMM(flightData?.flyDuration)) ||
                                  addHHMM(flightData?.layover) ||
                                  ""}
                            </>
                          )}
                        </>
                      }
                      imgUrl={""}
                    /> */}
                  </div>
                )}
              </div>
            )}
          </div>

          {flightType !== "Arrival" && (
            <div
              className={`${flightType === "Layover" ? styles.dashLine : styles.line}`}>
              <div className={`${flightType === "Layover" && styles.square}`} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewFlightStepperElement;
