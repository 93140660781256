import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDowm5oSXZUyjtK5KZVJTR27aMIxV74jnk",
  authDomain: "zenvoya-53aba.firebaseapp.com",
  projectId: "zenvoya-53aba",
  storageBucket: "zenvoya-53aba.firebasestorage.app",
  messagingSenderId: "411192459575",
  appId: "1:411192459575:web:64f8fa7d152fff672b5ab2",
};

// Initilize app
initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();

// sign in with popup
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
