import { FlightPreferences, HotelPreferences } from "../../types";

export function getPreferenceCount(
  flightData: FlightPreferences | null | undefined,
  hotelData: HotelPreferences | null | undefined,
  selectedFlightOptions:
    | { flights: boolean; hotels: boolean }
    | null
    | undefined
): number {
  if (!flightData || !hotelData || !selectedFlightOptions) {
    console.error(
      "Invalid input: flightData, hotelData, or selectedFlightOptions is null or undefined."
    );
    return 0;
  }

  let totalCount = 0;

  if (selectedFlightOptions.flights) {
    totalCount += 0;
    totalCount += flightData.connections >= 1 ? 1 : 0;
    totalCount += flightData.departureTime?.length || 0;
    totalCount += flightData.airlines?.length || 0;
    if (flightData.refundable === true) {
      totalCount += 1;
    }
  }

  if (selectedFlightOptions.hotels) {
    totalCount += 0;
    totalCount += hotelData.hotelChains?.length || 0;
    if (hotelData.refundable) {
      totalCount += 1;
    }
  }

  return totalCount;
}
