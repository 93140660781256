import React from "react";
import styles from "./sliderContainer.module.scss";
import { Box, Slider } from "@mui/material";

interface iProps {
  className?: string;
  handleChange: (_event: Event, newValue: number | number[]) => void;
  scrollValue: number;
  flightPreferences: any;
}

function SliderContainer(props: iProps) {
  const {
    handleChange,
    scrollValue,
    flightPreferences,
    className = "",
  } = props;
  return (
    <div className={`${className} ${styles.sliderContainer}`}>
      <Box maxWidth={220} minWidth={170} sx={{pt: 2}}>
        <Slider
          valueLabelDisplay="auto"
          // aria-label="slider"
          aria-label="Small"
          onChange={handleChange}
          value={scrollValue}
          draggable={false}
          min={0}
          max={flightPreferences.maxConnectionStops}
          sx={{
            color: "#A4D1F0",
            height: 8,
            "& .MuiSlider-track": {
              border: "0.5px solid #A4D1F0",
              backgroundColor: "#D8EFFF",
            },
            "& .MuiSlider-rail": {
              border: "0.5px solid #696969",
              backgroundColor: "#9E9E9E", // Color for the slider rail (if needed)
            },
            "& .MuiSlider-thumb": {
              height: 24,
              width: 24,
              backgroundColor: "#ffff",
              border: "1.3px solid #696969",
              "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                boxShadow: "#D8D8D8",
              },
              "&::before": {
                display: "none",
              },
            },
            "& .MuiSlider-valueLabel": {
              display: "none",
            },
          }}
        />
      </Box>
      <div className={styles.stopCard}>
       {scrollValue > 0 ? <span className={styles.stop}>upto {scrollValue} stops</span>: <span className={styles.stop}>Non-Stop</span>}
      </div>
    </div>
  );
}

export default SliderContainer;
