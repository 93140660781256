import React from "react";
import {
  seatArlineRegular,
  planeArrivalRegular,
  planeDepartureRegular,
  hourglassLight,
} from "../../../constant";
import styles from "./flight.module.scss";

interface NewFlightDetailsProps {
  title: string;
  subTitle?: string;
  description: string | React.ReactNode;
  subDescription?: string;
  imgUrl?: string;
  destinationImg?: string;
  flightNumber?: string;
  flightName?: string;
  operatorName?: string;
  duration?: string;
  manufactureName?: string;
  hide?: boolean;
}

function NewFlightDetails(props: Readonly<NewFlightDetailsProps>): JSX.Element {
  const {
    title,
    subTitle,
    description,
    subDescription,
    imgUrl,
    destinationImg,
    flightNumber,
    flightName,
    operatorName,
    duration,
    manufactureName,
    hide,
  } = props;

  const switchIcons = (title: string) => {
    switch (title) {
      case "Departure":
        return planeDepartureRegular;
      case "Seats":
        return seatArlineRegular;
      case "Arrival":
        return planeArrivalRegular;
      case "Layover":
        return hourglassLight;
      default:
        return;
    }
  };

  return (
    <div className={styles.main}>
      {hide && hide ? (
        <div className={styles.flightDetailCard}>
          <section className={styles.flightHeader}>
            {flightNumber && (
              <p className={styles.flightNumber}>
                {flightNumber} &nbsp;<b>|</b>&nbsp;
              </p>
            )}
            <p className={styles.flightName}>{flightName && flightName}</p>
            {operatorName && (
              <p className={styles.operator}>
                {" "}
                &nbsp; • Operated by {operatorName}
              </p>
            )}
          </section>
          <section className={styles.flightDescription}>
            {duration && (
              <p className={styles.duration}>
                Duration: {duration} | {manufactureName && manufactureName}
              </p>
            )}
          </section>
        </div>
      ) : (
        <div className={styles.container}>
          {title === "Layover" ? (
            <section className={styles.layoverCard}>
              <img src={switchIcons(title)} alt="" className={styles.layIcon} />

              <div className={styles.layoverDetailCard}>
                {title && <p className={styles.layTitle}>{title}</p>}
                {description && (
                  <p className={styles.layDes}>
                    <b>|</b>
                    {description} in Madrid
                  </p>
                )}
              </div>
            </section>
          ) : (
            <section
              className={`${title === "Layover" ? styles.layCard : styles.contentCard}`}>
              {imgUrl ? (
                <img src={imgUrl} alt="" className={styles.imgIcon} />
              ) : (
                <div className={styles.iconCard}>
                  <img
                    src={switchIcons(title)}
                    alt=""
                    className={`${title === "Layover" ? styles.layoverIcon : styles.icon}`}
                  />
                </div>
              )}
              <div className={styles.detailsCard}>
                <p className={styles.title}>
                  {title && title}
                  {subTitle && (
                    <span className={styles.subTitle}>
                      {" "}
                      &nbsp;&nbsp; <b>|</b> &nbsp;&nbsp; {subTitle}
                    </span>
                  )}
                </p>{" "}
                <p className={styles.desc}>
                  {description}
                  {subDescription && (
                    <span>
                      {" "}
                      &nbsp;&nbsp; <b>|</b> &nbsp;&nbsp; {subDescription}
                    </span>
                  )}
                </p>
              </div>
            </section>
          )}

          {destinationImg && (
            <img
              src={destinationImg}
              alt="destination"
              className={styles.destinationImg}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default NewFlightDetails;
