import { ENVIRONMENT_TYPE } from "./common";

export const ENVIRONMENT = {
  staging: {
    environment: ENVIRONMENT_TYPE.STAGING,
    SOCKET_URL: "",
    APP_URL: "https://app-beta.zenvoya.ai/",
    WEB_URL: "https://www-dev.zenvoya.ai/",
    ENDPOINT: "https://api-staging.zenvoya.ai",
    GOOGLE_AUTH_CLIENT_ID:
      "176254359773-2u5j9dv1qpg1dngo1nqr7n6ttn6knvi5.apps.googleusercontent.com",
    azure: {
      // AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      // AZURE_URL: "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-JV76FH2EB6",
    // GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-JV76FH2EB6",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
    WEATHER_API: {
      API_KEY: "d96f7b60596e4def827195242242507",
      BASE_URL: "d96f7b60596e4def827195242242507",
    },
  },
  dev: {
    environment: ENVIRONMENT_TYPE.DEV,
    SOCKET_URL: "",
    APP_URL: "https://app-dev.zenvoya.ai/",
    // APP_URL: "http://localhost:3000/",
    WEB_URL: "https://www-dev.zenvoya.ai/",
    // WEB_URL: "http://localhost:3000/",
    // ENDPOINT: "https://api-dev.zenvoya.ai",
    ENDPOINT: "https://api-dev-main.zenvoya.ai",
    // ENDPOINT: "https://api-dev-main.zenvoya.ai",
    // GOOGLE_AUTH_CLIENT_ID:
    //   "176254359773-2u5j9dv1qpg1dngo1nqr7n6ttn6knvi5.apps.googleusercontent.com",
    GOOGLE_AUTH_CLIENT_ID:
      "643694378557-60uta6pjlhds86si515v007r4ecuulrg.apps.googleusercontent.com",
    azure: {
      // AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      // AZURE_URL: "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-8E95BH5C18",
    // GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-8E95BH5C18",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
    WEATHER_API: {
      API_KEY: "d96f7b60596e4def827195242242507",
    },
  },
  test: {
    environment: ENVIRONMENT_TYPE.TEST,
    SOCKET_URL: "",
    APP_URL: "https://app-prompt.zenvoya.ai/",
    ENDPOINT: "https://api-test.zenvoya.ai",
    WEB_URL: "https://www-dev.zenvoya.ai/",
    GOOGLE_AUTH_CLIENT_ID:
      "176254359773-2u5j9dv1qpg1dngo1nqr7n6ttn6knvi5.apps.googleusercontent.com",
    azure: {
      // AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      // AZURE_URL: "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-8E95BH5C18",
    // GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-JV76FH2EB6",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
    WEATHER_API: {
      API_KEY: "d96f7b60596e4def827195242242507",
    },
  },
  production: {
    environment: ENVIRONMENT_TYPE.PRODUCTION,
    SOCKET_URL: "",
    APP_URL: "https://chat.zenvoya.ai/",
    // APP_URL: "http://localhost:3000/",
    ENDPOINT: "https://api.zenvoya.ai",
    WEB_URL: "https://beta.zenvoya.ai/",
    GOOGLE_AUTH_CLIENT_ID:
      "176254359773-2u5j9dv1qpg1dngo1nqr7n6ttn6knvi5.apps.googleusercontent.com",
    azure: {
      //   AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      //   AZURE_URL:
      //     "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-BQSZGSMTNF",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
    WEATHER_API: {
      API_KEY: "d96f7b60596e4def827195242242507",
    },
  },
};

const {
  SOCKET_URL,
  environment,
  APP_URL,
  ENDPOINT,
  azure,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_MAP_API_KEY,
  S3_BUCKET_CONFIG,
  WEATHER_API,
  WEB_URL,
  GOOGLE_AUTH_CLIENT_ID,
} = ENVIRONMENT.dev || {};
const { AZURE_API_KEY, AZURE_URL } = azure || {};

export {
  SOCKET_URL,
  environment,
  WEB_URL,
  APP_URL,
  ENDPOINT,
  AZURE_API_KEY,
  AZURE_URL,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_MAP_API_KEY,
  S3_BUCKET_CONFIG,
  WEATHER_API,
  GOOGLE_AUTH_CLIENT_ID,
};
