import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Airport } from "../../../../types";
import Loader from "../../../reusableComponent/loader/Loader";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { StateContext } from "../../../../context/globalContext/context";
import { CityAirports } from "../../../../interface";
import styles from "./airportSelectionPopover.module.scss";

interface AirportProps {
  readonly popoverTrigger: ReactNode;
  // readonly bookingDetails: BookingDetails;
  // readonly setBookingDetails: React.Dispatch<
  //   React.SetStateAction<BookingDetails>
  // >;
  readonly isPopoverOpen: boolean;
  // readonly isArrivalPopoverOpen: boolean;
  readonly setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly handleApplyButton: () => void;
  // readonly departureCity?: string;
  // readonly arrivalCity?: string;
  // readonly indexNumber: number;
  readonly label: string;
  readonly value: string;
  handleAirportChange: (flight: {
    airportCode: string;
    airportName: string;
  }) => void;
}

export default function NewSingleAirportSelectionPopover({
  popoverTrigger,
  // bookingDetails,
  // setBookingDetails,
  isPopoverOpen,
  // isArrivalPopoverOpen,
  setIsPopoverOpen,
  handleApplyButton,
  // departureCity,
  // arrivalCity,
  // indexNumber,
  label,
  value,
  handleAirportChange,
}: AirportProps): JSX.Element {
  const state = useContext(StateContext);

  const { airPorts }: iGlobalContext = state;
  const [selectedValue, setSelectedValue] = useState({
    airportCode: "",
    airportName: "",
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAirport = airPorts
      .flatMap((city: CityAirports) => city?.airports)
      .find((airport: Airport) => airport?.code === event?.target.value);

    if (selectedAirport) {
      setSelectedValue({
        airportCode: selectedAirport?.code,
        airportName: selectedAirport?.fullName,
      });
    }
  };

  const handleUpdate = useMemo(
    () => () => {
      handleAirportChange(selectedValue);
    },
    [handleAirportChange, selectedValue]
  );

  // useEffect(() => {
  //   if (value === selectedValue) {
  //     return;
  //   }
  //   if (value) {
  //     setSelectedValue(value);
  //   }
  // }, [value, selectedValue]);
  // const [address, setAddress] = useState<string>("");

  // const [loader, setLoader] = useState<iBookingAirportLoaderTypes>({
  //   arrivalLoader: false,
  //   departureLoader: false,
  // });

  // let fetchAirportController: any = useRef(null);
  // const [inputAddress, setInputAddress] = useState<airportAddressFieldTypes>({
  //   address: "",
  //   city: "",
  //   state: "",
  //   country: "",
  //   countryCode: "",
  //   latitude: 0,
  //   longitude: 0,
  // });

  // const handleSearchAirport = async () => {
  //   const cities = [
  //     {
  //       name: `${inputAddress.city}, ${inputAddress.state}, ${inputAddress.country}`,
  //       latitude: inputAddress?.latitude?.toString(),
  //       longitude: inputAddress?.longitude?.toString(),
  //       countryCode: inputAddress.countryCode ?? "",
  //     },
  //   ];

  //   if (inputAddress.address === "") {
  //     toastMessage.error("Please enter city details");
  //     return;
  //   }
  //   setLoader((prevData) => ({
  //     ...prevData,
  //     departureLoader: true,
  //     arrivalLoader: false,
  //   }));
  //   const requestBody = {
  //     cities: cities,
  //     radius: "100 miles",
  //   };

  //   fetchAirportController.current = new AbortController();

  //   try {
  //     const data = await getAirports(
  //       requestBody,
  //       fetchAirportController.current.signal
  //     );

  //     if (fetchAirportController.current.signal.aborted) {
  //       return;
  //     }
  //     if (data) {
  //       if (Array.isArray(data)) {
  //         setAirPorts((prevData) =>
  //           Array.isArray(prevData) ? [...prevData, ...data] : [...data]
  //         );
  //       }
  //       setBookingDetails((prevData) => ({
  //         ...prevData,
  //         isFormUpdated: {
  //           ...prevData.isFormUpdated,
  //           departureForm: false,
  //         },
  //       }));
  //     }
  //   } catch (err) {
  //     setAirPorts([]);
  //     console.error("Failed to fetch flight data", err);
  //     setBookingDetails((prevData) => ({
  //       ...prevData,
  //       isFormUpdated: {
  //         ...prevData.isFormUpdated,
  //         departureForm: false,
  //       },
  //     }));
  //   } finally {
  //     setLoader((prevData) => ({
  //       ...prevData,
  //       departureLoader: false,
  //       arrivalLoader: false,
  //     }));
  //     setBookingDetails((prevData) => ({
  //       ...prevData,
  //       isFormUpdated: {
  //         ...prevData.isFormUpdated,
  //         departureForm: false,
  //       },
  //     }));
  //   }
  // };

  // const departureNewFromCity = `${bookingDetails?.airPort[0]?.departureSelectedLocation?.city}, ${bookingDetails?.airPort[0]?.departureSelectedLocation?.state}, ${bookingDetails?.airPort[0]?.departureSelectedLocation?.country}`;

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <div>
            {isPopoverOpen && (
              <div>
                <h1 className={styles.heading}>{label}</h1>
                <div className={styles.autoCompleteField}>
                  {/* <div className={styles.input}>
                    <AutoCompleteAirportAddress
                      onPlaceSelected={(place) => {
                        console.log("place", place);
                        handleSearchAirport();
                      }}
                      setSelectedLocation={setBookingDetails}
                      address={address}
                      setAddress={setAddress}
                      setInputAddress={setInputAddress}
                    />
                  </div> */}
                </div>
                {/* {loader.departureLoader ? ( */}
                {false ? (
                  <div className={styles.loader}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    {Array.isArray(airPorts) &&
                    airPorts.reduce(
                      (uniqueCities: CityAirports[], city: CityAirports) => {
                        // Check if the city is already added
                        if (!uniqueCities.some((c) => c?.city === city?.city)) {
                          uniqueCities.push(city);
                        }
                        return uniqueCities;
                      },
                      []
                      // )

                      // ?.filter((city: CityAirports) => {
                      //   const isDepartureEmpty =
                      //     !bookingDetails?.airPort[0]?.departure;
                      //   const isDepartureMatch =
                      //     city.city ===
                      //     `${bookingDetails?.airPort[0]?.newDepartureCitySearch ? departureNewFromCity : bookingDetails?.airPort[0]?.fromCity}`;
                      //   return !isDepartureEmpty && isDepartureMatch;
                      // }
                    ).length === 0 ? (
                      <p className={styles.errorMessage}>
                        {" "}
                        Unable to find an airport.
                        <br /> Try a different location.
                      </p>
                    ) : (
                      <FormControl className={styles.formControlContainer}>
                        <RadioGroup
                          name="radio"
                          onChange={handleChange}
                          value={selectedValue.airportCode}>
                          {Array.isArray(airPorts) &&
                            airPorts
                              .reduce(
                                (
                                  uniqueCities: CityAirports[],
                                  city: CityAirports
                                ) => {
                                  if (
                                    !uniqueCities.some(
                                      (c) => c.city === city.city
                                    )
                                  ) {
                                    uniqueCities.push(city);
                                  }
                                  return uniqueCities;
                                },
                                []
                              )
                              // .filter(
                              //   (city: CityAirports) =>
                              //     city.city ===
                              //     "London, Greater London, United Kingdom"
                              // )
                              .map((city: CityAirports) => (
                                <div key={city.city}>
                                  {city?.airports
                                    ?.slice(0, 5)
                                    .map((airport: Airport) => (
                                      <FormControlLabel
                                        key={airport.code}
                                        value={airport.code}
                                        control={<CustomRadio />}
                                        label={
                                          <p
                                            className={styles.airportLabel}
                                            style={{
                                              color:
                                                airport.code ===
                                                selectedValue.airportCode
                                                  ? "#222222"
                                                  : "#696969",
                                            }}>
                                            {airport?.code} -{" "}
                                            {airport?.fullName}
                                          </p>
                                        }
                                      />
                                    ))}
                                </div>
                              ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      }
      handleApplyButton={handleUpdate}
    />
  );
}

export function CustomRadio(props: Readonly<RadioProps>) {
  return (
    <Radio
      // disableRipple
      color="default"
      {...props}
      icon={<span className={styles.icon} />}
      checkedIcon={<span className={styles.checkedIcon} />}
    />
  );
}
