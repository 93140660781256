import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, TextField, Avatar, Typography, styled } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { checkIfUserIsLoggedIn } from "../../utils/loginAndLocalStorageutils";
import BackButtonHandler from "../../component/BackButtonHandler";
import profileIcon from "../../assets/profileIcon.svg";
import { iGlobalContext } from "../../context/globalContext/interface";
import { getConfigMessage, s3Operations } from "../../utils";
// import { getUserDetailsApi } from "../../api/getUserDetails";
import formatPhoneNumber from "../../hooks/phoneFormater";
import { toastMessage } from "../../helpers/toast/toastMessage";
import styles from "./editAccount.module.scss";
import { APP_CONFIG } from "../../constant/common";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { getUserDetails, updateUserDetails } from "../../api/user";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { UserDetailsresponse, UserDetailsResponse } from "../../interface";

const Input = styled("input")({
  display: "none",
});

export default function EditAccount() {
  const [upload, setUpload] = useState<boolean>(false);
  const { handleCustomError } = useCustomErrorHandler();
  React.useEffect(() => {
    const updateUserDetails = () => {};

    // Get JWT from local storage and check if the user is logged in
    if (!userInfo?.loggedIn) checkIfUserIsLoggedIn(setUserInfo);

    if (nav !== "") setPrevNav(nav);
    setNav("");
    updateUserDetails();
  }, []);

  const navigate = useNavigate();

  const state = React.useContext(StateContext);
  const {
    setPrevNav,
    nav,
    setNav,
    prevNav,
    prevRoute,
    isMobile,
    setLoginCreateAccountState,
    loginCreateAccountState,
    userInfo,
    setUserInfo,
    appConfig,
    setSignInClick,
  }: iGlobalContext = state;
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
    profileImage: "",
  });
  const [isError, setIsError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const phoneInputRef = useRef<HTMLDivElement>(null);
  const successMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.TOAST_PROFILE_UPDATE)
      ?.value ?? "Your profile is updated!";

  const handleBack = () => {
    isMobile
      ? navigate(-1)
      : setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
  };
  async function handleUpdateClick() {
    if (userInfo?.phone && userInfo?.phone.length < 14) {
      setIsError(true);
      return;
    }
    if (userInfo?.name === "") {
      setNameError(true);
      return;
    }
    const payload = {
      id: userInfo?.id,
      name: userDetails?.name,
      email: userDetails?.email,
      phone: userDetails?.phone,
      profile_pic: userDetails.profileImage,
    };
    const response: UserDetailsresponse = await updateUserDetails(payload);
    // Null check for the API response
    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "user.update");
      return null;
    }

    if (response && response?.statusCode === 200) {
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
      setUserInfo((prevData) => ({
        ...prevData,
        name: response?.data?.name,
        phone: response?.data?.phone,
        profile_pic: response?.data?.profile_pic ?? "",
      }));
      setTimeout(next, 1000);
      toastMessage.success(successMessage);
      triggerGAEvent(GA_LABEL_ENUMS.home_submit_edit_profile_click);
    } else {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    }
    function next() {
      // setNav(prevNav);
      // if (prevRoute === "") navigate("/");
      // else navigate(`${prevRoute}`);
      setSignInClick(false);
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatPhoneNumber(inputValue);
    setUserDetails((prevData) => ({ ...prevData, phone: formattedValue }));
    setIsError(false);
  };

  const fileChooseHandler = useCallback(
    async (event: any) => {
      const file: File = event.target.files[0];
      if (!file) {
        return;
      }
      setUpload(false);
      toast.loading("Please wait while we upload your profile picture");
      const response: any = await s3Operations.upload({
        params: {
          Key: file.name,
          Body: file,
        },
        uploadType: "profile",
      });
      if (response) {
        setUserDetails((prevData) => ({
          ...prevData,
          profileImage: response?.Location,
        }));
        toast.dismiss();
        setUpload(true);
        triggerGAEvent(GA_LABEL_ENUMS.home_upload_image_click);
        toastMessage.success("SUCCESS!", "Profile picture update successful.");
      }
      if (!response) {
        setUpload(false);
        toastMessage.error(
          TOAST_MESSAGES.PROFILE.profilePictureUpdateFailure.messageTitle,
          TOAST_MESSAGES.PROFILE.profilePictureUpdateFailure.message
        );
      }
    },
    [appConfig, setUserInfo]
  );

  async function handleUserDetails() {
    const response: UserDetailsResponse = await getUserDetails(userInfo?.id);
    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "user.detail");
      return null;
    }
    const userData = response?.data;
    if (userData) {
      setUserDetails((prevData) => ({
        ...prevData,
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        profile_pic: userData.profile_pic,
      }));
      setUserInfo((prevData) => ({
        ...prevData,
        id: userData.id,
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        profile_pic: userData.profile_pic,
        loggedIn: true,
      }));
    }
    if (!response) {
      console.error("Something went wrong");
      return;
    }
  }

  const deleteProfilePicture = useCallback(() => {
    setUserDetails((prevData) => ({ ...prevData, profileImage: "" }));
    toastMessage.success("Success", "Profile picture is deleted successfully");
    setUpload(false);
  }, [setUserDetails]);

  useEffect(() => {
    handleUserDetails();
  }, []);

  const handleNameKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      phoneInputRef.current?.focus();
    }
  };

  return (
    <div className={styles.mainContainer}>
      <BackButtonHandler onBack={handleBack} />
      <h4 className={styles.heading}>Edit Account</h4>

      <div className={styles.inputContainer}>
        <div className={styles.imageInputContainer}>
          <label htmlFor="addFile">
            <Avatar
              src={
                userDetails?.profileImage
                  ? userDetails?.profileImage
                  : profileIcon
              }
              alt=""
              className={styles.avatar}
            />
          </label>
          {upload ? (
            <Typography
              className={styles.uploadRemoveButtonText}
              onClick={deleteProfilePicture}>
              Remove profile picture
            </Typography>
          ) : (
            <label htmlFor="addFile">
              <Input
                accept="image/*"
                type="file"
                id="addFile"
                onChange={fileChooseHandler}
              />
              <Typography className={styles.uploadRemoveButtonText}>
                TAP TO UPLOAD IMAGE
              </Typography>
            </label>
          )}
        </div>
        <div className={styles.inputLabelContainer}>
          <label className={styles.labelText}>YOUR NAME</label>
          <TextField
            size="small"
            placeholder="enter your first and last name"
            value={userDetails?.name}
            onChange={(e: any) => {
              setUserDetails((prevData) => ({
                ...prevData,
                name: e.target.value,
              }));
            }}
            onKeyDown={handleNameKeyDown}
            InputProps={{
              style: {
                height: "40px",
                width: "100%",
                fontSize: "12px",
                color: "#696969",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
              },
            }}
            error={nameError}
            helperText={nameError && "Enter your name"}
          />
        </div>

        <div className={styles.inputLabelContainer}>
          <label className={styles.labelText}>EMAIL ADDRESS</label>
          <TextField
            size="small"
            disabled
            placeholder="enter your email"
            value={userDetails?.email}
            InputProps={{
              style: {
                height: "40px",
                width: "100%",
                fontSize: "12px",
                color: "#696969",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
              },
            }}
          />
        </div>
        <div className={styles.inputLabelContainer}>
          <label className={styles.labelText}>MOBILE PHONE</label>
          <TextField
            inputRef={phoneInputRef}
            size="small"
            placeholder="(123) 456-7890"
            value={userDetails?.phone}
            type="tel"
            onChange={handleChange}
            error={isError}
            helperText={isError && "Invalid phone number"}
            InputProps={{
              style: {
                height: "40px",
                width: "120px",
                fontSize: "12px",
                color: "#696969",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
              },
            }}
          />
        </div>
      </div>

      <Button
        className={styles.button}
        onClick={handleUpdateClick}
        size="small"
        sx={{
          color: "secondary.contrastText",
          backgroundColor: "secondary.main",
          alignSelf: "center",
          ":hover": { bgcolor: "secondary.main" },
        }}
        variant="contained">
        SAVE CHANGES
      </Button>
    </div>
  );
}
