export const transformHotelData = (
  data: any[],
  isThisMultiCityTrip: boolean
) => {
  return data
    .map((entry) => {
      const validHotels = isThisMultiCityTrip
        ? entry.hotels
        : entry.hotels.filter(
            (hotel: any) =>
              hotel.accommodation_location_city &&
              hotel.accommodation_location_city.trim() !== ""
          );
      if (validHotels.length === 0) return null;

      // Map valid hotels to the required format
      return validHotels.map((hotel: any) => {
        const startDate = entry?.start_date
          ? new Date(entry?.start_date)
          : null;
        const endDate = entry?.end_date ? new Date(entry?.end_date) : null;

        // checkIn & checkOut same add one more day
        if (
          !startDate ||
          isNaN(startDate?.getTime()) ||
          !endDate ||
          isNaN(endDate?.getTime())
        ) {
          console.warn("Invalid start_date or end_date for entry:", entry);
          return null;
        }
        let checkOut = endDate;
        // Add one day to check-out if check-in and check-out are the same
        if (startDate?.getTime() === endDate?.getTime()) {
          checkOut = new Date(endDate);
          checkOut.setDate(checkOut?.getDate() + 1);
        }
        return {
          checkIn: startDate?.toISOString().split("T")[0],
          checkOut: checkOut?.toISOString().split("T")[0],
          country: hotel?.accommodation_location_country_code || "",
          city: hotel?.accommodation_location_city || "",
          latitude: hotel?.accommodation_location_latitude || "",
          longitude: hotel?.accommodation_location_longitude || "",
        };
      });
    })
    .filter(Boolean)
    .flat();
};
