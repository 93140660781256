import React, { useCallback, useEffect } from "react";
import styles from "./popover.module.scss";

interface PopoverProps {
  readonly className?: string;
  readonly children: React.ReactNode;
  readonly popOverElement: React.ReactNode;
}

function Popover({ children, popOverElement, className }: PopoverProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        event.target instanceof Node &&
        !(event.target as Element)?.closest(`.${styles.popOverContainer}`)
      ) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={`${className} ${styles.popOverContainer}`}>
      <div onClick={handleClick}>{children}</div>
      {isOpen && (
        <div className={styles.dropdown} onClick={() => setIsOpen(false)}>
          {popOverElement}
        </div>
      )}
    </div>
  );
}

export default Popover;
