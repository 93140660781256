import React from "react";
import styles from "./dayActivityInput.module.scss";
import { cancelGreyIcon } from "../../../../../constant";
import { TextareaAutosize } from "@mui/base";

export default function DayActivityInput({
  key,
  timeOfDay,
  inputValue,
  onInputChange,
  handleClearInput,
}: Readonly<{
  key: string;
  timeOfDay: any;
  inputValue: string;
  onInputChange: any;
  handleClearInput: any;
}>) {
  return (
    <div key={key} className={styles.midContainer}>
      <h1>{timeOfDay}</h1>
      <TextareaAutosize
        onChange={onInputChange}
        value={inputValue}
        // value={dayItineraryEle?.description}
        placeholder={""}
        className={styles.input}
        minRows={3}
        maxRows={3}
        spellCheck="true"
      />
      <img className={styles.clearText} src={cancelGreyIcon} alt="cross" onClick={handleClearInput} />
    </div>
  );
}
