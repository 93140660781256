import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./stripeSuccess.module.scss";
import { bookedSuccesIcon, ROUTES } from "../../constant";
import { TOAST_MESSAGES } from "../../constant/messages/toastMessages";
import { Button } from "@mui/material";
import {
  updateDaywiseTripDate,
  updateDaywiseTripDateForMetaData,
} from "../../utils";
import { iGlobalContext } from "../../context/globalContext/interface";
import { StateContext } from "../../context/globalContext/context";
import { saveBookedTransactionDetailsAPI } from "../../api/booking/saveBookedTransactionDetailsAPI";
// import { toastMessage } from "../../utils/ToastProvider";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";
import { confirmBookHotelAndFlight } from "../../api/booking/booking";
import { ScreenTypes } from "../../enums";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { iCurrentTripContext } from "../../context/currentTripContext/currentTripContextInterface";
import { useCurrentTripContext } from "../../context/currentTripContext/currentTripContext";
import Navbar from "../../component/desktopLandingPage/navbar/navbar";
import { TRIP_DETAILS_TABS } from "../../component/myTripsPage/myTripsConstants";
import { iHistoryContext } from "../../context/historyContext/interface";
import { useHistoryContext } from "../../context/historyContext/historyContext";
import { v4 } from "uuid";
import { iChatData } from "../../types";

export default function StripeSuccess() {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    bookingDetails,
    selectedChatData,
    // userInfo,
    selectedFlightOptions,
    bookedtransIds,
    // setBookedTransIds,
    setTripOrItinearyId,
    setFirstScreen,
    setScreen,
    setMyTripActiveTab,
    setIsUserNavigatingFromPaymentGateway,
    userInfo,
    setIsUserJustBookedTrip,
    isMyTripsTheFirstSelectedScreen,
    setActiveTripDetailsTab,
    setFlightData,
    setHotelData,
    chatData,
    setChatData,
    // setSelectedChatData,
  }: iGlobalContext = state;
  const { getCurrentTrip }: iCurrentTripContext = useCurrentTripContext();
  const { saveHistory, getHistory }: iHistoryContext = useHistoryContext();
  const {
    itineraryID = "",
    flightTransactionId = "",
    hotelTransactionId = "",
  } = bookedtransIds || {};
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const { adultsCount, childrenCount, seniorsCount } = bookingDetails;
  /**
   * Form data
   */

  const rawTravelerInfoArrayDataRef = localStorage.getItem(
    "_traveler_info_array"
  );
  const travelerInfoArrayDataRef =
    rawTravelerInfoArrayDataRef && JSON.parse(rawTravelerInfoArrayDataRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const saveBookedTransactionDetails = async ({
  //   isFlight,
  //   bookedTransactionId,
  //   bookedItineraryId,
  //   startDate,
  //   endDate,
  // }: {
  //   isFlight: boolean;
  //   bookedTransactionId: string;
  //   bookedItineraryId: string;
  //   startDate: string;
  //   endDate: string;
  // }) => {
  //   const itineraryData = updateDaywiseTripDate(
  //     selectedChatData?.results,
  //     startDate
  //   );
  //   const metaData: any = {
  //     // chatData: chatData,
  //     selectedChatData: {
  //       ...selectedChatData,
  //       destinationList: updateDaywiseTripDateForMetaData(
  //         selectedChatData?.destinationList,
  //         startDate
  //       ),
  //       prompt: [],
  //       postText: "",
  //       preText: "",
  //       results: itineraryData || selectedChatData?.results,
  //     },
  //   };

  //   let travelerInfo = {
  //     adultsCount,
  //     childrenCount,
  //     seniorsCount,
  //     travelersDetailsList: travelerInfoArrayDataRef?.current || [],
  //   };

  //   if (!bookedItineraryId) return;
  //   const saveItineraryDetailsResponse = await saveBookedTransactionDetailsAPI(
  //     isFlight,
  //     selectedChatData.id,
  //     userInfo?.id,
  //     metaData,
  //     "booked",
  //     bookedTransactionId,
  //     bookedItineraryId,
  //     startDate,
  //     endDate,
  //     travelerInfo,
  //     itineraryData || selectedChatData?.results
  //   );
  //   if (
  //     saveItineraryDetailsResponse?.message === "Itineraries has been saved"
  //   ) {
  //     // console.log("DONE");
  //     return saveItineraryDetailsResponse;
  //   } else {
  //     throw new Error("save Itinerary failed");
  //     // console.log("Failed");
  //   }
  // };

  const [isLoading, setIsLoading] = useState(false);
  const [isBookingFailed, setIsBookingFailed] = useState(false);

  // const saveFlightItenirary = useCallback(async () => {
  //   setIsLoading(true);
  //   const response = await saveBookedTransactionDetails({
  //     isFlight: true,
  //     bookedTransactionId: flightTransactionId,
  //     bookedItineraryId: itineraryID,
  //     startDate: bookingDetails.travelFromDate,
  //     endDate: bookingDetails.travelToDate,
  //   });
  //   if (response) {
  //     toastMessage.success("Success", "Flight booking Completed");
  //     setBookedTransIds(null);
  //     return response;
  //   }
  //   setIsLoading(false);
  // }, [
  //   bookingDetails.travelFromDate,
  //   bookingDetails.travelToDate,
  //   flightTransactionId,
  //   itineraryID,
  //   saveBookedTransactionDetails,
  //   setBookedTransIds,
  // ]);

  // const saveHotelItenirary = useCallback(async () => {
  //   setIsLoading(true);
  //   const response = await saveBookedTransactionDetails({
  //     isFlight: false,
  //     bookedTransactionId: hotelTransactionId,
  //     bookedItineraryId: itineraryID,
  //     startDate: bookingDetails.travelFromDate,
  //     endDate: bookingDetails.travelToDate,
  //   });
  //   if (response) {
  //     toastMessage.success("Success", "Hotel booking Completed");
  //     setBookedTransIds(null);
  //     return response;
  //   }
  //   setIsLoading(false);
  // }, [
  //   bookingDetails.travelFromDate,
  //   bookingDetails.travelToDate,
  //   hotelTransactionId,
  //   itineraryID,
  //   saveBookedTransactionDetails,
  //   setBookedTransIds,
  // ]);

  // const saveHotelAndFlightItenirary = useCallback(async () => {
  //   setIsLoading(true);
  //   // alert("wait");
  //   const [flightBookingConfirmed, hotelBookingConfirmed] = await Promise.all([
  //     saveBookedTransactionDetails({
  //       isFlight: true,
  //       bookedTransactionId: flightTransactionId,
  //       bookedItineraryId: itineraryID,
  //       startDate: bookingDetails.travelFromDate,
  //       endDate: bookingDetails.travelToDate,
  //     }),
  //     saveBookedTransactionDetails({
  //       isFlight: false,
  //       bookedTransactionId: hotelTransactionId,
  //       bookedItineraryId: itineraryID,
  //       startDate: bookingDetails.travelFromDate,
  //       endDate: bookingDetails.travelToDate,
  //     }),
  //   ]);
  //   if (flightBookingConfirmed && hotelBookingConfirmed) {
  //     console.log(flightBookingConfirmed, hotelBookingConfirmed);
  //     toastMessage.success("Success", "Hotel and Flight booking Completed");
  //     setBookedTransIds(null);
  //     // return {flightBookingConfirmed, hotelBookingConfirmed}
  //   }
  //   setIsLoading(false);
  // }, [
  //   bookingDetails.travelFromDate,
  //   bookingDetails.travelToDate,
  //   flightTransactionId,
  //   hotelTransactionId,
  //   itineraryID,
  //   saveBookedTransactionDetails,
  //   setBookedTransIds,
  // ]);

  const confirmBookingAPI = useCallback(
    async ({
      bookedHotelTransactionId,
      bookedFlightTransactionId,
      bookedItineraryId,
      startDate,
      endDate,
    }: {
      bookedHotelTransactionId: string;
      bookedFlightTransactionId: string;
      bookedItineraryId: string;
      startDate: string;
      endDate: string;
    }) => {
      setIsBookingFailed(false);

      if (!bookedItineraryId) return;
      const response = await confirmBookHotelAndFlight(
        {
          itineraryId: bookedItineraryId,
        },
        setIsLoading
      );
      if (response) {
        const itineraryData = updateDaywiseTripDate(
          selectedChatData?.results,
          startDate
        );
        const metaData: any = {
          // chatData: chatData,
          selectedChatData: {
            ...selectedChatData,
            destinationList: updateDaywiseTripDateForMetaData(
              selectedChatData?.destinationList,
              startDate
            ),
            prompt: [],
            postText: "",
            preText: "",
            results: itineraryData || selectedChatData?.results,
          },
        };

        let travelerInfo = {
          adultsCount,
          childrenCount,
          seniorsCount,
          travelersDetailsList: travelerInfoArrayDataRef?.current || [],
        };
        // console.log(bookedItineraryId, "bookedItineraryId");
        if (!bookedItineraryId) return;
        const saveItineraryDetailsResponse =
          await saveBookedTransactionDetailsAPI(
            // false, // to be removed soon
            selectedChatData.id,
            userInfo?.id,
            metaData,
            "booked",
            // "asdf", // to be removed soon
            bookedItineraryId,
            startDate,
            endDate,
            // travelerInfo,
            itineraryData || selectedChatData?.results,
            bookedHotelTransactionId,
            bookedFlightTransactionId,
            setIsLoading
          );
        if (saveItineraryDetailsResponse?.statusCode === 200) {
          // console.log("DONE");
          // return saveItineraryDetailsResponse;
          // clearing booking page data
          // window.localStorage.removeItem("_trip_trove_selected_chat_data");
          setFlightData(null);
          setHotelData(null);
          /**
           * Update the history and change the ID for this itinerary
           */
          const tempChatData = chatData.map((ele: iChatData) => {
            if (ele?.id === selectedChatData?.id) {
              return {
                ...ele,
                id: v4(),
                isSaved: false,
              };
            }
            return ele;
          });
          setChatData(tempChatData);
          await saveHistory(tempChatData, "PUT");
          // await getHistory(true);
          console.log(tempChatData);
          if (
            selectedFlightOptions?.flights &&
            !selectedFlightOptions?.hotels
          ) {
            // toastMessage.success("Success", "Flight booking Completed");
            // setBookedTransIds(null);
            return;
          }
          if (
            selectedFlightOptions?.hotels &&
            !selectedFlightOptions?.flights
          ) {
            // toastMessage.success("Success", "Hotel booking Completed");
            // setBookedTransIds(null);
            return;
          }
          if (selectedFlightOptions?.hotels && selectedFlightOptions?.flights) {
            // toastMessage.success(
            //   "Success",
            //   "Flight and hotel booking Completed"
            // );
            // setBookedTransIds(null);
          }
        } else {
          setIsBookingFailed(true);
          if (saveItineraryDetailsResponse?.message)
            throw new Error(saveItineraryDetailsResponse?.message);
          // console.log("Failed");
        }
      }
      if (!response) {
        setIsBookingFailed(true);
      }
    },
    [
      adultsCount,
      chatData,
      childrenCount,
      saveHistory,
      selectedChatData,
      selectedFlightOptions?.flights,
      selectedFlightOptions?.hotels,
      seniorsCount,
      setChatData,
      setFlightData,
      setHotelData,
      travelerInfoArrayDataRef,
      userInfo?.id,
    ]
  );

  useEffect(() => {
    confirmBookingAPI({
      bookedFlightTransactionId: flightTransactionId,
      bookedHotelTransactionId: hotelTransactionId,
      bookedItineraryId: itineraryID,
      startDate: bookingDetails.travelFromDate,
      endDate: bookingDetails?.travelToDate,
    });
  }, []);

  // console.log(window.history, "window.history");

  const handleViewItinerary = useCallback(() => {
    // window.history.replaceState(null, '', ROUTES.TripDetails);
    setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
    setIsUserJustBookedTrip(true);
    // window.history.pushState({}, "", ROUTES.MyTrips);

    getCurrentTrip(userInfo?.id);
    // setBookedTransIds(null);

    setTripOrItinearyId(selectedChatData?.id);
    if (isMobile) {
      // window.history.deleteAll();
      window.history.pushState({}, "", ROUTES.MyTrips);
      navigate(ROUTES.TripDetails);
    } else {
      setIsUserNavigatingFromPaymentGateway(true);
      // window.history.pushState(null, "", "/");
      // window.history.replaceState(null, "", "/");
      navigate(ROUTES.Home, { replace: true });
      if (isMyTripsTheFirstSelectedScreen) setFirstScreen(ScreenTypes.MyTrips);
      if (!isMyTripsTheFirstSelectedScreen)
        setFirstScreen(ScreenTypes.topDestination);
      setScreen(ScreenTypes.TripDetails);
    }
    // if (isMobile) navigate(ROUTES.MyTrips);
    // else {
    //   setFirstScreen(ScreenTypes.MyTrips);
    //   setScreen("");
    // }
    toast.dismiss();
    setMyTripActiveTab("UPCOMING");
  }, [
    getCurrentTrip,
    isMobile,
    isMyTripsTheFirstSelectedScreen,
    navigate,
    selectedChatData?.id,
    setActiveTripDetailsTab,
    setFirstScreen,
    setIsUserJustBookedTrip,
    setIsUserNavigatingFromPaymentGateway,
    setMyTripActiveTab,
    setScreen,
    setTripOrItinearyId,
    userInfo?.id,
  ]);

  if (isBookingFailed || !itineraryID)
    return (
      <NewLoader
        isOpen
        onClose={() => {
          if (isMobile) navigate(ROUTES.TravelerInformation);
          else {
            setIsUserNavigatingFromPaymentGateway(true);
            navigate(ROUTES.Home);
            setFirstScreen(ScreenTypes.topDestination);
            setScreen(ScreenTypes.TravellerInfo);
          }
        }}
        text={TOAST_MESSAGES.BOOKING.BOOK.bookFailure.message}
        hideLoader
        loadFullScreen
      />
    );

  return (
    <>
      {!isMobile && <Navbar />}
      <div className={styles.successContainer}>
        {isLoading && (
          <NewLoader
            isOpen={isLoading}
            onClose={() => setIsLoading(false)}
            text="Loading.."
            loadFullScreen
          />
        )}
        {!isLoading && !!itineraryID && (
          <div className={styles.mainContainer}>
            <div className={styles.booking}>
              <img src={bookedSuccesIcon} alt="booked" />
              <h1>{TOAST_MESSAGES.BOOKING.BOOK.bookingSuccess.messageTitle}</h1>
            </div>
            <p className={styles.informationText}>
              {TOAST_MESSAGES.BOOKING.BOOK.bookingSuccess.message}
            </p>
            <h1 className={styles.confirmationNumber}>{itineraryID}</h1>
            <Button className={styles.button} onClick={handleViewItinerary}>
              View Itinerary
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
