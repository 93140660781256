import React, { useCallback, useEffect } from "react";
import styles from "./tabsContainer.module.scss";

interface iProps {
  className?: string;
  tabsArr: string[];
  activeTab: string;
  setActiveTab: (value: any) => void;
}

function TabsContainer(props: iProps): JSX.Element {
  const { className = "", tabsArr = [], activeTab, setActiveTab } = props;

  const updateActiveTabPosition = useCallback(() => {
    const allTabsContainer = document.querySelector("#tabsContainer")?.children;
    if (allTabsContainer && activeTab) {
      const activeTabElement = Array.from(allTabsContainer).find(
        (tab) => (tab as HTMLElement).textContent === activeTab
      ) as HTMLElement | undefined;

      if (activeTabElement) {
        document.documentElement.style.setProperty(
          "--setLeftOffset",
          `${activeTabElement.getBoundingClientRect().left}px`
        );
        document.documentElement.style.setProperty(
          "--setSelectedWidth",
          `${activeTabElement.getBoundingClientRect().width}px`
        );
      }
    }
  }, [activeTab]);

  useEffect(() => {
    // Initial calculation
    updateActiveTabPosition();

    // Recalculate on window resize
    window.addEventListener("resize", updateActiveTabPosition);

    return () => {
      window.removeEventListener("resize", updateActiveTabPosition);
    };
  }, [updateActiveTabPosition]);

  return (
    <div className={`${className} ${styles.tabsContainer}`} id="tabsContainer">
      {tabsArr.slice(0, 4).map((item, idx) => (
        <div
          key={idx}
          className={`${styles.tab} ${activeTab === item && styles.active}`}
          onClick={() => setActiveTab(item)}
        >
          {item}
        </div>
      ))}
      <p className={`${styles.testHover} ${styles.active}`} />
    </div>
  );
}

TabsContainer.defaultProps = {
  className: "",
};

export default TabsContainer;
