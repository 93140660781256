export const HOTEL_ROOMS_DATA = [
  {
    roomName: "Standard Single Room",
    roomId: 22057312,
    roomDimensions: {
      size: 10,
      unit: "m2",
    },
    maxAdults: 1,
    maxChildren: 0,
    maxOccupancy: 1,
    roomTypeId: 22057312,
    bedInformation: [
      {
        quantity: 1,
        bedType: "Single bed(s)",
      },
    ],
    roomAmenities: [
      "Tea/Coffee maker",
      "Shower",
      "Safety deposit box",
      "TV",
      "Telephone",
      "Hairdryer",
      "Wake up service/Alarm clock",
      "Iron",
      "Desk",
      "Ironing facilities",
      "Free toiletries",
      "Fan",
      "Toilet",
      "Private bathroom",
      "Heating",
      "Bath or shower",
      "Carpeted",
      "Laptop safe",
      "Flat-screen TV",
      "Wake-up service",
      "Electric kettle",
      "Wardrobe or closet",
      "Towels",
      "Linen",
      "Toilet paper",
      "Trash cans",
      "Shampoo",
      "Conditioner",
      "Body soap",
      "Socket near the bed",
      "Smoke alarm",
      "Key card access",
    ],
    roomImages: [
      {
        url: "https://static.cupid.travel/rooms-large-pictures/47441685.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/hd/34505001.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/47441646.jpg",
        description: "",
      },
    ],
    roomPrices: [
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJRFEWRKPK5FFKS2SI5LFKU2TLBETIMSEI5JDESCKLJFEMTKSGJFEYRSDIU3FIS2TJM2UOVSTJUZEQSSWJBDESUSLKFFUMTKWI5JDETSLJJEVKT2TJJJUWUSBKUZFKU2UJE2UMRCNKJBUMSSWIJDEOURSKZGEMQ2FJ5KEWQ2LKJEUMRKVGJMEOUSNIRCVMU2MJJMTGRKLKQZFES2RGJCVOVSTJNFVMRKUJ5IVGU2KIZBVMU2SJNEEONKCIZEVEMS2JRDEWRSDJYZEGS2WIZDEKVSDIZETKS2UI5HEGSCJKVNFMSKSGJHUYSSMIVHVKS22JFJEMVJUKVJVISJVIFKESVSDJRDVCWSEIVKEWV2KKJBFKT2UJNFUWRSEKZJU2MSVJFDEOVSFKJBUQSSWJVLEOTKTJZFEUSKVJ5LUUVCLKJAVKMSWIJKUUSSOIVKVES2OJNJEWRCJKIZEUTCGINCUWVCLKNFUMRCVJNLUWRKJKZDVKRKVJNEESRSNKVIVOS2QJFITERKVKZJFOSK2INKTEU2TKNFEEQKUJFJUGWSKKZDEMSKSLJKUYRSDIU3FIS2TJFJEMVKVKNBVISJVJNDEOVJSJJGEURKFI5JDETSLJJEVKT2TJMZESTK2IUZFCU2TJFNEWVSTKJBUMSSGIJDEGURSIJDVMQ2FINKEWQSQKFMVIR2OLJIUMWJTKRCTORBUJNFEQUL4GEYDMNZQGJ6DE7DMOAYWCMDDMV6DCLL4KVJUI7CHIJ6DEMBSGUWTAMRNGIZHYMRQGI2S2MBTFUYDG7D4GAXDAMA",
        roomOnly: true,
        refundable: false,
        rateType: "Non-refundable",
        cancellationPolicy: null,
        supplierPrice: "1370.72",
        tax: "195.56",
        suggestedSellingPrice: "1370.72",
        sellingPrice: "1370.72",
        toSave: "1370.72_195.56_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 195.56,
        },
      },
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJRFEWRKPK5FFKS2SI5LFKU2TLBETIMSEI5JDESCKLJFEMTKSGJFEYRSDIU3FIS2TJM2UOVSTJUZEQSSWJBDESUSLKFFUMTKWI5JDETSLJJEVKT2TJJJUWUSBKUZFKU2UJE2UMRCNKJBUMSSWIJDEOURSKZGEMQ2FJ5KEWQ2LKJEUMRKVGJMEOUSNIRCVMU2MJJMTGRKLKQZFES2RGJCVOVSTJNFVMRKUJ5IVGU2KIZBVMU2SJNEEONKCIZEVEMS2JRDEWRSDJYZEGS2WIZDEKVSDIZETKS2UI5HEGSCJKVNFMSKSGJHUYSSMIVHVKS22JFJEMVJUKVJVISJVIFKESVSDJRDVCWSEIVKEWV2KKJBFKT2UJNFUWRSEKZJU2MSVJFDEOVSFKJBUQSSWJVLEOTKTJZFEUSKVJ5LUUVCLKJAVKMSWIJKUUSSOIVKVES2OJNJEWRCJKIZEUTCGINCUWVCLKNFUMRCVJNLUWRKJKZDVKRKVJNEESRSNKVIVOS2PJJITERKVKZJFOSK2INKTEU2TKNFEEQKUJFJUGWSKKZDEMTKSGJBEOTSDIVBVIU2DJFJEKVKVKFJUISJVJNDEOVJSJJGEURKFI5JDETSLJJEVKT2TJMZESTK2IUZFCU2TJFNEWVSTKJBUMSSGIJDEGURSIJDVMQ2FINKEWQSQKFMVIS2NIJLUMWKZIRETORBUJFFEKUL4GEYDMNZQGJ6DE7DMOAYWCMDDMV6DCLL4KVJUI7CHIJ6DEMBSGUWTAMRNGIZHYMRQGI2S2MBTFUYDG7D4GAXDAMA",
        roomOnly: false,
        refundable: false,
        rateType: "Non-refundable",
        cancellationPolicy: null,
        supplierPrice: "1506.04",
        tax: "214.89",
        suggestedSellingPrice: "1506.04",
        sellingPrice: "1506.04",
        toSave: "1506.04_214.89_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 214.89,
        },
      },
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJRFEWRKPK5FFKS2SI5LFKU2TLBETIMSEI5JDESCKLJFEMTKSGJFEYRSDIU3FIS2TJM2UOVSTJUZEQSSWJBDESUSLKFFUMTKWI5JDETSLJJEVKT2TJJJUWUSBKUZFKU2UJE2UMRCNKJBUMSSWIJDEOURSKZGEMQ2FJ5KEWQ2LKJEUMRKVGJMEOUSNIRCVMU2MJJMTGRKLKQZFES2RGJCVOVSTJNFVMRKUJ5IVGU2KIZBVMU2SJNEEONKCIZEVEMS2JRDEWRSDJYZEGS2WIZDEKVSDIZETKS2UI5HEGSCJKVNFMSKSGJHUYSSMIVHVKS22JFJEMVJUKVJVISJVIFKESVSDJRDVCWSEIVKEWV2KKJBFKT2UJNFUWRSEKZJU2MSVJFDEOVSFKJBUQSSWJVLEOTKTJZFEUSKVJ5LUUVCLKJAVKMSWIJKUUTSGIVEVESSVI42UERSHKIZEETCKINCVOVCLINFU4RCVKNLUWVKJJZEEKRKVJNEESSJTIVEVISSYJFHEQRSBKVJUWSKSIJKTEU2TLJFEEQSEJVJEGRCKLJDEMUKSGJDEYRSLIZBVGSSTJNHEMRCJKVJVOSKWJJDEOVBSIZFFMTSFJFIUWTSLJJGEKTKWJNNESUSDKVMVGU2UJE2UGVSDKJBUISS2IJCEKURSIJGEMQKYLFGUUVSHGRMVGNCNJJMVAUK2IRAU2USWIZKVSRCFJRFFGR2FKFCEGTSCGJDUCWKEKVGUEUKQKJFEKNT4GEYDMNZQGJ6DE7DMOAYWCMDDMV6DCLL4KVJUI7CHIJ6DEMBSGUWTAMRNGIZHYMRQGI2S2MBTFUYDG7D4GAXDAMA",
        roomOnly: true,
        refundable: true,
        rateType: "Refundable",
        cancellationPolicy:
          "If cancelled before Feb 21, 2025 09:00 AM, the cancellation fee is 210.43 USD.",
        supplierPrice: "1571.18",
        tax: "224.17",
        suggestedSellingPrice: "1571.18",
        sellingPrice: "1571.18",
        toSave: "1571.18_224.17_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 224.17,
        },
      },
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJRFEWRKPK5FFKS2SI5LFKU2TLBETIMSEI5JDESCKLJFEMTKSGJFEYRSDIU3FIS2TJM2UOVSTJUZEQSSWJBDESUSLKFFUMTKWI5JDETSLJJEVKT2TJJJUWUSBKUZFKU2UJE2UMRCNKJBUMSSWIJDEOURSKZGEMQ2FJ5KEWQ2LKJEUMRKVGJMEOUSNIRCVMU2MJJMTGRKLKQZFES2RGJCVOVSTJNFVMRKUJ5IVGU2KIZBVMU2SJNEEONKCIZEVEMS2JRDEWRSDJYZEGS2WIZDEKVSDIZETKS2UI5HEGSCJKVNFMSKSGJHUYSSMIVHVKS22JFJEMVJUKVJVISJVIFKESVSDJRDVCWSEIVKEWV2KKJBFKT2UJNFUWRSEKZJU2MSVJFDEOVSFKJBUQSSWJVLEOTKTJZFEUSKVJ5LUUVCLKJAVKMSWIJKUUTSGIVEVESSVI42UERSHKIZEETCKINCVOVCLINFU4RCVKNLUWVKJJZEEKRKVJNEESSJTIVEVIWSYJFHEQRSBKVJUWSKSIJKTEU2TLJFEEQSEJVJEGRCKLJFEMU2SGJNEOSSDIVJVGSSTJFHEKVKTKVJVOSKWJJDEOVBSIZFFMTSFJFIUWTSLJJGEKTKWJNNESUSDKVMVGU2UJE2UGVSDKJBUISS2IJCEKURSIJGEMQKYLFGUUV2IIEZUGNCOIJKVAUK2IRAU2USWIZKVSRCFJRFFGR2FKFCEGTSCGJDUCWKEKVGUEUKQKJBEKU34GEYDMNZQGJ6DE7DMOAYWCMDDMV6DCLL4KVJUI7CHIJ6DEMBSGUWTAMRNGIZHYMRQGI2S2MBTFUYDG7D4GAXDAMA",
        roomOnly: false,
        refundable: true,
        rateType: "Refundable",
        cancellationPolicy:
          "If cancelled before Feb 21, 2025 09:00 AM, the cancellation fee is 205.41 USD.",
        supplierPrice: "1686.44",
        tax: "240.63",
        suggestedSellingPrice: "1686.44",
        sellingPrice: "1686.44",
        toSave: "1686.44_240.63_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 240.63,
        },
      },
    ],
  },
  {
    roomName: "Standard Double Room",
    roomId: 37262,
    roomDimensions: {
      size: 15,
      unit: "m2",
    },
    maxAdults: 2,
    maxChildren: 1,
    maxOccupancy: 2,
    roomTypeId: 37262,
    bedInformation: [
      {
        quantity: 1,
        bedType: "Double bed(s)",
      },
    ],
    roomAmenities: [
      "Tea/Coffee maker",
      "Shower",
      "Safety deposit box",
      "TV",
      "Telephone",
      "Hairdryer",
      "Wake up service/Alarm clock",
      "Iron",
      "Desk",
      "Ironing facilities",
      "Free toiletries",
      "Fan",
      "Toilet",
      "Private bathroom",
      "Heating",
      "Bath or shower",
      "Carpeted",
      "Laptop safe",
      "Flat-screen TV",
      "Wake-up service",
      "Electric kettle",
      "Wardrobe or closet",
      "Towels",
      "Linen",
      "Toilet paper",
      "Trash cans",
      "Shampoo",
      "Conditioner",
      "Body soap",
      "Socket near the bed",
      "Smoke alarm",
      "Key card access",
    ],
    roomImages: [
      {
        url: "https://static.cupid.travel/rooms-large-pictures/34504973.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/34504987.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/34504976.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/47441682.jpg",
        description: "",
      },
    ],
    roomPrices: [
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJNFEWVKPK5JVKSKSJBKTIU2TLBETKRKUI5JDESCKLJFEMTKSGJFEYRSDIU3FIS2TJM2UOVSTJUZEQSSWJBDESUSLKFFUMTKWI5JDETSLJJEVKT2TJJJUWUSBKUZFKU2UJE2UMRCNKJBUMSSWIJDEOURSKZGEMQ2FJ5KEWQ2LKJEUMRKVGJMEOUSNIRCVMU2MJJMTGRKLKQZFES2RGJCVOVSTJNFVMRKUJ5IVGU2KIZBVMU2SJNEEONKCIZEVEMS2JRDEWRSDJYZEGS2WIZDEKVSDIZETKS2UI5HEGSCJKVNFMSKSGJHUYSSMIVHVKS22JFJEMVJUKVJVISJVIFKESVSDJRDVCWSEIVKEWV2KKJBFKT2UJNFUWRSEKZJU2MSVJFDEOVSFKJBUQSSWJVLEOTKTJZFEUSKVJ5LUUVCLKJAVKMSWIJKUUSSOIVKVES2OJNJEWRCJKIZEUTCGINCUWVCLKNFUMRCVJNLUWRKJKZDVKRKVJNEESRSNKVIVOS2OJNITERKVKZJFOSK2INKTEU2TKNFEEQKUJFJUGWSKKZDEMS2SGJLEYSSDIZBVIUZSJFJEMVKVKNBVISJVJNDEOVJSJJGEURKFI5JDETSLJJEVKT2TJMZESTK2IUZFCU2TJFNEWVSTKJBUMSSGIJDEGURSIJDVMQ2FINKEWQSQKFMVISKOJJJUMWJUIRHTORBUJNFEQUL4GEYDMNZQGJ6DE7DMOAYWCMDDMV6DCLL4KVJUI7CHIJ6DEMBSGUWTAMRNGIZHYMRQGI2S2MBTFUYDG7D4GAXDAMA",
        roomOnly: true,
        refundable: false,
        rateType: "Non-refundable",
        cancellationPolicy: null,
        supplierPrice: "1452.87",
        tax: "207.30",
        suggestedSellingPrice: "1452.87",
        sellingPrice: "1452.87",
        toSave: "1452.87_207.30_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 207.3,
        },
      },
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJNFEWVKPK5JVKSKSJBKTIU2TLBETKRKUI5JDESCKLJFEMTKSGJFEYRSDIU3FIS2TJM2UOVSTJUZEQSSWJBDESUSLKFFUMTKWI5JDETSLJJEVKT2TJJJUWUSBKUZFKU2UJE2UMRCNKJBUMSSWIJDEOURSKZGEMQ2FJ5KEWQ2LKJEUMRKVGJMEOUSNIRCVMU2MJJMTGRKLKQZFES2RGJCVOVSTJNFVMRKUJ5IVGU2KIZBVMU2SJNEEONKCIZEVEMS2JRDEWRSDJYZEGS2WIZDEKVSDIZETKS2UI5HEGSCJKVNFMSKSGJHUYSSMIVHVKS22JFJEMVJUKVJVISJVIFKESVSDJRDVCWSEIVKEWV2KKJBFKT2UJNFUWRSEKZJU2MSVJFDEOVSFKJBUQSSWJVLEOTKTJZFEUSKVJ5LUUVCLKJAVKMSWIJKUUSSOIVKVES2OJNJEWRCJKIZEUTCGINCUWVCLKNFUMRCVJNLUWRKJKZDVKRKVJNEESRSNKVIVOS2OI5GTERKVKZJFOSK2INKTEU2TKNFEEQKUJFJUGWSKKZDEMTKTINBEOUSDIVFVIU2DJFJEKVKVKFJUISJVJNDEOVJSJJGEURKFI5JDETSLJJEVKT2TJMZESTK2IUZFCU2TJFNEWVSTKJBUMSSGIJDEGURSIJDVMQ2FINKEWQSQKFMVIS2PIJMUMWK2IRETORBUJFFEKUL4GEYDMNZQGJ6DE7DMOAYWCMDDMV6DCLL4KVJUI7CHIJ6DEMBSGUWTAMRNGIZHYMRQGI2S2MBTFUYDG7D4GAXDAMA",
        roomOnly: false,
        refundable: false,
        rateType: "Non-refundable",
        cancellationPolicy: null,
        supplierPrice: "1588.24",
        tax: "226.60",
        suggestedSellingPrice: "1588.24",
        sellingPrice: "1588.24",
        toSave: "1588.24_226.60_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 226.6,
        },
      },
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJNFEWVKPK5JVKSKSJBKTIU2TLBETKRKUI5JDESCKLJFEMTKSGJFEYRSDIU3FIS2TJM2UOVSTJUZEQSSWJBDESUSLKFFUMTKWI5JDETSLJJEVKT2TJJJUWUSBKUZFKU2UJE2UMRCNKJBUMSSWIJDEOURSKZGEMQ2FJ5KEWQ2LKJEUMRKVGJMEOUSNIRCVMU2MJJMTGRKLKQZFES2RGJCVOVSTJNFVMRKUJ5IVGU2KIZBVMU2SJNEEONKCIZEVEMS2JRDEWRSDJYZEGS2WIZDEKVSDIZETKS2UI5HEGSCJKVNFMSKSGJHUYSSMIVHVKS22JFJEMVJUKVJVISJVIFKESVSDJRDVCWSEIVKEWV2KKJBFKT2UJNFUWRSEKZJU2MSVJFDEOVSFKJBUQSSWJVLEOTKTJZFEUSKVJ5LUUVCLKJAVKMSWIJKUUTSGIVEVESSVI42UERSHKIZEETCKINCVOVCLINFU4RCVKNLUWVKJJZEEKRKVJNEESSJTIVEVGSSYJFHEQRSBKVJUWSKSIJKTEU2TLJFEEQSEJVJEGRCKLJFEMT2SGJDEYSSLIUZFGSSTJNHEMRCJKVJVOSKWJJDEOVBSIZFFMTSFJFIUWTSLJJGEKTKWJNNESUSDKVMVGU2UJE2UGVSDKJBUISS2IJCEKURSIJGEMQKYLFGUUV2HLFMVGNCNLJLVAUK2IRAU2USWIZKVSRCFJRFFGR2FKFCEGTSCGJDUCWKEKVGUEUKQKJFEKNT4GEYDMNZQGJ6DE7DMOAYWCMDDMV6DCLL4KVJUI7CHIJ6DEMBSGUWTAMRNGIZHYMRQGI2S2MBTFUYDG7D4GAXDAMA",
        roomOnly: true,
        refundable: true,
        rateType: "Refundable",
        cancellationPolicy:
          "If cancelled before Feb 21, 2025 09:00 AM, the cancellation fee is 220.44 USD.",
        supplierPrice: "1661.36",
        tax: "237.03",
        suggestedSellingPrice: "1661.36",
        sellingPrice: "1661.36",
        toSave: "1661.36_237.03_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 237.03,
        },
      },
    ],
  },
  {
    roomName: "Standard Twin Room",
    roomId: 22057301,
    roomDimensions: {
      size: 15,
      unit: "m2",
    },
    maxAdults: 2,
    maxChildren: 1,
    maxOccupancy: 2,
    roomTypeId: 22057301,
    bedInformation: [
      {
        quantity: 2,
        bedType: "Single bed(s)",
      },
    ],
    roomAmenities: [
      "Tea/Coffee maker",
      "Shower",
      "Safety deposit box",
      "TV",
      "Telephone",
      "Hairdryer",
      "Wake up service/Alarm clock",
      "Iron",
      "Desk",
      "Ironing facilities",
      "Free toiletries",
      "Fan",
      "Toilet",
      "Private bathroom",
      "Heating",
      "Bath or shower",
      "Carpeted",
      "Laptop safe",
      "Flat-screen TV",
      "Wake-up service",
      "Electric kettle",
      "Wardrobe or closet",
      "Towels",
      "Linen",
      "Toilet paper",
      "Trash cans",
      "Shampoo",
      "Conditioner",
      "Body soap",
      "Socket near the bed",
      "Smoke alarm",
      "Key card access",
    ],
    roomImages: [
      {
        url: "https://static.cupid.travel/rooms-large-pictures/hd/34504950.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/34504951.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/hd/47441687.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/hd/34504946.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/hd/34504959.jpg",
        description: "",
      },
      {
        url: "https://static.cupid.travel/rooms-large-pictures/34505012.jpg",
        description: "",
      },
    ],
    roomPrices: [
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJRFEWVKPJZBFIS2SI5KTMU2TLBFFMS2WKVJEGQSKLJHEMR2SGIZEWUSDIUZFOU2TJM2UOVSFJZJUKSKOIVLFKVJSJBEUMTSFJFJTETSJJJFFKT2TJMZEUQSNKUZFKU2SJE2UKVCFKZBUESSWJZDEGURSJ5DVURCVK5GTEVCHGVDFMTKTKNMUYRSKIRCVCMSRJNFEMVSNKIZFGSKRGJCU6USTIJFVEQKWINGVUVKJGVDVIR2SINCEUNKDIREVEMSTJE2UIVJSKVFFGS2SJFDEGV2LKVFDKR2WKVLUGSCLKVNEKSKRJNHUUSSMKVHVGS22JFJEUVJUKRBVKSSSIZDEOVSTJZFEMTSGIVJDEQSHJZBUKNSUJNBUWTSDKUZFOU2VJFHEORKVKVJUQSKFLJKUSVBSJZEUUSSWIFKVGSKJLJBVMRKVGJIEWQSJKZKVEQ2CJJLEURSHKIZEETCGJNCUGVCLKNFUMRCVINLUWRKJIUZVKRKXKNIUWSSEKZIVOS2WJNFEURKPKJFFKSKSJFKE6UKTKNETKSKUIVLEGRSKGVBEMUKSJNHUUSSDKRGVGSSTJI2UMVKVKJBUMR2SIVLFKVJSJBEUMTSFJFJEWTKKJJEVKT2SJNMEWUSBKUZFKU2CJE2UCVSTKJBVMSSWIJDEGN2CKJDVCMSUIVGFEWKHGU3EQWKVKNIHYMJQGY3TAMT4GJ6GY4BRMEYGGZL4GEWXYVKTIR6EOQT4GIYDENJNGAZC2MRSPQZDAMRVFUYDGLJQGN6HYMBOGAYA",
        roomOnly: true,
        refundable: false,
        rateType: "Non-refundable",
        cancellationPolicy: null,
        supplierPrice: "1452.87",
        tax: "207.30",
        suggestedSellingPrice: "1452.87",
        sellingPrice: "1452.87",
        toSave: "1452.87_207.30_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 207.3,
        },
      },
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJRFEWVKPJZBFIS2SI5KTMU2TLBFFMS2WKVJEGQSKLJHEMR2SGIZEWUSDIUZFOU2TJM2UOVSFJZJUKSKOIVLFKVJSJBEUMTSFJFJTETSJJJFFKT2TJMZEUQSNKUZFKU2SJE2UKVCFKZBUESSWJZDEGURSJ5DVURCVK5GTEVCHGVDFMTKTKNMUYRSKIRCVCMSRJNFEMVSNKIZFGSKRGJCU6USTIJFVEQKWINGVUVKJGVDVIR2SINCEUNKDIREVEMSTJE2UIVJSKVFFGS2SJFDEGV2LKVFDKR2WKVLUGSCLKVNEKSKRJNHUUSSMKVHVGS22JFJEUVJUKRBVKSSSIZDEOVSTJZFEMTSGIVJDEQSHJZBUKNSUJNBUWTSDKUZFOU2VJFHEORKVKVJUQSKFLJKUSVBSJZEUUSSWIFKVGSKJLJBVMRKVGJIEWQSJKZKVEQ2CJJLEURSHKIZEETCGJNCUGVCLKNFUMRCVINLUWRKJIUZVKRKVKNEESWJTIVFU2USYJFHEUVKPKJFVUS2SJFKTMUSCKVETKQ2UIVLEGUSKGVBEMR2SGJJEWSSLKVFVKS2TJFJEMRSVKNJUMSSWJNDEKUSDJBFEMTKVJFJEWTSLJJEEKT2RJNNEWTK2IUZFCU2UJFLECVSTKJBUESRVJJDEGURSIJIFCWKUJNHUEWKGLFNEISJXIQ2ESSSFKF6DCMBWG4YDE7BSPRWHAMLBGBRWK7BRFV6FKU2EPRDUE7BSGAZDKLJQGIWTEMT4GIYDENJNGAZS2MBTPR6DALRQGA",
        roomOnly: false,
        refundable: false,
        rateType: "Non-refundable",
        cancellationPolicy: null,
        supplierPrice: "1588.24",
        tax: "226.60",
        suggestedSellingPrice: "1588.24",
        sellingPrice: "1588.24",
        toSave: "1588.24_226.60_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 226.6,
        },
      },
      {
        uniqueId:
          "GE5ESNBSIZKVMQ2QJJNEERSPKIZEMR2OIRKVOVCTKNFVMRCWKNLUWVKKGVDVMRKWGJEEWRSOIVEVCS2PJRFEWVKPJZBFIS2SI5KTMU2TLBFFMS2WKVJEGQSKLJHEMR2SGIZEWUSDIUZFOU2TJM2UOVSFJZJUKSKOIVLFKVJSJBEUMTSFJFJTETSJJJFFKT2TJMZEUQSNKUZFKU2SJE2UKVCFKZBUESSWJZDEGURSJ5DVURCVK5GTEVCHGVDFMTKTKNMUYRSKIRCVCMSRJNFEMVSNKIZFGSKRGJCU6USTIJFVEQKWINGVUVKJGVDVIR2SINCEUNKDIREVEMSTJE2UIVJSKVFFGS2SJFDEGV2LKVFDKR2WKVLUGSCLKVNEKSKRJNHUUSSMKVHVGS22JFJEUVJUKRBVKSSSIZDEOVSTJZFEMTSGIVJDEQSHJZBUKNSUJNBUWTSDKUZFOU2VJFHEORKVKVJUQSKFLJKUSVBSJZEUUSSWIFKVGS2JKZDVMSKWIJKUSNKFKZJVEQ2GJJNEMRSDKIZEUTCKINCUOVCLJNFVMRCVINLUWSKMIZDVKVKVGJIUWSSEKZIVOS2WJNFEURKPKJFFKSKSJFKE6UKTKNETKTKUI5JEGRCKKZHEMT2SJNHUUSSDKRGVGSSTJNHEKVSVKNBUISJVI5LEKVKLJBFEKWSGI5GVGTSJJJFFKTKWJMZESUSCKVJVCU2TJE2USVCLKJBUESSWIFMFSTKKK5DVSWKTGRGVUV2QKFNEIQKNKJLEMVKZIRCUYSSTI5CVCRCDJZBDER2BLFCFKTKCKFIFESSFGZ6DCMBWG4YDE7BSPRWHAMLBGBRWK7BRFV6FKU2EPRDUE7BSGAZDKLJQGIWTEMT4GIYDENJNGAZS2MBTPR6DALRQGA",
        roomOnly: true,
        refundable: true,
        rateType: "Refundable",
        cancellationPolicy:
          "If cancelled before Feb 21, 2025 09:00 AM, the cancellation fee is 220.44 USD.",
        supplierPrice: "1661.36",
        tax: "237.03",
        suggestedSellingPrice: "1661.36",
        sellingPrice: "1661.36",
        toSave: "1661.36_237.03_0.00_0.00",
        taxIncluded: "yes",
        feesIncluded: false,
        currency: "USD",
        included: {
          description: "Tax",
          amount: 237.03,
        },
      },
    ],
  },
];
