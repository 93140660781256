import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { Libraries, LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../../constant/environment";
import { LocationTypes } from "../../../types";
import styles from "./input.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";

const libraries: Libraries = ["places"];

interface AutocompleteAddressProps {
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  selectedLocation: LocationTypes;
  setSelectedLocation: Dispatch<SetStateAction<LocationTypes>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  setIsFormUpdated: Dispatch<SetStateAction<boolean>>;
}

const AutocompleteAddress: React.FC<AutocompleteAddressProps> = ({
  onPlaceSelected,
  selectedLocation,
  setSelectedLocation,
  address,
  setAddress,
  setIsFormUpdated,
}) => {
  const state = React.useContext(StateContext);
  const { loginCreateAccountState, isMobile }: iGlobalContext = state;
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded && !autocompleteServiceRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, [scriptLoaded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddress(value);
    setIsFormUpdated(true);
    if (value && autocompleteServiceRef.current) {
      autocompleteServiceRef.current.getPlacePredictions(
        { input: value },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setPredictions(predictions);
          } else {
            setPredictions([]);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  const handleSelect = (placeId: string) => {
    if (placesServiceRef.current) {
      placesServiceRef.current.getDetails({ placeId }, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place
        ) {
          setAddress(place.formatted_address || "");

          const addressComponents = place.address_components || [];
          let city = "";
          let state = "";
          let country = "";

          addressComponents.forEach((component) => {
            const types = component.types;
            if (types.includes("locality")) {
              city = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (types.includes("country")) {
              country = component.long_name;
            }
          });

          setSelectedLocation({
            ...selectedLocation,
            city: city,
            state: state,
            country: country,
          });
          setIsFormUpdated(true);

          setPredictions([]);
          onPlaceSelected(place);
        }
      });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <LoadScript
          googleMapsApiKey={GOOGLE_MAP_API_KEY}
          libraries={libraries}
          onLoad={() => setScriptLoaded(true)}
        />
      </div>

      {scriptLoaded && (
        <div className={styles.inputBox}>
          {isMobile && loginCreateAccountState.flag === true ? (
            <input
              type="text"
              value={address}
              onChange={handleChange}
              placeholder="Enter city name"
              className={styles.inputField1}
            />
          ) : (
            <input
              type="text"
              value={address}
              onChange={handleChange}
              placeholder="Enter city name"
              className={styles.inputField}
              // className={`${className}`}
            />
          )}
          {predictions.length > 0 && (
            <div className={styles.card}>
              {isMobile && loginCreateAccountState.flag === true ? (
                <div className={styles.listCard1}>
                  <ul className={styles.ulList}>
                    {predictions.map((prediction) => (
                      <li
                        key={prediction.place_id}
                        onClick={() => handleSelect(prediction.place_id)}
                        className={styles.lists}>
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className={styles.listCard}>
                  <ul className={styles.ulList}>
                    {predictions.map((prediction) => (
                      <li
                        key={prediction.place_id}
                        onClick={() => handleSelect(prediction.place_id)}
                        className={styles.lists}>
                        {prediction.description}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutocompleteAddress;
