import { useCallback, useContext, useEffect, useState } from "react";
import hotelRoom from "../../../../assets/hotelRoom.png";
import HotelRating from "../hotelRating/hotelRating";
// import { Amenity } from "../../../../types";
// import { expandIcon, starSharpSolid } from "../../../../constant";
import styles from "./hotel.module.scss";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { HOTEL_CARD_TYPES } from "../../../../enums";
import { searchGrayIcon } from "../../../../constant";
import TravelerPopover from "../../bookingPopover/travelerPopover/TravelerPopover";
import DateRangePicker from "../../inputFields/dateRangePicker/dateRangePicker";
import { HOTEL_ROOMS_DATA } from "./hotel_roomsData";
import RoomWithImages from "../../../hotelDetails/components/selectRoomModal/components/RoomWithImages";
import RoomDetailsModal from "../../../hotelDetails/components/roomDetailsModal/roomDetailsModal";

interface HotelCardProps {
  readonly star: number;
  readonly hotelName: string;
  readonly hotelLocation: string;
  readonly hotelRating?: number;
  readonly reviewCount?: number;
  readonly Amenity?: string[];
  readonly hotelList: boolean;
  readonly hotelMainImage?: string;
  readonly reviewHeading?: string;
  readonly handleViewDetails?: () => void;
  readonly city?: string;
  readonly hotelDetails?: any;
  readonly hotelCardType?: HOTEL_CARD_TYPES;
}

function HotelCard({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  Amenity,
  hotelList,
  hotelMainImage,
  reviewHeading,
  handleViewDetails,
  city,
  hotelDetails,
  hotelCardType = HOTEL_CARD_TYPES.ADDRESSS,
}: HotelCardProps): JSX.Element {
  // const [showReviewsModal, setShowReviewsModal] = useState(false);
  const globalContext = useContext(StateContext);
  const { setShowReviews }: iGlobalContext = globalContext;
  const [roomCount, setRoomCount] = useState(1);
  const [isRoomCountPopoverOpen, setIsRoomCountPopoverOpen] = useState(false);
  const [checkInBookingDetails, setCheckInBookingDetails] = useState<any>({});
  const [checkOutBookingDetails, setCheckOutBookingDetails] = useState<any>({});
  const [roomDetailsData, setRoomDetailsData] = useState<any>(null);
  const [showRoomDetails, setShowRoomDetails] = useState(false);
  const [isLoading] = useState(false);

  const handleReviewClick = useCallback(() => {
    setShowReviews(true);
  }, [setShowReviews]);

  const renderRoomWithImages = useCallback(() => {
    return HOTEL_ROOMS_DATA.map((room, idx) => (
      <RoomWithImages
        key={idx}
        checkHotelAvailability={(): any => {}}
        isChecking={false}
        room={room}
        selectedHotelRoom={null}
        sessionId="sessionId"
        setRoomDetailsData={setRoomDetailsData}
        setShowRoomDetails={setShowRoomDetails}
      />
    ));
  }, [setRoomDetailsData, setShowRoomDetails]);

  return (
    <div
      className={`${styles.main} ${hotelCardType === HOTEL_CARD_TYPES.SEARCH && styles.searchTypeCls}`}
    >
      <div className={styles.container}>
        <div className={styles.firstCard}>
          <section className={styles.imgBox}>
            <img
              src={hotelMainImage ?? hotelRoom}
              alt="flight"
              className={`${hotelList ? styles.hotelImg : styles.packageImg}`}
            />
            <div className={styles.hotelAndStarContainer}>
              <p className={styles.hotelNameText}>{hotelName}</p>
              {!!star && (
                <p className={styles.ratingsContainer}>{star} star hotel</p>
              )}
            </div>
          </section>
        </div>

        {(hotelCardType === HOTEL_CARD_TYPES.ADDRESSS ||
          hotelCardType === HOTEL_CARD_TYPES.REFUND) && (
          <section className={styles.contentCard}>
            <div className={styles.reviewsContainer}>
              {reviewCount !== undefined && hotelRating !== undefined && (
                <HotelRating
                  hotelRating={hotelRating}
                  onReviewClick={handleReviewClick}
                  reviewCount={reviewCount}
                  reviewHeading={reviewHeading}
                />
              )}
              {/* <span className={styles.star}>{star} star property</span> */}
            </div>
            {hotelCardType === HOTEL_CARD_TYPES.ADDRESSS && (
              <div className={styles.divider} />
            )}
            {hotelCardType === HOTEL_CARD_TYPES.ADDRESSS && (
              <div className={styles.hotelDetailCard}>
                <div className={styles.hotelNameCard}>
                  {/* <p className={styles.hotelName}>{hotelName}</p> */}
                  <p className={styles.location}>
                    {hotelLocation}
                    {city && `, ${city}`}
                  </p>

                  {hotelDetails?.hotelBookId && (
                    <p className={styles.confirmationTicket}>
                      Confirmation: {hotelDetails?.hotelBookId}
                    </p>
                  )}
                </div>
                {/* {!!hotelDetails?.distance && ( */}
                {/* // <div className={styles.addCard}> */}
                {/* <p className={styles.address}>{hotelLocation}</p> */}
                {/* <p className={styles.distance}>
                  {hotelDetails?.distance} mi from Center
                </p> */}
                {/* </div> */}
                {/* )} */}
              </div>
            )}
            {hotelCardType === HOTEL_CARD_TYPES.REFUND && (
              <p className={styles.refundText}>
                {hotelDetails?.refundable || "* Refundable"}
              </p>
            )}

            {/* <div className={styles.amenitiesCard}>
          {Amenity &&
            Amenity.map((i, index) => (
              <p className={styles.amenities} key={index}>
                {i}
                {index !== Amenity.length - 1 &&
                  "\u00A0\u00A0" + " | " + "\u00A0\u00A0"}
              </p>
            ))}
        </div> */}
          </section>
        )}
        {hotelCardType === HOTEL_CARD_TYPES.SEARCH && (
          <div className={styles.hotelSearchContainer}>
            <div className={styles.searchIconContainer}>
              <img
                src={searchGrayIcon}
                alt="search icon"
                className={styles.searchIcon}
              />
              <p>Search Criteria</p>
            </div>
            <div className={styles.criteriaContainer}>
              <div className={styles.roomsContainer}>
                <p className={styles.label}>Rooms</p>
                <TravelerPopover
                  adultsCount={1}
                  setAdultsCount={() => {}}
                  childrenCount={0}
                  setChildrenCount={() => {}}
                  seniorsCount={0}
                  setSeniorsCount={() => {}}
                  numberOfRoomCount={roomCount}
                  setNumberOfRoomCount={setRoomCount}
                  isPopoverOpen={isRoomCountPopoverOpen}
                  setIsPopoverOpen={setIsRoomCountPopoverOpen}
                  popoverTrigger={
                    <div
                      className={`${isRoomCountPopoverOpen ? styles.activeTravelBtn : styles.travelBtn}`}
                    >
                      {roomCount > 0 && <p>&nbsp;&nbsp;{roomCount} Room</p>}
                    </div>
                  }
                  showRoom
                />
              </div>

              <DateRangePicker
                startDate={hotelDetails.checkIn}
                endDate={hotelDetails.checkOut}
                bookingDetails={checkInBookingDetails}
                setBookingDetails={setCheckInBookingDetails}
                handleUpdateData={() => {}}
                label="check in"
              />
              <DateRangePicker
                startDate={hotelDetails.checkIn}
                endDate={hotelDetails.checkOut}
                bookingDetails={checkOutBookingDetails}
                setBookingDetails={setCheckOutBookingDetails}
                handleUpdateData={() => {}}
                label="check out"
              />
            </div>
          </div>
        )}
        {hotelCardType === HOTEL_CARD_TYPES.ROOM && (
          <div className={styles.roomDetailsContainer}>
            {renderRoomWithImages()}
            {Array.isArray(HOTEL_ROOMS_DATA) &&
              HOTEL_ROOMS_DATA?.length === 0 &&
              !isLoading && (
                <>
                  <div className={styles.showCard}>No Rooms found</div>
                  {/* )} */}
                </>
              )}
            {/* <RoomDetailsModal
              isOpen={showRoomDetails}
              roomDetailsData={roomDetailsData}
              onClose={() => {
                setShowRoomDetails(false);
              }}
            /> */}
          </div>
        )}
      </div>
      {/* <section className={styles.secondCard}>
        {reviewCount !== undefined && hotelRating !== undefined && (
          <HotelRating
            hotelRating={hotelRating}
            onReviewClick={() => setShowReviews(true)}
            reviewCount={reviewCount}
            reviewHeading={reviewHeading}
          />
        )}

        <div className={styles.viewBtn} onClick={handleViewDetails}>
          <p className={styles.viewTitle}>VIEW DETAILS</p>{" "}
          <img src={expandIcon} alt="" className={styles.expIcon} />
        </div>
      </section> */}
      {/* <ReviewsModal
        isOpen={showReviewsModal}
        onClose={() => setShowReviewsModal(false)}
      /> */}
    </div>
  );
}

export default HotelCard;
