import {
  calendarRangeRegular,
  ROUTES,
  slidersRegular,
} from "../../../constant";
import { ScreenTypes } from "../../../enums";

export const desktopSidebarTabs = [
  // {
  //   id: 0,
  //   icon: "",
  //   title: "For you",
  //   desc: "",
  //   routInfo: ROUTES.ForYou,
  //   enumType: ScreenTypes.ForYou,
  // },
  {
    id: 1,
    icon: slidersRegular,
    title: "Current Trip",
    desc: "Today’s details about your Paris, Amsterdam and Barcelona trip",
    routInfo: "/today",
    enumType: ScreenTypes.CurrentTrip,
  },
  {
    id: 2,
    icon: calendarRangeRegular,
    title: "My Trips",
    desc: "Upcoming, Past, Saved and Cancelled Trips",
    routInfo: ROUTES.MyTrips,
    enumType: ScreenTypes.MyTrips,
  },
];
