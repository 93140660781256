import React, { useState } from "react";
import { BookingDetails } from "../../../../types";
import NewSingleAirportSelectionPopover from "../../bookingPopover/newSingleAirportSelectionPopover/NewSingleAirportSelectionPopover";
import styles from "./airPort.module.scss";

interface AirPortProps {
  readonly className?: string;
  readonly bookingDetails: BookingDetails;
  // readonly setBookingDetails: React.Dispatch<
  //   React.SetStateAction<BookingDetails>
  // >;
  readonly departure: string;
  readonly arrival: string;
  readonly departureAirportFullName: string;
  // readonly arrivalAirportFullName: string;
  readonly handleUpdateData: () => void;
  // readonly arrivalCity: string;
  // readonly departureCity: string;
  // readonly indexNumber: number;
  readonly label?: string;
  readonly title?: string;
  readonly airportName?: string;
  readonly value: string;
  readonly handleAirportChange: (flight: {
    airportCode: string;
    airportName: string;
  }) => void;
}

function NewSingleAirportPicker({
  className,
  bookingDetails,
  // setBookingDetails,
  // departure,
  // arrival,
  // departureAirportFullName,
  // arrivalAirportFullName,
  handleUpdateData,
  // arrivalCity,
  // departureCity,
  // indexNumber,
  label,
  title,
  value,
  airportName,
  handleAirportChange,
}: AirPortProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      {bookingDetails && (
        <NewSingleAirportSelectionPopover
          label={title ?? ""}
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          handleApplyButton={handleUpdateData}
          value={value}
          handleAirportChange={handleAirportChange}
          popoverTrigger={
            <div className={`${className} ${styles.main}`}>
              <p className={styles.label}>{label}</p>
              <div className={styles.airportCard}>
                <div className={styles.card}>
                  <p className={styles.btnName}>{value}</p>
                  {airportName && <p className={styles.city}>{airportName}</p>}
                </div>
              </div>
            </div>
          }
        />
      )}
    </React.Fragment>
  );
}

export default NewSingleAirportPicker;
