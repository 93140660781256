export interface SaveItineraryDetailsResponse {
    statusCode: number;
    message:    string;
    data:       SaveItineraryDetailsResponseData;
}

export interface SaveItineraryDetailsResponseData {
    tripName:                   string;
    id:                         string;
    userId:                     string;
    metaData:                   string;
    status:                     string;
    numberOfDaysSelected:       string;
    year:                       string;
    selectedMonth:              string;
    is_admin_created:           boolean;
    data:                       DataData;
    featured:                   boolean;
    planTripArray:              string;
    orginatedCity:              null;
    numberOfExperienceSelected: null;
    startDate:                  null;
    endDate:                    null;
    itineraryId:                null;
    flightTransactionId:        null;
    hotelTransactionId:         null;
    topDestination:             null;
    createdAt:                  Date;
    updatedAt:                  Date;
}

export interface DataData {
    name: string;
    from:                            string;
    from_latitude:                   string;
    from_longitude:                  string;
    number_of_days:                  string;
    start_date:                      string;
    trip_type:                       string;
    number_of_travelers:             string;
    number_of_adults:                string;
    number_of_children:              string;
    number_of_senior_citizens:       string;
    flight_refundable:               string;
    flight_cabin_class:              string;
    number_of_stops:                 string;
    budget_per_person:               string;
    number_of_rooms:                 string;
    hotel_refundable:                string;
    hotel_star_ratings:              string;
    airlines:                        string;
    hotels:                          string;
    departure_airport:               string;
    arrival_airport:                 string;
    complete_itinerary_description:  string;
    complete_itinerary_flights_cost: string;
    complete_itinerary_hotels_cost:  string;
    itinerary_version:               string;
    city_wise_itinerary:             CityWiseItinerary[];
}

export interface CityWiseItinerary {
    city_name:                  string;
    city_state:                 string;
    city_country:               string;
    city_country_code:          string;
    city_type:                  string;
    city_latitude:              string;
    city_longitude:             string;
    city_itinerary_description: string;
    city_itinerary:             CityItinerary[];
}

export interface CityItinerary {
    day:                          string;
    city_name:                    string;
    day_itinerary_description:    string;
    itinerary_by_time_of_the_day: ItineraryByTimeOfTheDay[];
}

export interface ItineraryByTimeOfTheDay {
    time_of_the_day:              TimeOfTheDay;
    description:                  string;
    activity_type:                string;
    departure_city_name?:         string;
    departure_city_state?:        string;
    departure_city_country?:      string;
    departure_city_country_code?: string;
    departure_city_lattitude?:    string;
    departure_city_longitude?:    string;
    arrival_city_name?:           string;
    arrival_city_state?:          string;
    arrival_city_country?:        string;
    arrival_city_country_code?:   string;
    arrival_city_lattitude?:      string;
    arrival_city_longitude?:      string;
    "accommodation.location"?:    string;
}

export enum TimeOfTheDay {
    Afternoon = "afternoon",
    Evening = "evening",
    Morning = "morning",
}
