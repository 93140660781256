import React, { useContext } from "react";
import styles from "./chatContent.module.scss";
import { profileIcon, avatarIcon } from "../../../constant";
import Loader from "../../../component/reusableComponent/loader/Loader";
import Markdown from "react-markdown";
import { CHAT_BOT_NAME } from "../../../constant/common";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";

export default function ChatContent({
  message,
  isUserMessage = false,
  isLoading = false,
  preText,
  paddingLeft = false,
}: {
  message: string;
  isUserMessage?: boolean;
  isLoading?: boolean;
  preText?: any;
  paddingLeft?: boolean;
}) {
  const context = useContext(StateContext);
  const { userInfo }: iGlobalContext = context;
  return (
    <div className={styles.container}>
      <div className={styles.systemChatIcon}>
        <img
          className={styles.chatIcon}
          alt="chat logo"
          src={
            isUserMessage
              ? userInfo?.profile_pic
                ? userInfo?.profile_pic
                : profileIcon
              : avatarIcon
          }
        />
        <h3 className={styles.heading}>
          {isUserMessage ? "YOU" : CHAT_BOT_NAME.name}
        </h3>
      </div>

      {isLoading && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
      <Markdown
        unwrapDisallowed
        className={`${styles.markdown} ${paddingLeft && !isUserMessage && styles.markdownPaddingLeft}`}>
        {!isLoading && message !== "" ? message : preText}
      </Markdown>
    </div>
  );
}
