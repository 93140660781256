import React, { useState } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { iGlobalContext } from "../../context/globalContext/interface";
import styles from "./resetPasswordPage.module.scss";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { getUserDetailsByToken, resetPassword } from "../../api/authentication";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import Navbar from "../../component/desktopLandingPage/navbar/navbar";
import { getUserDetailsByTokenTypes, resetPasswordType } from "../../interface";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ResetPasswordPage() {
  const state = React.useContext(StateContext);
  const { handleCustomError } = useCustomErrorHandler();
  const {
    setPrevNav,
    nav,
    setNav,
    setResetPasswordToken,
    isMobile,
  }: iGlobalContext = state;
  const { id } = useParams();

  React.useEffect(() => {
    setResetPasswordToken(id);
    if (nav !== "") setPrevNav(nav);
    setNav("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (id) getUserDetails();
    async function getUserDetails() {
      const response: getUserDetailsByTokenTypes = await getUserDetailsByToken(
        id || ""
      );
      if (response?.statusCode !== 200) {
        handleCustomError(response?.statusCode, "reset.passwordlink");
        return null;
      }
      setUserId(response?.data.id);
      // console.log(response?.data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // password state
  const [userId, setUserId] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  async function handleUpdateClick() {
    if (newPassword !== confirmPassword) {
      toastMessage.error("", "New password not matching");
      return;
    }
    if (newPassword.length < 6) {
      toastMessage.error("", "Password length should be atleast 6 character");
      return;
    }
    const payLoad = {
      id: userId,
      newPassword: newPassword.trim(),
    };
    const response: resetPasswordType = await resetPassword(payLoad);

    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "reset.password");
      return null;
    }
    if (response.message === "Password updated successfully") {
      toastMessage.success(
        "Success",
        "Password updated, return to previous tab to continue."
      );
      setTimeout(() => {
        navigate("/");
        setNav("LANDING_PAGE");
      }, 4000);
    } else {
      toastMessage.error("OOPS!", response?.message);
    }
  }

  return (
    <React.Fragment>
      {!isMobile && <Navbar />}
      <div className={styles.mainContainer}>
        <div className={styles.inputLabelContainer}>
          <label className={styles.label}>NEW PASSWORD</label>
          <TextField
            type={showNewPassword ? "text" : "password"}
            size="small"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={(e: any) => {
              setNewPassword(e.target.value);
            }}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword((prev: any) => !prev)}
                    edge="end"
                    aria-label={
                      showNewPassword ? "Hide password" : "Show password"
                    }>
                    {showNewPassword ? (
                      <Visibility sx={{ height: "3vh" }} />
                    ) : (
                      <VisibilityOff sx={{ height: "3vh" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                height: "30px",
                width: "280px",
                fontSize: "12px",
                color: "#696969",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
              },
            }}
          />
        </div>
        <div className={styles.inputLabelContainer}>
          <label className={styles.label}>CONFIRM PASSWORD</label>
          <TextField
            size="small"
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={(e: any) => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                    edge="end"
                    aria-label={
                      showConfirmPassword ? "Hide password" : "Show password"
                    }>
                    {showConfirmPassword ? (
                      <Visibility sx={{ height: "3vh" }} />
                    ) : (
                      <VisibilityOff sx={{ height: "3vh" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                height: "30px",
                width: "280px",
                fontSize: "12px",
                color: "#696969",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "12px",
              },
            }}
          />
        </div>

        <Button
          onClick={handleUpdateClick}
          size="small"
          className={styles.button}
          sx={{
            color: "secondary.contrastText",
            backgroundColor: "secondary.main",
            ":hover": { bgcolor: "secondary.light" },
          }}>
          Save changes
        </Button>
      </div>
    </React.Fragment>
  );
}
