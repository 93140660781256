import React, { useRef, useState } from "react";
import {
  accomodationsData,
  foodPreferencesData,
} from "../../utils/initialData";
import {
  iGlobalContext,
  iSelectedHotelRoom,
  iSelectedHotelRoomArr,
} from "./interface";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { HomePageNav } from "../../constant/common";
import { iItineraryDetailsData } from "../../interface/common";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  AirportDataTypes,
  BookingDetails,
  FlightChangeTypes,
  FlightSelectedOptions,
  HotelPagination,
  iAppConfig,
  iChatData,
  // iselectedChatData,
  showMoreTypes,
  UserInfo,
} from "../../types";
import { my_trips_tabs } from "../../component/myTripsPage/myTripsConstants";

export const StateContext = React.createContext({} as iGlobalContext);

export function StateProvider({ children }: any) {
  // const [isIOSNativeApp, setIsIOSNativeApp] = useLocalStorage(
  //   "_is_ios_native_app",
  //   false
  // );
  const [iosNativeLoginData, setIosNativeLoginData] = useLocalStorage<{
    username: string;
    email: string;
  }>("_ios_native_login_data", {
    username: "",
    email: "",
  });
  const [isUserJustBookedTrip, setIsUserJustBookedTrip] = useLocalStorage(
    "_trip_trove_is_user_just_booked_trip",
    false
  );
  const [bookClickBeforeLoginFlag, setBookClickBeforeLoginFlag] =
    useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [hasMoreTripPagination, setHasMoreTripPagination] =
    useState<any>("more");

  const [
    shouldUserNavigateBacktoTripDetailsPage,
    setShouldUserNavigateBackTotripDetailsPage,
  ] = useLocalStorage("__user_tripDetails_pageState", false);
  const [isFetchingHotelDetails, setIsFetchingHotelDetails] = useState(false);
  const [isFetchingAirports, setIsFetchingAirports] = useState(false);
  const [isCheckingFlight, setIsCheckingFlight] = useState(false);
  const [checkFlightDetails, setCheckFlightDetails] = useState<boolean>(false);
  const [isFetchingFlights, setIsFetchingFlights] = useState(false);
  const [isFetchingHotels, setIsFetchingHotels] = useState(false);
  const [noFlightFound, setNoFlightFound] = useState(false);
  const [selectedHotelRoom, setSelectedHotelRoom] =
    useLocalStorage<iSelectedHotelRoom | null>("selected_hotel_room", null);
  const [selectedHotelRoomArr, setSelectedHotelRoomArr] = useLocalStorage<
    iSelectedHotelRoomArr[]
  >("selected_hotel_room_arr", []);
  const [getHotelRoomsBody, setGetHotelRoomsBody] = useLocalStorage<any>(
    "_get_hotel_rooms_body",
    null
  );
  const [showSelectRoomModal, setShowSelectRoomModal] = useState(false);
  const [openOverlayScreen, setOpenOverlayScreen] = useSessionStorage(
    "_trip_trove_open_overlay",
    false
  );
  const [hotelDetailsPageId, setHotelDetailsPageId] = useLocalStorage(
    "hotel_details_pageId",
    ""
  );
  const [showReviewsModal, setShowReviews] = useLocalStorage(
    "set_show_reviews",
    false
  );
  const [showPropertyDetails, setShowPropertyDetails] = useLocalStorage(
    "show_property_details",
    false
  );
  const [showMoreFlag, setShowMoreFlag] = useState(false);
  const [nav, setNav] = useSessionStorage(
    "_trip_trove_nav",
    HomePageNav.LANDING_PAGE
  );
  const [myTripActiveTab, setMyTripActiveTab] = useSessionStorage(
    "_myTripTab",
    my_trips_tabs[0]
  );
  const [activeTripDetailsTab, setActiveTripDetailsTab] = useLocalStorage(
    "_trip_trove_trip_details_active_tabs",
    "ITINERARY"
  );
  // ZIPCODE
  const [zip, setZip] = useState<number>();
  // header background
  const [headerBackground, setHeaderBackground] = useState("transparent");
  interface ObjType {
    id: number;
    data: string;
    check: boolean;
  }
  interface MonthsType {
    id: number;
    data: string;
    numOfDays: string;
    year: string;
    check: boolean;
  }

  const [climate, setClimate] = useSessionStorage(
    "_trip_trove_climate",
    [] as ObjType[]
  );
  const [population, setPopulation] = useSessionStorage(
    "_trip_trove_population",
    [] as ObjType[]
  );
  const [experience, setExperience] = useSessionStorage(
    "_trip_trove_experience",
    [] as ObjType[]
  );
  const [accomodations, setAccomodations] = useSessionStorage(
    "_trip_trove_accomodation",
    accomodationsData
  );
  const [foodPreferences, setFoodPreferences] = useSessionStorage(
    "_trip_trove_food_preferences",
    foodPreferencesData
  );
  const [months, setMonths] = useSessionStorage(
    "_trip_trove_months",
    [] as MonthsType[]
  );
  const [budget, setBudget] = useSessionStorage(
    "_trip_trove_budget",
    [] as any[]
  );
  const [userLocation, setUserLocation] = useState("");
  // Onloading of Itienerary details state
  const [itineraryDetailsLoad, setItineraryDetailsLoad] = useState(false);
  // Staring and Dest State
  const [startingValue, setStartingValue] = React.useState({
    value: "",
    flag: false,
  });
  const [destValue, setDestValue] = React.useState({ value: "", flag: false });
  // User Location state
  const [latLong, setLatLong] = useState({
    lat: "",
    long: "",
  });
  // API Response Data
  const [apiResponse, setApiResponse] = useSessionStorage(
    "_trip_trove_api_response",
    []
  );
  const [singleItineraryDetails, setSingleItineraryDetails] = useSessionStorage(
    "_trip_trove_single_itinerary_details",
    {}
  );
  const [data, setData] = useState("");
  // First two category Display state
  const [firstTwoCategoryState, setFirstTwoCategoryState] =
    React.useState(false);
  // Destination selected Flag
  const [selectedDestFlag, setSelectedDestFlag] = useSessionStorage(
    "_trip_trove_select_destination_flag",
    false
  );
  // Loading Screen
  const [loadingScreenState, setLoadingScreenState] = React.useState(false);
  // Selected Budget and Duration Flag
  const [budgetDurationFlag, setBudgetDurationFlag] = useState(false);
  // Result test State
  const [test, setTest] = useState(false);
  // Menu State
  const [menuOpenState, setMenuOpenState] = React.useState(false);
  // Log in in Createa account
  const [loginCreateAccountState, setLoginCreateAccountState] = useState({
    flag: false,
    page: "",
  });
  const [saveScreenState, setSaveScreenState] = React.useState(false);
  // Edit category flag
  const [editState, setEditState] = React.useState(false);
  // IS logged in state
  const [isLogin, setIsLogin] = useState(false);
  // Edit account
  const [prevNav, setPrevNav] = useSessionStorage("_trip_trove_prev_nav", "");
  // Edit account pre Route
  const [prevRoute, setPrevRoute] = useSessionStorage(
    "_trip_trove_prev_route",
    ""
  );
  // Saved Itineraries List
  const [savedItinerariesList, setSavedItinerariesList] = useState([]);
  // Itinerary Individual Details
  const [itinerayDetails, setItineraryDetails] = useState({});
  // Starting and Destination country
  const [startDestCountry, setStartDestCountry] = React.useState({
    start: "",
    dest: "",
  });
  // Number of Days Slider
  const [sliderValue, setSliderValue] = React.useState(3);
  // Log in flag
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [internationDest, setInternationalDest] = React.useState(false);
  //click on signin button from menu state
  const [signInClick, setSignInClick] = useState(false);
  // Trip with N number of days
  const [tripForNDays, setTripForNDays] = useSessionStorage(
    "_trip_trove_trip_for_N_days",
    []
  );
  // Add more id
  const [addMoreId, setAddMoreId] = useState("");
  // Plan  Trip message
  const [fetchingPlanTripMessage, setFetchingPlanTripMessage] = React.useState(
    "Creating trip plan..."
  );
  // Reset Passsword Token
  const [resetPasswordToken, setResetPasswordToken] = React.useState("");
  // shared Itinerary ID
  const [sharedItineraryId, setSharedItineraryId] = useSessionStorage(
    "_trip_trove_shared_itinerary_id",
    ""
  );
  const [tripOrItinearyId, setTripOrItinearyId] = useSessionStorage(
    "_trip_trove_trip_id",
    ""
  );
  // Abort Get trip plan
  const [abortTripAPICall, setAbortTripAPICall] = React.useState(false);
  // Plan trip stream Data
  const [dataStream, setDataStream] = React.useState("");
  const [tripPlanDataStream, setTripPlanDataStream] = React.useState<any>([]);
  // Extra Metadata
  const [extraMeta, setExtraMeta] = useSessionStorage(
    "_trip_trove_extra_meta",
    {
      orginatedCity: "",
      destinationCity: "",
      selectedMonth: "",
      year: "",
      internationalFlag: false,
      numberOfDaysSelected: 2,
      numberOfExperienceSelected: "",
      planTripArray: [],
    }
  );
  //View or edit state
  const [editViewState, setEditViewState] = React.useState(false);
  // State to add a Add more button in experience
  const [moreButton, setMoreButton] = useState(false);
  const [expHeight, setExpHeight] = useState(0);
  // Query State
  const [query, setQuery] = useSessionStorage("_trip_trove_query", {
    listingQuery: "",
    planTripQuery: "",
    updateTripQuery: "",
  });
  const [screen, setScreen] = useSessionStorage("screen_details", "");
  const [isMyTripsTheFirstSelectedScreen, setIsMyTripsTheFirstSelectedScreen] =
    useLocalStorage("_first_selected_screen_while_booking", false);
  const [firstScreen, setFirstScreen] = useSessionStorage(
    "first_screen_details",
    ""
  );
  const [profile, setProfile] = useSessionStorage("userInfo", "");
  // Deelinking Data for Airport Codes and dates
  const [deepLinkData, setDeepLinkData] = React.useState({
    start: "",
    end: "",
    currentDate: "",
  });
  const [metaData, setMetaData] = useSessionStorage(
    "_trip_trove_itinerary_details",
    []
  );
  const [shareClick, setShareClick] = useSessionStorage(
    "_trip_trove_share_click",
    false
  );
  /**
   * Edting Flag for Experience
   */
  const [editExperienceFlag, setEditExperienceFlag] = useSessionStorage(
    "_trip_trove_edit_experience_flag",
    false
  );
  const [editClimatePopulationFlag, setEditClimatePopulationFlag] =
    useSessionStorage("_trip_trove_edit_climate_population_flag", false);

  const [isAdmin, setIsAdmin] = useSessionStorage("_trip_trove_is_admin", true);
  const [isMobile, setIsMobile] = useState(false);

  // Chat input text
  const [chatInputText, setChatInputText] = useSessionStorage(
    "_trip_trove_chat_input_text",
    ""
  );

  // Itinerary details data
  const [itineraryDetailsData, setItineraryDetailsData] = useSessionStorage(
    "_trip_trove_itinerary_details",
    [] as iItineraryDetailsData[]
  );

  const [showMoreReqBody, setShowMoreReqBody] = useSessionStorage(
    "_trip_trove_show_more_req_body",
    []
  );

  const [chatData, setChatData] = useSessionStorage(
    "_trip_trove_chat_data",
    [] as iChatData[]
  );
  const [
    isUserNavigatingFromPaymentGateway,
    setIsUserNavigatingFromPaymentGateway,
  ] = useLocalStorage("is_user_navigating_from_payment_gateway", false);
  const [selectedChatData, setSelectedChatData] = useSessionStorage(
    "_trip_trove_selected_chat_data",
    {} as iChatData
  );

  const [enableMultiSelect, setenableMultiSelect] = useSessionStorage(
    "_trip_trove_enable_multi_select",
    false
  );

  const [openDetailsSliderFlag, setOpenDetailsSliderFlag] = useSessionStorage(
    "_trip_trove_open_details_slider_flag",
    false
  );

  const [isItinerarySavedFlag, setIsItinerarySavedFlag] = useSessionStorage(
    "_trip_trove_is_itinerary_saved_flag",
    false
  );

  const [userInfo, setUserInfo] = useLocalStorage<UserInfo>("user_info", {
    id: "",
    name: "",
    email: "",
    phone: "",
    profile_pic: "",
    loggedIn: false,
    role: 0,
    preferences: {},
  });

  const scrollContainerRef: any = useRef(null);

  const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useLocalStorage(
    "_trip_trove_sidebar_open",
    false
  );

  const [manageHotelsArr, setManageHotelsArr] = useLocalStorage(
    "selected_manage_hotels",
    [] as any[]
  );

  const [appConfig, setAppConfig] = useLocalStorage(
    "_trip_trove_app_config",
    [] as iAppConfig[]
  );

  const [modifyItineraryPrompt, setModifyItineraryPrompt] = useSessionStorage(
    "_trip_trove_modify_itinerary_prompt",
    ""
  );
  const [airPorts, setAirPorts] = useLocalStorage<AirportDataTypes[]>(
    "airPorts",
    [] // Start with an empty array since no data is fetched yet
  );
  const [flightChange, setFlightChange] = useLocalStorage<FlightChangeTypes>(
    "flightChange",
    {
      flightCancel: false,
      flightChange: false,
    }
  );
  const [hotelPagination, setHotelPagination] = useLocalStorage<
    HotelPagination[]
  >("hotelPagination", []);
  const [bookingDetails, setBookingDetails] = useLocalStorage<BookingDetails>(
    "booking_fields",
    {
      adultsCount: 1,
      childrenCount: 0,
      seniorsCount: 0,
      numberOfRoomCount: 1,
      airportFrom: "",
      airportTo: "",
      travelFromDate: "",
      travelToDate: "",
      routeType: "",
      flightClass: "",
      tripType: 2,
      update: false,
      hideFlights: false,
      hideHotels: false,
      isThisMultiCityTrip: false,
      isFormUpdated: {
        arrivalForm: false,
        departureForm: false,
      },
      isDataModified: {
        flight: false,
        hotel: false,
      },
      selectedFlightId: "",
      isFlightAvailable: false,
      airlines: null,
      hotelChains: null,
      flightDayPart: "",
      numberOfStops: "",
      flightRefundable: "",
      noMoreFlights: false,
      hotelUniqueId: "",
      expandedAccordions: [],
      numberOfDays: 0,
      cityInfo: {
        departure: "",
        arrival: "",
      },
      selectedLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      selectedArrivalLocation: {
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
      },
      flightPreferences: {
        connections: 0,
        departureTime: [],
        refundable: false,
        airlines: [],
      },
      hotelPreferences: {
        refundable: false,
        hotelChains: [],
      },
      flightHistory: [
        {
          city_name: "",
          flights: [],
          number_of_days: 0,
        },
      ],
      // totalTravelCities: [
      //   {
      //     city: "",
      //     country: "",
      //     countryFullName: "",
      //     latitude: "",
      //     longitude: "",
      //     numberOfStay: 0,
      //   },
      // ],
      // totalHotelStays: [
      //   {
      //     cityName: "",
      //     numberOfStay: 0,
      //   },
      // ],
      hotels: [
        {
          checkIn: "",
          checkOut: "",
          country: "",
          city: "",
          latitude: "",
          longitude: "",
          cityNotAvailable: false,
        },
      ],
      airPort: [
        {
          arrival: "",
          departure: "",
          fromCity: "",
          destinationCity: "",
          departureDate: "",
          returnDate: "",
          departureAirportFullName: "",
          arrivalAirportFullName: "",
          arrivalCityLatitude: "",
          arrivalCityLongitude: "",
          departureCityLatitude: "",
          departureCityLongitude: "",
          noOfDays: 0,
          newArrivalCitySearch: false,
          newDepartureCitySearch: false,
          arrivalCityDetails: "",
          departureCityDetails: "",
          arrivalSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
          departureSelectedLocation: {
            city: "",
            state: "",
            country: "",
            latitude: 0,
            longitude: 0,
          },
        },
      ],
      travelJourneyData: [
        {
          departureCityAddress: "",
          arrivalCityAddress: "",
          departureDate: "",
          returnDate: "",
          days: [],
          flightCheckIn: "",
          flightCheckOut: "",
          totalDays: 0,
          flightTime: "",
        },
      ],
      visibleList: {
        flight: 0,
        hotel: 4,
        package: 2,
      },
      filters: {
        starRating: [3],
      },
    }
  );

  const [showMore, setShowMore] = useLocalStorage<showMoreTypes>("showMore", {
    visibleList: {
      flight: 4,
      hotel: 4,
      package: 2,
    },
  });

  const [flightData, setFlightData] = useLocalStorage<any>(
    "flight_search",
    null
  );
  const [hotelData, setHotelData] = useLocalStorage<any>("hotel_search", null);
  const [bookedtransIds, setBookedTransIds] = useLocalStorage<any>(
    "booked_transIds",
    null
  );
  const [showCancelHotelsModal, setShowCancelHotelsModal] = useState(false);
  const [selectedFlightOptions, setSelectedFlightOptions] =
    useLocalStorage<FlightSelectedOptions>("flight_SelectOption", {
      flights: true,
      hotels: true,
    });

  const [trigerredListItineraryAPIFlag, setTrigerredListItineraryAPIFlag] =
    useState(false);

  // const [activeTabIndex, setActiveTabIndex] = useSessionStorage(
  //   "_trip_trove_active_tab_index",
  //   0
  // );

  const [selectedCityListPrompContext, setSelectedCityListPrompContext] =
    useSessionStorage("_trip_trove_city_list_prompt_context", null);

  const [flightPreferencesOptions, setFlightPreferencesOptions] =
    useLocalStorage("flight_preferences_options", {
      flightPreferencesScrollValue: 2,
      selectedTimings: [],
      isSelectedRefundable: false,
      selectedAirlineOptions: {},
    });

  const [hotelPreferencesOptions, setHotelPreferencesOptions] = useLocalStorage(
    "hotel_preferences_options",
    {
      isSelectedHotelRefundable: false,
      selectedHotelOptions: {},
    }
  );

  const [hotelDetailsPageState, setHotelDetailsPageState] =
    useLocalStorage<any>("hotel_details_pagestate", null);

  const [flightAvailabilityData, setFlightAvailabilityData] = useSessionStorage(
    "_trip_trove_flight_availability_data",
    {}
  );

  const [showBookingPreferences, setShowBookingPreferences] =
    useLocalStorage<boolean>("booking_prefernces_popup", false);

  // const [itineraryTransactionId, setItineraryTransactionId] = useLocalStorage("_trip_trove_itinerary_transaction_id", )

  return (
    <StateContext.Provider
      value={{
        showCancelHotelsModal,
        setShowCancelHotelsModal,
        manageHotelsArr,
        setManageHotelsArr,
        iosNativeLoginData,
        setIosNativeLoginData,
        // isIOSNativeApp,
        // setIsIOSNativeApp,
        bookClickBeforeLoginFlag,
        setBookClickBeforeLoginFlag,
        hotelPreferencesOptions,
        setHotelPreferencesOptions,
        flightPreferencesOptions,
        setFlightPreferencesOptions,
        setShowBookingPreferences,
        showBookingPreferences,
        isUserJustBookedTrip,
        setIsUserJustBookedTrip,
        activeTripDetailsTab,
        setActiveTripDetailsTab,
        setShouldUserNavigateBackTotripDetailsPage,
        shouldUserNavigateBacktoTripDetailsPage,
        setIsFetchingHotelDetails,
        isFetchingHotelDetails,
        setIsFetchingAirports,
        isFetchingAirports,
        isFetchingFlights,
        isFetchingHotels,
        noFlightFound,
        setNoFlightFound,
        isMyTripsTheFirstSelectedScreen,
        setIsMyTripsTheFirstSelectedScreen,
        setIsFetchingFlights,
        setIsFetchingHotels,
        isUserNavigatingFromPaymentGateway,
        setIsUserNavigatingFromPaymentGateway,
        selectedHotelRoomArr,
        setSelectedHotelRoomArr,
        setHotelData,
        hotelData,
        setSelectedHotelRoom,
        selectedHotelRoom,
        bookedtransIds,
        setBookedTransIds,
        setGetHotelRoomsBody,
        getHotelRoomsBody,
        hotelDetailsPageState,
        setHotelDetailsPageState,
        setShowPropertyDetails,
        setShowReviews,
        showPropertyDetails,
        showReviewsModal,
        setHotelDetailsPageId,
        hotelDetailsPageId,
        openOverlayScreen,
        setOpenOverlayScreen,
        deepLinkData,
        setDeepLinkData,
        tripPlanDataStream,
        setTripPlanDataStream,
        query,
        setQuery,
        dataStream,
        setDataStream,
        startDestCountry,
        setStartDestCountry,
        abortTripAPICall,
        setAbortTripAPICall,
        startingValue,
        setStartingValue,
        destValue,
        setDestValue,
        userLocation,
        setUserLocation,
        nav,
        setNav,
        climate,
        setClimate,
        population,
        setPopulation,
        experience,
        setExperience,
        data,
        setData,
        latLong,
        setLatLong,
        apiResponse,
        setApiResponse,
        headerBackground,
        setHeaderBackground,
        zip,
        setZip,
        firstTwoCategoryState,
        setFirstTwoCategoryState,
        selectedDestFlag,
        setSelectedDestFlag,
        accomodations,
        setAccomodations,
        foodPreferences,
        setFoodPreferences,
        months,
        setMonths,
        budget,
        setBudget,
        loadingScreenState,
        setLoadingScreenState,
        budgetDurationFlag,
        setBudgetDurationFlag,
        menuOpenState,
        setMenuOpenState,
        loginCreateAccountState,
        setLoginCreateAccountState,
        saveScreenState,
        setSaveScreenState,
        test,
        setTest,
        editState,
        setEditState,
        isLogin,
        setIsLogin,
        prevNav,
        setPrevNav,
        internationDest,
        setInternationalDest,
        prevRoute,
        setPrevRoute,
        isLoggedIn,
        setIsLoggedIn,
        sliderValue,
        setSliderValue,
        signInClick,
        setSignInClick,
        savedItinerariesList,
        setSavedItinerariesList,
        itinerayDetails,
        setItineraryDetails,
        tripForNDays,
        setTripForNDays,
        addMoreId,
        setAddMoreId,
        moreButton,
        setMoreButton,
        expHeight,
        setExpHeight,
        singleItineraryDetails,
        setSingleItineraryDetails,
        fetchingPlanTripMessage,
        setFetchingPlanTripMessage,
        resetPasswordToken,
        setResetPasswordToken,
        extraMeta,
        setExtraMeta,
        editViewState,
        setEditViewState,
        sharedItineraryId,
        setSharedItineraryId,
        itineraryDetailsLoad,
        setItineraryDetailsLoad,
        showMoreFlag,
        setShowMoreFlag,
        metaData,
        setMetaData,
        shareClick,
        setShareClick,
        editExperienceFlag,
        setEditExperienceFlag,
        editClimatePopulationFlag,
        setEditClimatePopulationFlag,
        isAdmin,
        setIsAdmin,
        isMobile,
        setIsMobile,
        chatInputText,
        setChatInputText,
        itineraryDetailsData,
        setItineraryDetailsData,
        showMoreReqBody,
        setShowMoreReqBody,
        chatData,
        setChatData,
        selectedChatData,
        setSelectedChatData,
        enableMultiSelect,
        setenableMultiSelect,

        openDetailsSliderFlag,
        setOpenDetailsSliderFlag,
        isItinerarySavedFlag,
        setIsItinerarySavedFlag,
        scrollContainerRef,
        screen,
        setScreen,
        firstScreen,
        setFirstScreen,
        profile,
        setProfile,
        userInfo,
        setUserInfo,
        bookingDetails,
        setBookingDetails,
        showMore,
        setShowMore,
        flightData,
        setFlightData,
        isOpenMobileSidebar,
        setIsOpenMobileSidebar,
        appConfig,
        setAppConfig,
        modifyItineraryPrompt,
        setModifyItineraryPrompt,
        trigerredListItineraryAPIFlag,
        setTrigerredListItineraryAPIFlag,
        // activeTabIndex,

        // setActiveTabIndex,
        selectedCityListPrompContext,
        setSelectedCityListPrompContext,
        selectedFlightOptions,
        setSelectedFlightOptions,
        showSelectRoomModal,
        setShowSelectRoomModal,
        flightAvailabilityData,
        setFlightAvailabilityData,
        tripOrItinearyId,
        setTripOrItinearyId,
        isCheckingFlight,
        setIsCheckingFlight,
        checkFlightDetails,
        setCheckFlightDetails,
        setAirPorts,
        airPorts,
        hotelPagination,
        setHotelPagination,
        myTripActiveTab,
        setMyTripActiveTab,
        hasMoreTripPagination,
        setHasMoreTripPagination,
        inputFocus,
        setInputFocus,
        setFlightChange,
        flightChange
      }}>
      {children}
    </StateContext.Provider>
  );
}
