import { iCityDetailsResult } from "../../../types";

export const getFlightTravelDetails = (tripData: iCityDetailsResult[]) => {
  function filterValidFlights(flights: any[]) {
    if (!Array.isArray(flights) || flights.length === 0) {
      return []; // Return an empty array if the input is invalid
    }

    return flights.filter((flight) => {
      return (
        flight.departure_city_name &&
        // flight.departure_city_state &&
        // flight.departure_city_country &&
        // flight.departure_city_country_code &&
        // flight.departure_city_lattitude &&
        // flight.departure_city_longitude &&
        flight.arrival_city_name
        // &&
        // flight.arrival_city_state &&
        // flight.arrival_city_country &&
        // flight.arrival_city_country_code &&
        // flight.arrival_city_lattitude &&
        // flight.arrival_city_longitude
      );
    });
  }
  if (!tripData || tripData.length === 0) {
    return [];
  }
  const travelDetails: any[] = [];

  tripData.forEach((trip) => {
    // Count the number of days in the city
    const numberOfDays = trip?.tripDetails?.length || 0;

    // Collect flight details for the city
    const flights: any = [];
    trip?.tripDetails?.forEach((day) => {
      const dateInfo = day?.date;
      const dayFlights = day?.itinerary_by_time_of_the_day_data?.filter(
        (activity: any) =>
          activity?.activity_type?.toLowerCase() ===
          "transportation.flight".toLowerCase()
      );
      
      const filterFlights = filterValidFlights(dayFlights);
      filterFlights?.forEach((flight: any) => {
        flights.push({
          // departure_city_name:
          //   flight?.departure_city_name && flight?.departure_city_country
          //     ? `${flight.departure_city_name}, ${flight?.departure_city_state}, ${flight.departure_city_country}`
          //     : flight?.departure_city_name ||
          //       flight?.departure_city_country ||
          //       "",
          dateInfo,
          departure_city_name: [
            flight?.departure_city_name,
            flight?.departure_city_state,
            flight?.departure_city_country,
          ]
            .filter(Boolean)
            .join(", "),
          departure_city_latitude:
            flight?.departure_city_lattitude ||
            flight?.departure_city_latitude ||
            "",
          departure_city_longitude: flight?.departure_city_longitude || "",
          departure_city_country_code:
            flight?.departure_city_country_code || "",
          time_of_the_day: flight?.time_of_the_day || "",

          // arrival_city_name:
          //   flight?.arrival_city_name && flight?.arrival_city_country
          //     ? `${flight.arrival_city_name}, ${flight?.arrival_city_state}, ${flight.arrival_city_country}`
          //     : flight?.arrival_city_name ||
          //       flight?.arrival_city_country ||
          //       "",
          arrival_city_name: [
            flight?.arrival_city_name,
            flight?.arrival_city_state,
            flight?.arrival_city_country,
          ]
            .filter(Boolean)
            .join(", "),
          arrival_city_country_code: flight?.arrival_city_country_code || "",
          arrival_city_latitude:
            flight?.arrival_city_lattitude ||
            flight?.arrival_city_latitude ||
            "",
          arrival_city_longitude: flight?.arrival_city_longitude || "",
        });
      });
    });

    // Add the city details with number of days and flights
    travelDetails.push({
      city_name: trip?.city_name || "",
      number_of_days: numberOfDays,
      flights: flights,
    });
  });
  return travelDetails;
};
