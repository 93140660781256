import { Button } from "@mui/material";
import { backIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { useContext } from "react";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import styles from "./privacy-policy.module.scss";

function PrivacyPolicy(): JSX.Element {
  const state = useContext(StateContext);
  const { isMobile }: iGlobalContext = state;
  const navigate = useNavigate();

  function handleBackButton() {
    triggerGAEvent(GA_LABEL_ENUMS.faqs_back_button_click);

    if (isMobile) window.history.back();
    else {
      navigate("/");
    }
    // if (checkIfThereIsChatContext(chatData)) navigate(ROUTES.TopDestination);
    // else navigate("/");
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.closeBtnContainer}>
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}
          >
            <img src={backIcon} alt="Close" />
            <p>BACK</p>
          </Button>
        </div>
        <div className={styles.pdfContainer}>
          <iframe
            src={`https://beta.zenvoya.ai/privacy-policy`}
            width="100%"
            height="100%"
            style={{
              border: "none",
              minHeight: "600px",
            }}
            title="Privacy Policy PDF"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
