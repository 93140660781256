import React, { useContext } from "react";
import { Stack } from "@mui/material";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import PreviewPreference from "./previewPreference/previewPreference";

export default function EditPreferenceDialog() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Context
  const state = useContext(StateContext);
  const {
    setLoginCreateAccountState,
    loginCreateAccountState,

    setSignInClick,
  }: iGlobalContext = state;
  // console.log("loginCreateAccountState", loginCreateAccountState);
  
  function handleCancel() {
    setSignInClick(false);
    // setSaveScreenState(false)
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    // console.log(loginCreateAccountState);
  }

  return (
    <Stack
      zIndex={1}
      spacing={0}
      alignItems="center"
      justifyContent="center"
      justifySelf="center"
      alignSelf="center">
      <Stack
        sx={{
          height: "100vh",
          width: { xs: "100vw" },
          // transform: "translateY(-30px)",
          maxWidth: { md: "400px" },
        }}
        justifyContent="center"
        alignItems="center">
        <Stack
          sx={{ width: "100%", pl:{sm:3, xs: 3, md: 0}, pr: {sm:3, xs: 3, md: 0}}}
          // px={{ xs: 3, sm: 3, md: 0, lg: 0, xl: 0 }}
        
          >
          {/* <Stack alignSelf="end" mb={2} mr={1.2}>
            <img
              onClick={handleCancel}
              style={{ width: "25px", cursor: "pointer" }}
              src={updateCloseIcon}
              alt="cross"
            />
          </Stack> */}

          <Stack
            sx={{
              height: "90vh",
              width: "100%",
              overflow: "auto",
              backgroundColor: "#ffff",
              borderRadius: "4px",
              overflowX: "hidden",
            }}>
            <PreviewPreference />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
