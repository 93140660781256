import { whiteEditIcon } from "../../../../constant";
import {
  iChatData,
  iCityDetailsResult,
  iMyTripDetails,
} from "../../../../types";
import { Skeleton } from "@mui/material";
import styles from "./myTripsCard.module.scss";
import { MY_TRIP_TABS } from "../../myTripsConstants";
import { getDDYYYY, getMonthDate, getMonthDateUTC } from "../../../../utils/dateUtils";
import { getPassengerCount, getTripName } from "../../../../utils";

export default function MyTripCard({
  headingContainerClassName,
  headingClassName,
  travellersClassName,
  className,
  onCardClick,
  trip,
  showNextIcon = false,
  handleEditTripName,
}: {
  travellersClassName?: string;
  headingClassName?: string;
  headingContainerClassName?: string;
  className?: string;
  onCardClick?: any;
  showNextIcon?: any;
  trip: any;
  handleEditTripName?: any;
}) {
  if (!trip) return <></>;
  const {
    // tripsArr = [],
    // tripDate = "",
    travellersInfo,
    // type = "",
    tripType = "",
    status: type = "",
    metaData = "",
    tripName = "",

    startDate = "2024-09-25T07:15:07.139",
    endDate = "2024-09-25T07:15:07.139",
  } = trip;

  const parsedTripData =
    typeof metaData === "string" ? JSON.parse(metaData)[0] : metaData;
  const { selectedChatData = {} } = parsedTripData || {};
  const { destinationList = [] } = selectedChatData || {};
  // console.log({ trip }, { startDate }, { endDate });

  // console.log(
  //   { trip },
  //   // metaData,
  //   tripName,
  //   destinationList,
  //   "jsonMetaData"
  // );
  // if (!destinationList.length)

  // console.log({ destinationList });

  return (
    <div
      className={`${className} ${styles.mainContainer}`}
      onClick={onCardClick}
    >
      {/* {destinationList.length > 1 && ( */}
      {/* {tripType === MY_TRIP_TABS.SAVED &&
        (type.toUpperCase() === "SAVED" || type.toUpperCase() === "DRAFT") && (
          <div
            className={` ${styles.savedTrips} ${type.toUpperCase() === "SAVED" && styles.sharedTrip} ${type.toUpperCase() === "DRAFT" && styles.draftTrip}`}
          >
            <p>{ type.toUpperCase()}</p>
          </div>
        )} */}
      {tripType === MY_TRIP_TABS.SAVED && type.toUpperCase() === "DRAFT" && (
        <div
          className={` ${styles.savedTrips}  ${type.toUpperCase() === "DRAFT" && styles.draftTrip}`}
        >
          <p>{type.toUpperCase()}</p>
        </div>
      )}
      {startDate && endDate && (
        <div className={styles.tripDate}>
          <p>
            {getMonthDateUTC(startDate)} - {getDDYYYY(endDate)}
          </p>
        </div>
      )}
      {/* )} */}

      <div className={`${className} ${styles.imageContainer}`}>
        {destinationList[destinationList.length - 1]?.imageURL &&
          destinationList.map((ele: iCityDetailsResult) => (
            <img
              src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/destinationPlaceholder.png";
                currentTarget.style.objectFit = "cover";
                currentTarget.style.height = "100%";
                currentTarget.style.width = `${100 / destinationList.length}%`;
              }}
              style={{ width: `${100 / destinationList.length}%` }}
              className={styles.img}
              // src={ele.imageURL}
              alt="city Imge"
            />
          ))}
      </div>

      <div
        className={`${styles.placeHeadingContainer} ${headingContainerClassName}`}
      >
        <div className={styles.placeHeadingMobContainer}>
          <h1 className={`${headingClassName} ${styles.placeHeadingMob}`}>
            {tripName ||
              getTripName({ destinationList: destinationList } as iChatData) ||
              destinationList.reduce(
                (acc: any, ele: iCityDetailsResult, index: number) =>
                  acc + ele?.city_name,
                ""
              ) ||
              // tripsArr.reduce(
              //   (acc: any, ele: iCityDetailsResult, index: number) =>
              //     index === destinationList.length - 1
              //       ? acc.substring(0, acc.length - 2) +
              //         " " +
              //         ele?.cityName?.split(",")[0]
              //       : ele?.cityName?.split(",")[0] + ", ",
              //   ""
              // ) ||
              ""}
            {/* {.cityName?.split(",")[0]} */}
          </h1>

          <h2
            className={travellersClassName}
            onClick={() => {
              // // console.log("hi inside");
              handleEditTripName();
            }}
          >
            {getPassengerCount(
              travellersInfo?.adultsCount
                ? Number(travellersInfo?.adultsCount)
                : 0,
              travellersInfo?.childrenCount
                ? Number(travellersInfo?.childrenCount)
                : 0,
              travellersInfo?.seniorsCount
                ? Number(travellersInfo?.seniorsCount)
                : 0
            )}
            {/* {travellersInfo} */}
          </h2>
        </div>
        {showNextIcon && (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEditTripName();
            }}
          >
            <img
              className={styles.nextIcon}
              // src={selectedIcon}
              src={whiteEditIcon}
              alt="selected"
              onClick={() => {
                handleEditTripName();
              }}
            />
          </div>
        )}
        {/* <div style={{ display: showArrowIcon ? "flex" : "none" }}>
          {selected ? (
            <img
              className={styles.selectedIcon}
              // src={selectedIcon}
              src={arrowNextIcon}
              alt="selected"
            />
          ) : (
            <img
              className={styles.nextIcon}
              src={arrowNextIcon}
              alt="arrowNextIcon"
            />
          )}
        </div> */}
      </div>
    </div>
  );
}

// import { whiteEditIcon } from "../../../../constant";
// import { iCityDetailsResult, iMyTripDetails } from "../../../../types";
// import { Skeleton } from "@mui/material";
// import styles from "./myTripsCard.module.scss";
// import { MY_TRIP_TABS } from "../../myTripsConstants";
// import { getDDYYYY, getMonthDate } from "../../../../utils/dateUtils";
// import { getPassengerCount } from "../../../../utils";

// export default function MyTripCard({
//   headingContainerClassName,
//   headingClassName,
//   travellersClassName,
//   className,
//   onCardClick,
//   trip,
//   showNextIcon = false,
//   handleEditTripName,
// }: {
//   travellersClassName?: string;
//   headingClassName?: string;
//   headingContainerClassName?: string;
//   className?: string;
//   onCardClick?: any;
//   showNextIcon?: any;
//   trip: any;
//   handleEditTripName?: any;
// }) {
//   const {
//     tripsArr = [],
//     tripDate = "",
//     travellersInfo,
//     // type = "",
//     tripType = "",
//     status: type = "",
//     metaData = "",
//     tripName = "",

//     startDate = "2024-09-25T07:15:07.139",
//     endDate = "2024-09-25T07:15:07.139",
//   } = trip;

//   const parsedTripData = metaData && JSON.parse(metaData);
//   const { selectedChatData = {} } = parsedTripData[0] || {};
//   const { destinationList = [] } = selectedChatData || {};
//   // console.log({ trip }, { startDate }, { endDate });

//   // console.log(
//     { trip },
//     // metaData,
//     tripName,
//     destinationList,
//     "jsonMetaData"
//   );
//   // if (!destinationList.length)

//   return (
//     <div
//       className={`${className} ${styles.mainContainer}`}
//       onClick={onCardClick}
//     >
//       {/* {destinationList.length > 1 && ( */}
//       {/* {tripType === MY_TRIP_TABS.SAVED &&
//         (type.toUpperCase() === "SAVED" || type.toUpperCase() === "DRAFT") && (
//           <div
//             className={` ${styles.savedTrips} ${type.toUpperCase() === "SAVED" && styles.sharedTrip} ${type.toUpperCase() === "DRAFT" && styles.draftTrip}`}
//           >
//             <p>{ type.toUpperCase()}</p>
//           </div>
//         )} */}
//       {tripType === MY_TRIP_TABS.SAVED && type.toUpperCase() === "DRAFT" && (
//         <div
//           className={` ${styles.savedTrips}  ${type.toUpperCase() === "DRAFT" && styles.draftTrip}`}
//         >
//           <p>{type.toUpperCase()}</p>
//         </div>
//       )}
//       {startDate && endDate && (
//         <div className={styles.tripDate}>
//           <p>
//             {getMonthDate(startDate)} - {getDDYYYY(endDate)}
//           </p>
//         </div>
//       )}
//       {/* )} */}

//       <div className={`${className} ${styles.imageContainer}`}>
//         {destinationList[destinationList.length - 1]?.imageURL ? (
//           destinationList.map((ele: iCityDetailsResult) => (
//             <img
//               src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
//               onError={({ currentTarget }) => {
//                 currentTarget.onerror = null; // prevents looping
//                 currentTarget.src = "/destinationPlaceholder.png";
//                 currentTarget.style.objectFit = "cover";
//                 currentTarget.style.height = "100%";
//                 currentTarget.style.width = `${100 / destinationList.length}%`;
//               }}
//               style={{ width: `${100 / destinationList.length}%` }}
//               className={styles.img}
//               // src={ele.imageURL}
//               alt="city Imge"
//             />
//           ))
//         ) : tripsArr[tripsArr.length - 1]?.imageURL ? (
//           tripsArr.map((ele: iCityDetailsResult) => (
//             <img
//               src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
//               onError={({ currentTarget }) => {
//                 currentTarget.onerror = null; // prevents looping
//                 currentTarget.src = "/destinationPlaceholder.png";
//                 currentTarget.style.objectFit = "cover";
//                 currentTarget.style.height = "100%";
//                 currentTarget.style.width = `${100 / tripsArr.length}%`;
//               }}
//               style={{ width: `${100 / tripsArr.length}%` }}
//               className={styles.img}
//               // src={ele.imageURL}
//               alt="city Imge"
//             />
//           ))
//         ) : (
//           <img
//             src={"https://error.error"}
//             onError={({ currentTarget }) => {
//               currentTarget.onerror = null; // prevents looping
//               currentTarget.src = "/destinationPlaceholder.png";
//               currentTarget.style.objectFit = "cover";
//               currentTarget.style.height = "100%";
//               currentTarget.style.width = `${100}%`;
//             }}
//             style={{ width: `${100}%` }}
//             className={styles.img}
//             // src={ele.imageURL}
//             alt="city Imge"
//           />
//           // <Skeleton
//           //   animation="wave"
//           //   variant="rectangular"
//           //   width={"100%"}
//           //   height={"100%"}
//           // />
//         )}
//       </div>

//       <div
//         className={`${styles.placeHeadingContainer} ${headingContainerClassName}`}
//       >
//         <div className={styles.placeHeadingMobContainer}>
//           <h1 className={`${headingClassName} ${styles.placeHeadingMob}`}>
//             {tripName ||
//               destinationList.reduce(
//                 (acc: any, ele: iCityDetailsResult, index: number) =>
//                   index === destinationList.length - 1
//                     ? acc.substring(0, acc.length - 2) +
//                       " " +
//                       ele?.cityName?.split(",")[0]
//                     : ele?.cityName?.split(",")[0] + ", ",
//                 ""
//               ) ||
//               tripsArr.reduce(
//                 (acc: any, ele: iCityDetailsResult, index: number) =>
//                   index === destinationList.length - 1
//                     ? acc.substring(0, acc.length - 2) +
//                       " " +
//                       ele?.cityName?.split(",")[0]
//                     : ele?.cityName?.split(",")[0] + ", ",
//                 ""
//               ) ||
//               ""}
//             {/* {.cityName?.split(",")[0]} */}
//           </h1>

//           <h2
//             className={travellersClassName}
//             onClick={() => {
//               // // console.log("hi inside");
//               handleEditTripName();
//             }}
//           >
//                {getPassengerCount(
//                 travellersInfo?.adultsCount
//                   ? Number(travellersInfo?.adultsCount)
//                   : 0,
//                 travellersInfo?.childrenCount
//                   ? Number(travellersInfo?.childrenCount)
//                   : 0,
//                 travellersInfo?.seniorsCount
//                   ? Number(travellersInfo?.seniorsCount)
//                   : 0
//               )}
//             {/* {travellersInfo} */}
//           </h2>
//         </div>
//         {showNextIcon && (
//           <div
//             onClick={(e) => {
//               e.preventDefault();
//               e.stopPropagation();
//               handleEditTripName();
//             }}
//           >
//             <img
//               className={styles.nextIcon}
//               // src={selectedIcon}
//               src={whiteEditIcon}
//               alt="selected"
//               onClick={() => {
//                 handleEditTripName();
//               }}
//             />
//           </div>
//         )}
//         {/* <div style={{ display: showArrowIcon ? "flex" : "none" }}>
//           {selected ? (
//             <img
//               className={styles.selectedIcon}
//               // src={selectedIcon}
//               src={arrowNextIcon}
//               alt="selected"
//             />
//           ) : (
//             <img
//               className={styles.nextIcon}
//               src={arrowNextIcon}
//               alt="arrowNextIcon"
//             />
//           )}
//         </div> */}
//       </div>
//     </div>
//   );
// }
