import { Messages } from "../constant/messages/toastMessages";
import { ApiInfo, ToastMessagesType } from "../types";

const useCustomErrorHandler = () => {
  const handleCustomError = (statusCode: number, apiName: ApiInfo) => {
    if (!statusCode || !apiName) {
      return;
    }
    if (statusCode === 200) {
      return;
    }

    let message = "";
    let title = "Error";

    // Context-specific prefixes
    // const contextPrefix = `${contextType}.failure`;
    const contextPrefix =
      `${apiName}.failure.${statusCode}` as `${string}.failure.${number}`;

    switch (statusCode) {
      // Client-Side
      case 400:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Bad Request";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 401:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Unauthorized";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 402:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Please get the access to proceed";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Access Required!";
        break;
      case 403:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Forbidden";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 404:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Not Found";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;

      //   Server-Side Errors
      case 500:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Internal Server Error";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 501:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Not Implemented";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 502:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Bad Gateway";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 503:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Service Unavailable";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;
      case 504:
        message =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]?.message ||
          "Gateway Timeout";
        title =
          Messages[`${contextPrefix}` as keyof ToastMessagesType]
            ?.messageTitle || "Error";
        break;

      default:
        message =
          Messages.ApiBreakdown?.message || "An unexpected error occurred.";
        title = Messages.ApiBreakdown?.messageTitle || "Unknown Error";
    }

    console.error(`Error (${statusCode}): ${title} - ${message}`);
    // toastMessage.error(title, message);
  };

  return { handleCustomError };
};

export default useCustomErrorHandler;
