import { endPoints } from "../constant/api_constants/url";
import { API_METHODS } from "../enums";
import { fetchApi } from "../utils/apiFunction";
import { handleApiResponse } from "../utils/handleApiResponse";

// const handleApiResponse = (response: any) => {
//   const { statusCode, message } = response;

//   if (statusCode >= 200 && statusCode <= 204) {
//     return response;
//   } else if (statusCode >= 400 && statusCode <= 404) {
//     console.error("Client side error occurred:", message || "Unknown error");
//     throw new Error(
//       `Client error: ${message || "Unknown error"} (${statusCode})`
//     );
//   } else if (statusCode >= 500 && statusCode <= 504) {
//     console.error("Server side error occurred:", message || "Unknown error");
//     throw new Error(
//       `Server error: ${message || "Unknown error"} (${statusCode})`
//     );
//   } else {
//     console.error("Unexpected response:", message || "Unknown error");
//     throw new Error(
//       `Unexpected error: ${message || "Unknown error"} (${statusCode})`
//     );
//   }
// };

export const getPreviewPreferenceApi = async (userId: string) => {
  const endpoint = endPoints.preference(userId);
  const response = await fetchApi(endpoint, API_METHODS.GET);
  return handleApiResponse(response);
};

// export const getPreviewPreferenceApi = async (userId: string) => {
//   const endpoint = endPoints.preference(userId);
//   return await fetchApi(endpoint, API_METHODS.GET);
// };

export const addUserPreferencesApi = async (payload: any): Promise<any> => {
  const endpoint = endPoints.updatePreference;
  return await fetchApi(endpoint, API_METHODS.POST, payload);
};

export const updateUserPreferencesApi = async (payload: any): Promise<any> => {
  const endpoint = endPoints.updatePreference;
  return await fetchApi(endpoint, API_METHODS.PUT, payload);
};
