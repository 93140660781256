const FormatInputToUTC = (
  dateString: string,
  showDayCommaSeparated: boolean
): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    timeZone: "UTC",
  };
  const date = new Date(dateString);
  const weekday = date.toLocaleDateString("en-US", {
    weekday: "short",
    timeZone: "UTC",
  });
  const formattedDate = date.toLocaleDateString("en-US", options).toUpperCase();
  const finalDate = showDayCommaSeparated
    ? `${weekday}, ${formattedDate}`
    : `${weekday} ${formattedDate}`;
  return finalDate;
};

export default FormatInputToUTC;
