import { useContext } from "react";
import DialogPopup from "../dialogPopup/dialogPopup";
import Loader from "../loader/Loader";
import styles from "./NewLoader.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";

interface iProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  loaderIcon1?: any;
  text: string;
  subText?: string;
  loaderIcon2?: any;
  failureIcon?: any;
  cancelButtonClassName?: string;
  hideLoader?: boolean;
  loadFullScreen?: boolean;
  bottomBtnText?: string;
}
function NewLoader(props: iProps): JSX.Element {
  const {
    className = "",
    isOpen,
    onClose,
    loaderIcon1,
    loaderIcon2,
    text = "",
    cancelButtonClassName = "",
    hideLoader = false,
    loadFullScreen = false,
    failureIcon,
    subText = "",
    bottomBtnText = "Cancel",
  } = props;

  const globalContext = useContext(StateContext);
  const { isMobile }: iGlobalContext = globalContext;

  if (isMobile || loadFullScreen)
    return (
      <DialogPopup className={`${styles.newLoaderContainer}`} isOpen={isOpen}>
        <div className={`${className} ${styles.newLoaderComponent}`}>
          <div className={styles.imageContainer}>
            {loaderIcon1 && (
              <img
                className={styles.loaderIcon}
                src={loaderIcon1}
                alt="loading icon"
              />
            )}
            {loaderIcon2 && (
              <img
                className={styles.loaderIcon}
                src={loaderIcon2}
                alt="loading icon"
              />
            )}
            {failureIcon && (
              <img
                className={styles.failureIconStyles}
                src={failureIcon}
                alt="failure icon"
              />
            )}
          </div>

          {text && (
            <p
              className={`${styles.loaderText} ${subText && styles.subTextExists}`}
            >
              {text}
            </p>
          )}
          {subText && <p className={`${styles.loaderSubText}`}>{subText}</p>}
          {!hideLoader && <Loader />}
          {bottomBtnText && (
            <button
              className={`${styles.cancelBtn} ${cancelButtonClassName}`}
              onClick={onClose}
            >
              {bottomBtnText}
            </button>
          )}
        </div>
      </DialogPopup>
    );

  return (
    <div
      className={`${styles.newLoaderContainer}`}
      // isOpen={isOpen}
      style={{ display: isOpen ? "flex" : "none" }}
    >
      <div className={`${className} ${styles.newLoaderComponent}`}>
        <div className={styles.imageContainer}>
          {loaderIcon1 && (
            <img
              className={styles.loaderIcon}
              src={loaderIcon1}
              alt="loading icon"
            />
          )}
          {loaderIcon2 && (
            <img
              className={styles.loaderIcon}
              src={loaderIcon2}
              alt="loading icon"
            />
          )}
          {failureIcon && (
            <img
              className={styles.failureIconStyles}
              src={failureIcon}
              alt="failure icon"
            />
          )}
        </div>

        {text && (
          <p
            className={`${styles.loaderText} ${subText && styles.subTextExists}`}
          >
            {text}
          </p>
        )}
        {subText && <p className={styles.loaderSubText}>{subText}</p>}
        {!hideLoader && <Loader />}
        <button
          className={`${cancelButtonClassName} ${styles.cancelBtn} `}
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default NewLoader;
