

export const handleApiResponse = (response: any) => {
  const { statusCode, message } = response;

  if (statusCode >= 200 && statusCode <= 204) {
    return response;
  } else if (statusCode >= 400 && statusCode <= 404) {
    console.error("Client side error occurred:", message || "Unknown error");
    throw new Error(
      `Client error: ${message || "Unknown error"} (${statusCode})`
    );
  } else if (statusCode >= 500 && statusCode <= 504) {
    console.error("Server side error occurred:", message || "Unknown error");
    throw new Error(
      `Server error: ${message || "Unknown error"} (${statusCode})`
    );
  } else {
    console.error("Unexpected response:", message || "Unknown error");
    throw new Error(
      `Unexpected error: ${message || "Unknown error"} (${statusCode})`
    );
  }
};




