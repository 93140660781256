import { useContext } from "react";
import {
  // zenvolyaNewLogo,
  zenvoyaChatIcon,
  // ROUTES,
  // VIEW_RECOMMENDED_TRIPS,
  // SENDING_CHAT_MESSAGE,
  scrollRightBtnIcon,
  avatarIcon,
} from "../../../constant";

import DestinationCard from "../../reusableComponent/destinationCard/DestinationCard";
import InputWithMic from "../../reusableComponent/inputWithMic/InputWithMic";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { StateContext } from "../../../context/globalContext/context";
import Loader from "../../reusableComponent/loader/Loader";
import styles from "./recomm.module.scss";
import InitialChat from "../initialChat/initialChat";
// import { RESPONSE_DUMMY } from "../../../constant/responseDummy";
// import { localStorageName, ScreenTypes } from "../../../enums";
import { iAppConfig, iCityDetailsResult } from "../../../types";
// import ReactGA from "react-ga4";
// import { toastMessage } from "../../../helpers/toast/toastMessage";
import { APP_CONFIG, CHAT_BOT_NAME } from "../../../constant/common";
import Prompts from "../../prompts/prompts";
import {
  // RecommendedTripContextProvider,
  useRecommendedTripContext,
} from "../../../context/recommendedTripContext/recommendedTripContext";
import { iRecommendedTripContext } from "../../../context/recommendedTripContext/recommendedTripContextInterface";
// import UpcomingTripCard from "../../reusableComponent/upcomingTripCard/UpcomingTripCard";
// import { upcomingDummyTripData } from "../../../constant/dummyData";
import UpcomingTripCarousel from "../upcomingTripCarousel/UpcomingTripCarousel";

function RecommendedTrips(): JSX.Element {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    // setScreen,
    appConfig,
    // setPrevRoute,
    chatData,
    userInfo
  }: iGlobalContext = context;

  const {
    frequentlyViewTrips,
    index,
    handleScrollLeft,
    sliderRef,
    handleViewItineraryDetails,
    handleScrollRight,
    disableInput,
    handleCreateItinerary,
    handleCancel,
    setDisableInput,
    upcomingTripArr,
    scrollContainerRef,
  }: iRecommendedTripContext = useRecommendedTripContext();

  return (
    // <RecommendedTripContextProvider>
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.contentBox}>
          <div className={styles.contentCard} ref={scrollContainerRef}>
            <div className={styles.upcomingtripContainer}>
              {userInfo?.loggedIn && upcomingTripArr?.length > 0 && (
                <>
                  <h1 className={styles.heading}>Upcoming Trips</h1>
                  <UpcomingTripCarousel carouselArr={upcomingTripArr} />
                  {/* <div className={styles.upcomingtripSubContainer}>
                  <UpcomingTripCard
                    destinationList={upcomingDummyTripData}
                    onCardClick={() => {
                      setPrevRoute(ROUTES.Home);
                      navigate(ROUTES.TripDetails);
                    }}
                  />
                  <h1 className={styles.bookedDate}>
                    Booked on JUNE 8 2024 | 4:37pm
                  </h1>
                </div> */}
                </>
              )}
            </div>
            {/* <img className={styles.logo} alt="logo" src={zenvolyaNewLogo} /> */}
            <div className={styles.midSectionContainer}>
              {/* <div className={styles.horizontalScrollContainer}>
              {frequentlyViewTrips.length > 0 && (
                <div className={styles.recommendedTripsContainer}>
                  <h1 className={styles.recommendedTripsHeading}>
                    Recommended Trips
                  </h1>
                  {index.first > 0 && (
                    <div
                      onClick={handleScrollLeft}
                      className={styles.leftBtnContainer}
                    >
                      <img
                        className={styles.leftBtn}
                        src={scrollRightBtnIcon}
                        alt="left btn"
                      />
                    </div>
                  )}

                  <div className={styles.scrollContainer} ref={sliderRef}>
                    {frequentlyViewTrips.length &&
                      frequentlyViewTrips.map((ele: any, index: number) => (
                        <DestinationCard
                          recommendedTripCard
                          width="calc(50vw - 7px)"
                          key={index}
                          destinationData={
                            // userInfo?.loggedIn
                            //   ?
                            // ele?.destinationList[0]
                            // : JSON.parse(
                            //     ele.metaData
                            //   )[0]?.selectedChatData?.destinationList?.filter(
                            //     (ele: iCityDetailsResult) => ele.checkedFlag
                            //   )[0]
                            JSON.parse(
                              ele.metaData
                            )[0]?.selectedChatData?.destinationList?.filter(
                              (ele: iCityDetailsResult) => ele.checkedFlag
                            )[0]
                          }
                          onClick={() => handleViewItineraryDetails(ele)}
                          selectable={false}
                        />
                      ))}
                  </div>
                  <div
                    onClick={handleScrollRight}
                    className={styles.rightBtnContainer}
                  >
                    <img
                      className={styles.rightBtn}
                      src={scrollRightBtnIcon}
                      alt="right btn"
                    />
                  </div>
                </div>
              )}
            </div> */}
              <div className={styles.contentContainer}>
                <div className={styles.sectionContainer}>
                  <div className={styles.systemChatIcon}>
                    <img
                      className={styles.chatIcon}
                      alt="chat logo"
                      // src={zenvoyaChatIcon}
                      src={avatarIcon}
                    />
                    <h3 className={styles.heading}>{CHAT_BOT_NAME.name}</h3>
                  </div>
                  <div
                    className={styles.constText}
                    dangerouslySetInnerHTML={{
                      __html:
                        appConfig?.length > 0
                          ? appConfig?.filter(
                              (ele: iAppConfig) =>
                                ele.key === APP_CONFIG.HOME_CONSTANT_2
                            )[0]?.value
                          : "",
                    }}
                  />
                </div>
                {disableInput && (
                  <div className={styles.chatLoaderContainer}>
                    <div className={styles.chatLoaderSubContainer}>
                      <InitialChat
                        message={chatInputText || chatData[0]?.message}
                      />
                    </div>
                  </div>
                )}
                {disableInput && <Loader />}
                {!disableInput && <Prompts setValue={setChatInputText} />}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.searchCard}>
          <div className={styles.inputBox}>
            <InputWithMic
              onStopRecording={handleCreateItinerary}
              onSend={handleCreateItinerary}
              value={chatInputText}
              setValue={setChatInputText}
              disable={disableInput}
              handleCancel={handleCancel}
              setDisableInput={setDisableInput}
              disableSendBtn={chatInputText === "" ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
    // </RecommendedTripContextProvider>
  );
}

export default RecommendedTrips;
