import { BookingDetails } from "../../../../types";
import styles from "./duration.module.scss";
import FormatInputToUTC from "../../../../hooks/utcDateFormater";
import { useContext } from "react";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";

interface iProps {
  bookingDetails: BookingDetails;
  hotel: boolean;
  flight: boolean;
  stayCount: number;
}

function Duration({
  bookingDetails,
  hotel,
  flight,
  stayCount,
}: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { selectedChatData, selectedFlightOptions }: iGlobalContext = state;
  const lastCheckoutDate =
    bookingDetails?.hotels.length > 0
      ? bookingDetails?.hotels[bookingDetails?.hotels.length - 1].checkOut
      : null;
  return (
    // <div className={styles.main}>
    //   <div className={styles.container}>
    //     {bookingDetails.travelFromDate === "" ? (
    //       <p className={styles.title}>Duration</p>
    //     ) : (
    //       <p className={styles.title}>{flight ? "RETURN" : "CHECK OUT"}</p>
    //     )}
    //     <div className={styles.durationCard}>
    //       <p className={styles.desc}>
    //         {bookingDetails.travelToDate
    //           ? FormatInputToUTC(bookingDetails.travelToDate, true)
    //           : ""}
    //       </p>
    //       {stayCount > 0 ? (
    //         <>
    //           {flight && (
    //             <p className={styles.days}>
    //               {stayCount} {stayCount > 1 ? "DAYS" : "DAY"}
    //             </p>
    //           )}
    //           {hotel && (
    //             <p className={styles.days}>
    //               {stayCount - 1} {stayCount ? "NIGHTS" : "NIGHT"}
    //             </p>
    //           )}
    //         </>
    //       ) : (
    //         <p className={styles.days}></p>
    //       )}
    //     </div>
    //   </div>
    // </div>
    <div className={styles.main}>
      <div className={styles.container}>
        {bookingDetails.travelFromDate === "" ? (
          <p className={styles.title}>Duration</p>
        ) : (
          <p className={styles.title}>{flight ? "RETURN" : "CHECK OUT"}</p>
        )}
        <div className={styles.durationCard}>
          {selectedFlightOptions.hotels && !selectedFlightOptions.flights ? (
            <p className={styles.desc}>
              {lastCheckoutDate
                ? FormatInputToUTC(lastCheckoutDate, true)
                : FormatInputToUTC(bookingDetails.travelToDate, true)}
            </p>
          ) : (
            <p className={styles.desc}>
              {FormatInputToUTC(bookingDetails.travelToDate, true)}
            </p>
          )}
          {selectedChatData.number_of_days ? (
            <>
              {flight && (
                <p className={styles.days}>
                  {selectedChatData.number_of_days}{" "}
                  {selectedChatData.number_of_days > 1 ? "DAYS" : "DAY"}
                </p>
              )}
              {hotel && (
                <p className={styles.days}>
                  {selectedChatData.number_of_days - 1}{" "}
                  {selectedChatData.number_of_days > 1 ? "NIGHTS" : "NIGHT"}
                </p>
              )}
            </>
          ) : (
            <p className={styles.days}></p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Duration;
