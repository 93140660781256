import { iMyTripDetails } from "../../types";

export const my_trips_tabs = ["UPCOMING", "PAST", "CANCELLED", "SAVED"];

export enum TRIP_DETAILS_TABS {
  DAILY_PLANS = "ITINERARY",
  ACTIVITIES = "ACTIVITIES",
  FLIGHTS = "FLIGHTS",
  HOTELS = "HOTELS",
}

export enum MY_TRIP_TABS {
  UPCOMING = "UPCOMING",
  PAST = "PAST",
  CANCELLED = "CANCELLED",
  SAVED = "SAVED",
}

export const upcomingDummyTripDataArr: any = [
  {
    tripDate: "AUG 12 - 23, 2024",
    travellersInfo: "2 adults, 2 children",
    tripType: MY_TRIP_TABS.UPCOMING,
    tripsArr: [
      {
        id: "be19bfdd-5e6d-40a4-a375-4d5243c15964",
        cityName: "Mumbai, India",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Oahu, known as 'The Gathering Place,' is the third-largest Hawaiian island and home to the state capital, Honolulu. It offers a mix of natural beauty, vibrant city life, and rich cultural experiences.",
        tripDetails: [
          {
            day: "Day 1",
            city_name: "Oahu",
            day_itinerary_description:
              "Arrive in Oahu and get settled in your hotel. Spend the evening exploring Waikiki Beach and enjoying the local nightlife.",
            itinerary_by_time_of_the_day: [
              {
                id: "c30b3afe-9570-461b-bdb3-13860abd29d1",
                description:
                  "Arrive at Daniel K. Inouye International Airport and transfer to your hotel in Waikiki.",
                time_of_the_day: "Morning",
              },
              {
                id: "ed5e2e90-62c3-440c-996e-9e994ea305bd",
                description:
                  "Check-in and relax at your hotel. Take a leisurely stroll along Waikiki Beach.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "39320732-0a26-49e9-9a57-284d9ef4cc09",
                description:
                  "Enjoy dinner at a beachfront restaurant and explore the vibrant nightlife of Waikiki.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 2",
            city_name: "Oahu",
            day_itinerary_description:
              "Explore the natural beauty of Oahu with a hike to Diamond Head and a visit to Hanauma Bay for snorkeling.",
            itinerary_by_time_of_the_day: [
              {
                id: "096d7842-4c2f-4437-8ec1-4e03007b2d09",
                description:
                  "Hike to the summit of Diamond Head for panoramic views of Honolulu and the Pacific Ocean.",
                time_of_the_day: "Morning",
              },
              {
                id: "c8b2f190-2c47-4a4a-a326-a03bf60e7ca2",
                description:
                  "Visit Hanauma Bay Nature Preserve for snorkeling and exploring the vibrant marine life.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "0d99e3ae-33e1-442a-a1c7-655bd50f49e3",
                description:
                  "Return to Waikiki and enjoy a traditional Hawaiian dinner at a local restaurant.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 3",
            city_name: "Oahu",
            day_itinerary_description:
              "Experience the cultural side of Oahu with a visit to the Polynesian Cultural Center and a traditional luau.",
            itinerary_by_time_of_the_day: [
              {
                id: "6a9eb42e-c84a-4246-adb8-fbb6261486f8",
                description:
                  "Drive to the Polynesian Cultural Center and explore the various villages representing different Polynesian cultures.",
                time_of_the_day: "Morning",
              },
              {
                id: "afcfaf01-0664-4fbc-a5c7-0f5984f3136e",
                description:
                  "Participate in hands-on cultural activities and watch traditional performances.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "4ae3ff0c-ec73-477c-8024-7bb8e62a7fbd",
                description:
                  "Enjoy a traditional Hawaiian luau with a buffet dinner and live entertainment.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "f1dfc081-a376-47e7-bbd5-8451b427467a",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "1a151e12-5e28-4653-b5f8-b47d7244ae75",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
      {
        id: "8038f02f-e769-4746-beb3-300a3ff4d65f",
        cityName: "Paris Amsterdam, Canada",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Maui, known as 'The Valley Isle,' is famous for its stunning beaches, lush rainforests, and the iconic Haleakalā volcano. It offers a perfect blend of adventure and relaxation.",
        tripDetails: [
          {
            day: "Day 4",
            city_name: "Maui",
            day_itinerary_description:
              "Travel to Maui and spend the day exploring the charming town of Lahaina and relaxing on Kaanapali Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "fc2c69ad-7082-439d-baff-808856cf6309",
                description:
                  "Fly from Oahu to Maui and transfer to your hotel in Lahaina.",
                time_of_the_day: "Morning",
              },
              {
                id: "3c709502-f03f-4d34-bd11-0697e883d974",
                description:
                  "Explore the historic town of Lahaina, visiting art galleries, shops, and the famous Banyan Tree.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "ace1a820-15cb-496f-bf30-75607d95b8f5",
                description:
                  "Relax on Kaanapali Beach and enjoy a beachfront dinner.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 5",
            city_name: "Maui",
            day_itinerary_description:
              "Experience the thrill of surfing and the beauty of Maui's beaches with a surf lesson and a visit to Wailea Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "19aa5965-a806-485e-b229-27e6255457f0",
                description:
                  "Take a surf lesson at a local surf school and ride the waves of Maui's beautiful beaches.",
                time_of_the_day: "Morning",
              },
              {
                id: "bb044286-ca09-4fc8-b22a-04ec80477bbf",
                description:
                  "Visit Wailea Beach, known for its crystal-clear waters and pristine sands. Relax and soak up the sun.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "c9c8083d-0e46-46b9-8192-d2fca2dd9cbc",
                description:
                  "Enjoy a sunset dinner at a beachfront restaurant in Wailea.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 6",
            city_name: "Maui",
            day_itinerary_description:
              "Embark on a scenic drive along the Road to Hana, exploring waterfalls, beaches, and lush landscapes.",
            itinerary_by_time_of_the_day: [
              {
                id: "dfc7518b-bdf1-4e8d-9098-744b65a0c64d",
                description:
                  "Start your journey on the Road to Hana, stopping at various viewpoints and waterfalls along the way.",
                time_of_the_day: "Morning",
              },
              {
                id: "f9cc0381-4216-4766-89d6-672aa73934cf",
                description:
                  "Visit the black sand beach at Waianapanapa State Park and explore the surrounding trails.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "abacafc7-abbf-480f-a8e7-6179c9464c99",
                description:
                  "Return to your hotel in Lahaina and enjoy a relaxing evening.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 7",
            city_name: "Maui",
            day_itinerary_description:
              "Conclude your trip with a visit to Haleakalā National Park for a sunrise experience and a hike through the volcanic landscape.",
            itinerary_by_time_of_the_day: [
              {
                id: "9089a5ee-7faf-4d84-9ef7-836afeecfe41",
                description:
                  "Drive to Haleakalā National Park and witness the breathtaking sunrise from the summit of the volcano.",
                time_of_the_day: "Morning",
              },
              {
                id: "505d3fed-73e5-4940-b75b-c8c2a4d63f55",
                description:
                  "Hike through the unique volcanic landscape of Haleakalā, exploring the various trails and viewpoints.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "d51f3722-94f8-48fa-844e-070a3ede1ef5",
                description:
                  "Return to your hotel, pack your bags, and prepare for your departure.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "fa284528-00c4-4589-a300-5c63314f55ed",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "9b49a159-7de5-4602-a215-2cf3629715ed",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
      {
        id: "8038f02f-e769-4746-beb3-300a3ff4d65f",
        cityName: "And Barcelona, Brazil",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Maui, known as 'The Valley Isle,' is famous for its stunning beaches, lush rainforests, and the iconic Haleakalā volcano. It offers a perfect blend of adventure and relaxation.",
        tripDetails: [
          {
            day: "Day 4",
            city_name: "Maui",
            day_itinerary_description:
              "Travel to Maui and spend the day exploring the charming town of Lahaina and relaxing on Kaanapali Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "fc2c69ad-7082-439d-baff-808856cf6309",
                description:
                  "Fly from Oahu to Maui and transfer to your hotel in Lahaina.",
                time_of_the_day: "Morning",
              },
              {
                id: "3c709502-f03f-4d34-bd11-0697e883d974",
                description:
                  "Explore the historic town of Lahaina, visiting art galleries, shops, and the famous Banyan Tree.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "ace1a820-15cb-496f-bf30-75607d95b8f5",
                description:
                  "Relax on Kaanapali Beach and enjoy a beachfront dinner.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 5",
            city_name: "Maui",
            day_itinerary_description:
              "Experience the thrill of surfing and the beauty of Maui's beaches with a surf lesson and a visit to Wailea Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "19aa5965-a806-485e-b229-27e6255457f0",
                description:
                  "Take a surf lesson at a local surf school and ride the waves of Maui's beautiful beaches.",
                time_of_the_day: "Morning",
              },
              {
                id: "bb044286-ca09-4fc8-b22a-04ec80477bbf",
                description:
                  "Visit Wailea Beach, known for its crystal-clear waters and pristine sands. Relax and soak up the sun.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "c9c8083d-0e46-46b9-8192-d2fca2dd9cbc",
                description:
                  "Enjoy a sunset dinner at a beachfront restaurant in Wailea.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 6",
            city_name: "Maui",
            day_itinerary_description:
              "Embark on a scenic drive along the Road to Hana, exploring waterfalls, beaches, and lush landscapes.",
            itinerary_by_time_of_the_day: [
              {
                id: "dfc7518b-bdf1-4e8d-9098-744b65a0c64d",
                description:
                  "Start your journey on the Road to Hana, stopping at various viewpoints and waterfalls along the way.",
                time_of_the_day: "Morning",
              },
              {
                id: "f9cc0381-4216-4766-89d6-672aa73934cf",
                description:
                  "Visit the black sand beach at Waianapanapa State Park and explore the surrounding trails.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "abacafc7-abbf-480f-a8e7-6179c9464c99",
                description:
                  "Return to your hotel in Lahaina and enjoy a relaxing evening.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 7",
            city_name: "Maui",
            day_itinerary_description:
              "Conclude your trip with a visit to Haleakalā National Park for a sunrise experience and a hike through the volcanic landscape.",
            itinerary_by_time_of_the_day: [
              {
                id: "9089a5ee-7faf-4d84-9ef7-836afeecfe41",
                description:
                  "Drive to Haleakalā National Park and witness the breathtaking sunrise from the summit of the volcano.",
                time_of_the_day: "Morning",
              },
              {
                id: "505d3fed-73e5-4940-b75b-c8c2a4d63f55",
                description:
                  "Hike through the unique volcanic landscape of Haleakalā, exploring the various trails and viewpoints.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "d51f3722-94f8-48fa-844e-070a3ede1ef5",
                description:
                  "Return to your hotel, pack your bags, and prepare for your departure.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "fa284528-00c4-4589-a300-5c63314f55ed",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "9b49a159-7de5-4602-a215-2cf3629715ed",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
    ],
    bookedOnDate: "Booked on JUNE 8 2024 | 4:37pm",
  },
  {
    tripDate: "JUN 1 - 7, 2025",
    travellersInfo: "2 adults, 2 children",
    tripType: MY_TRIP_TABS.UPCOMING,
    tripsArr: [
      {
        id: "be19bfdd-5e6d-40a4-a375-4d5243c15964",
        cityName: "Reykjavik with College Friends, London",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Oahu, known as 'The Gathering Place,' is the third-largest Hawaiian island and home to the state capital, Honolulu. It offers a mix of natural beauty, vibrant city life, and rich cultural experiences.",
        tripDetails: [
          {
            day: "Day 1",
            city_name: "Oahu",
            day_itinerary_description:
              "Arrive in Oahu and get settled in your hotel. Spend the evening exploring Waikiki Beach and enjoying the local nightlife.",
            itinerary_by_time_of_the_day: [
              {
                id: "c30b3afe-9570-461b-bdb3-13860abd29d1",
                description:
                  "Arrive at Daniel K. Inouye International Airport and transfer to your hotel in Waikiki.",
                time_of_the_day: "Morning",
              },
              {
                id: "ed5e2e90-62c3-440c-996e-9e994ea305bd",
                description:
                  "Check-in and relax at your hotel. Take a leisurely stroll along Waikiki Beach.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "39320732-0a26-49e9-9a57-284d9ef4cc09",
                description:
                  "Enjoy dinner at a beachfront restaurant and explore the vibrant nightlife of Waikiki.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 2",
            city_name: "Oahu",
            day_itinerary_description:
              "Explore the natural beauty of Oahu with a hike to Diamond Head and a visit to Hanauma Bay for snorkeling.",
            itinerary_by_time_of_the_day: [
              {
                id: "096d7842-4c2f-4437-8ec1-4e03007b2d09",
                description:
                  "Hike to the summit of Diamond Head for panoramic views of Honolulu and the Pacific Ocean.",
                time_of_the_day: "Morning",
              },
              {
                id: "c8b2f190-2c47-4a4a-a326-a03bf60e7ca2",
                description:
                  "Visit Hanauma Bay Nature Preserve for snorkeling and exploring the vibrant marine life.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "0d99e3ae-33e1-442a-a1c7-655bd50f49e3",
                description:
                  "Return to Waikiki and enjoy a traditional Hawaiian dinner at a local restaurant.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 3",
            city_name: "Oahu",
            day_itinerary_description:
              "Experience the cultural side of Oahu with a visit to the Polynesian Cultural Center and a traditional luau.",
            itinerary_by_time_of_the_day: [
              {
                id: "6a9eb42e-c84a-4246-adb8-fbb6261486f8",
                description:
                  "Drive to the Polynesian Cultural Center and explore the various villages representing different Polynesian cultures.",
                time_of_the_day: "Morning",
              },
              {
                id: "afcfaf01-0664-4fbc-a5c7-0f5984f3136e",
                description:
                  "Participate in hands-on cultural activities and watch traditional performances.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "4ae3ff0c-ec73-477c-8024-7bb8e62a7fbd",
                description:
                  "Enjoy a traditional Hawaiian luau with a buffet dinner and live entertainment.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "f1dfc081-a376-47e7-bbd5-8451b427467a",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "1a151e12-5e28-4653-b5f8-b47d7244ae75",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
    ],
    bookedOnDate: "Booked on JULY 1, 2024  |  11:14am",
  },
];

export const savedDummyTripDataArr: any = [
  {
    tripDate: "",
    travellersInfo: "2 adults, 2 children",
    type: "SHARED",
    tripType: MY_TRIP_TABS.SAVED,
    tripsArr: [
      {
        id: "be19bfdd-5e6d-40a4-a375-4d5243c15964",
        cityName: "Paris, London",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Oahu, known as 'The Gathering Place,' is the third-largest Hawaiian island and home to the state capital, Honolulu. It offers a mix of natural beauty, vibrant city life, and rich cultural experiences.",
        tripDetails: [
          {
            day: "Day 1",
            city_name: "Oahu",
            day_itinerary_description:
              "Arrive in Oahu and get settled in your hotel. Spend the evening exploring Waikiki Beach and enjoying the local nightlife.",
            itinerary_by_time_of_the_day: [
              {
                id: "c30b3afe-9570-461b-bdb3-13860abd29d1",
                description:
                  "Arrive at Daniel K. Inouye International Airport and transfer to your hotel in Waikiki.",
                time_of_the_day: "Morning",
              },
              {
                id: "ed5e2e90-62c3-440c-996e-9e994ea305bd",
                description:
                  "Check-in and relax at your hotel. Take a leisurely stroll along Waikiki Beach.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "39320732-0a26-49e9-9a57-284d9ef4cc09",
                description:
                  "Enjoy dinner at a beachfront restaurant and explore the vibrant nightlife of Waikiki.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 2",
            city_name: "Oahu",
            day_itinerary_description:
              "Explore the natural beauty of Oahu with a hike to Diamond Head and a visit to Hanauma Bay for snorkeling.",
            itinerary_by_time_of_the_day: [
              {
                id: "096d7842-4c2f-4437-8ec1-4e03007b2d09",
                description:
                  "Hike to the summit of Diamond Head for panoramic views of Honolulu and the Pacific Ocean.",
                time_of_the_day: "Morning",
              },
              {
                id: "c8b2f190-2c47-4a4a-a326-a03bf60e7ca2",
                description:
                  "Visit Hanauma Bay Nature Preserve for snorkeling and exploring the vibrant marine life.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "0d99e3ae-33e1-442a-a1c7-655bd50f49e3",
                description:
                  "Return to Waikiki and enjoy a traditional Hawaiian dinner at a local restaurant.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 3",
            city_name: "Oahu",
            day_itinerary_description:
              "Experience the cultural side of Oahu with a visit to the Polynesian Cultural Center and a traditional luau.",
            itinerary_by_time_of_the_day: [
              {
                id: "6a9eb42e-c84a-4246-adb8-fbb6261486f8",
                description:
                  "Drive to the Polynesian Cultural Center and explore the various villages representing different Polynesian cultures.",
                time_of_the_day: "Morning",
              },
              {
                id: "afcfaf01-0664-4fbc-a5c7-0f5984f3136e",
                description:
                  "Participate in hands-on cultural activities and watch traditional performances.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "4ae3ff0c-ec73-477c-8024-7bb8e62a7fbd",
                description:
                  "Enjoy a traditional Hawaiian luau with a buffet dinner and live entertainment.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "f1dfc081-a376-47e7-bbd5-8451b427467a",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "1a151e12-5e28-4653-b5f8-b47d7244ae75",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
      {
        id: "8038f02f-e769-4746-beb3-300a3ff4d65f",
        cityName: "Paris Amsterdam, Canada",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Maui, known as 'The Valley Isle,' is famous for its stunning beaches, lush rainforests, and the iconic Haleakalā volcano. It offers a perfect blend of adventure and relaxation.",
        tripDetails: [
          {
            day: "Day 4",
            city_name: "Maui",
            day_itinerary_description:
              "Travel to Maui and spend the day exploring the charming town of Lahaina and relaxing on Kaanapali Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "fc2c69ad-7082-439d-baff-808856cf6309",
                description:
                  "Fly from Oahu to Maui and transfer to your hotel in Lahaina.",
                time_of_the_day: "Morning",
              },
              {
                id: "3c709502-f03f-4d34-bd11-0697e883d974",
                description:
                  "Explore the historic town of Lahaina, visiting art galleries, shops, and the famous Banyan Tree.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "ace1a820-15cb-496f-bf30-75607d95b8f5",
                description:
                  "Relax on Kaanapali Beach and enjoy a beachfront dinner.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 5",
            city_name: "Maui",
            day_itinerary_description:
              "Experience the thrill of surfing and the beauty of Maui's beaches with a surf lesson and a visit to Wailea Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "19aa5965-a806-485e-b229-27e6255457f0",
                description:
                  "Take a surf lesson at a local surf school and ride the waves of Maui's beautiful beaches.",
                time_of_the_day: "Morning",
              },
              {
                id: "bb044286-ca09-4fc8-b22a-04ec80477bbf",
                description:
                  "Visit Wailea Beach, known for its crystal-clear waters and pristine sands. Relax and soak up the sun.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "c9c8083d-0e46-46b9-8192-d2fca2dd9cbc",
                description:
                  "Enjoy a sunset dinner at a beachfront restaurant in Wailea.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 6",
            city_name: "Maui",
            day_itinerary_description:
              "Embark on a scenic drive along the Road to Hana, exploring waterfalls, beaches, and lush landscapes.",
            itinerary_by_time_of_the_day: [
              {
                id: "dfc7518b-bdf1-4e8d-9098-744b65a0c64d",
                description:
                  "Start your journey on the Road to Hana, stopping at various viewpoints and waterfalls along the way.",
                time_of_the_day: "Morning",
              },
              {
                id: "f9cc0381-4216-4766-89d6-672aa73934cf",
                description:
                  "Visit the black sand beach at Waianapanapa State Park and explore the surrounding trails.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "abacafc7-abbf-480f-a8e7-6179c9464c99",
                description:
                  "Return to your hotel in Lahaina and enjoy a relaxing evening.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 7",
            city_name: "Maui",
            day_itinerary_description:
              "Conclude your trip with a visit to Haleakalā National Park for a sunrise experience and a hike through the volcanic landscape.",
            itinerary_by_time_of_the_day: [
              {
                id: "9089a5ee-7faf-4d84-9ef7-836afeecfe41",
                description:
                  "Drive to Haleakalā National Park and witness the breathtaking sunrise from the summit of the volcano.",
                time_of_the_day: "Morning",
              },
              {
                id: "505d3fed-73e5-4940-b75b-c8c2a4d63f55",
                description:
                  "Hike through the unique volcanic landscape of Haleakalā, exploring the various trails and viewpoints.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "d51f3722-94f8-48fa-844e-070a3ede1ef5",
                description:
                  "Return to your hotel, pack your bags, and prepare for your departure.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "fa284528-00c4-4589-a300-5c63314f55ed",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "9b49a159-7de5-4602-a215-2cf3629715ed",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
      {
        id: "8038f02f-e769-4746-beb3-300a3ff4d65f",
        cityName: "And Barcelona, Brazil",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Maui, known as 'The Valley Isle,' is famous for its stunning beaches, lush rainforests, and the iconic Haleakalā volcano. It offers a perfect blend of adventure and relaxation.",
        tripDetails: [
          {
            day: "Day 4",
            city_name: "Maui",
            day_itinerary_description:
              "Travel to Maui and spend the day exploring the charming town of Lahaina and relaxing on Kaanapali Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "fc2c69ad-7082-439d-baff-808856cf6309",
                description:
                  "Fly from Oahu to Maui and transfer to your hotel in Lahaina.",
                time_of_the_day: "Morning",
              },
              {
                id: "3c709502-f03f-4d34-bd11-0697e883d974",
                description:
                  "Explore the historic town of Lahaina, visiting art galleries, shops, and the famous Banyan Tree.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "ace1a820-15cb-496f-bf30-75607d95b8f5",
                description:
                  "Relax on Kaanapali Beach and enjoy a beachfront dinner.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 5",
            city_name: "Maui",
            day_itinerary_description:
              "Experience the thrill of surfing and the beauty of Maui's beaches with a surf lesson and a visit to Wailea Beach.",
            itinerary_by_time_of_the_day: [
              {
                id: "19aa5965-a806-485e-b229-27e6255457f0",
                description:
                  "Take a surf lesson at a local surf school and ride the waves of Maui's beautiful beaches.",
                time_of_the_day: "Morning",
              },
              {
                id: "bb044286-ca09-4fc8-b22a-04ec80477bbf",
                description:
                  "Visit Wailea Beach, known for its crystal-clear waters and pristine sands. Relax and soak up the sun.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "c9c8083d-0e46-46b9-8192-d2fca2dd9cbc",
                description:
                  "Enjoy a sunset dinner at a beachfront restaurant in Wailea.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 6",
            city_name: "Maui",
            day_itinerary_description:
              "Embark on a scenic drive along the Road to Hana, exploring waterfalls, beaches, and lush landscapes.",
            itinerary_by_time_of_the_day: [
              {
                id: "dfc7518b-bdf1-4e8d-9098-744b65a0c64d",
                description:
                  "Start your journey on the Road to Hana, stopping at various viewpoints and waterfalls along the way.",
                time_of_the_day: "Morning",
              },
              {
                id: "f9cc0381-4216-4766-89d6-672aa73934cf",
                description:
                  "Visit the black sand beach at Waianapanapa State Park and explore the surrounding trails.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "abacafc7-abbf-480f-a8e7-6179c9464c99",
                description:
                  "Return to your hotel in Lahaina and enjoy a relaxing evening.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 7",
            city_name: "Maui",
            day_itinerary_description:
              "Conclude your trip with a visit to Haleakalā National Park for a sunrise experience and a hike through the volcanic landscape.",
            itinerary_by_time_of_the_day: [
              {
                id: "9089a5ee-7faf-4d84-9ef7-836afeecfe41",
                description:
                  "Drive to Haleakalā National Park and witness the breathtaking sunrise from the summit of the volcano.",
                time_of_the_day: "Morning",
              },
              {
                id: "505d3fed-73e5-4940-b75b-c8c2a4d63f55",
                description:
                  "Hike through the unique volcanic landscape of Haleakalā, exploring the various trails and viewpoints.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "d51f3722-94f8-48fa-844e-070a3ede1ef5",
                description:
                  "Return to your hotel, pack your bags, and prepare for your departure.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "fa284528-00c4-4589-a300-5c63314f55ed",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "9b49a159-7de5-4602-a215-2cf3629715ed",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
    ],
    bookedOnDate: "Booked on JUNE 8 2024 | 4:37pm",
  },
  {
    tripDate: "JUN 1 - 7, 2025",
    type: "DRAFT",
    travellersInfo: "2 adults, 2 children",
    tripType: MY_TRIP_TABS.SAVED,
    tripsArr: [
      {
        id: "be19bfdd-5e6d-40a4-a375-4d5243c15964",
        cityName: "Reykjavik with College Friends, London",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Oahu, known as 'The Gathering Place,' is the third-largest Hawaiian island and home to the state capital, Honolulu. It offers a mix of natural beauty, vibrant city life, and rich cultural experiences.",
        tripDetails: [
          {
            day: "Day 1",
            city_name: "Oahu",
            day_itinerary_description:
              "Arrive in Oahu and get settled in your hotel. Spend the evening exploring Waikiki Beach and enjoying the local nightlife.",
            itinerary_by_time_of_the_day: [
              {
                id: "c30b3afe-9570-461b-bdb3-13860abd29d1",
                description:
                  "Arrive at Daniel K. Inouye International Airport and transfer to your hotel in Waikiki.",
                time_of_the_day: "Morning",
              },
              {
                id: "ed5e2e90-62c3-440c-996e-9e994ea305bd",
                description:
                  "Check-in and relax at your hotel. Take a leisurely stroll along Waikiki Beach.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "39320732-0a26-49e9-9a57-284d9ef4cc09",
                description:
                  "Enjoy dinner at a beachfront restaurant and explore the vibrant nightlife of Waikiki.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 2",
            city_name: "Oahu",
            day_itinerary_description:
              "Explore the natural beauty of Oahu with a hike to Diamond Head and a visit to Hanauma Bay for snorkeling.",
            itinerary_by_time_of_the_day: [
              {
                id: "096d7842-4c2f-4437-8ec1-4e03007b2d09",
                description:
                  "Hike to the summit of Diamond Head for panoramic views of Honolulu and the Pacific Ocean.",
                time_of_the_day: "Morning",
              },
              {
                id: "c8b2f190-2c47-4a4a-a326-a03bf60e7ca2",
                description:
                  "Visit Hanauma Bay Nature Preserve for snorkeling and exploring the vibrant marine life.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "0d99e3ae-33e1-442a-a1c7-655bd50f49e3",
                description:
                  "Return to Waikiki and enjoy a traditional Hawaiian dinner at a local restaurant.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 3",
            city_name: "Oahu",
            day_itinerary_description:
              "Experience the cultural side of Oahu with a visit to the Polynesian Cultural Center and a traditional luau.",
            itinerary_by_time_of_the_day: [
              {
                id: "6a9eb42e-c84a-4246-adb8-fbb6261486f8",
                description:
                  "Drive to the Polynesian Cultural Center and explore the various villages representing different Polynesian cultures.",
                time_of_the_day: "Morning",
              },
              {
                id: "afcfaf01-0664-4fbc-a5c7-0f5984f3136e",
                description:
                  "Participate in hands-on cultural activities and watch traditional performances.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "4ae3ff0c-ec73-477c-8024-7bb8e62a7fbd",
                description:
                  "Enjoy a traditional Hawaiian luau with a buffet dinner and live entertainment.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "f1dfc081-a376-47e7-bbd5-8451b427467a",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "1a151e12-5e28-4653-b5f8-b47d7244ae75",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
    ],
    bookedOnDate: "Booked on JULY 1, 2024  |  11:14am",
  },
];

export const pastDummyTripDataArr: any = [
  {
    tripDate: "MAY 24 - JUN 12, 2024",
    travellersInfo: "2 adults, 2 children",
    tripType: MY_TRIP_TABS.PAST,
    tripsArr: [
      {
        id: "be19bfdd-5e6d-40a4-a375-4d5243c15964",
        cityName: "Yellowstone Family Trip, London",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Oahu, known as 'The Gathering Place,' is the third-largest Hawaiian island and home to the state capital, Honolulu. It offers a mix of natural beauty, vibrant city life, and rich cultural experiences.",
        tripDetails: [
          {
            day: "Day 1",
            city_name: "Oahu",
            day_itinerary_description:
              "Arrive in Oahu and get settled in your hotel. Spend the evening exploring Waikiki Beach and enjoying the local nightlife.",
            itinerary_by_time_of_the_day: [
              {
                id: "c30b3afe-9570-461b-bdb3-13860abd29d1",
                description:
                  "Arrive at Daniel K. Inouye International Airport and transfer to your hotel in Waikiki.",
                time_of_the_day: "Morning",
              },
              {
                id: "ed5e2e90-62c3-440c-996e-9e994ea305bd",
                description:
                  "Check-in and relax at your hotel. Take a leisurely stroll along Waikiki Beach.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "39320732-0a26-49e9-9a57-284d9ef4cc09",
                description:
                  "Enjoy dinner at a beachfront restaurant and explore the vibrant nightlife of Waikiki.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 2",
            city_name: "Oahu",
            day_itinerary_description:
              "Explore the natural beauty of Oahu with a hike to Diamond Head and a visit to Hanauma Bay for snorkeling.",
            itinerary_by_time_of_the_day: [
              {
                id: "096d7842-4c2f-4437-8ec1-4e03007b2d09",
                description:
                  "Hike to the summit of Diamond Head for panoramic views of Honolulu and the Pacific Ocean.",
                time_of_the_day: "Morning",
              },
              {
                id: "c8b2f190-2c47-4a4a-a326-a03bf60e7ca2",
                description:
                  "Visit Hanauma Bay Nature Preserve for snorkeling and exploring the vibrant marine life.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "0d99e3ae-33e1-442a-a1c7-655bd50f49e3",
                description:
                  "Return to Waikiki and enjoy a traditional Hawaiian dinner at a local restaurant.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 3",
            city_name: "Oahu",
            day_itinerary_description:
              "Experience the cultural side of Oahu with a visit to the Polynesian Cultural Center and a traditional luau.",
            itinerary_by_time_of_the_day: [
              {
                id: "6a9eb42e-c84a-4246-adb8-fbb6261486f8",
                description:
                  "Drive to the Polynesian Cultural Center and explore the various villages representing different Polynesian cultures.",
                time_of_the_day: "Morning",
              },
              {
                id: "afcfaf01-0664-4fbc-a5c7-0f5984f3136e",
                description:
                  "Participate in hands-on cultural activities and watch traditional performances.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "4ae3ff0c-ec73-477c-8024-7bb8e62a7fbd",
                description:
                  "Enjoy a traditional Hawaiian luau with a buffet dinner and live entertainment.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "f1dfc081-a376-47e7-bbd5-8451b427467a",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "1a151e12-5e28-4653-b5f8-b47d7244ae75",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
    ],
    bookedOnDate: "Booked on JAN 4, 2024  |  9:13pm",
  },
  {
    tripDate: "APR 5 - 8, 2024",
    travellersInfo: "2 adults",
    tripType: MY_TRIP_TABS.PAST,
    tripsArr: [
      {
        id: "be19bfdd-5e6d-40a4-a375-4d5243c15964",
        cityName: "Amalfi Coast Anniversary Trip, London",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Oahu, known as 'The Gathering Place,' is the third-largest Hawaiian island and home to the state capital, Honolulu. It offers a mix of natural beauty, vibrant city life, and rich cultural experiences.",
        tripDetails: [
          {
            day: "Day 1",
            city_name: "Oahu",
            day_itinerary_description:
              "Arrive in Oahu and get settled in your hotel. Spend the evening exploring Waikiki Beach and enjoying the local nightlife.",
            itinerary_by_time_of_the_day: [
              {
                id: "c30b3afe-9570-461b-bdb3-13860abd29d1",
                description:
                  "Arrive at Daniel K. Inouye International Airport and transfer to your hotel in Waikiki.",
                time_of_the_day: "Morning",
              },
              {
                id: "ed5e2e90-62c3-440c-996e-9e994ea305bd",
                description:
                  "Check-in and relax at your hotel. Take a leisurely stroll along Waikiki Beach.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "39320732-0a26-49e9-9a57-284d9ef4cc09",
                description:
                  "Enjoy dinner at a beachfront restaurant and explore the vibrant nightlife of Waikiki.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 2",
            city_name: "Oahu",
            day_itinerary_description:
              "Explore the natural beauty of Oahu with a hike to Diamond Head and a visit to Hanauma Bay for snorkeling.",
            itinerary_by_time_of_the_day: [
              {
                id: "096d7842-4c2f-4437-8ec1-4e03007b2d09",
                description:
                  "Hike to the summit of Diamond Head for panoramic views of Honolulu and the Pacific Ocean.",
                time_of_the_day: "Morning",
              },
              {
                id: "c8b2f190-2c47-4a4a-a326-a03bf60e7ca2",
                description:
                  "Visit Hanauma Bay Nature Preserve for snorkeling and exploring the vibrant marine life.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "0d99e3ae-33e1-442a-a1c7-655bd50f49e3",
                description:
                  "Return to Waikiki and enjoy a traditional Hawaiian dinner at a local restaurant.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 3",
            city_name: "Oahu",
            day_itinerary_description:
              "Experience the cultural side of Oahu with a visit to the Polynesian Cultural Center and a traditional luau.",
            itinerary_by_time_of_the_day: [
              {
                id: "6a9eb42e-c84a-4246-adb8-fbb6261486f8",
                description:
                  "Drive to the Polynesian Cultural Center and explore the various villages representing different Polynesian cultures.",
                time_of_the_day: "Morning",
              },
              {
                id: "afcfaf01-0664-4fbc-a5c7-0f5984f3136e",
                description:
                  "Participate in hands-on cultural activities and watch traditional performances.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "4ae3ff0c-ec73-477c-8024-7bb8e62a7fbd",
                description:
                  "Enjoy a traditional Hawaiian luau with a buffet dinner and live entertainment.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "f1dfc081-a376-47e7-bbd5-8451b427467a",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "1a151e12-5e28-4653-b5f8-b47d7244ae75",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
    ],
    bookedOnDate: "Booked on FEB 19, 2024  |  12:43pm",
  },
  {
    tripDate: "MAR 12 - 16, 2025",
    travellersInfo: "2 adults",
    tripType: MY_TRIP_TABS.PAST,
    tripsArr: [
      {
        id: "be19bfdd-5e6d-40a4-a375-4d5243c15964",
        cityName: "Guiness Brewery Tour, London",
        imageURL: "https://error.error",
        checkFlag: false,
        locations: [],
        description:
          "Oahu, known as 'The Gathering Place,' is the third-largest Hawaiian island and home to the state capital, Honolulu. It offers a mix of natural beauty, vibrant city life, and rich cultural experiences.",
        tripDetails: [
          {
            day: "Day 1",
            city_name: "Oahu",
            day_itinerary_description:
              "Arrive in Oahu and get settled in your hotel. Spend the evening exploring Waikiki Beach and enjoying the local nightlife.",
            itinerary_by_time_of_the_day: [
              {
                id: "c30b3afe-9570-461b-bdb3-13860abd29d1",
                description:
                  "Arrive at Daniel K. Inouye International Airport and transfer to your hotel in Waikiki.",
                time_of_the_day: "Morning",
              },
              {
                id: "ed5e2e90-62c3-440c-996e-9e994ea305bd",
                description:
                  "Check-in and relax at your hotel. Take a leisurely stroll along Waikiki Beach.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "39320732-0a26-49e9-9a57-284d9ef4cc09",
                description:
                  "Enjoy dinner at a beachfront restaurant and explore the vibrant nightlife of Waikiki.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 2",
            city_name: "Oahu",
            day_itinerary_description:
              "Explore the natural beauty of Oahu with a hike to Diamond Head and a visit to Hanauma Bay for snorkeling.",
            itinerary_by_time_of_the_day: [
              {
                id: "096d7842-4c2f-4437-8ec1-4e03007b2d09",
                description:
                  "Hike to the summit of Diamond Head for panoramic views of Honolulu and the Pacific Ocean.",
                time_of_the_day: "Morning",
              },
              {
                id: "c8b2f190-2c47-4a4a-a326-a03bf60e7ca2",
                description:
                  "Visit Hanauma Bay Nature Preserve for snorkeling and exploring the vibrant marine life.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "0d99e3ae-33e1-442a-a1c7-655bd50f49e3",
                description:
                  "Return to Waikiki and enjoy a traditional Hawaiian dinner at a local restaurant.",
                time_of_the_day: "Evening",
              },
            ],
          },
          {
            day: "Day 3",
            city_name: "Oahu",
            day_itinerary_description:
              "Experience the cultural side of Oahu with a visit to the Polynesian Cultural Center and a traditional luau.",
            itinerary_by_time_of_the_day: [
              {
                id: "6a9eb42e-c84a-4246-adb8-fbb6261486f8",
                description:
                  "Drive to the Polynesian Cultural Center and explore the various villages representing different Polynesian cultures.",
                time_of_the_day: "Morning",
              },
              {
                id: "afcfaf01-0664-4fbc-a5c7-0f5984f3136e",
                description:
                  "Participate in hands-on cultural activities and watch traditional performances.",
                time_of_the_day: "Afternoon",
              },
              {
                id: "4ae3ff0c-ec73-477c-8024-7bb8e62a7fbd",
                description:
                  "Enjoy a traditional Hawaiian luau with a buffet dinner and live entertainment.",
                time_of_the_day: "Evening",
              },
            ],
          },
        ],
        complete_itinerary_hotels_cost: {
          id: "f1dfc081-a376-47e7-bbd5-8451b427467a",
          value: "$1200",
          checkedFlag: true,
        },
        complete_itinerary_flights_cost: {
          id: "1a151e12-5e28-4653-b5f8-b47d7244ae75",
          value: "$800",
          checkedFlag: true,
        },
        checkedFlag: true,
      },
    ],
    bookedOnDate: "Booked on DEC 27, 2023  |  8:51am",
  },
];
