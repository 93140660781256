import * as React from "react";
import styles from "./cityTabs.module.scss";
import { Stack, IconButton } from "@mui/material";
import { iChatData, iCityDetailsResult } from "../../../types";
import Markdown from "react-markdown";
import { modifyEditIcon, outgoingFlightIcon } from "../../../constant";
import CustomAccordion from "../../../component/reusableComponent/customAccordion/CustomAccordion";
import DestinationCardWithImages from "../../../component/reusableComponent/destinationCardWithImages/DestinationCardWithImages";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import EditActivityDialog from "../../../component/dialog/editAcitvityDialog/EditActivityDialog";
import DiscardChanges from "../../../component/dialog/discardChanges/discardChanges";
import Loader from "../../../component/reusableComponent/loader/Loader";
import { saveItineraryDetailsAPI } from "../../../api/saveItineraryDetails";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { getConfigMessage } from "../../../utils";
import { APP_CONFIG } from "../../../constant/common";
import toast from "react-hot-toast";
import WeatherWidget from "../../../component/weatherWidget/weatherWidget";
import { iWeatherData } from "../../../component/home/todayLanding/todayLandingPage";
import { getDayOfWeekUTC, getMonthDateUTC } from "../../../utils/dateUtils";
import { ACTIVITY_TYPE_IDENTIFIER } from "../../../enums";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import { TripTypes } from "../../../interface";

export default function CityTabs({
  itineraryDescription = "",
  itineraryVersion = 0,
  destinationList,
  handleEditActivity = () => {},
  disableInput = false,
  isSavedActivityFlag = false,
  flexDirection = "column",
  showWeatherAdvisory = false,
  showPlaneIcon = false,
  startDate = "",
  showEditActivityFlag,
  setShowEditActivityFlag,
  savedStatus = "draft",
  handleHeadingClick,
  handleEditGAActivity,
  handleEditActivityGADone,
  isThereFlightBooked = false,
}: Readonly<{
  itineraryDescription?: string;
  destinationList: iCityDetailsResult[];
  itineraryVersion?: string | number;
  handleEditActivity?: any;
  disableInput?: boolean;
  isSavedActivityFlag?: boolean;
  flexDirection?: "column" | "column-reverse";
  showWeatherAdvisory?: boolean;
  showPlaneIcon?: boolean;
  startDate?: string;
  showEditActivityFlag: boolean;
  setShowEditActivityFlag: any;
  savedStatus?: string;
  handleHeadingClick?: any;
  handleEditGAActivity?: any;
  handleEditActivityGADone?: any;
  isThereFlightBooked?: boolean;
}>) {
  const globalContext = React.useContext(StateContext);
  const {
    selectedChatData,
    setSelectedChatData,
    appConfig,
    userInfo,
    chatData,
    setChatData,
  }: iGlobalContext = globalContext;

  async function saveItineraryBeforeEditing() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_modify_activities_click);
    if (handleEditGAActivity) handleEditGAActivity();
    const metaData: any = {
      selectedChatData: {
        ...selectedChatData,
        isSaved: true,
        prompt: [],
        postText: "",
        preText: "",
      },
    };
    if (!userInfo?.id || !userInfo?.loggedIn) {
      toast.dismiss();
      setShowEditActivityFlag(true);
      localStorage.setItem(
        "_trip_trove_prev_destination_list",
        JSON.stringify(destinationList)
      );
      return;
    }
    const saveItineraryPayload = {
      tripName: selectedChatData?.tripName ?? "",
      userId: userInfo?.id,
      metaData,
      status: savedStatus,
      numberOfDaysSelected: Number(selectedChatData.noOfDays),
      year: "",
      selectedMonth: "",
      is_admin_created: userInfo?.role === 1,
      data: selectedChatData?.results || null,
    };
    const response = await saveItineraryDetailsAPI(
      saveItineraryPayload,
      selectedChatData?.isSaved ? "PUT" : "POST",
      selectedChatData?.isSaved ? selectedChatData?.id : ""
    );
    toast.dismiss();
    if (response?.statusCode === 200) {
      let checkIfMultipleListFlag = true;
      let tempChatData = chatData?.map((chatDataEle: iChatData) => {
        if (chatDataEle.id === selectedChatData.id) {
          checkIfMultipleListFlag = false;
          const updatedSelectedChatData = {
            ...chatDataEle,
            isSaved: true,
            id: response?.data?.id,
          };
          setSelectedChatData(updatedSelectedChatData);
          return updatedSelectedChatData;
        }
        return chatDataEle;
      });
      if (checkIfMultipleListFlag) {
        tempChatData = chatData?.map((chatDataEle: any) => {
          if (
            !chatDataEle?.singleSelectedDestination &&
            chatDataEle?.destinationList.length > 0
          ) {
            return {
              ...chatDataEle,
              destinationList: chatDataEle?.destinationList.map(
                (destEle: iChatData) => {
                  if (destEle.id === selectedChatData.id) {
                    const updatedSelectedChatData = {
                      ...destEle,
                      isSaved: true,
                      id: response?.data?.id,
                    };
                    setSelectedChatData(updatedSelectedChatData);
                    return updatedSelectedChatData;
                  }
                  return destEle;
                }
              ),
            };
          }
          return chatDataEle;
        });
      }
      setChatData(tempChatData);
      setShowEditActivityFlag(true);
    } else {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    }
  }

  /**
   * Edit activity Dialog
   */
  const [dayItinerary, setDayItinerary] = React.useState([]);
  const [openEditActivityDialog, setOpenEditActivityDialog] =
    React.useState(false);
  const [activityDate, setActivityDate] = React.useState("");
  async function handleEditActivityLocal(data: any, date: string) {
    if (!selectedChatData?.isSaved) await saveItineraryBeforeEditing();
    setDayItinerary(data);
    if (date) setActivityDate(date);
    else setActivityDate("");
    handleEditActivity();
    setOpenEditActivityDialog(true);
  }

  /**
   * Discard changes Dialog
   */
  const [openDiscardChangesDialog, setOpenDiscardChangesDialog] =
    React.useState(false);
  function handleCancelDicard() {
    setOpenEditActivityDialog(true);
    setOpenDiscardChangesDialog(false);
  }
  function handleDicard() {
    setOpenEditActivityDialog(false);
    setOpenDiscardChangesDialog(false);
  }
  const [weatherData, setWeatherData] = React.useState({} as iWeatherData);

  function isShowingPlaneIcon(array: any) {
    return (
      array?.itinerary_by_time_of_the_day?.some((ele: any) =>
        Object.values(ele).includes(ACTIVITY_TYPE_IDENTIFIER.Flight)
      ) ||
      // &&isThereFlightBooked
      false
    );
  }

  function handleCloseEditActivityDialog() {
    setOpenEditActivityDialog(false);
  }

  if (!destinationList) return <></>;
  return (
    <div className={styles.customTabPanelContainer}>
      <DiscardChanges
        open={openDiscardChangesDialog}
        onClose={handleCancelDicard}
        handleDiscard={handleDicard}
      />
      <EditActivityDialog
        dayItinerary={dayItinerary}
        open={openEditActivityDialog}
        setOpenDiscardChangesDialog={setOpenDiscardChangesDialog}
        onClose={handleCloseEditActivityDialog}
        activityDate={activityDate}
        savedStatus={savedStatus}
      />
      {!startDate && (
        <div
          className={styles.modifyAndImagesContainer}
          style={{ flexDirection: flexDirection }}
        >
          <div>
            <div className={styles.imageContainer}>
              <DestinationCardWithImages
                multiCity={selectedChatData?.trip_type === TripTypes.MultiCity}
                itineraryVersion={itineraryVersion}
                showArrowIcon={false}
                destinationList={destinationList}
              />
            </div>
            <Markdown className={styles.cityDescription}>
              {itineraryDescription}
            </Markdown>
          </div>
        </div>
      )}
      <Stack>
        {/* <pre>{JSON.stringify(destinationList[0]?.tripDetails, null, 2)}</pre> */}
        {destinationList?.map(
          (destinationEle: iCityDetailsResult, firstIndex: number) =>
            destinationEle?.tripDetails?.map((ele: any, index: number) => {
              const match = ele?.day?.match(/\d+/); // Matches any digit(s) in the string
              let count = match ? parseInt(match[0], 10) - 1 : 0;
              const currentTripDate = String(
                new Date(
                  new Date(startDate).setDate(
                    new Date(startDate).getUTCDate() + count
                  )
                )
              );
              return (
                <Stack key={index + currentTripDate}>
                  <CustomAccordion
                    defaultExpanded={
                      (index === 0 && firstIndex === 0) || showEditActivityFlag
                    }
                    borderBottom={true}
                    headingComponent={
                      <button
                        className={styles.headingFlightComp}
                        tabIndex={0}
                        onClick={() => {
                          if (handleHeadingClick) handleHeadingClick();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            if (handleHeadingClick) handleHeadingClick();
                          }
                        }}
                      >
                        <div>
                          {startDate !== "" ? (
                            <div className={styles.subHeadingContainer}>
                              <h1>
                                {getDayOfWeekUTC(ele?.date || currentTripDate)}{" "}
                                &nbsp;{" "}
                                {getMonthDateUTC(ele?.date || currentTripDate)}
                              </h1>
                              <h1>{ele?.city_name}</h1>
                            </div>
                          ) : (
                            <div className={styles.subHeadingContainer}>
                              <h1>{ele?.day}</h1>
                              <h1>{ele?.city_name}</h1>
                            </div>
                          )}

                          <p className={styles.accordDesc}>
                            {ele?.day_itinerary_description}
                          </p>
                        </div>
                        {(showPlaneIcon || isShowingPlaneIcon(ele)) && (
                          <img
                            src={outgoingFlightIcon}
                            alt="plane"
                            className={styles.planeDepartureIcon}
                          />
                        )}
                        {!isShowingPlaneIcon(ele) && showWeatherAdvisory && (
                          <WeatherWidget
                            weatherData={weatherData}
                            setWeatherData={setWeatherData}
                            cityName={ele?.city_name || ""}
                            className={styles.weatherWidgetContainer}
                          />
                        )}
                      </button>
                    }
                    bodyComponent={
                      <Stack className={styles.accordionDetailsContainer}>
                        {ele?.itinerary_by_time_of_the_day?.map(
                          (subEle: any, index: number) => {
                            return (
                              <div
                                className={styles.stepperContainer}
                                key={index + subEle?.time_of_the_day}
                              >
                                <div className={styles.circle}></div>
                                <div
                                  className={
                                    index !==
                                    ele?.itinerary_by_time_of_the_day?.length -
                                      1
                                      ? styles.line
                                      : ""
                                  }
                                ></div>
                                <div className={styles.activityContainer}>
                                  <div className={styles.timeInADay}>
                                    <div className={styles.timeInADayContainer}>
                                      <h1 className={styles.title}>
                                        {subEle?.time_of_the_day}
                                      </h1>
                                      {index === 0 && (
                                        <IconButton
                                          onClick={() =>
                                            handleEditActivityLocal(
                                              ele?.itinerary_by_time_of_the_day,
                                              ele?.date || currentTripDate
                                            )
                                          }
                                          className={styles.modifyBtn}
                                        >
                                          <img
                                            src={modifyEditIcon}
                                            alt={"modify"}
                                          />
                                        </IconButton>
                                      )}
                                    </div>
                                    <div
                                      className={styles.descriptionContainer}
                                    >
                                      <p>•</p>
                                      <Markdown className={styles.description}>
                                        {subEle?.description}
                                      </Markdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </Stack>
                    }
                  />
                </Stack>
              );
            })
        )}
      </Stack>
      {disableInput && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  );
}
