export const trip_details_tabs = [
  "ITINERARY",
  "ACTIVITIES",
  "FLIGHTS",
  "HOTELS",
];

export enum TRIP_DETAILS_TABS {
  DAILY_PLANS = "ITINERARY",
  ACTIVITIES = "ACTIVITIES",
  FLIGHTS = "FLIGHTS",
  HOTELS = "HOTELS",
}
