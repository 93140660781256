import {
  bedWhite,
  expandWhiteIcon,
  planeEnginesWhite,
} from "../../../constant";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../constant/google-analytics/GAEvents";
import {
  BookingDetails,
  ISelectedOptions,
} from "../../../types";
import styles from "./travel.module.scss";

interface iProps {
  handleShowDetails: () => void;
  bookingDetails: BookingDetails;
  selectedOptions: ISelectedOptions;
}

function TravelDetails({
  handleShowDetails,
  bookingDetails,
  selectedOptions,
}: iProps): JSX.Element {
  const handleFlightClass = (flightClass: string) => {
    switch (flightClass) {
      case "E":
        return "Economy";
      case "P":
        return "Premium Plus";
      case "B":
        return "Business";
      case "F":
        return "First Class";
      default:
        return;
    }
  };

  const formatDateRange = (
    startDate: string,
    endDate: string
  ): string | null => {
    if (
      !startDate ||
      !endDate ||
      isNaN(Date.parse(startDate)) ||
      isNaN(Date.parse(endDate))
    ) {
      return "";
    }

    // Format both dates to ISO with "T00:00:00"
    const formatISODate = (dateStr: string): string => {
      return new Date(dateStr).toISOString().split("T")[0] + "T00:00:00";
    };

    const formattedStartDate = formatISODate(startDate);
    const formattedEndDate = formatISODate(endDate);

    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "2-digit",
    };

    const start = new Date(formattedStartDate).toLocaleDateString(
      "en-US",
      options
    );
    const end = new Date(formattedEndDate).toLocaleDateString("en-US", options);

    return `${start} - ${end}`;
  };

  const extractOnlyFlightCities: string[] = (
    bookingDetails?.flightHistory.map((city) => city?.city_name || "") || []
  ).filter((value, index, self) => self.indexOf(value) === index);

  const extractOnlyHotelCities: string[] = (
    bookingDetails?.hotels.map((city) => city?.city || "") || []
  ).filter((value, index, self) => self.indexOf(value) === index);

  const extractFlightAndHotel: string[] = extractOnlyFlightCities
    .concat(extractOnlyHotelCities)
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div className={styles.main}>
      <div className={styles.container} onClick={handleShowDetails}>
        <section className={styles.card1}>
          {selectedOptions.flights && !selectedOptions.hotels && (
            <img src={planeEnginesWhite} alt="" className={styles.bedIcon} />
          )}
          {!selectedOptions.flights && selectedOptions.hotels && (
            <img src={bedWhite} alt="" className={styles.bedIcon} />
          )}
          {selectedOptions.flights && selectedOptions.hotels && (
            <>
              <img src={planeEnginesWhite} alt="" className={styles.bedIcon} />
              <img src={bedWhite} alt="" className={styles.bedIcon} />
            </>
          )}

          <div className={styles.details}>
            {selectedOptions.flights && selectedOptions.hotels && (
              <div className={styles.detailsCard}>
                {" "}
                {extractFlightAndHotel &&
                  extractFlightAndHotel.map((i, index) => (
                    <p className={styles.city} key={index}>
                      {i}
                      {index < extractFlightAndHotel.length - 1 && "-"}
                    </p>
                  ))}
              </div>
            )}
            {!selectedOptions.flights && selectedOptions.hotels && (
              <div className={styles.detailsCard}>
                {" "}
                {extractOnlyHotelCities &&
                  extractOnlyHotelCities.map((i, index) => (
                    <p className={styles.city} key={index}>
                      {i}
                      {index < extractOnlyHotelCities.length - 1 && "-"}
                    </p>
                  ))}
              </div>
            )}
            {selectedOptions.flights && !selectedOptions.hotels && (
              <div className={styles.detailsCard}>
                {" "}
                {extractOnlyFlightCities &&
                  extractOnlyFlightCities.map((i, index) => (
                    <p className={styles.city} key={index}>
                      {i}
                      {index < extractOnlyFlightCities.length - 1 && "-"}
                    </p>
                  ))}
              </div>
            )}
            <p className={styles.desc}>
              {formatDateRange(
                bookingDetails.travelFromDate,
                bookingDetails.travelToDate
              )}{" "}
              {bookingDetails.adultsCount > 0 && (
                <span>
                  • {bookingDetails.adultsCount}{" "}
                  {bookingDetails.adultsCount > 1 ? "adults" : "adult"}
                </span>
              )}
              {bookingDetails.childrenCount > 0 && (
                <span>
                  , {bookingDetails.childrenCount}{" "}
                  {bookingDetails.childrenCount > 1 ? "children" : "child"}
                </span>
              )}
              {bookingDetails.seniorsCount > 0 && (
                <span>
                  , {bookingDetails.seniorsCount}{" "}
                  {bookingDetails.seniorsCount > 1 ? "seniors" : "senior"}
                </span>
              )}{" "}
              {bookingDetails.flightClass && (
                <span>• {handleFlightClass(bookingDetails.flightClass)}</span>
              )}
            </p>
          </div>
        </section>
        <section
          onClick={() => {
            triggerGAEvent(GA_LABEL_ENUMS.booking_downward_accordion_click);
          }}>
          <div className={styles.dropDowIcon} onClick={handleShowDetails}>
            <img
              src={expandWhiteIcon}
              alt="dropIcon"
              className={styles.dropIcon}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default TravelDetails;
