import React, { useCallback, useContext, useState } from "react";

import { backIcon, ROUTES } from "../../constant";
import { Button } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import styles from "./modifyHotelReservation.module.scss";
import { ScreenTypes } from "../../enums";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import BookedHotelAccordion from "../../component/reusableComponent/bookedHotelCardList/BookedHotelAccordion";
import ModifyingHotelAccordion from "./components/modifyingHotelAccordion/modifyingHotelAccordion";
import CustomButton from "../../component/button/button";
import { useNavigate } from "react-router-dom";

export default function ModifyHotelReservation() {
  const globalContext = useContext(StateContext);
  const { isMobile, setScreen, manageHotelsArr }: iGlobalContext =
    globalContext;
  const navigate = useNavigate();
  const handleBackButton = useCallback(() => {
    if (isMobile) window.history.back();
    if (!isMobile) setScreen(ScreenTypes.TripDetails);
  }, [isMobile, setScreen]);

  const [showRoomsLayout, setShowRoomsLayout] = useState(false);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div
          className={styles.buttonContainer}
          //   style={{ marginBottom: isSharedTrip ? "0px" : "-10px" }}
        >
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}
          >
            <img src={backIcon} alt="Close" />
            <p>BACK</p>
          </Button>
          <p className={styles.modifyHotelsHeader}>Modify Reservation</p>
          <p
            className={`${styles.modifyHotelsHeader} ${styles.valueDifference}`}
          >
            $0
          </p>
        </div>
      </div>
      <div className={styles.flightAccord}>
        {manageHotelsArr?.length > 0 ? (
          manageHotelsArr?.map((hotel: any, index: number) => {
            // const {
            //   checkIn = "",
            //   checkOut = "",
            //   city = "",
            //   hotelInformation = {},
            //   roomInfo = {},
            // } = ele;
            // const { stars, name, hotelMainImage, address, hotelId } =
            //   hotelInformation;
            // const { noOfRooms, bedInformation = [] } = roomInfo;
            const {
              // hotelMainImage,
              // stars,
              // name,
              // address,
              hotelInformation,
              amenities,
              totalPrice,
              cancellationPolicy,
              // hotelBookId,
              checkIn = "",
              checkOut = "",
              city = "",
            } = hotel;
            const {
              stars,
              name,
              hotelMainImage,
              address,
              hotelId,
              rating,
              reviewCount,
              reviewHeading,
            } = hotelInformation;
            return (
              <ModifyingHotelAccordion
                showRoomsLayout={showRoomsLayout}
                key={hotelId}
                checkIn={checkIn}
                checkOut={checkOut}
                id={index}
                hotelMainImage={hotelMainImage}
                star={stars}
                hotelName={name}
                hotelLocation={address}
                hotelRating={rating}
                reviewCount={reviewCount}
                cost={totalPrice?.price}
                cancellation={cancellationPolicy}
                Amenity={amenities}
                hotelList={true}
                discount={""}
                discountCost={""}
                taxesIncluded={totalPrice?.taxesIncluded}
                reviewHeading={reviewHeading}
                hotelId={hotelId}
                hotelDetails={hotel}
                city={city}
                // handleCheckboxChange={() => handleCheckboxChange(hotel)}
                hotelAccordionClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_hotel_accordion_click
                  )
                }
                hotelViewDetailsClick={() =>
                  triggerGAEvent(
                    GA_LABEL_ENUMS.trip_details_hotel_view_deatils_accordion_click
                  )
                }
              />
            );
          })
        ) : (
          <p className={styles.noHotel}>No hotel booked</p>
        )}

        <div className={styles.footerSection}>
          <CustomButton
            className={`${styles.hoteltBtn}`}
            //   disabled={!ifAnyOfTheHotelsSelected}
            onClick={() => {
              if (!showRoomsLayout) setShowRoomsLayout(true);
              if (showRoomsLayout) navigate(ROUTES.ModifyHotelsSuccess);
            }}
          >
            {!showRoomsLayout ? "Search" : "Continue"}
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
