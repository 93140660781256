import React, { useState } from "react";
import styles from "./departure.module.scss";

type DepartureOption =
  | "Early Morning"
  | "Morning"
  | "Afternoon"
  | "Evening"
  | "Night";

interface DepartureTimesProps {
  options?: DepartureOption[];
  initialSelected?: DepartureOption[];
  onChange?: (selected: DepartureOption[]) => void;
}

const DepartureTimes: React.FC<DepartureTimesProps> = ({
  options = ["Early Morning", "Morning", "Afternoon", "Evening", "Night"],
  initialSelected = [],
  onChange,
}) => {
  const [selected, setSelected] = useState<DepartureOption[]>(initialSelected);

  const handleSelection = (option: DepartureOption) => {
    let newSelected;
    if (selected.includes(option)) {
      newSelected = selected.filter((item) => item !== option);
    } else {
      newSelected = [...selected, option];
    }
    setSelected(newSelected);
    onChange?.(newSelected);
  };

  return (
    <div className={styles.main}>
      <section className={styles.container}>
        <div className={styles.innerCard}>
          <p className={styles.departureTime}>Departure Times</p>
          <div className={styles.departureTimes}>
            {options.map((option, index) => (
              <button
                key={index}
                className={`${styles.option} ${
                  selected.includes(option) ? styles.selected : ""
                }`}
                onClick={() => handleSelection(option)}>
                {option}
              </button>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DepartureTimes;
