import React, { useContext } from "react";
import styles from "./modifyHotelAccordion.module.scss";

import CustomAccordion from "../../../../component/reusableComponent/customAccordion/CustomAccordion";
import {
  getDayOfWeek,
  getDayOfWeekUTC,
  getMonthDateUTC,
} from "../../../../utils/dateUtils";
import { bedFontLight } from "../../../../constant";
import HotelCard from "../../../../component/booking/hotelBooking/hotelCard/hotelCard";
import { HOTEL_CARD_TYPES } from "../../../../enums";

interface IProps {
  readonly checkIn?: any;
  readonly checkOut?: any;
  readonly star: Readonly<number>;
  readonly hotelName: Readonly<string>;
  readonly hotelLocation: Readonly<string>;
  readonly hotelRating: Readonly<number>;
  readonly reviewCount: Readonly<number>;
  readonly cost: Readonly<number>;
  readonly cancellation: Readonly<string>;
  readonly Amenity: Readonly<string[]>;
  readonly hotelList: Readonly<boolean>;
  readonly discount?: Readonly<string>;
  readonly discountCost?: Readonly<string>;
  readonly selected?: Readonly<number | null>;
  readonly onSelect?: Readonly<() => void>;
  readonly id?: Readonly<number | null>;
  readonly hotelMainImage?: Readonly<string>;
  readonly taxesIncluded: Readonly<string>;
  readonly reviewHeading?: Readonly<string>;
  readonly hotelId?: Readonly<string>;
  readonly hotelDetails?: Readonly<any>;
  readonly city?: Readonly<string>;
  readonly hotelAccordionClick?: () => void;
  readonly hotelViewDetailsClick?: () => void;
  readonly showRoomsLayout?: boolean;
  // readonly setShowSelectRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModifyingHotelAccordion({
  star,
  hotelName,
  hotelLocation,
  hotelRating,
  reviewCount,
  cost,
  cancellation,
  Amenity,
  hotelList,
  discount,
  discountCost,
  selected,
  onSelect,
  id,
  hotelMainImage,
  taxesIncluded,
  reviewHeading,
  hotelId,
  hotelDetails,
  city,
  checkIn,
  checkOut,
  hotelAccordionClick,
  showRoomsLayout,
  // setShowSelectRoomModal,
}: IProps) {
  // console.log("HOTEL", checkIn, checkOut);
  return (
    <CustomAccordion
      key={id}
      className={styles.customFlightStyles}
      summaryClass={styles.summaryFlightClass}
      accordianDetailsClass={styles.accordFlightDetailsClass}
      defaultExpanded
      hideDropDownIcon
      headingComponent={
        <div
          className={styles.headingFlightComp}
          onClick={() => {
            if (hotelAccordionClick) hotelAccordionClick();
          }}
        >
          <div className={styles.accordTitleCard}>
            {checkIn && checkOut && (
              <p className={styles.dayAndCity}>
                {getDayOfWeekUTC(checkIn)} &nbsp;
                <span>{getMonthDateUTC(checkIn)}</span> - &nbsp;
                {getDayOfWeek(checkOut)} &nbsp;
                <span>{getMonthDateUTC(checkOut)}</span>
              </p>
            )}
            {city && <p className={styles.accordDesc}>{city.split(",")[0]}</p>}
          </div>
          {bedFontLight && (
            <img
              src={bedFontLight}
              alt="plane"
              className={`${styles.planeDepartureIcon} ${styles.removePadding}`}
            />
          )}
        </div>
      }
      bodyComponent={
        <div className={styles.main}>
          <div className={styles.container}>
            <div className={`${styles.contentCard}`}>
              <div
                className={`${styles.hotelListCard} ${styles.selectedHotelListCards}`}
              >
                <HotelCard
                  star={star}
                  hotelName={hotelName || "Hotel name"}
                  hotelLocation={hotelLocation || "Hotel location"}
                  hotelRating={hotelRating || 0}
                  reviewCount={reviewCount || 0}
                  Amenity={Amenity?.slice(0, 3)}
                  hotelList={hotelList}
                  hotelMainImage={hotelMainImage}
                  reviewHeading={reviewHeading || "No reviews"}
                  handleViewDetails={() => {}}
                  city={city}
                  hotelDetails={hotelDetails}
                  hotelCardType={
                    showRoomsLayout
                      ? HOTEL_CARD_TYPES.ROOM
                      : HOTEL_CARD_TYPES.SEARCH
                  }
                />
              </div>
            </div>
          </div>
        </div>
      }
      zeroMarginOnExpand
      borderBottom
    />
  );
}
