export const getFlightTravelData = (data: any) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return [];
  }

  const generateDays = (numDays: any) => {
    if (typeof numDays !== "number" || numDays <= 0) {
      return [];
    }
    return Array.from({ length: numDays }, (_, i) => `Day ${i + 1}`);
  };

  return data?.reduce((acc, city) => {
    if (!city || typeof city !== "object") {
      return acc;
    }

    const { city_name, number_of_days, flights } = city;

    if (!Array.isArray(flights) || flights?.length === 0) {
      // console.log(`No flights available for city: ${city_name}`);
      return acc;
    }

    flights?.forEach((flight) => {
      if (!flight || typeof flight !== "object") {
        console.error("Invalid flight object: skipping.", flight);
        return;
      }

      acc.push({
        departureCityAddress: flight?.departure_city_name || "",
        departureCountryCode: flight?.departure_city_country_code || "",
        departureLatitude: flight?.departure_city_latitude || null,
        departureLongitude: flight?.departure_city_longitude || null,
        arrivalCityAddress: flight?.arrival_city_name || "",
        arrivalCityCountryCode: flight?.arrival_city_country_code || "",
        arrivalLatitude: flight?.arrival_city_latitude || null,
        arrivalLongitude: flight?.arrival_city_longitude || null,
        departureDate: flight?.dateInfo,
        returnDate: "",
        days: generateDays(number_of_days),
        flightCheckIn: "",
        flightCheckOut: "",
        totalDays: number_of_days || 0,
        flightTime: flight?.time_of_the_day || "",
      });
    });

    return acc;
  }, []);
};
