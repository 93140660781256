import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import styles from "./topDestination.module.scss";
import { iGlobalContext } from "../../context/globalContext/interface";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { avatarIcon, ROUTES } from "../../constant";
import { iAppConfig, iChatData } from "../../types/common";
import CardWithShowMore from "./cardWithShowMore/CardWithShowMore";
import ChatContent from "./chatContent/ChatContent";
import SelectedTrip from "./selectedTrip/SelectedTrip";
import DeskTopDestination from "../../component/deskTopDestination/deskTopDestination";
import updatedNewTrip from "../../assets/updatedNewTrip.png";
import { APP_CONFIG, CHAT_BOT_NAME } from "../../constant/common";
import { iTopDestinationContext } from "../../context/topDestinationContext/topDestinationContextInterface";
import { useTopDestinationContext } from "../../context/topDestinationContext/TopDestinationContext";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import NewLoader from "../../component/reusableComponent/newLoader/NewLoader";

export default function TopDestination() {
  const state = React.useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    chatData,
    enableMultiSelect,
    setenableMultiSelect,
    isMobile,
    appConfig,
    inputFocus,
    loginCreateAccountState,
  }: iGlobalContext = state;

  const {
    handleCheckboxChange,
    handleShowMore,
    handleViewSingleTripDetails,
    navigate,
    disableInput,
    handleSendMessage,
    handleCancel,
    openLoaderOverlay,
    setOpenLoaderOverlay,
    // snackbarMessage,
    // openSnackbar,
    // handleCancelMultiTrip,
    // handleCreateTrip,
  }: iTopDestinationContext = useTopDestinationContext();

  // Disable scroll on focus of input field
  // useEffect(() => {
  //   const handleTouchMove = (e: any) => {
  //     e.preventDefault();
  //   };
  //   function handleResize(event: any) {
  //     const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
  //     if (
  //       (event?.target?.height * event?.target?.scale) / window.screen.height <
  //       VIEWPORT_VS_CLIENT_HEIGHT_RATIO
  //     ) {
  //       //  toastMessage.success("OPEN");
  //       document.body.addEventListener("touchmove", handleTouchMove, {
  //         passive: false,
  //       });
  //       document.documentElement.addEventListener(
  //         "touchmove",
  //         handleTouchMove,
  //         {
  //           passive: false,
  //         }
  //       );
  //     } else {
  //       //  toastMessage.success("CLOSE");
  //       document.body.removeEventListener("touchmove", handleTouchMove);
  //       document.documentElement.removeEventListener(
  //         "touchmove",
  //         handleTouchMove
  //       );
  //     }
  //   }
  //   if ("visualViewport" in window && !loginCreateAccountState?.flag) {
  //     window?.visualViewport?.addEventListener("resize", handleResize);
  //   }
  //   return () => {
  //     window?.visualViewport?.removeEventListener("resize", handleResize);
  //   };
  // }, [loginCreateAccountState]);

  return (
    <div>
      <NewLoader
        text="Please wait"
        // className={styles.loaderMain}
        // cancelButtonClassName={styles.cancelBtn}
        isOpen={openLoaderOverlay}
        onClose={() => {
          handleCancel();
          setOpenLoaderOverlay(false);
        }}
        loadFullScreen
      />
      {isMobile ? (
        <div
          className={`${inputFocus && styles.superContainerFocus} ${styles.superContainer}`}
        >
          <div className={styles.sectionContainer}>
            <div className={styles.systemChatIcon}>
              <img
                className={styles.chatIcon}
                alt="chat logo"
                // src={zenvoyaChatIcon}
                src={avatarIcon}
              />
              <h3 className={styles.heading}>{CHAT_BOT_NAME.name}</h3>
            </div>
            <div
              className={styles.constText}
              dangerouslySetInnerHTML={{
                __html:
                  appConfig?.length > 0
                    ? appConfig?.filter(
                        (ele: iAppConfig) =>
                          ele.key === APP_CONFIG.HOME_CONSTANT_2
                      )[0]?.value
                    : "",
              }}
            />
          </div>
          {chatData?.map((eleChatData: iChatData, index: number) => (
            <div key={index}>
              {!eleChatData?.singleSelectedDestination && (
                <ChatContent
                  isUserMessage={eleChatData?.isUserMessage}
                  message={eleChatData?.message}
                  preText={eleChatData?.preText}
                  isLoading={
                    eleChatData?.destinationList?.length > 0
                      ? false
                      : eleChatData?.isLoading
                  }
                />
              )}
              {!eleChatData?.singleSelectedDestination &&
                eleChatData.destinationList.length > 0 && (
                  <CardWithShowMore
                    onCheckboxChange={handleCheckboxChange}
                    data={eleChatData}
                    enableMultiSelect={enableMultiSelect}
                    handleShowMore={() => handleShowMore(eleChatData)}
                    postText={eleChatData?.postText}
                  />
                )}
              {eleChatData?.singleSelectedDestination && (
                <SelectedTrip
                  chatData={eleChatData}
                  handleViewTripDetails={() =>
                    handleViewSingleTripDetails(eleChatData)
                  }
                />
              )}
            </div>
          ))}

          {/* {showChatLoaderFlag && (
            <InitialChat showUser={false} message={chatInputText} />
          )} */}
          {/* {showChatLoaderFlag && <Loader />} */}
          <div className={styles.showMoreLikeIconContainer}>
            <Button
              onClick={() => {
                // ReactGA.event({
                //   category: NEW_TRIP.CATEGORY,
                //   action: NEW_TRIP.ACTION,
                //   label: ` ${NEW_TRIP.LABEL}`,
                // });
                triggerGAEvent(GA_LABEL_ENUMS.New_Trip_Click);
                setenableMultiSelect(false);
                setTimeout(() => {
                  navigate(ROUTES.NewTrip);
                }, 100);
              }}
              className={styles.button}
            >
              <img
                src={updatedNewTrip}
                alt="show more"
                className={styles.buttonIcon}
              />
              NEW TRIP
            </Button>
          </div>
          <div
            className={
              inputFocus ? styles.inputContainerFocus : styles.inputContainer
            }
          >
            <InputWithMic
              disable={disableInput}
              onSend={() => handleSendMessage(chatInputText)}
              onStopRecording={() => handleSendMessage(chatInputText)}
              value={chatInputText}
              setValue={setChatInputText}
              handleCancel={handleCancel}
              disableSendBtn={chatInputText === "" ? true : false}
            />
          </div>
          {/* <CustomSnackbar
            disableCreateTripBtn={snackbarMessage.count === 0}
            open={openSnackbar}
            handleClose={handleCancelMultiTrip}
            handleCreateTrip={() => {
              let tempChatData = {} as iChatData;
              chatData.forEach((ele: iChatData) => {
                if (ele.destinationList.length > 1) {
                  const selectedCity = ele.destinationList.reduce(
                    (acc: number, destListEle: iCityDetailsResult) => {
                      if (destListEle.checkedFlag) return acc + 1;
                      return acc;
                    },
                    0
                  );
                  if (selectedCity > 0) tempChatData = ele;
                }
              });
              handleCreateTrip(tempChatData);
            }}
            messageOne={snackbarMessage.messageOne}
            messageTwo={snackbarMessage.messageTwo}
          /> */}
        </div>
      ) : (
        <DeskTopDestination />
      )}
    </div>
  );
}
