export interface GetItinerariesListResponse {
  statusCode: number;
  message: string;
  data: ItineraryList[];
}

export interface ItineraryList {
  id: string;
  tripName: TripName;
  userId: string;
  orginatedCity: null;
  numberOfDaysSelected: string;
  numberOfExperienceSelected: null;
  metaData: MetaDataClass | string;
  data: ItineraryList;
  startDate: null;
  endDate: null;
  itineraryId: null;
  flightTransactionId: null;
  hotelTransactionId: null;
  topDestination: null;
  bookFilter: BookFilters | null;
  status: Status;
  featured: boolean;
  is_admin_created: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface BookFilters {
  cities: string[];
  hotels: Hotel[];
  update: boolean;
  airPort: AirPorts[];
  arrCity: string;
  checkIn: string;
  depCity: string;
  filters: Filter;
  airlines: string;
  checkOut: string;
  cityInfo: CityInfos;
  tripType: number;
  airportTo: string;
  routeType: string;
  searchType?: null;
  adultsCount: number;
  airportFrom: DepartureAirports;
  citiesInput: string;
  flightClass: string;
  hideFlights: boolean;
  hotelChains: string;
  visibleList: VisibleLists;
  seniorsCount: number;
  travelToDate: string;
  childrenCount: number;
  flightDayPart: string;
  flightHistory: FlightHistorys[];
  hotelUniqueId: string;
  isFormUpdated: IsFormUpdate;
  noMoreFlights: boolean;
  numberOfStops: string;
  isDataModified: IsDataModified;
  travelFromDate: Date;
  totalHotelStays: TotalHotelStay[];
  flightRefundable: string;
  hotelPreferences: HotelPreference;
  selectedFlightId: string;
  selectedLocation: Location;
  flightPreferences: FlightPreference;
  isFlightAvailable: boolean;
  numberOfRoomCount: number;
  totalTravelCities: TotalTravelCity[];
  travelJourneyData: TravelJourneyData[];
  expandedAccordions: any[];
  selectedArrivalLocation: Location;
}

export interface AirPorts {
  arrival: string;
  fromCity: FromLocation;
  noOfDays: number;
  departure: DepartureAirports;
  returnDate: string;
  departureDate: string;
  destinationCity: FromLocation;
  arrivalCityDetails: string;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityDetails: string;
  newArrivalCitySearch: boolean;
  departureCityLatitude: string;
  arrivalAirportFullName: string;
  departureCityLongitude: string;
  newDepartureCitySearch: boolean;
  arrivalSelectedLocation: Location;
  departureAirportFullName: string;
  departureSelectedLocation: Location;
}

export interface Locations {
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
  countryCode?: string;
}

export enum DepartureAirports {
  Blr = "BLR",
  Empty = "",
}

export enum FromLocation {
  BengaluruKarnatakaIndia = "Bengaluru, Karnataka, India",
  BostonMAUnitedStates = "Boston, MA, United States",
  Empty = "",
  TorontoONCanada = "Toronto, ON, Canada",
}

export interface CityInfos {
  arrival: string;
  departure: string;
}

export interface Filter {
  starRating: number[];
}

export interface FlightHistorys {
  flights: Flights[];
  city_name: TripName;
  number_of_days: number;
}

export enum TripName {
  Bengaluru = "Bengaluru",
  Boston = "Boston",
  CapeTown = "Cape Town",
  Nairobi = "Nairobi",
  Toronto = "Toronto",
}

export interface Flights {
  arrival_city_name: FromLocation;
  departure_city_name: FromLocation;
  arrival_city_latitude: string;
  arrival_city_longitude: string;
  departure_city_latitude: string;
  departure_city_longitude: string;
  arrival_city_country_code: CityCountryCode1;
  departure_city_country_code: CityCountryCode1;
}

export enum CityCountryCode1 {
  CA = "CA",
  In = "IN",
  Ke = "KE",
  Us = "US",
  Za = "ZA",
}

export interface FlightPreference {
  airlines: any[];
  refundable: boolean;
  connections: number;
  departureTime: any[];
}

export interface HotelPreference {
  refundable: boolean;
  hotelChains: any[];
}

export interface Hotel {
  city: TripName;
  checkIn: Date;
  country: CityCountryCode1;
  checkOut: Date;
  latitude: string;
  longitude: string;
}

export interface IsDataModified {
  hotel: boolean;
  flight: boolean;
}

export interface IsFormUpdate {
  arrivalForm: boolean;
  departureForm: boolean;
}

export interface TotalHotelStay {
  cityName: TripName;
  numberOfStay: number;
}

export interface TotalTravelCity {
  city: TripName;
  country: string;
  latitude: string;
  longitude: string;
  numberOfStay: number;
  countryFullName?: CityCountryCodeType;
}

export enum CityCountryCodeType {
  Canada = "Canada",
  India = "India",
  Kenya = "Kenya",
  SouthAfrica = "South Africa",
  UnitedStates = "United States",
}

export interface TravelJourneyData {
  days: Day[];
  totalDays: number;
  returnDate: string;
  departureDate: string;
  flightCheckIn: string;
  flightCheckOut: string;
  arrivalLatitude?: string;
  arrivalLongitude?: string;
  departureLatitude?: string;
  arrivalCityAddress: FromLocation;
  departureLongitude?: string;
  departureCityAddress: FromLocation;
  departureCountryCode?: CityCountryCode1;
  arrivalCityCountryCode?: CityCountryCode1;
}

export enum Days {
  Day1 = "Day 1",
  Day2 = "Day 2",
  Day3 = "Day 3",
  Day4 = "Day 4",
  Day5 = "Day 5",
  Day6 = "Day 6",
  Day7 = "Day 7",
}

export interface VisibleLists {
  hotel: number;
  flight: number;
  package: number;
}

export interface DeatilsList {
  from: FromLocation;
  hotels: string;
  airlines: string;
  trip_type: TripTypes;
  start_date: string;
  from_latitude: string;
  from_longitude: string;
  number_of_days: string;
  arrival_airport: ArrivalAirport;
  number_of_rooms: string;
  number_of_stops: string;
  hotel_refundable: string;
  number_of_adults: string;
  budget_per_person: string;
  departure_airport: DepartureAirports;
  flight_refundable: string;
  itinerary_version: string;
  flight_cabin_class: FlightCabinClasses;
  hotel_star_ratings: string;
  number_of_children: string;
  city_wise_itinerary: CityWiseItineraries[];
  number_of_travelers: string;
  number_of_senior_citizens: string;
  complete_itinerary_description: string;
  complete_itinerary_hotels_cost: CompleteItineraryHotelsCost;
  complete_itinerary_flights_cost: CompleteItineraryFlightsCosts;
}

export enum ArrivalAirport {
  Bos = "BOS",
  Empty = "",
  Yyz = "YYZ",
}

export interface CityWiseItineraries {
  city_name: TripName;
  city_type: CityTypes;
  city_state: CityState;
  city_country: CityCountryCode1;
  city_latitude: string;
  city_itinerary: CityItineraries[];
  city_longitude: string;
  city_country_code: CityCountryCode1;
  city_itinerary_description: string;
}

export interface CityItineraries {
  day: Day;
  city_name: TripName;
  day_itinerary_description: string;
  itinerary_by_time_of_the_day: ItineraryByTimeOfTheDays[];
}

export interface ItineraryByTimeOfTheDays {
  description: string;
  activity_type: string;
  time_of_the_day: TimeOfTheDays;
  arrival_city_name?: TripName;
  arrival_city_state?: CityState;
  departure_city_name?: TripName;
  arrival_city_country?: CityCountryCode1;
  departure_city_state?: CityState;
  arrival_city_lattitude?: string;
  arrival_city_longitude?: string;
  departure_city_country?: CityCountryCode1;
  departure_city_lattitude?: string;
  departure_city_longitude?: string;
  arrival_city_country_code?: CityCountryCode1;
  departure_city_country_code?: CityCountryCode1;
  "accommodation.location"?: string;
  id?: string;
}

export enum CityState {
  Karnataka = "Karnataka",
  Ma = "MA",
  NairobiCounty = "Nairobi County",
  On = "ON",
  WesternCape = "Western Cape",
}

export enum TimeOfTheDays {
  Afternoon = "afternoon",
  Evening = "evening",
  Morning = "morning",
  Night = "night",
}

export enum CityTypes {
  City = "city",
}

export enum CompleteItineraryFlightsCosts {
  The1200 = "$1200",
  The800 = "$800",
}

export enum CompleteItineraryHotelsCost {
  The500 = "$500",
  The600 = "$600",
  The700 = "$700",
}

export enum FlightCabinClasses {
  Economy = "economy",
}

export enum TripTypes {
  Roundtrip = "roundtrip",
  MultiCity = "multicity",
}

export interface MetaDataClass {
  selectedChatData: SelectedChatsData;
}

export interface SelectedChatsData {
  id: string;
  from: FromLocation;
  cities: any[];
  hotels: string;
  prompt: any[];
  result: string;
  isSaved: boolean;
  message: string;
  preText: string;
  results: ItineraryList;
  airlines: string;
  fromCity: FromLocation;
  messages: any[];
  noOfDays: number;
  postText: string;
  tripName: TripName;
  isLoading: boolean;
  trip_type: TripTypes;
  singleCity: boolean;
  start_date: string;
  numberOfdays: number;
  from_latitude: string;
  isUserMessage: boolean;
  from_longitude: string;
  number_of_days: string;
  arrival_airport: ArrivalAirport;
  destinationList: DestinationLists[];
  number_of_rooms: string;
  number_of_stops: string;
  hotel_refundable: string;
  number_of_adults: string;
  budget_per_person: string;
  departure_airport: DepartureAirports;
  flight_refundable: string;
  itinerary_version: string;
  flight_cabin_class: FlightCabinClasses;
  hotel_star_ratings: string;
  number_of_children: string;
  city_wise_itinerary: any[];
  number_of_travelers: string;
  number_of_senior_citizens: string;
  singleSelectedDestination: boolean;
  complete_itinerary_description: string;
  complete_itinerary_hotels_cost: CompleteItineraryHotelsCost;
  complete_itinerary_flights_cost: CompleteItineraryFlightsCosts;
}

export interface DestinationLists {
  id: string;
  cityName: string;
  imageURL: string;
  checkFlag?: boolean;
  city_name: TripName;
  city_type: CityTypes;
  locations: any[];
  city_state: CityState;
  checkedFlag: boolean;
  description: string;
  tripDetails: CityItineraries[];
  city_country: CityCountryCode1;
  city_latitude: string;
  city_itinerary: any[];
  city_longitude: string;
  city_country_code: CityCountryCode1;
  city_itinerary_description: string;
  complete_itinerary_hotels_cost: CompleteItinerarySCosts;
  complete_itinerary_flights_cost: CompleteItinerarySCosts;
}

export interface CompleteItinerarySCosts {
  id: string;
  value: Value;
  checkedFlag: boolean;
}

export enum Value {
  The1200 = "$1200",
  The600 = "$600",
  The700 = "$700",
}

export enum Status {
  Draft = "draft",
}
