import { useCallback, useContext } from "react";
import styles from "./flightPreferences.module.scss";
import SliderContainer from "./sliderContainer/sliderContainer";
import ToggleSwitchComponent from "../components/toggleSwitch/toggleSwitch";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { Chip } from "@mui/material";

function FlightPreferences() {
  const state = useContext(StateContext);
  const {
    flightPreferencesOptions,
    setFlightPreferencesOptions,
    bookingDetails,
    setBookingDetails,
  }: iGlobalContext = state;

  const splitAirlines = (airlineData: string) => {
    if (airlineData === "") {
      return [];
    }
    return airlineData.split(",").map((airline) => airline.trim());
  };
  const airlines = splitAirlines(bookingDetails.airlines ?? "");
  const FLIGHT_PREFERENCES = {
    maxConnectionStops: 5,
    departureTimings: [
      {
        id: 1,
        title: "Early Morning",
        startTime: "12:00am",
        endTime: "4:59am",
      },
      {
        id: 2,
        title: "Morning",
        startTime: "5:00am",
        endTime: "11:59am",
      },
      {
        id: 3,
        title: "Afternoon",
        startTime: "12:00pm",
        endTime: "4:59pm",
      },
      {
        id: 4,
        title: "Evening",
        startTime: "5:00pm",
        endTime: "8:59pm",
      },
      {
        id: 5,
        title: "Night",
        startTime: "9:00pm",
        endTime: "11:59pm",
      },
    ],
    isRefundable: true,
    airlines: airlines,
  };

  const handleChange = (_event: Event, newValue: number | number[]) => {
    // setScrollValue(newValue as number);
    setFlightPreferencesOptions({
      ...flightPreferencesOptions,
      flightPreferencesScrollValue: newValue as number,
    });
    setBookingDetails((prevData) => ({
      ...prevData,
      flightPreferences: {
        ...prevData.flightPreferences,
        connections: newValue as number,
      },
    }));
    // setIsFormUpdated(true);
  };

  const handleSelectedDepartureTimings = (selectedTime: string) => {
    setBookingDetails((prevDetails) => {
      const currentPreferences =
        prevDetails.flightPreferences.departureTime || [];
      const isAlreadySelected = currentPreferences.includes(selectedTime);
      const updatedDepartureTime = isAlreadySelected
        ? currentPreferences.filter((time) => time !== selectedTime)
        : [...currentPreferences, selectedTime];

      return {
        ...prevDetails,
        flightPreferences: {
          ...prevDetails.flightPreferences,
          departureTime: updatedDepartureTime,
        },
      };
    });
  };

  const handleRefundFilter = useCallback(
    (event: any, checked: boolean) => {
      setBookingDetails((prevData) => ({
        ...prevData,
        flightPreferences: {
          ...prevData.flightPreferences,
          refundable: event.target.checked,
        },
      }));
    },
    [setBookingDetails]
  );

  const handleCheckboxChange = (airline: string) => {
    const updatedAirlines = bookingDetails.flightPreferences.airlines.includes(
      airline
    )
      ? bookingDetails.flightPreferences.airlines.filter(
          (item) => item !== airline
        )
      : [...bookingDetails.flightPreferences.airlines, airline];

    setBookingDetails({
      ...bookingDetails,
      flightPreferences: {
        ...bookingDetails.flightPreferences,
        airlines: updatedAirlines,
      },
    });
  };

  return (
    <div className={styles.flightPreferencesContainer}>
      <div className={styles.header}>Connections</div>
      <SliderContainer
        className={styles.sliderComponent}
        flightPreferences={FLIGHT_PREFERENCES}
        handleChange={handleChange}
        scrollValue={bookingDetails?.flightPreferences?.connections ?? 0}
      />

      {bookingDetails?.flightPreferences?.departureTime?.length > 0 && (
        <>
          <div className={styles.header}>Departure Time</div>
          <div className={styles.chipCard}>
            {FLIGHT_PREFERENCES.departureTimings &&
              FLIGHT_PREFERENCES.departureTimings
                // .slice(0, showAll ? foodPreferData.length : 6)
                .map((activity, index) => (
                  <div className={styles.chipContainer} key={index}>
                    <Chip
                      label={activity.title}
                      size="medium"
                      variant="outlined"
                      onClick={() => {
                        handleSelectedDepartureTimings(activity?.title?.toLowerCase());
                      }}
                      className={`${styles.normalChip} ${
                        bookingDetails?.flightPreferences?.departureTime?.includes(
                          activity.title.toLowerCase()
                        )
                          ? styles.activeChip
                          : styles.chip
                      }`}
                      style={{
                        backgroundColor:
                          bookingDetails?.flightPreferences?.departureTime?.includes(
                            activity.title.toLowerCase()
                          )
                            ? "#D8EFFF"
                            : "#ffff",
                      }}
                    />
                    <p className={styles.timeContainer}>
                      {activity?.startTime}&nbsp;-&nbsp;{activity?.endTime}
                    </p>
                  </div>
                ))}
          </div>
        </>
      )}

      <div className={styles.toggleSwitchContainer}>
        <ToggleSwitchComponent
          handleRefundFilter={handleRefundFilter}
          isSelectedRefundable={bookingDetails?.flightPreferences?.refundable}
        />
      </div>
      {FLIGHT_PREFERENCES?.airlines.length >= 1 && (
        <>
          <div className={styles.header}>Airlines</div>
          <div className={styles.selectCard}>
            {FLIGHT_PREFERENCES?.airlines?.map(
              (airline: any, index: number) => (
                <div className={styles.customCheckboxContainer}>
                  <section key={index} className={styles.inputBox}>
                    <input
                      type="checkbox"
                      id={airline}
                      name={airline}
                      value={airline}
                      checked={
                        Array.isArray(
                          bookingDetails?.flightPreferences?.airlines
                        ) &&
                        bookingDetails.flightPreferences.airlines.includes(
                          airline
                        )
                      }
                      onChange={() => handleCheckboxChange(airline)}
                      className={styles.customCheckbox}
                    />
                    <span className={styles.checkmark}></span>
                  </section>
                  <label htmlFor={airline} className={styles.label}>
                    {airline}
                  </label>
                  <br />
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default FlightPreferences;
