import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./context";
import { ErrorBoundary } from "@sentry/react";
import { initializeSentry } from "./constant/sentryConfiguration";
import { ToastProvider } from "./utils/ToastProvider";
import { APP_URL } from "./constant/environment";

initializeSentry();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ErrorBoundary
    fallback={() => <p>Something went wrong in Zenvoya</p>}
    onError={() => (window.location.href = APP_URL)}
    // showDialog
  >
    <BrowserRouter>
      <ContextProvider>
        <ToastProvider>
          {/* <Toaster position="top-center" reverseOrder={false} /> */}
          <App />
        </ToastProvider>
      </ContextProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
