export interface GetHistoryResponse {
  statusCode: number;
  message: string;
  data: History[];
}

export interface History {
  id: string;
  sessionId: null;
  userId: string;
  history: History[];
  createdAt: Date;
  updatedAt: Date;
}

export interface History {
  id: string;
  prompt: Prompt[];
  message: string;
  isLoading: boolean;
  isUserMessage: boolean;
  destinationList: any[];
  singleSelectedDestination: boolean;
  from?: From;
  cities?: any[];
  hotels?: string;
  result?: string;
  isSaved?: boolean;
  preText?: string;
  results?: Results;
  airlines?: string;
  fromCity?: From;
  messages?: any[];
  noOfDays?: number;
  postText?: string;
  trip_type?: TripType;
  singleCity?: boolean;
  start_date?: string;
  numberOfdays?: number;
  from_latitude?: string;
  from_longitude?: string;
  number_of_days?: string;
  arrival_airport?: string;
  number_of_rooms?: string;
  number_of_stops?: string;
  hotel_refundable?: string;
  number_of_adults?: string;
  budget_per_person?: string;
  departure_airport?: DepartureAirport;
  flight_refundable?: string;
  itinerary_version?: string;
  flight_cabin_class?: FlightCabinClass;
  hotel_star_ratings?: string;
  number_of_children?: string;
  city_wise_itinerary?: any[];
  number_of_travelers?: string;
  number_of_senior_citizens?: string;
  complete_itinerary_description?: string;
  complete_itinerary_hotels_cost?: CompleteItineraryFlightsCost;
  complete_itinerary_flights_cost?: CompleteItineraryFlightsCost;
}

export enum CompleteItineraryFlightsCost {
  The1200 = "$1200",
  The200 = "$200",
  The2400 = "$2400",
  The500 = "$500",
  The600 = "$600",
  The700 = "$700",
  The800 = "$800",
}

export enum DepartureAirport {
  Blr = "BLR",
  Empty = "",
  Iad = "IAD",
}

// export interface DestinationList {
//     id:                              string;
//     cityName:                        string;
//     imageURL?:                       string;
//     checkFlag?:                      boolean;
//     city_name:                       CityName;
//     city_type:                       CityType;
//     locations:                       any[];
//     city_state:                      string;
//     checkedFlag?:                    boolean;
//     description:                     string;
//     tripDetails:                     TripDetail[];
//     city_country:                    CityCountry;
//     city_latitude:                   string;
//     city_itinerary:                  any[];
//     city_longitude:                  string;
//     city_country_code:               CityCountryCode;
//     city_itinerary_description:      string;
//     complete_itinerary_hotels_cost:  any;
//     complete_itinerary_flights_cost: any;
// }

export enum CityCountry {
  Botswana = "Botswana",
  Canada = "Canada",
  India = "India",
  Italy = "Italy",
  Kenya = "Kenya",
  Singapore = "Singapore",
  SouthAfrica = "South Africa",
  Turkey = "Turkey",
  UnitedStates = "United States",
}

export enum CityCountryCode {
  BW = "BW",
  CA = "CA",
  In = "IN",
  It = "IT",
  Ke = "KE",
  Sg = "SG",
  Tr = "TR",
  Us = "US",
  Za = "ZA",
}

export enum CityName {
  Bengaluru = "Bengaluru",
  Boston = "Boston",
  CapeTown = "Cape Town",
  Dinokeng = "Dinokeng",
  Gaborone = "Gaborone",
  Istanbul = "Istanbul",
  Johannesburg = "Johannesburg",
  Nairobi = "Nairobi",
  Palolem = "Palolem",
  Panaji = "Panaji",
  Pilanesberg = "Pilanesberg",
  Rome = "Rome",
  Singapore = "Singapore",
  Toronto = "Toronto",
  Vagator = "Vagator",
  Washington = "Washington",
}

export enum CityType {
  Beach = "beach",
  City = "city",
}

// export interface CompleteItinerarySCost {
//     id:          string;
//     value:       CompleteItineraryFlightsCost;
//     checkedFlag: boolean;
// }

export interface TripDetail {
  day: Day;
  city_name: CityName;
  day_itinerary_description?: string;
  itinerary_by_time_of_the_day: any[];
  city_itinerary_description?: string;
}

// export enum Day {
//     Day1 = "Day 1",
//     Day2 = "Day 2",
//     Day3 = "Day 3",
//     Day4 = "Day 4",
//     Day5 = "Day 5",
//     Day6 = "Day 6",
//     Day7 = "Day 7",
// }

// export interface ItineraryByTimeOfTheDay {
//     id?:                          string;
//     description:                  string;
//     activity_type:                string;
//     time_of_the_day:              TimeOfTheDay;
//     arrival_city_name?:           CityName;
//     arrival_city_state?:          string;
//     departure_city_name?:         CityName;
//     arrival_city_country?:        CityCountry;
//     departure_city_state?:        string;
//     arrival_city_lattitude?:      string;
//     arrival_city_longitude?:      string;
//     departure_city_country?:      CityCountry;
//     departure_city_lattitude?:    string;
//     departure_city_longitude?:    string;
//     arrival_city_country_code?:   CityCountryCode;
//     departure_city_country_code?: CityCountryCode;
//     "accommodation.location"?:    string;
//     arrival_city_statet?:         string;
//     arrival_city?:                string;
//     departure_city?:              string;
// }

// export enum TimeOfTheDay {
//     Afternoon = "afternoon",
//     Evening = "evening",
//     Morning = "morning",
//     Night = "night",
// }

export enum FlightCabinClass {
  Economy = "economy",
}

export enum From {
  BengaluruKarnatakaIndia = "Bengaluru, Karnataka, India",
  WashingtonDCUnitedStates = "Washington, DC, United States",
}

export interface Prompt {
  role: Role;
  content: string;
}

export enum Role {
  Assistant = "assistant",
  User = "user",
}

export interface Results {
  from: From;
  hotels: string;
  airlines: string;
  trip_type: TripType;
  start_date: string;
  from_latitude: string;
  from_longitude: string;
  number_of_days: string;
  arrival_airport: string;
  number_of_rooms: string;
  number_of_stops: string;
  hotel_refundable: string;
  number_of_adults: string;
  budget_per_person: string;
  departure_airport: DepartureAirport;
  flight_refundable: string;
  itinerary_version: string;
  flight_cabin_class: FlightCabinClass;
  hotel_star_ratings: string;
  number_of_children: string;
  city_wise_itinerary: any[];
  number_of_travelers: string;
  number_of_senior_citizens: string;
  complete_itinerary_description: string;
  complete_itinerary_hotels_cost: CompleteItineraryFlightsCost;
  complete_itinerary_flights_cost: CompleteItineraryFlightsCost;
}

// export interface CityWiseItinerary {
//     city_name:                  CityName;
//     city_type:                  CityType;
//     city_state:                 string;
//     city_country:               CityCountry;
//     city_latitude:              string;
//     city_itinerary:             TripDetail[];
//     city_longitude:             string;
//     city_country_code:          CityCountryCode;
//     city_itinerary_description: string;
// }

export enum TripType {
  Roundtrip = "roundtrip",
}
