import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./upcomingTrip.module.scss";
import {
  MY_TRIP_TABS,
  // savedDummyTripDataArr,
  TRIP_DETAILS_TABS,
} from "../../myTripsConstants";
import MyTripCard from "../myTripsCard/myTripsCard";
import { ROUTES, whiteArrowIcon } from "../../../../constant";
import toast from "react-hot-toast";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { getItinerariesListAPI } from "../../../../api/getSaveItinerariesList";
import { StateContext } from "../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import {
  sortDatesInAscendingOrder,
  sortDatesOldtoNew,
} from "../../../../utils/dateUtils";
import { Itineraries } from "../../../../types";
import Loader from "../../../reusableComponent/loader/Loader";
import { useNavigate } from "react-router-dom";
import { ScreenTypes } from "../../../../enums";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import { useInView } from "react-intersection-observer";
import useCustomErrorHandler from "../../../../hooks/useCustomErrorHandler";
import NewLoader from "../../../reusableComponent/newLoader/NewLoader";
import { GetItinerariesListResponse } from "../../../../interface";

interface iProps {
  className?: string;
  handleEditTripName: any;
  isTripNameEdited?: boolean;
  mytripScrollContainerRef?: any;
  editTripNameData?: any;
  tripName?: any;
}

function UpcomingTrips(props: iProps): JSX.Element {
  const {
    className,
    handleEditTripName,
    isTripNameEdited = false,
    mytripScrollContainerRef,
    editTripNameData,
    tripName,
  } = props;
  const globalContext = useContext(StateContext);
  const {
    setTripOrItinearyId,
    setPrevRoute,
    setScreen,
    isMobile,
    userInfo,
    setActiveTripDetailsTab,
    // mytripScrollContainerRef
  }: iGlobalContext = globalContext;
  const { handleCustomError } = useCustomErrorHandler();
  const [isLoadingItineraryList, setIsLoadingItineraryList] = useState(true);
  const [listItinery, setListItinery] = useState<Itineraries>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skipLimit, setSkipLimit] = useState({
    skip: 0,
    limit: 10,
  });
  const { ref, inView } = useInView({});

  const getListItineries = useCallback(
    async function () {
      try {
        if (!hasMore || (!inView && skipLimit.skip > 0)) return;
        setIsLoadingItineraryList(true);
        // setTripCount(0)
        const response:GetItinerariesListResponse | any = await getItinerariesListAPI(
          userInfo?.id,
          "upcoming",
          skipLimit.skip,
          skipLimit.limit
        );
        if (response?.statusCode !== 200) {
          handleCustomError(response?.statusCode, "itinerary.list");
          return null;
        }
        setIsLoadingItineraryList(false);
        if (response && response?.data) {
          setSkipLimit({
            skip: skipLimit.skip + skipLimit.limit,
            limit: 10,
          });
          setListItinery([...listItinery, ...response?.data]);
          if (response?.data?.length === 0) setHasMore(false);
          // setListItinery(sortDatesOldtoNew(response?.data, "startDate"));
          // setTripCount(response?.data.length);

          // console.log(
          //   sortDatesInAscendingOrder(response?.data, "startDate"),
          //   "response"
          // );
        } else {
          throw new Error("No response data");
        }
      } catch (error) {
        toastMessage.error("Something went wrong!");
      }
    },
    [userInfo?.id, inView]
  );
  useEffect(() => {
    getListItineries();
  }, [inView]);

  useEffect(() => {
    setListItinery(
      listItinery.map((ele: any) =>
        ele?.id === editTripNameData?.id ? { ...ele, tripName: tripName } : ele
      )
    );
  }, [isTripNameEdited]);

  const navigate = useNavigate();
  const handleViewTrip = useCallback(
    (trip: any) => {
      setActiveTripDetailsTab(TRIP_DETAILS_TABS.DAILY_PLANS);
      toast.dismiss();
      setPrevRoute(ROUTES.MyTrips);
      // console.log(trip);
      setTripOrItinearyId(trip?.id || "");
      triggerGAEvent(GA_LABEL_ENUMS.my_trips_upcoming_trips_card_click);
      if (!isMobile) setScreen(ScreenTypes.TripDetails);
      else navigate(ROUTES.TripDetails);
    },
    [isMobile, navigate, setPrevRoute, setScreen, setTripOrItinearyId]
  );

  if (isLoadingItineraryList && listItinery.length === 0)
    return (
      <div className={styles.loader}>
        <Loader />
        {/* <NewLoader
          isOpen={true}
          onClose={() => {}}
          text="Please wait"
          loadFullScreen={isMobile ? false : true}
        /> */}
      </div>
    );

  return (
    <div>
      {listItinery?.length > 0 ? (
        <div>
          {listItinery.map((trip: any, index: any) => {
            // console.log({ trip });
            return (
              <div className={styles.upcomingTripSubContainer} key={index}>
                <MyTripCard
                  trip={trip}
                  showNextIcon={true}
                  handleEditTripName={() =>
                    handleEditTripName(trip, MY_TRIP_TABS.UPCOMING)
                  }
                  onCardClick={() => handleViewTrip(trip)}
                />

                <h1 className={styles.bookedDate}>{trip?.bookedOnDate}</h1>
              </div>
            );
          })}
          {hasMore && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <p className={styles.noData}>You don't have any upcoming trips.</p>
      )}
    </div>
  );
}

UpcomingTrips.defaultProps = {
  className: "",
};

export default UpcomingTrips;
