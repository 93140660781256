import { useState } from "react";
import { arrowRightArrowLeftRegular } from "../../../../constant";
import { BookingDetails } from "../../../../types";
import styles from "./air.module.scss";
import AirportSelectionPopover from "../../bookingPopover/airportSelectionPopover/AirportSelectionPopover";
import React from "react";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";

interface iProps {
  className?: string;
  bookingDetails: BookingDetails;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingDetails>>;
  departure: string;
  arrival: string;
  departureAirportFullName: string;
  arrivalAirportFullName: string;
  handleUpdateData: () => void;
  arrivalCity: string;
  departureCity: string;
  indexNumber: number;
}

function AirPortPicker({
  className,
  bookingDetails,
  setBookingDetails,
  departure,
  arrival,
  departureAirportFullName,
  arrivalAirportFullName,
  handleUpdateData,
  arrivalCity,
  departureCity,
  indexNumber,
}: iProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const hideIcon =
    bookingDetails.travelJourneyData[0]?.flightCheckIn === null &&
    bookingDetails.travelJourneyData[0]?.flightCheckOut === null &&
    bookingDetails.isFormUpdated.departureForm === false;
  return (
    <>
      {bookingDetails && (
        <AirportSelectionPopover
          indexNumber={indexNumber}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          handleApplyButton={handleUpdateData}
          arrivalCity={arrivalCity}
          departureCity={departureCity}
          popoverTrigger={
            <div
              className={`${className} ${styles.main}`}
              onClick={() => {
                triggerGAEvent(GA_LABEL_ENUMS.booking_airports_click);
              }}>
              <React.Fragment>
                <p className={styles.label}>AIRPORTS</p>
                {bookingDetails?.airPort[0]?.departure === "" &&
                bookingDetails?.airPort[0]?.arrival === "" ? (
                  <div
                    className={`${isPopoverOpen ? styles.activeBtn : styles.btn}`}>
                    {" "}
                    <div className={styles.card}>
                      <p className={styles.placeHolder}>{"Search airports"}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${isPopoverOpen ? styles.activeBtn : styles.btn}`}>
                    {bookingDetails?.airPort[0]?.newDepartureCitySearch ? (
                      <div className={styles.card}>
                        {bookingDetails?.airPort[0]?.departure === "" ? (
                          <>
                            <p className={styles.btnName}>{""}</p>
                            <p className={styles.placeHolder}>Select</p>
                          </>
                        ) : (
                          <>
                            <p className={styles.btnName}>
                              {bookingDetails?.airPort[0]?.departure}
                            </p>
                            <p className={styles.city}>
                              {
                                bookingDetails?.airPort[0]
                                  ?.departureAirportFullName
                              }
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={styles.card}>
                        <p className={styles.btnName}>
                          {bookingDetails?.airPort[0]?.departure}
                        </p>
                        <p className={styles.city}>
                          {bookingDetails?.airPort[0]?.departureAirportFullName}
                        </p>
                      </div>
                    )}
                    {hideIcon ? null : (
                      <img
                        src={arrowRightArrowLeftRegular}
                        className={styles.arrowIcon}
                        alt=""
                      />
                    )}

                    {bookingDetails?.airPort[0]?.arrival === "" ? (
                      <section className={styles.card}>
                        <p className={styles.btnName}></p>
                        <p className={styles.placeHolder}>Select</p>
                      </section>
                    ) : (
                      <section className={styles.card}>
                        <p className={styles.btnName}>
                          {bookingDetails?.airPort[0]?.arrival}
                        </p>
                        <p className={styles.city}>
                          {bookingDetails?.airPort[0]?.arrivalAirportFullName}
                        </p>
                      </section>
                    )}
                  </div>
                )}

                {/* )} */}
              </React.Fragment>
            </div>
          }
        />
      )}
    </>
  );
}

export default AirPortPicker;
