import { CityItinerary, CityWiseItinerary } from "./saveItinarary";

export interface ItineraryDetailsResponse {
  statusCode: number;
  message: string;
  data: ItineraryDetailsResponseData;
}

export interface ItineraryDetailsResponseData {
  id: string;
  tripName: Name;
  userId: string;
  orginatedCity: null;
  numberOfDaysSelected: string;
  numberOfExperienceSelected: null;
  metaData: MetaData;
  data: ResultsClass;
  startDate: null;
  endDate: null;
  itineraryId: null;
  flightTransactionId: null;
  hotelTransactionId: null;
  topDestination: null;
  bookFilter: BookFilter;
  status: string;
  featured: boolean;
  is_admin_created: boolean;
  createdAt: Date;
  updatedAt: Date;
  trip_type?: string;
}

export interface BookFilter {
  cities: string[];
  update: boolean;
  airPort: AirPort[];
  arrCity: string;
  checkIn: string;
  depCity: string;
  filters: Filters;
  airlines: string;
  checkOut: string;
  cityInfo: CityInfo;
  tripType: number;
  airportTo: string;
  routeType: string;
  searchType: null;
  adultsCount: number;
  airportFrom: string;
  citiesInput: string;
  flightClass: string;
  hideFlights: boolean;
  hotelChains: string;
  visibleList: VisibleList;
  seniorsCount: number;
  travelToDate: string;
  childrenCount: number;
  flightDayPart: string;
  flightHistory: FlightHistory[];
  hotelUniqueId: string;
  isFormUpdated: IsFormUpdated;
  noMoreFlights: boolean;
  numberOfStops: string;
  travelFromDate: string;
  flightRefundable: string;
  hotelPreferences: HotelPreferences;
  selectedFlightId: string;
  selectedLocation: Location;
  flightPreferences: FlightPreferences;
  isFlightAvailable: boolean;
  numberOfRoomCount: number;
  travelJourneyData: TravelJourneyDatum[];
  expandedAccordions: any[];
  selectedArrivalLocation: Location;
}

export interface AirPort {
  arrival: string;
  fromCity: string;
  noOfDays: number;
  departure: string;
  returnDate: string;
  departureDate: string;
  destinationCity: string;
  arrivalCityDetails: string;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityDetails: string;
  newArrivalCitySearch: boolean;
  departureCityLatitude: string;
  arrivalAirportFullName: string;
  departureCityLongitude: string;
  newDepartureCitySearch: boolean;
  arrivalSelectedLocation: Location;
  departureAirportFullName: string;
  departureSelectedLocation: Location;
}

// export interface Location {
//     city:         string;
//     state:        string;
//     country:      string;
//     latitude:     number;
//     longitude:    number;
//     countryCode?: string;
// }

export interface CityInfo {
  arrival: string;
  departure: string;
}

export interface Filters {
  starRating: number[];
}

export interface FlightHistory {
  flights: Flight[];
  city_name: Name;
  number_of_days: number;
}

export enum Name {
  Rome = "Rome",
}

export interface Flight {
  arrival_city_name: string;
  departure_city_name: string;
  arrival_city_latitude: string;
  arrival_city_longitude: string;
  departure_city_latitude: string;
  departure_city_longitude: string;
  arrival_city_country_code: string;
  departure_city_country_code: string;
}

export interface FlightPreferences {
  airlines: any[];
  refundable: boolean;
  connections: number;
  departureTime: any[];
}

export interface HotelPreferences {
  refundable: boolean;
  hotelChains: any[];
}

export interface IsFormUpdated {
  arrivalForm: boolean;
  departureForm: boolean;
}

export interface TravelJourneyDatum {
  days: any[];
  totalDays: number;
  returnDate: string;
  departureDate: string;
  flightCheckIn: string;
  flightCheckOut: string;
  arrivalCityAddress: string;
  departureCityAddress: string;
}

export interface VisibleList {
  hotel: number;
  flight: number;
  package: number;
}

export interface ResultsClass {
  from: string;
  hotels: string;
  airlines: string;
  trip_type: string;
  start_date: string;
  from_latitude: string;
  from_longitude: string;
  number_of_days: string;
  arrival_airport: string;
  number_of_rooms: string;
  number_of_stops: string;
  hotel_refundable: string;
  number_of_adults: string;
  budget_per_person: string;
  departure_airport: string;
  flight_refundable: string;
  itinerary_version: string;
  flight_cabin_class: string;
  hotel_star_ratings: string;
  number_of_children: string;
  city_wise_itinerary: CityWiseItinerary[];
  number_of_travelers: string;
  number_of_senior_citizens: string;
  complete_itinerary_description: string;
  complete_itinerary_hotels_cost: string;
  complete_itinerary_flights_cost: string;
}

// export interface CityWiseItinerary {
//     city_name:                  Name;
//     city_type:                  string;
//     city_state:                 string;
//     city_country:               string;
//     city_latitude:              string;
//     city_itinerary:             CityItinerary[];
//     city_longitude:             string;
//     city_country_code:          string;
//     city_itinerary_description: string;
// }

// export interface CityItinerary {
//     day:                          string;
//     city_name:                    Name;
//     day_itinerary_description:    string;
//     itinerary_by_time_of_the_day: ItineraryByTimeOfTheDay[];
// }

// export interface ItineraryByTimeOfTheDay {
//     description:                  string;
//     activity_type:                string;
//     time_of_the_day:              TimeOfTheDay;
//     arrival_city_name?:           string;
//     arrival_city_state?:          string;
//     departure_city_name?:         string;
//     arrival_city_country?:        string;
//     departure_city_state?:        string;
//     arrival_city_lattitude?:      string;
//     arrival_city_longitude?:      string;
//     departure_city_country?:      string;
//     departure_city_lattitude?:    string;
//     departure_city_longitude?:    string;
//     arrival_city_country_code?:   string;
//     departure_city_country_code?: string;
//     "accommodation.location"?:    string;
//     id?:                          string;
// }

// export enum TimeOfTheDay {
//     Afternoon = "afternoon",
//     Evening = "evening",
//     Morning = "morning",
// }

export interface MetaData {
  selectedChatData: SelectedChatData;
}

export interface SelectedChatData {
  id: string;
  from: string;
  cities: any[];
  hotels: string;
  prompt: any[];
  result: string;
  isSaved: boolean;
  message: string;
  preText: string;
  results: ResultsClass;
  airlines: string;
  fromCity: string;
  messages: any[];
  noOfDays: number;
  postText: string;
  tripName: Name;
  isLoading: boolean;
  trip_type: string;
  singleCity: boolean;
  start_date: string;
  numberOfdays: number;
  from_latitude: string;
  isUserMessage: boolean;
  from_longitude: string;
  number_of_days: string;
  arrival_airport: string;
  destinationList: DestinationList[];
  number_of_rooms: string;
  number_of_stops: string;
  hotel_refundable: string;
  number_of_adults: string;
  budget_per_person: string;
  departure_airport: string;
  flight_refundable: string;
  itinerary_version: string;
  flight_cabin_class: string;
  hotel_star_ratings: string;
  number_of_children: string;
  city_wise_itinerary: any[];
  number_of_travelers: string;
  number_of_senior_citizens: string;
  singleSelectedDestination: boolean;
  complete_itinerary_description: string;
  complete_itinerary_hotels_cost: string;
  complete_itinerary_flights_cost: string;
}

export interface DestinationList {
  id: string;
  cityName: string;
  imageURL: string;
  checkFlag: boolean;
  city_name: Name;
  city_type: string;
  locations: any[];
  city_state: string;
  checkedFlag: boolean;
  description: string;
  tripDetails: CityItinerary[];
  city_country: string;
  city_latitude: string;
  city_itinerary: any[];
  city_longitude: string;
  city_country_code: string;
  city_itinerary_description: string;
  complete_itinerary_hotels_cost: CompleteItinerarySCost;
  complete_itinerary_flights_cost: CompleteItinerarySCost;
}

export interface CompleteItinerarySCost {
  id: string;
  value: string;
  checkedFlag: boolean;
}

export interface ItineraryImageURLResponse {
  statusCode: number;
  message: string;
  data: ImageURL;
}

export interface ImageURL {
  image: string;
}
export interface DeleteItineraryResponse {
  statusCode: number;
  message: string;
  data: ItineraryResponseData;
}

export interface ItineraryResponseData {
  status: boolean;
}
