import { TravelJourney } from "../../types";

export const handleFlightTime = (FlightData: TravelJourney[]) => {
  if (!Array.isArray(FlightData) || FlightData.length === 0) {
    return null;
  }
  // Extract and collect unique flight times
  const flightTimes = Array.from(
    new Set(
      FlightData.map((item: { flightTime: string }) => item.flightTime).filter(
        Boolean
      )
    )
  );

  if (flightTimes.length === 0) {
    return null;
  }

  // Return the flight times as a comma-separated string
  return flightTimes.join(", ");
};
