import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { iCurrentTripContext } from "./currentTripContextInterface";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getItinerariesListAPI } from "../../api/getSaveItinerariesList";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import { StateContext } from "../globalContext/context";
import { iGlobalContext } from "../globalContext/interface";
import { GetItinerariesListResponse } from "../../interface";
const CurrentTripContext = createContext({} as iCurrentTripContext);
export const CurrentTripContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const globalContext = useContext(StateContext);
  const { userInfo }: iGlobalContext = globalContext;
  const [currentTripData, setCurrentTripData] = useLocalStorage(
    "_trip_trove_current_trip_data",
    {}
  );
  const { handleCustomError } = useCustomErrorHandler();
  async function getCurrentTrip(userId: string) {
    setCurrentTripData({});
    const response:GetItinerariesListResponse = await getItinerariesListAPI(userId, "current", 0, 10);
    if (response?.statusCode !== 200) {
      handleCustomError(response?.statusCode, "itinerary.list");
      return null;
    }
    if (response?.data?.length > 0) {
      setCurrentTripData(response?.data[0]);
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!userInfo?.id) {
      setCurrentTripData({});
    }
  }, [userInfo]);

  const value = { currentTripData, setCurrentTripData, getCurrentTrip };
  return (
    <CurrentTripContext.Provider value={value}>
      {children}
    </CurrentTripContext.Provider>
  );
};
export const useCurrentTripContext = () => useContext(CurrentTripContext);
