import React from "react";
import DialogPopup from "../../../../component/reusableComponent/dialogPopup/dialogPopup";
import styles from "./cancelHotelsModal.module.scss";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
}

function CancelHotelsModal(props: iProps) {
  const { isOpen = false, onClose } = props;
  return (
    <DialogPopup isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.cancellationPolicyContainer}>
          <p className={styles.header}>CANCELLATION POLICY</p>
          <p className={styles.policyContent}>
            Cancel anytime before January 1, 2025 for a full refund. After the
            deadline, a cancellation charge equal to one night will be charged.
          </p>
          <p className={styles.refundContainer}>
            Refund amount<span>$657.80</span>
          </p>
        </div>
        <span className={styles.title}>Cancel this reservation?</span>
        <div className={styles.btnCard}>
          <button className={styles.cancelBtn} onClick={onClose}>
            No
          </button>
          <button className={styles.discBtn} onClick={onClose}>
            YES
          </button>
        </div>
      </div>
    </DialogPopup>
  );
}

export default CancelHotelsModal;
