import { useContext } from "react";
import styles from "./flight.module.scss";
import { StateContext } from "../../../../../context/globalContext/context";
import { iGlobalContext } from "../../../../../context/globalContext/interface";
import AirPortPicker from "../../airPortPicker/airPortPicker";
import { planeDepartureRegular } from "../../../../../constant";
import DateInputToUTC from "../../../../../hooks/utcDateFormate";
import {
  iHotelInputPayload,
  LocationTypes,
  userStayCount,
} from "../../../../../types";

interface iProps {
  departureDate: string;
  arrival: string;
  departure: string;
  fromCity: string;
  destinationCity: string;
  returnDate: string;
  showAccomodation: boolean;
  indexNumber: number;
  arrivalCityLatitude: string;
  arrivalCityLongitude: string;
  departureCityLatitude: string;
  departureCityLongitude: string;
  departureAirportFullName: string;
  arrivalAirportFullName: string;
  hotelStayCount: number;
  newArrivalCitySearch: boolean;
  newDepartureCitySearch: boolean;
  arrivalCityDetails: string;
  departureCityDetails: string;
  arrivalSelectedLocation: LocationTypes;
  departureSelectedLocation: LocationTypes;
  cities: iHotelInputPayload[];
}

function FlightHotelDetails({
  departureDate,
  arrival,
  departure,
  fromCity,
  destinationCity,
  showAccomodation,
  indexNumber,
  returnDate,
  arrivalCityLatitude,
  arrivalCityLongitude,
  departureCityLatitude,
  departureCityLongitude,
  arrivalAirportFullName,
  departureAirportFullName,
  hotelStayCount,
  newArrivalCitySearch,
  newDepartureCitySearch,
  arrivalCityDetails,
  departureCityDetails,
  arrivalSelectedLocation,
  departureSelectedLocation,
  cities,
}: iProps): JSX.Element {
  const state = useContext(StateContext);
  const { bookingDetails, setBookingDetails }: iGlobalContext = state;

  const handleUserStayCountInEachCity = (data: iHotelInputPayload[]) => {
    if (!data || (Array.isArray(data) && data.length === 0)) {
      return;
    }

    return data.map((entry) => {
      const checkInDate = new Date(entry.checkIn);
      const checkOutDate = new Date(entry.checkOut);
      const numberOfStay =
        (checkOutDate.getTime() - checkInDate.getTime()) /
          (1000 * 60 * 60 * 24) +
        1;
      return {
        cityName: entry.city,
        numberOfStay: numberOfStay,
      };
    });
  };

  const stayData = handleUserStayCountInEachCity(cities);
  const cityName = destinationCity?.split(",")[0]?.trim() || "";

  function getNumberOfStay(data: any[] | undefined, destinationCity: string) {
    const city = data?.find((item) => item.cityName === destinationCity);
    return city ? city.numberOfStay : null;
  }

  const numberOfStay = getNumberOfStay(stayData, cityName);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.flightHotelCard}>
          <div className={styles.flightCard}>
            <p className={styles.departure}>Departure</p>
            <section className={styles.departCard}>
              <img
                src={planeDepartureRegular}
                alt="flight"
                className={styles.flightImg}
              />
              {departureDate && (
                <p className={styles.departDate}>
                  {DateInputToUTC(departureDate)}
                </p>
              )}
            </section>
          </div>
          {showAccomodation && (
            <div className={styles.hotelCard}>
              <p className={styles.accomodation}>Accomodations</p>
              {numberOfStay > 0 ? (
                <p className={styles.nights}>
                  {numberOfStay === 1 ? numberOfStay : numberOfStay - 1} Nights
                </p>
              ) : (
                <p className={styles.nights}>None</p>
              )}
            </div>
          )}
        </div>

        <p className={styles.dateInfo}>
          Dates are based on Travel Date above, and your itinerary
        </p>

        <div className={styles.card2}>
          <div key={indexNumber}>
            <AirPortPicker
              key={indexNumber}
              arrival={arrival}
              departure={departure}
              fromCity={fromCity}
              destinationCity={destinationCity}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              className={styles.travelTitle}
              hideTitle={false}
              indexNumber={indexNumber}
              arrivalCityLatitude={arrivalCityLatitude}
              arrivalCityLongitude={arrivalCityLongitude}
              departureCityLatitude={departureCityLatitude}
              departureCityLongitude={departureCityLongitude}
              arrivalAirportFullName={arrivalAirportFullName}
              departureAirportFullName={departureAirportFullName}
              newDepartureCitySearch={newDepartureCitySearch}
              newArrivalCitySearch={newArrivalCitySearch}
              arrivalCityDetails={arrivalCityDetails}
              departureCityDetails={departureCityDetails}
              arrivalSelectedLocation={arrivalSelectedLocation}
              departureSelectedLocation={departureSelectedLocation}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightHotelDetails;
