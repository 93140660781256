import { useCallback, useContext, useState } from "react";
import { checkHotelRoomAvailable } from "../../../../../api/hotelDetailsAPI";
import { toastMessage } from "../../../../../helpers/toast/toastMessage";
import { iGlobalContext } from "../../../../../context/globalContext/interface";
import { StateContext } from "../../../../../context/globalContext/context";
import { ScreenTypes } from "../../../../../enums";
import { ROUTES } from "../../../../../constant";
import { useNavigate } from "react-router-dom";
import { TOAST_MESSAGES } from "../../../../../constant/messages/toastMessages";

export interface iReturnProps {
  isChecking: boolean;
  setIsChecking: React.Dispatch<React.SetStateAction<boolean>>;
  checkHotelAvailability: (
    hotelUniqueId: string,
    sessionId: string,
    type: string,
    price: number,
    actualPrice: number,
    feesIncluded: boolean,
    taxIncluded: boolean
  ) => Promise<void>;
}

interface iProps {
  onClose: () => void;
  checkingAvailabilityController: any;
}

export const useSelectRoomHook = (props: iProps): iReturnProps => {
  const { onClose, checkingAvailabilityController } = props;
  const [isChecking, setIsChecking] = useState(false);
  const navigate = useNavigate();

  const state = useContext(StateContext);
  const {
    getHotelRoomsBody,
    setSelectedHotelRoom,
    selectedHotelRoomArr,
    setSelectedHotelRoomArr,
    // selectedHotelRoom,
    //   hotelData = [],
    //   setHotelData,
    selectedFlightOptions,
    setScreen,
    isMobile,
    selectedChatData,
  }: iGlobalContext = state;

  const { checkIn, checkOut, city } = getHotelRoomsBody || {};
  // toast.dismiss();

  const isThisAMulticityTrip =
    selectedChatData?.destinationList &&
    selectedChatData?.destinationList?.length > 1;

  const checkHotelAvailability = useCallback(
    async (
      hotelUniqueId: string,
      sessionId: string,
      type: string,
      price: number,
      actualPrice: number,
      feesIncluded: boolean,
      taxIncluded: boolean
    ) => {
      checkingAvailabilityController.current = new AbortController();
      const response = await checkHotelRoomAvailable(
        { hotels: [{ uniqueId: hotelUniqueId, sessionId }] },
        checkingAvailabilityController.current,
        setIsChecking
      );

      // // console.log(response, "response2");
      if (
        Array.isArray(response) &&
        response[0]?.uniqueId &&
        response[0]?.prebookId &&
        response[0]?.sessionId
      ) {
        // toastMessage.success(
        //   "Selected hotel room is available, Please proceed with your booking"
        // );
        // setTimeout(toast.dismiss, 2000);
        let roomData = {
          selectedHotelId: getHotelRoomsBody.hotelId,
          selectedRoomId: hotelUniqueId,
          checkIn,
          checkOut,
          preBookId: response[0]?.prebookId,
          sessionId: response[0]?.sessionId,
          responseUniqueId: response[0]?.uniqueId,
          type,
          price,
          actualPrice,
          feesIncluded,
          taxIncluded,
          isBooked: false,
        };
        setSelectedHotelRoom(roomData);
        setSelectedHotelRoomArr(
          selectedHotelRoomArr.map((eachItem) => {
            if (
              eachItem?.checkIn === checkIn &&
              eachItem?.checkOut === checkOut &&
              eachItem?.city === city
            ) {
              return { ...eachItem, selectedHotelRoom: roomData };
            }
            return eachItem;
          })
        );

        if (
          selectedFlightOptions.hotels &&
          !selectedFlightOptions.flights &&
          !isThisAMulticityTrip
        ) {
          if (!isMobile) {
            setScreen(ScreenTypes.TravellerInfo);
          }
          if (isMobile) navigate(ROUTES.TravelerInformation);
          onClose();
          return;
        }
        onClose();
      }
      if (!response) {
        toastMessage.error(
          TOAST_MESSAGES.BOOKING.Search.HotelAvailabilityFailure.messageTitle,
          TOAST_MESSAGES.BOOKING.Search.HotelAvailabilityFailure.message
        );
      }
    },
    [
      checkingAvailabilityController,
      checkIn,
      checkOut,
      city,
      getHotelRoomsBody?.hotelId,
      isMobile,
      isThisAMulticityTrip,
      navigate,
      onClose,
      selectedFlightOptions.flights,
      selectedFlightOptions.hotels,
      selectedHotelRoomArr,
      setScreen,
      setSelectedHotelRoom,
      setSelectedHotelRoomArr,
    ]
  );

  return {
    checkHotelAvailability,
    isChecking,
    setIsChecking,
  };
};
