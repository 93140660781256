import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function saveItineraryDetailsAPI(
  payload: any,
  method: "POST" | "PUT",
  itineraryId?: string
  // tripName: string,
  // id: string,
  // userId: string,
  // metaData: any,
  // status: string | null,
  // numberOfDaysSelected: number,
  // year: any,
  // selectedMonth: any,
  // is_admin_created: boolean,
  // data?: any
) {
  const URL = `${ENDPOINT}/app/v1/users/itineraries`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    ...payload,
    // tripName,
    // id,
    // userId,
    // metaData,
    // status,
    // numberOfDaysSelected,
    // year,
    // selectedMonth,
    // is_admin_created,
    // data,
  };
  if (itineraryId)
    body = {
      ...body,
      id: itineraryId,
    };
  try {
    if (!payload?.userId) return;
    let response = null;
    if (method === "POST") response = await axios.post(URL, body, config);
    else response = await axios.put(URL, body, config);
    // console.log(response);
    return response?.data;
  } catch (error) {
    // console.log(error);
  }
}
