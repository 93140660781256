import React, { ReactNode } from "react";
import CustomPopover from "../../../reusableComponent/customPopver/CustomPopover";
import "react-calendar/dist/Calendar.css";
import { starSharpSolid } from "../../../../constant";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../constant/google-analytics/GAEvents";
import styles from "./propertyPopover.module.scss";

export default function PropertyPopoverPopover({
  popoverTrigger,
  isPopoverOpen,
  setIsPopoverOpen,
  handleApplyButton,
  selectedValues,
  setSelectedValues,
}: {
  readonly popoverTrigger: ReactNode;
  readonly isPopoverOpen: boolean;
  readonly selectedValues: number[];
  readonly setSelectedValues: React.Dispatch<React.SetStateAction<number[]>>;
  readonly setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly handleApplyButton: () => void;
}) {
  const inputData = [1, 2, 3, 4, 5];

  const handleInputValueSelection = (selectedValue: number) => {
    triggerGAEvent(GA_LABEL_ENUMS.booking_property_type_star_click);

    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(selectedValue)) {
        return prevSelectedValues.filter((value) => value !== selectedValue);
      } else {
        return [...prevSelectedValues, selectedValue];
      }
    });
  };

  return (
    <CustomPopover
      popoverTrigger={popoverTrigger}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={
        <div className={styles.mainContainer}>
          <div className={styles.content}>
            {inputData.map((i) => (
              <div
                className={`${selectedValues.includes(i) ? styles.selectedCard : styles.starCards}`}
                onClick={() => handleInputValueSelection(i)}>
                <p className={styles.num} key={i}>
                  {i}
                </p>
                <img
                  src={starSharpSolid}
                  alt="icon"
                  className={styles.starIcon}
                />
              </div>
            ))}
          </div>
        </div>
      }
      handleApplyButton={handleApplyButton}
    />
  );
}
