import React from "react";
import styles from "./paymentDetails.module.scss";
import CustomAccordion from "../../../../reusableComponent/customAccordion/CustomAccordion";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../../../../constant/google-analytics/GAEvents";
import { creditCardIcon } from "../../../../../constant";
import { getDayOfWeek, getMonthDate } from "../../../../../utils/dateUtils";
import { Grid } from "@mui/material";

export default function PaymentDetails({
  hotelPayment,
  cardDetails,
}: Readonly<{
  hotelPayment: any;
  cardDetails: any;
}>) {
  return (
    <CustomAccordion
      className={styles.customFlightStyles}
      summaryClass={styles.summaryFlightClass}
      accordianDetailsClass={styles.accordFlightDetailsClass}
      defaultExpanded={true}
      headingComponent={
        <button
          type="button"
          className={styles.headingFlightComp}
          onClick={() =>
            triggerGAEvent(
              GA_LABEL_ENUMS.trip_details_flight_traveler_accordion_click
            )
          }
        >
          <div className={styles.accordTitleCard}>
            <p className={styles.accordDesc}>Payments</p>
          </div>
          <img
            src={creditCardIcon}
            alt="plane"
            className={styles.planeDepartureIcon}
          />
        </button>
      }
      bodyComponent={hotelPayment?.map((ele: any, index: number) => (
        <div
          key={index + ele?.checkInDate}
          className={styles.paymentDetailsContainer}
          style={{
            borderBottom:
              index !== hotelPayment?.length - 1 ? "1px solid #dddcdc" : "none",
          }}
        >
          <div className={styles.paymentHeaderContainer}>
            <p>
              {getDayOfWeek(ele?.checkInDate) +
                " " +
                getMonthDate(ele?.checkInDate)}{" "}
              -{" "}
              {getDayOfWeek(ele?.checkOutDate) +
                " " +
                getMonthDate(ele?.checkOutDate)}{" "}
              <span>|</span> {ele?.city}
            </p>
            <h1>{ele?.hotelName}</h1>
          </div>
          <Grid container className={styles.gridDetailsContainer}>
            {/* <Grid xs={12} className={styles.grid}>
              <h1>Room price</h1>
            </Grid> */}
            {ele?.roomCount !== null && ele?.base !== null && (
              <>
                <Grid xs={3.5} className={styles.grid}>
                  <p>
                    {" "}
                    {ele?.roomCount} &nbsp;
                    {ele?.roomCount > 1 ? "Rooms" : "Room"}
                  </p>
                </Grid>
                <Grid xs={8.5} className={styles.grid}>
                  <h2>${ele?.base?.toFixed(2)}</h2>
                </Grid>
              </>
            )}

            {ele?.taxes !== null && (
              <>
                <Grid xs={3.5} className={styles.grid}>
                  <p>Taxes + Fees</p>
                </Grid>
                <Grid xs={8.5} className={styles.grid}>
                  <h2>
                    $
                    {typeof ele?.taxes === "number"
                      ? ele?.taxes?.toFixed(2)
                      : ele?.taxes}
                  </h2>
                </Grid>
              </>
            )}

            {ele?.total !== null && (
              <>
                <Grid xs={3.5} className={styles.grid}>
                  <p>Total</p>
                </Grid>
                <Grid xs={8.5} className={styles.grid}>
                  <h2>${ele?.total?.toFixed(2)}</h2>
                  {cardDetails && (
                    // <p>{`${"(" + "Paid - Mastercard*5478" + ")"}`}</p>
                    <p
                      className={styles.masterCard}
                    >{`${"("}Paid - ${cardDetails?.cardType}*${cardDetails?.cardNumber}${")"}`}</p>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </div>
      ))}
      zeroMarginOnExpand
      borderBottom
    />
  );
}
