import { useCallback, useMemo, useState } from "react";
import { calenderRangeLight } from "../../../../constant";
import DatePickerPopover from "../../bookingPopover/datePickerPopover/DatePickerPopover";
import styles from "./input.module.scss";

interface DatePickerProps {
  className?: string;
  handleUpdateData: () => void;
  label: string;
  dateInput: string | null;
  setDateInput: React.Dispatch<React.SetStateAction<string | null>>;
}

const NewDatePicker: React.FC<DatePickerProps> = ({
  className = "",
  label,
  dateInput = null,
  setDateInput,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const handleApplyButton = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  const displayDate = useMemo(() => {
    return dateInput
      ? new Date(dateInput)?.toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "2-digit",
        })
      : "Select a date";
  }, [dateInput]);

  return (
    <DatePickerPopover
      value={dateInput ? new Date(dateInput) : null}
      setValue={(date: Date) => setDateInput(date.toISOString())}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      handleApplyButton={handleApplyButton}
      popoverTrigger={
        <div className={`${className} ${styles.main}`}>
          <p className={styles.label}>{label}</p>
          <div className={styles[isPopoverOpen ? "activeTravelBtn" : "btn"]}>
            <img
              src={calenderRangeLight}
              alt="icon"
              className={styles.calendarIcon}
            />
            <p className={styles.btnName}>{displayDate}</p>
          </div>
        </div>
      }
    />
  );
};

export default NewDatePicker;
