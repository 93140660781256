import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import BackButtonHandler from "../../component/BackButtonHandler";
import { APP_URL } from "../../constant/environment";
import { iGlobalContext } from "../../context/globalContext/interface";
import ReactGA from "react-ga4";
import { closeWhiteIcon, ROUTES, shareIcon } from "../../constant";
import { iChatData, iCityDetailsResult } from "../../types";
import styles from "./savedItineraryDetail.module.scss";
import { getItineraryDetailsAPI } from "../../api/getItineraryDetails";
import Loader from "../../component/reusableComponent/loader/Loader";
import CityTabs from "../itineraryDetails/cityTabs/CityTabs";
import { Button } from "@mui/material";
import { RWebShare } from "react-web-share";
import { APP_CONFIG } from "../../constant/common";
import { getConfigMessage } from "../../utils";
import { toastMessage } from "../../helpers/toast/toastMessage";
import useCustomErrorHandler from "../../hooks/useCustomErrorHandler";
import {
  GA_LABEL_ENUMS,
  triggerGAEvent,
} from "../../constant/google-analytics/GAEvents";
import { ItineraryDetailsResponse } from "../../interface";

export default function SavedItineraryDetail(): JSX.Element {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    sharedItineraryId,

    setSelectedChatData,
    selectedChatData,
    setIsItinerarySavedFlag,
    setScreen,
    appConfig,
  }: iGlobalContext = state;
  // Axios controller to cancel API call
  const controller = new AbortController();
  const controllerAbortUpdateTrip = new AbortController();
  const [isLoading, setIsLoading] = useState(true);
  const { handleCustomError } = useCustomErrorHandler();
  const [noOfCitySelected, setNoOfCitySelected] = useState(1);
  // console.log(noOfCitySelected);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setIsItinerarySavedFlag(true);
    console.count("RENDER SAVED DETAILS");
    async function getSavedItineraryDetails() {
      setIsLoading(true);
      setSelectedChatData({} as iChatData);
      const response: ItineraryDetailsResponse = await getItineraryDetailsAPI(
        sharedItineraryId.split("/")[0]
      );
      if (response?.statusCode !== 200) {
        handleCustomError(response?.statusCode, "itinerary.details");
        return null;
      }
      if (!response) {
        toastMessage.error(
          "OOPS!",
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
        return;
      }
      const parseMetaData =
        typeof response?.data.metaData === "string"
          ? JSON.parse(response?.data.metaData)[0]
          : response?.data.metaData;
      // console.log(parseMetaData);
      if (!parseMetaData) {
        toastMessage.error("OOPS!", APP_URL);
        return;
      }

      setSelectedChatData(parseMetaData.selectedChatData);
      setIsLoading(false);
      // console.log("DETAILS", parsemetaData.selectedChatData);
    }

    getSavedItineraryDetails();

    return () => {
      controllerAbortUpdateTrip.abort();
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedItineraryId]);


  function handleBackButton() {
    navigate(ROUTES.Home);
    setScreen("");
  }

  useEffect(() => {
    setNoOfCitySelected(
      selectedChatData?.destinationList?.reduce(
        (acc: number, destListEle: iCityDetailsResult) => {
          if (destListEle.checkedFlag) return acc + 1;
          else return acc;
        },
        0
      )
    );
  }, [selectedChatData]);

  function handleOnShare() {
    triggerGAEvent(GA_LABEL_ENUMS.itinerary_details_share_click);
    ReactGA.event({
      category: "Button Click",
      action: `Click on Share Trip Details`,
      label: `User is sharing the itinerary ${APP_URL}/shared/${sharedItineraryId}`,
    });
  }

  const [tempSelectedChatData, setTempSelectedChatData] = useState(
    [] as iCityDetailsResult[]
  );
  useEffect(() => {
    if (selectedChatData)
      setTempSelectedChatData(
        selectedChatData?.destinationList?.filter(
          (ele: iCityDetailsResult) => ele.checkedFlag
        )
      );
  }, [selectedChatData]);

  const [showEditActivityFlag, setShowEditActivityFlag] = React.useState(false);

  if (isLoading || JSON.stringify(selectedChatData) === "{}")
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.mainContainer}>
      <BackButtonHandler onBack={handleBackButton} />
      {/* Itinerary Details */}
      <div className={styles.stickyHeading}>
        <div className={styles.closeBtnContainer}>
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}
          >
            <img
              className={styles.iconButton}
              src={closeWhiteIcon}
              alt="Close"
            />
            <p>CLOSE</p>
          </Button>
        </div>
        <div className={styles.buttonContainer}>
          <RWebShare
            data={{
              url: `${APP_URL}shared/${selectedChatData?.id}`,
              title: "Zenvoya",
            }}
            onClick={handleOnShare}
          >
            <Button className={`${styles.rowAlignButton}`}>
              <img className={styles.iconButton} src={shareIcon} alt="share" />
              SHARE
            </Button>
          </RWebShare>
        </div>
      </div>

      {/* Tab container */}
      <CityTabs
        isThereFlightBooked={true}
        itineraryDescription={selectedChatData?.complete_itinerary_description}
        destinationList={tempSelectedChatData}
        isSavedActivityFlag={true}
        showEditActivityFlag={showEditActivityFlag}
        setShowEditActivityFlag={setShowEditActivityFlag}
      />
    </div>
  );
}
