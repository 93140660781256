export const handleTripType = (
  trip_type: string | null | undefined
): number => {
  switch (trip_type) {
    case "oneway":
      return 1;
    case "roundtrip":
      return 2;
    case "multicity":
      return 3;
    default:
      return 2;
  }
};
