import React from "react";
import {
  outlinedCircleCloseIcon,
  thumbsDownIcon,
  thumbsUpIcon,
} from "../../../../constant";
import { Rating, Stack } from "@mui/material";
import DialogPopup from "../../../reusableComponent/dialogPopup/dialogPopup";
import styles from "./reviewsModal.module.scss";
import { REVIEWS_CONSTANTS } from "./reviewsConstants";
import { formatDateMMddyyyy } from "../../../../utils/dateUtils";
import StarIcon from "@mui/icons-material/Star";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

interface iProps {
  isOpen: boolean;
  onClose: () => void;
}

function ReviewsModal(props: iProps): JSX.Element {
  const { isOpen = false, onClose } = props;
  return (
    <DialogPopup isOpen={isOpen}>
      <Stack
        sx={{
          // minHeight: "100vh",
          mt: { sm: 0, xs: 2 },
          // justifyContent: { sm: "center", md: "center", lg: "center" },
        }}
        spacing={6}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{
            width: { xs: "100vw" },
            maxWidth: { md: "450px" },
          }}
        >
          <Stack
            sx={{ width: "100%" }}
            px={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}
            >
            <Stack
              alignSelf="end"
              mb={"17.14px"}
              mr={"24px"}
              sx={{
                opacity: "0.8",
              }}
              onClick={onClose}
            >
              <img
                style={{ width: "18.69px", cursor: "pointer" }}
                src={outlinedCircleCloseIcon}
                alt="cross"
              />
            </Stack>

            <Stack
              sx={{
                width: "100%",
                // marginBottom: "-20px",
                maxHeight: "80vh",
                overflow: "auto",
                bgcolor: "#f8f8f8",
                borderRadius: "8px",
              }}
            >
              <Stack pt={4} pb={3.5} pl={0} pr={0} spacing={3}>
                <div className={styles.reviewsContainer}>
                  <div className={styles.header}>Property Reviews</div>
                  {REVIEWS_CONSTANTS.map((review, idx) => {
                    const {
                      createdAt,
                      description,
                      reviewHeading,
                      reviewStarRatings,
                      complaint,
                      userName,
                    } = review || {};
                    return (
                      <div className={styles.reviewRow} key={idx}>
                        <div className={styles.ratings}>
                          <Rating
                            name="text-feedback"
                            value={reviewStarRatings / 2}
                            readOnly
                            style={{
                              gap: "1.93px",
                            }}
                            icon={
                              <StarRoundedIcon
                                style={{
                                  color: "#FCB400",
                                  fontSize: "14.1px",
                                }}
                              />
                            }
                            precision={0.5}
                            emptyIcon={
                              <StarRoundedIcon
                                style={{
                                  color: "#DDDDDD",
                                  fontSize: "14.1px",
                                }}
                              />
                            }
                          />
                          <span>{reviewStarRatings}/10</span>
                        </div>
                        <div className={styles.reviewHeading}>
                          {reviewHeading}
                        </div>

                        {description && (
                          <div className={styles.description}>
                            <img
                              src={thumbsUpIcon}
                              className={styles.descImg}
                              alt="like comment"
                            />
                            <p>{description}</p>
                          </div>
                        )}
                        {complaint && (
                          <div className={styles.description}>
                            <img
                              src={thumbsDownIcon}
                              className={styles.descImg}
                              alt="like comment"
                            />
                            <p>{complaint}</p>
                          </div>
                        )}
                        <div className={styles.ownerAndTimeContainer}>
                          {userName && (
                            <>
                              <div className={styles.ownerText}>
                                <p>|</p> &nbsp;{userName}
                              </div>
                              <p>|</p>
                            </>
                          )}
                          <div className={styles.dateText}>
                            {formatDateMMddyyyy(createdAt)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogPopup>
  );
}

export default ReviewsModal;
