import { useContext, useEffect } from "react";
import { ROUTES, avatarIcon } from "../../constant";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { iGlobalContext } from "../../context/globalContext/interface";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import { iAppConfig } from "../../types";
import Loader from "../../component/reusableComponent/loader/Loader";
import InitialChat from "../../component/desktopLandingPage/initialChat/initialChat";
import { APP_CONFIG, CHAT_BOT_NAME } from "../../constant/common";
import styles from "./new.module.scss";
import { iRecommendedTripContext } from "../../context/recommendedTripContext/recommendedTripContextInterface";
import { useRecommendedTripContext } from "../../context/recommendedTripContext/recommendedTripContext";
import BackButtonHandler from "../../component/BackButtonHandler";

function NewTrip(): JSX.Element {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    appConfig,
    isUserJustBookedTrip,
    loginCreateAccountState,
    userInfo,
  }: iGlobalContext = context;

  const {
    disableInput,
    handleCreateItinerary,
    scrollContainerRef,
    handleCancel,
    setDisableInput,
  }: iRecommendedTripContext = useRecommendedTripContext();

  // useEffect(() => {
  //   console.log({ loginCreateAccountState });
  // }, [loginCreateAccountState]);

  // Disable scroll on focus of input field
  // useEffect(() => {
  //   const handleTouchMove = (e: any) => {
  //     e.preventDefault();
  //   };
  //   function handleResize(event: any) {
  //     const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
  //     if (
  //       (event?.target?.height * event?.target?.scale) / window.screen.height <
  //       VIEWPORT_VS_CLIENT_HEIGHT_RATIO
  //     ) {
  //       //  toastMessage.success("OPEN");
  //       document.body.addEventListener("touchmove", handleTouchMove, {
  //         passive: false,
  //       });
  //       document.documentElement.addEventListener(
  //         "touchmove",
  //         handleTouchMove,
  //         {
  //           passive: false,
  //         }
  //       );
  //     } else {
  //       //  toastMessage.success("CLOSE");
  //       document.body.removeEventListener("touchmove", handleTouchMove);
  //       document.documentElement.removeEventListener(
  //         "touchmove",
  //         handleTouchMove
  //       );
  //     }
  //   }
  //   if ("visualViewport" in window && !loginCreateAccountState?.flag) {
  //     window?.visualViewport?.addEventListener("resize", handleResize);
  //   }
  //   return () => {
  //     window?.visualViewport?.removeEventListener("resize", handleResize);
  //   };
  // }, [loginCreateAccountState]);

  const navigate = useNavigate();
  function handleBack() {
    if (isUserJustBookedTrip) navigate(ROUTES.NewTrip);
  }

  return (
    <div className={styles.superContainer}>
      <BackButtonHandler onBack={handleBack} />
      <div className={styles.desktopScrollContainer} ref={scrollContainerRef}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            // src={exploreCityLogo}
            src={avatarIcon}
            alt="assistanceIcon"
          />
          <p>
            Hi
            {userInfo?.loggedIn ? `, ${userInfo?.name?.split(" ")[0]}!` : "!"}
          </p>

          <div
            dangerouslySetInnerHTML={{
              __html:
                appConfig?.length > 0
                  ? appConfig?.filter(
                      (ele: iAppConfig) =>
                        ele.key === APP_CONFIG.ASSISTANCE_INFO_TEXT
                    )[0]?.value
                  : "",
            }}
          />
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.systemChatIcon}>
            <img
              className={styles.chatIcon}
              alt="chat logo"
              // src={zenvoyaChatIcon}
              src={avatarIcon}
            />
            <h3 className={styles.heading}>{CHAT_BOT_NAME.name}</h3>
          </div>
          <div
            className={styles.constText}
            dangerouslySetInnerHTML={{
              __html:
                appConfig?.length > 0
                  ? appConfig?.filter(
                      (ele: iAppConfig) =>
                        ele.key === APP_CONFIG.HOME_CONSTANT_2
                    )[0]?.value
                  : "",
            }}
          />
        </div>
        {disableInput && (
          <div className={styles.chatLoaderContainer}>
            <div className={styles.chatLoaderSubContainer}>
              <InitialChat message={chatInputText} />
            </div>
          </div>
        )}
        {disableInput && <Loader />}
      </div>

      <div className={styles.inputContainer}>
        <InputWithMic
          onStopRecording={handleCreateItinerary}
          onSend={handleCreateItinerary}
          value={chatInputText}
          setValue={setChatInputText}
          disable={disableInput}
          handleCancel={handleCancel}
          setDisableInput={setDisableInput}
          disableSendBtn={chatInputText === "" ? true : false}
        />
      </div>
    </div>
  );
}

export default NewTrip;
