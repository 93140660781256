import CustomDialog from "../../reusableComponent/customDialog/CustomDialog";
import styles from "./bookedSuccessDialog.module.scss";
import { bookedSuccesIcon } from "../../../constant";
import { Button } from "@mui/material";
import { TOAST_MESSAGES } from "../../../constant/messages/toastMessages";

export default function BookedSuccessDialog({
  open,
  onClose,
  bookedId = "SDF-154-S85",
  handleViewItinerary = () => {},
}: {
  open: boolean;
  onClose: any;
  bookedId?: string;
  handleViewItinerary?: any;
}) {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <div className={styles.mainContainer}>
        <div className={styles.booking}>
          <img src={bookedSuccesIcon} alt="booked" />
          <h1>{TOAST_MESSAGES.BOOKING.BOOK.bookingSuccess.messageTitle}</h1>
        </div>
        <p className={styles.informationText}>
          {TOAST_MESSAGES.BOOKING.BOOK.bookingSuccess.message}
        </p>
        <h1 className={styles.confirmationNumber}>{bookedId}</h1>
        <Button className={styles.button} onClick={handleViewItinerary}>
          View Itinerary
        </Button>
      </div>
    </CustomDialog>
  );
}
